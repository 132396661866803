.font-bold {
  font-weight: bold;
}

.font-italic {
  font-style: italic;
}

.full-width {
  width: 100%;
}

main,
header,
section,
nav {
  display: block;
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
  /* make transparent link selection, adjust last value opacity 0 to 1.0 */
}

body,
table,
textarea,
input,
.row-count-helper {
  font-family: "SegoeUI", "Segoe UI", Arial, sans-serif;
  letter-spacing: normal;
}

a,
a:visited,
a:active,
a:focus {
  outline: 0 none;
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  position: relative;
}

body {
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
body.iosXR {
  top: constant(safe-area-inset-top);
  top: env(safe-area-inset-top);
  bottom: calc(constant(safe-area-inset-bottom) / 2);
  bottom: calc(env(safe-area-inset-bottom) / 2);
}
@media (orientation: landscape) {
  body.iosXR.full-screen {
    top: 0;
    bottom: 0;
  }
  body.iosXR .slideshow .slide .text-content {
    left: constant(safe-area-inset-left);
    left: env(safe-area-inset-left);
  }
}
body.iosXR.full-screen {
  top: 0;
  bottom: 0;
}
body.desktop:not(textarea),
body.desktop *:not(textarea), body.mobile-testing-on-web:not(textarea),
body.mobile-testing-on-web *:not(textarea) {
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.15) rgba(0, 0, 0, 0.05);
}
body.desktop:not(textarea)::-webkit-scrollbar,
body.desktop *:not(textarea)::-webkit-scrollbar, body.mobile-testing-on-web:not(textarea)::-webkit-scrollbar,
body.mobile-testing-on-web *:not(textarea)::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  background-color: rgba(0, 0, 0, 0.05);
}
body.desktop:not(textarea)::-webkit-scrollbar-track,
body.desktop *:not(textarea)::-webkit-scrollbar-track, body.mobile-testing-on-web:not(textarea)::-webkit-scrollbar-track,
body.mobile-testing-on-web *:not(textarea)::-webkit-scrollbar-track {
  background-color: transparent;
}
body.desktop:not(textarea)::-webkit-scrollbar-thumb,
body.desktop *:not(textarea)::-webkit-scrollbar-thumb, body.mobile-testing-on-web:not(textarea)::-webkit-scrollbar-thumb,
body.mobile-testing-on-web *:not(textarea)::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}

html,
html body,
html * {
  -webkit-touch-callout: none;
  -webkit-user-callout: none;
  -webkit-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-modify: none;
  -webkit-highlight: none;
  -webkit-text-size-adjust: none;
  -moz-user-select: none;
  user-select: none;
  touch-action: manipulation;
}
html input,
html textarea,
html .enable-copy-paste,
html .enable-copy-paste * {
  -moz-user-select: text;
  -webkit-user-select: text;
  user-select: text;
}

.warning-message,
.error-message {
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3;
  border-width: 0 0 1px 0;
}
.warning-message .ei,
.error-message .ei {
  font-size: 16px;
  vertical-align: middle;
}
.warning-message.inline,
.error-message.inline {
  position: static;
  text-align: left;
  border-width: 1px;
}
.warning-message.inline.text-center,
.error-message.inline.text-center {
  text-align: center;
}

.underline {
  text-decoration: underline;
}

.pointer {
  cursor: pointer;
}

.text-center {
  text-align: center;
}

.nowrap {
  white-space: nowrap;
}

.flex-grow {
  flex-grow: 1;
}

.flex-row-container {
  display: flex;
  flex-direction: row;
}
.flex-row-container > * {
  flex-grow: 1;
  flex-shrink: 1;
}

.pre-wrap {
  white-space: pre-wrap;
}

.clear-both {
  clear: both;
}

.clearfix:before, body.layout-cozy .field-display.layout-vertical .value-wrapper:before, .field-display.layout-vertical .value-wrapper:before,
.clearfix:after,
body.layout-cozy .field-display.layout-vertical .value-wrapper:after,
.field-display.layout-vertical .value-wrapper:after {
  content: " ";
  display: table;
}

.clearfix:after, body.layout-cozy .field-display.layout-vertical .value-wrapper:after, .field-display.layout-vertical .value-wrapper:after {
  clear: both;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.no-float {
  float: none !important;
}

ul.plain {
  margin: 0;
  padding: 0;
}
ul.plain,
ul.plain li {
  list-style: none;
  list-style-type: none;
}
ul.plain li {
  display: block;
  padding: 8px;
}

@media (max-width: 600px) {
  .hide-sm {
    display: none !important;
  }
}

@media (min-width: 600px) {
  .show-sm {
    display: none !important;
  }
}

@media (max-width: 960px) {
  .hide-md {
    display: none !important;
  }
}

@media (min-width: 960px) {
  .show-md {
    display: none !important;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

section.main {
  height: 100%;
}
body.mobile section.main.path-feedback ul.list, body.mobile section.main.path-goals ul.list, body.mobile section.main.path-habits ul.list, body.mobile section.main.path-friends ul.list {
  padding-bottom: 90px;
}

#mobileRoot {
  height: 100%;
}

section.main.tasks {
  top: 40px;
}

h1 {
  margin: 0;
  padding: 15px 0;
}

hr {
  margin: -1px 0;
  position: relative;
  z-index: 2;
}
hr:first-child {
  margin-top: 0;
}

.no-break {
  white-space: nowrap;
}

.warning-message,
.error-message {
  margin: 0;
  padding: 10px;
}
.warning-message.inline,
.error-message.inline {
  border-radius: 8px;
}

.has-feature-icon {
  display: flex;
  align-items: center;
  justify-content: stretch;
}
.has-feature-icon .ei.feature {
  font-size: 64px;
  margin-right: 16px;
  flex-basis: 1;
}
.has-feature-icon span {
  flex-grow: 1;
}

.spacer.h10 {
  height: 10px;
}

.ei.tap-area-10 {
  position: relative;
}
.ei.tap-area-10:after {
  content: "";
  display: block;
  position: absolute;
  top: -10px;
  right: -10px;
  bottom: -10px;
  left: -10px;
}

@keyframes cloud-status-rotating {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
@-webkit-keyframes cloud-status-rotating {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
.spin {
  transform-origin: center center;
  animation: cloud-status-rotating 1.5s ease infinite;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.lc-stacked-icon {
  position: relative;
}
.lc-stacked-icon .lc-icon.position-top-left {
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: top left;
  transform: scale(0.5);
}
.lc-stacked-icon .lc-icon.position-top-right {
  position: absolute;
  top: 0;
  right: 0;
  transform-origin: top right;
  transform: scale(0.5);
}
.lc-stacked-icon .lc-icon.position-bottom-left {
  position: absolute;
  bottom: 0;
  left: 0;
  transform-origin: bottom left;
  transform: scale(0.5);
}
.lc-stacked-icon .lc-icon.position-bottom-right {
  position: absolute;
  bottom: 0;
  right: 0;
  transform-origin: bottom right;
  transform: scale(0.5);
}

body {
  background: #12378F;
}
body.white-background {
  background: #fff;
}
body.theme-dark.desktop:not(textarea),
body.theme-dark.desktop *:not(textarea), body.theme-dark.mobile-testing-on-web:not(textarea),
body.theme-dark.mobile-testing-on-web *:not(textarea) {
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 255, 255, 0.15) rgba(255, 255, 255, 0.05);
}
body.theme-dark.desktop:not(textarea)::-webkit-scrollbar,
body.theme-dark.desktop *:not(textarea)::-webkit-scrollbar, body.theme-dark.mobile-testing-on-web:not(textarea)::-webkit-scrollbar,
body.theme-dark.mobile-testing-on-web *:not(textarea)::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  background-color: rgba(255, 255, 255, 0.05);
}
body.theme-dark.desktop:not(textarea)::-webkit-scrollbar-track,
body.theme-dark.desktop *:not(textarea)::-webkit-scrollbar-track, body.theme-dark.mobile-testing-on-web:not(textarea)::-webkit-scrollbar-track,
body.theme-dark.mobile-testing-on-web *:not(textarea)::-webkit-scrollbar-track {
  background-color: transparent;
}
body.theme-dark.desktop:not(textarea)::-webkit-scrollbar-thumb,
body.theme-dark.desktop *:not(textarea)::-webkit-scrollbar-thumb, body.theme-dark.mobile-testing-on-web:not(textarea)::-webkit-scrollbar-thumb,
body.theme-dark.mobile-testing-on-web *:not(textarea)::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.15);
}

.warning-message,
.error-message {
  background: #fff;
  border: 1px solid #f0ad4e;
  color: #f0ad4e;
}
.warning-message .ei,
.error-message .ei {
  color: #f0ad4e;
}
.warning-message button .ei,
.error-message button .ei {
  color: inherit;
}
.warning-message.no-border,
.error-message.no-border {
  border: 0 none !important;
}

.error-message {
  border-color: #DC2770;
  color: #DC2770;
}
.error-message .ei {
  color: #DC2770;
}
.error-message button .ei {
  color: inherit;
}

fieldset {
  border: 0px solid transparent;
  border-radius: 6px;
}
fieldset.field-margins {
  margin: 10px 0 0;
  padding: 10px 10px 0;
}
fieldset.has-border {
  border-width: 1px;
}
fieldset.has-border.collapsed {
  border-radius: 0;
  border-width: 1px 0 0 0;
}

.elevation-0 {
  /* Shadow 0dp */
  box-shadow: none;
}

.elevation-1 {
  /* Shadow 1dp */
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
}

.elevation-2 {
  /* Shadow 2dp */
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.elevation-3 {
  /* Shadow 3dp */
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.12), 0 1px 8px 0 rgba(0, 0, 0, 0.2);
}

.elevation-4 {
  /* Shadow 4dp */
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
}

.elevation-6 {
  /* Shadow 6dp */
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
}

.elevation-8 {
  /* Shadow 8dp */
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
}

.elevation-9 {
  /* Shadow 9dp */
  box-shadow: 0 9px 12px 1px rgba(0, 0, 0, 0.14), 0 3px 16px 2px rgba(0, 0, 0, 0.12), 0 5px 6px -3px rgba(0, 0, 0, 0.2);
}

.elevation-12 {
  /* Shadow 12dp */
  box-shadow: 0 12px 17px 2px rgba(0, 0, 0, 0.14), 0 5px 22px 4px rgba(0, 0, 0, 0.12), 0 7px 8px -4px rgba(0, 0, 0, 0.2);
}

.elevation-16 {
  /* Shadow 16dp */
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.elevation-24 {
  /* Shadow 24dp */
  box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 11px 15px -7px rgba(0, 0, 0, 0.2);
}

.transition-container {
  opacity: 0;
  height: 100%;
  will-change: opacity;
}
.main.private-route .transition-container {
  position: absolute;
  z-index: 1;
  inset: 0;
  overflow: hidden;
}

.loader-container {
  position: absolute;
  z-index: 10000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  will-change: opacity, backdrop-filter;
  transition: all 0.15s ease-out;
  pointer-events: none;
  background: rgba(18, 55, 143, 0.3);
  backdrop-filter: blur(0);
  transform: translate3d(0, 0, 0);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.loader-container:before {
  content: "";
  display: block;
  width: 100%;
  height: calc(50% - 50px);
  max-height: 200px;
  transition: height 0.15s ease-out;
  will-change: height;
}
.loader-container.loading {
  opacity: 1;
  pointer-events: all;
  backdrop-filter: blur(20px);
}
#initial-loader .loader-container {
  background: transparent;
}
#initial-loader .loader-container.loading {
  background: #12378F;
  backdrop-filter: unset;
}
.loader-container.transparent {
  background: transparent;
  backdrop-filter: unset;
}
.loader-container.opaque {
  background: #12378F;
  backdrop-filter: blur(0);
}
.loader-container.hiding {
  background: transparent;
  backdrop-filter: unset;
  pointer-events: none;
}
.loader-container .message {
  border-radius: 20px;
  background: #fff;
  box-shadow: 0 8px 24px 8px rgba(18, 55, 143, 0.2);
  width: max-content;
  max-width: 90%;
  padding: 8px 16px;
  margin: 32px 0 8px;
  text-align: center;
}
@media (min-width: 525px) {
  .loader-container .message {
    max-width: 480px;
  }
}

@keyframes generic-spin {
  from {
    transform: rotate(-90deg);
  }
  to {
    transform: rotate(270deg);
  }
}
.generic-spinning {
  transform-origin: center center;
  animation: generic-spin 2s ease-in-out infinite;
}

.dialog-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  transform: scale(0);
  opacity: 0;
  will-change: opacity;
  transition: opacity 0.15s ease-out;
}
.dialog-container.animating {
  opacity: 0;
  transform: scale(1);
}
.dialog-container.animating .popup {
  transform: scale(0.8);
}
.dialog-container.animating-out {
  opacity: 0;
  transform: scale(1);
}
.dialog-container.animating-out .popup {
  transform: scale(0.8);
}
.dialog-container.open {
  opacity: 1;
  transform: scale(1);
  overflow: auto;
}
.dialog-container.open .popup {
  transform: scale(1);
}
.dialog-container .popup {
  overflow: hidden;
  transform: scale(0.8);
  will-change: transform;
  transition: transform 0.15s ease-out;
}
.dialog-container .message {
  white-space: pre-wrap;
}
.dialog-container .buttons > button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 0 none;
  border-radius: 8px;
  outline: 0 none !important;
  cursor: pointer;
  transition: none;
}
.dialog-container .buttons > button:before {
  content: "";
  display: block;
  z-index: 0;
  position: absolute;
  inset: 0;
  border-radius: 8px;
  opacity: 0;
  will-change: opacity;
  transition: opacity 0.1s ease-out;
}
.dialog-container .buttons > button:hover:before, .dialog-container .buttons > button:focus:before {
  opacity: 1;
}
body.android .dialog-container .buttons > button span {
  position: relative;
  top: -1px;
}
.dialog-container .options {
  display: none;
}
.dialog-container .options.visible {
  display: block;
}
.dialog-container .options > div {
  display: flex;
  align-items: center;
  border-top: 1px solid transparent;
  cursor: pointer;
}
.dialog-container .options > div span {
  flex-grow: 1;
}
.dialog-container .options > div .icon-selected {
  display: none;
}
.dialog-container .options > div.selected .icon-selected {
  display: inline-block;
}
.dialog-container .options > div.selected .icon-unselected {
  display: none;
}

.dialog-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 65515;
}
.dialog-container .overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
.dialog-container .popup {
  width: 80%;
  min-width: 220px;
  max-width: 480px;
  z-index: 2;
  border-radius: 16px;
  position: relative;
  top: 20%;
  padding: 8px;
}
.dialog-container .message {
  padding: 16px;
  text-align: center;
  overflow-wrap: anywhere;
}
.dialog-container .buttons {
  text-align: center;
  padding: 0 8px 8px 8px;
}
.dialog-container .buttons > button {
  height: 36px !important;
  line-height: 36px !important;
  padding: 0 16px !important;
  margin: 0 0 0 8px !important;
}
.dialog-container .buttons > button:first-child {
  margin-left: 0 !important;
}
.dialog-container .options {
  padding: 0 8px 16px;
}
.dialog-container .options > div {
  padding: 8px 16px;
}
.dialog-container .options > div > .ei {
  padding: 0 0 0 5px;
}
.dialog-container .options > div div.color-warning {
  padding-top: 5px;
}
.dialog-container .options > div:first-child {
  border-radius: 16px 16px 0 0;
}
.dialog-container .options > div:last-child {
  border-radius: 0 0 16px 16px;
}
.dialog-container .options > div:first-child:last-child {
  border-radius: 16px;
}

.dialog-container {
  backdrop-filter: blur(20px);
}
.dialog-container .overlay {
  background: rgba(18, 55, 143, 0.7);
}
.dialog-container .popup {
  background: #fff;
  color: #3A506B;
}
.dialog-container .buttons > button {
  background: #F0F5FF !important;
  color: #12378F !important;
  box-shadow: unset !important;
}
.dialog-container .buttons > button:before {
  background: rgba(0, 0, 0, 0.07) !important;
}
.dialog-container .buttons > button.btn-delete, .dialog-container .buttons > button.btn-deleteall, .dialog-container .buttons > button.btn-deletezone, .dialog-container .buttons > button.btn-deletelist, .dialog-container .buttons > button.btn-deleteentry, .dialog-container .buttons > button.btn-deleteoption, .dialog-container .buttons > button.btn-deletegoal, .dialog-container .buttons > button.btn-deletehabit, .dialog-container .buttons > button.btn-deletedesktop, .dialog-container .buttons > button.btn-unshareanddelete, .dialog-container .buttons > button.btn-remove, .dialog-container .buttons > button.btn-removelist, .dialog-container .buttons > button.btn-overwrite, .dialog-container .buttons > button.btn-logout {
  background: #DC2770 !important;
  color: #fff !important;
}
.dialog-container .buttons > button.btn-delete:before, .dialog-container .buttons > button.btn-deleteall:before, .dialog-container .buttons > button.btn-deletezone:before, .dialog-container .buttons > button.btn-deletelist:before, .dialog-container .buttons > button.btn-deleteentry:before, .dialog-container .buttons > button.btn-deleteoption:before, .dialog-container .buttons > button.btn-deletegoal:before, .dialog-container .buttons > button.btn-deletehabit:before, .dialog-container .buttons > button.btn-deletedesktop:before, .dialog-container .buttons > button.btn-unshareanddelete:before, .dialog-container .buttons > button.btn-remove:before, .dialog-container .buttons > button.btn-removelist:before, .dialog-container .buttons > button.btn-overwrite:before, .dialog-container .buttons > button.btn-logout:before {
  background: rgba(255, 255, 255, 0.2) !important;
}
.dialog-container .buttons > button.btn-ok, .dialog-container .buttons > button.btn-yes, .dialog-container .buttons > button.btn-accept, .dialog-container .buttons > button.btn-confirm, .dialog-container .buttons > button.btn-proceed, .dialog-container .buttons > button.btn-open, .dialog-container .buttons > button.btn-review, .dialog-container .buttons > button.btn-reviewfields, .dialog-container .buttons > button.btn-now, .dialog-container .buttons > button.btn-savewithoutnotifications, .dialog-container .buttons > button.btn-invite, .dialog-container .buttons > button.btn-closeandnavigate {
  background: #12378F !important;
  color: #fff !important;
}
.dialog-container .buttons > button.btn-ok:before, .dialog-container .buttons > button.btn-yes:before, .dialog-container .buttons > button.btn-accept:before, .dialog-container .buttons > button.btn-confirm:before, .dialog-container .buttons > button.btn-proceed:before, .dialog-container .buttons > button.btn-open:before, .dialog-container .buttons > button.btn-review:before, .dialog-container .buttons > button.btn-reviewfields:before, .dialog-container .buttons > button.btn-now:before, .dialog-container .buttons > button.btn-savewithoutnotifications:before, .dialog-container .buttons > button.btn-invite:before, .dialog-container .buttons > button.btn-closeandnavigate:before {
  background: rgba(255, 255, 255, 0.2) !important;
}
.dialog-container .options {
  color: #3A506B;
}
.dialog-container .options > div {
  background: #F0F5FF;
  border-bottom: 1px solid #fff;
}
.dialog-container .options > div:last-child {
  border-bottom: 0 none;
}
.dialog-container .options > div .ei {
  color: #12378F;
}
.dialog-container .options > div.selected {
  background: #F0F5FF;
}
.dialog-container .options > div.selected span {
  color: #12378F;
}

.dialog-container .message {
  font-size: 20px;
  color: #24294B !important;
}
.dialog-container .buttons > button {
  font-size: 16px !important;
  font-family: "Segoe UI";
}
.dialog-container .options {
  font-size: 16px;
}
.dialog-container .options > div .ei {
  font-size: 16px;
}

.dnd-container:before, .dnd-container:after {
  content: "";
  display: block;
  pointer-events: none;
}
.dnd-container .panning-layer {
  overflow: hidden;
  overflow-x: auto;
  overflow-y: hidden;
  transform: translate3d(0, 0, 0);
  -webkit-overflow-scrolling: touch;
  backface-visibility: hidden;
  white-space: nowrap;
}
.dnd-container .panning-layer * {
  white-space: normal;
  -webkit-overflow-scrolling: auto;
  backface-visibility: initial;
}
body.scroll-block .dnd-container .panning-layer {
  overflow-x: hidden;
}
.dnd-container .vertical-container {
  display: inline-block;
  vertical-align: top;
}
.dnd-container .vertical-container ul {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  position: static;
  -webkit-transform: translateZ(0px);
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-perspective: 1000;
  perspective: 1000;
  overflow: hidden;
  overflow-y: auto;
  transform: translate3d(0, 0, 0);
  -webkit-overflow-scrolling: touch;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.dnd-container .vertical-container ul * {
  -webkit-overflow-scrolling: auto;
}
body.scroll-block .dnd-container .vertical-container ul {
  overflow-y: hidden;
}
.dnd-container .vertical-container ul,
.dnd-container .vertical-container ul li {
  list-style: none;
  list-style-type: none;
}
.dnd-container .vertical-container ul li {
  display: block;
  position: relative;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
}
.dnd-container .vertical-container ul li.highlight {
  overflow: hidden;
  transition: max-height 1.5s ease;
}
.dnd-container .vertical-container ul li.load-more-or-less {
  margin: 8px 0;
  border-radius: 6px;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  height: 48px;
  will-change: background-color;
  background-color: transparent;
  transition: background-color 0.3s linear;
}
.dnd-container .vertical-container ul li.load-more-or-less:not(.drag-started) .show-when-dragging {
  display: none;
}
.dnd-container .vertical-container ul li.load-more-or-less.drag-started .hide-when-dragging {
  display: none;
}
.dnd-container .vertical-container ul li.load-more-or-less.hovering {
  background-color: #fff;
}
.dnd-container .vertical-container ul li.load-more-or-less .load-less {
  flex-shrink: 0;
  flex-grow: 0;
  width: 48px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.dnd-container .vertical-container ul li.load-more-or-less .load-less .ei {
  font-size: 24px;
  margin: 0;
  text-align: center;
  line-height: 8px;
}
.dnd-container .vertical-container ul li.load-more-or-less .load-more {
  flex-grow: 1;
  flex-shrink: 0;
  text-align: center;
  color: #12378F;
  line-height: 46px;
}
.dnd-container .vertical-container ul li.load-more-or-less .load-more.disabled {
  color: #888;
}
.dnd-container .vertical-container ul li.load-more-or-less .load-more .ei {
  vertical-align: middle;
  font-size: 24px;
  margin-right: 5px;
}
.dnd-container .vertical-container.embedded ul {
  -webkit-transform: none;
  -webkit-perspective: none;
  perspective: none;
  transform: none;
  -webkit-overflow-scrolling: auto;
  backface-visibility: initial;
}
.dnd-container .delete {
  display: block;
  overflow: hidden;
  border-radius: 0 0 100px 100px;
  transform: translate3d(0, -100px, 0);
  will-change: transform, width;
  transition: transform 0.2s ease-out;
  text-align: center;
}
.dnd-container .delete .ei {
  will-change: font-size, color, background-color;
  transition: font-size 0.2s ease-out;
  text-align: center;
}
.dnd-container .delete.visible {
  transform: translate3d(0, -40px, 0);
}
.dnd-container .delete.visible.hovering {
  transform: translate3d(0, 0, 0);
}

ul.preview-layer,
div.preview-layer {
  display: block;
  pointer-events: none;
  opacity: 0;
  touch-action: none;
  overflow: visible;
  overflow-x: visible;
  overflow-y: visible;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  will-change: opacity, transform;
  -webkit-font-smoothing: subpixel-antialiased;
}
ul.preview-layer *,
div.preview-layer * {
  transform: none;
}
ul.preview-layer > *,
div.preview-layer > * {
  margin: 0 !important;
  will-change: transform, opacity;
  opacity: 1;
  transform: scale(1);
  transform-origin: center center;
  transition: transform 0.15s ease-out, opacity 0.15s ease-out;
}
ul.preview-layer.h-left.v-top > *,
div.preview-layer.h-left.v-top > * {
  transform-origin: left top;
}
ul.preview-layer.h-left.v-center > *,
div.preview-layer.h-left.v-center > * {
  transform-origin: left center;
}
ul.preview-layer.h-left.v-bottom > *,
div.preview-layer.h-left.v-bottom > * {
  transform-origin: left bottom;
}
ul.preview-layer.h-center.v-top > *,
div.preview-layer.h-center.v-top > * {
  transform-origin: center top;
}
ul.preview-layer.h-center.v-center > *,
div.preview-layer.h-center.v-center > * {
  transform-origin: center center;
}
ul.preview-layer.h-center.v-bottom > *,
div.preview-layer.h-center.v-bottom > * {
  transform-origin: center bottom;
}
ul.preview-layer.h-right.v-top > *,
div.preview-layer.h-right.v-top > * {
  transform-origin: right top;
}
ul.preview-layer.h-right.v-center > *,
div.preview-layer.h-right.v-center > * {
  transform-origin: right center;
}
ul.preview-layer.h-right.v-bottom > *,
div.preview-layer.h-right.v-bottom > * {
  transform-origin: right bottom;
}
ul.preview-layer.hovering-archive > *,
div.preview-layer.hovering-archive > * {
  transform: scale(0.5);
  opacity: 0.35;
}

.vertical-container .ei.no-permission {
  display: none;
  text-align: center;
  pointer-events: none;
  opacity: 0.5;
}
.vertical-container.no-permission .ei.no-permission {
  display: block;
}

body.android .dnd-container.dragging .panning-layer {
  touch-action: none;
  overflow-x: hidden;
}
body.android .dnd-container.dragging .vertical-container ul {
  touch-action: none;
  overflow-y: hidden;
}
.dnd-container:before, .dnd-container:after {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 2;
  width: 10px;
}
.dnd-container .panning-layer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0 22px 8px 0;
}
body.desktop .dnd-container .panning-layer {
  padding-bottom: 16px;
}
.wide-popup .dnd-container .panning-layer {
  padding: 0 2px 8px;
}
.path-settings-desktops .settings-desktops-form .dnd-container .panning-layer {
  padding-bottom: 36px;
}
.path-desktop .dnd-container .panning-layer > .vertical-container {
  border-bottom: 0 none !important;
}
.wide-popup .dnd-container > .panning-layer > .vertical-container {
  height: calc(100% - 24px);
  width: calc(100% - 20px);
  max-width: 400px;
}
.dnd-container > .panning-layer > .vertical-container.tree-view {
  height: 100%;
  width: auto;
  max-width: none;
  padding-right: 10px;
}
.dnd-container > .panning-layer > .vertical-container.tree-view > .list-wrapper {
  padding: 0 8px 36px;
}
.dnd-container .vertical-container {
  position: relative;
  width: 80%;
  height: 100%;
  margin: 0 10px 0 10px;
  padding: 0 0 0 0;
}
.dnd-container .vertical-container .ei.no-permission {
  position: absolute;
  top: 70px;
  left: 0;
  right: 0;
  margin: 0;
  padding: 0;
  z-index: 100;
  font-size: 128px;
}
.dnd-container .vertical-container.tree-view {
  width: auto;
}
.settings-desktops-form .dnd-container .vertical-container {
  margin-top: 0;
}
@media (orientation: landscape), (min-width: 500px) {
  .dnd-container .vertical-container {
    max-width: 400px;
  }
  .dnd-container .vertical-container.tree-view {
    max-width: none;
  }
}
.dnd-container .vertical-container:last-child {
  margin-right: 10px;
}
.dnd-container .vertical-container ul {
  width: 100%;
  height: 100%;
  z-index: 1;
  padding: 2px 15px 2px 0;
  margin: 0 0 0 0;
}
.dnd-container .vertical-container ul.ps > .ps__rail-y > .ps__thumb-y {
  right: auto;
  left: 3px;
}
.dnd-container .vertical-container ul li {
  margin: 0;
  padding: 0;
}
.dnd-container .vertical-container ul li:last-child {
  margin-bottom: 0;
}
.dnd-container .vertical-container ul li.highlight {
  max-height: 0;
}
.dnd-container .vertical-container ul li.highlight.appear {
  max-height: 500px;
}
.dnd-container .vertical-container ul li.embedded-list-item {
  padding-bottom: 28px;
}
.dnd-container .vertical-container ul li.embedded-list-item .embedded > h3 .actions-container {
  display: none;
}
.dnd-container .vertical-container ul li.embedded-list-item.collapsed {
  padding-bottom: 8px;
}
.dnd-container .vertical-container ul li.embedded-list-item.collapsed .embedded.collapsed > h3 {
  margin-bottom: -8px;
}
.dnd-container .vertical-container ul li.embedded-list-item.collapsed .embedded.collapsed > h3 > .actions-container {
  display: block;
}
.dnd-container .vertical-container ul li.embedded-list-item:empty {
  display: none;
}
.dnd-container .vertical-container ul.settings-field-group {
  padding: 0;
  margin: 0 0 -56px 0;
  position: relative;
  z-index: 2;
}
.dnd-container .vertical-container ul.settings-field-group[data-group-id=default_contact_before-tags], .dnd-container .vertical-container ul.settings-field-group[data-group-id=default_image_before-tags], .dnd-container .vertical-container ul.settings-field-group[data-group-id=default_image_before-image], .dnd-container .vertical-container ul.settings-field-group[data-group-id=default_task_before-tags], .dnd-container .vertical-container ul.settings-field-group[data-group-id=default_contact_before-status], .dnd-container .vertical-container ul.settings-field-group[data-group-id=default_image_before-status], .dnd-container .vertical-container ul.settings-field-group[data-group-id=default_task_before-status], .dnd-container .vertical-container ul.settings-field-group[data-group-id=default_image_before-name], .dnd-container .vertical-container ul.settings-field-group[data-group-id^=default_dynamic_before-status_], .dnd-container .vertical-container ul.settings-field-group[data-group-id^=default_dynamic_before-tags_] {
  margin-bottom: -36px;
}
.dnd-container .vertical-container ul.settings-field-group:last-child {
  margin-bottom: 0;
}
.dnd-container .vertical-container ul.settings-field-group li {
  margin-top: 9px;
  min-height: 42px;
}
.dnd-container .vertical-container ul.settings-field-group li:before {
  display: none;
}
.dnd-container .vertical-container ul.settings-field-group li .content {
  padding-top: 4px;
  font-weight: bold;
}
.dnd-container .vertical-container ul.settings-field-group li .content h4 {
  white-space: normal;
}
.dnd-container .vertical-container ul.settings-field-group li .content h4 span {
  text-transform: none;
}
.dnd-container .vertical-container ul.settings-field-group li .content h4 span .ei {
  float: right;
  margin: 0 8px;
}
.dnd-container .vertical-container ul.settings-field-group li .content .ei {
  font-size: 16px;
}
.dnd-container .vertical-container ul.settings-field-group li .drop-zone > div span {
  line-height: 12px;
  transform: none;
}
.dnd-container .vertical-container ul.settings-field-group li.placeholder {
  border: 0 none;
  background: transparent;
}
.dnd-container .vertical-container.list-dayplan ul {
  height: 100%;
  padding-bottom: 8px;
}
.dnd-container .vertical-container.list-dayplan ul .habits ul {
  height: calc(100% - 33px);
}
.dnd-container .vertical-container.embedded {
  width: 100%;
  max-width: none;
  margin: 8px 0 0;
  height: auto;
  overflow: visible;
}
.dnd-container .vertical-container.embedded .vertical-container.embedded {
  width: 100%;
  margin: 8px 0 0 0;
}
.dnd-container .vertical-container.embedded .list-wrapper {
  padding: 0 8px;
  overflow: visible;
}
.dnd-container .vertical-container.embedded ul {
  min-height: 90px;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 2px;
}
body.mobile .dnd-container .vertical-container.embedded ul {
  padding-right: 10px;
}
.wide-popup .dnd-container .vertical-container.embedded ul {
  min-height: 60px;
}
.dnd-container .delete {
  position: absolute;
  z-index: 3;
  top: 0;
  left: 50%;
  height: 100px;
  width: 200px;
  margin-left: -100px;
}
.dnd-container .delete .ei {
  margin-right: 0;
  height: 42px;
  width: 42px;
  border-radius: 21px;
  line-height: 38px;
  font-size: 24px;
  position: absolute;
  bottom: 10px;
  left: 79px;
}
.dnd-container .delete.visible.hovering .ei {
  font-size: 32px;
}

ul.preview-layer,
div.preview-layer {
  position: absolute;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  z-index: 10000;
}

.dnd-container .vertical-container ul li.embedded-list-item.new-layout {
  padding-bottom: 8px;
}
.dnd-container .vertical-container ul li.embedded-list-item.new-layout .list-container.embedded > .list-wrapper {
  padding-bottom: 0;
  height: calc(100% - 36px);
}

.dnd-container .vertical-container.new-layout > .list-wrapper {
  padding-bottom: 0;
  height: calc(100% - 44px);
}
.dnd-container .vertical-container.new-layout.tree-view > .list-wrapper {
  height: calc(100% - 8px);
}

.actions-container2 {
  display: flex;
  align-items: center;
  justify-content: stretch;
  height: 36px;
  border-radius: 0 0 8px 8px;
  position: relative;
  z-index: 1;
}
.actions-container2 > * {
  height: 100%;
  line-height: 36px;
  text-align: center;
}
.actions-container2 .ei {
  font-size: 16px;
}
.actions-container2 > .ei {
  flex-shrink: 0;
  width: 36px;
  position: relative;
  overflow: visible;
  font-size: 24px;
  margin: 0;
}
.actions-container2 > .ei:after {
  content: "";
  display: block;
  position: absolute;
  top: -8px;
  left: -8px;
  right: -2px;
  bottom: -8px;
}

.list-container h3 .actions-container2 > .ei {
  top: -2px;
}
.list-container h3 .actions-container2 > .ei.ei-add {
  top: -1px;
  background-color: currentColor;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 12px;
  font-size: 19px;
}
.list-container h3 .actions-container2 > .ei.ei-add:before {
  color: #fff;
}

.embedded-list-item.collapsed .list-container > .actions-container2 {
  display: none;
}

.dnd-container.begin-my-day-container {
  height: 100%;
  position: relative;
}
.dnd-container.begin-my-day-container .preview-layer {
  position: absolute;
  z-index: 65535;
}
.dnd-container.begin-my-day-container .preview-layer h4 {
  padding: 10px 10px 12px;
  margin: 0;
  border-radius: 8px;
  max-width: 320px;
  overflow: hidden;
}
.dnd-container.begin-my-day-container .preview-layer .drag-handle {
  border-radius: 6px;
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
  padding: 6px;
}
.dnd-container.begin-my-day-container .preview-layer .drag-handle svg {
  display: inline-block;
}
.dnd-container.begin-my-day-container .preview-layer .drag-handle .preview {
  display: inline-block;
}
.dnd-container.begin-my-day-container .preview-layer .drag-handle .preview span {
  display: inline-block;
  vertical-align: middle;
  max-width: 220px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dnd-container.begin-my-day-container section {
  position: relative;
}
.dnd-container.begin-my-day-container section:before {
  content: "";
  position: absolute;
  top: -9px;
  left: 0;
  right: 0;
  height: 8px;
  border-radius: 4px;
  background: #ffe600;
  pointer-events: none;
  opacity: 0;
  will-change: opacity;
  transition: opacity 0.2s ease-out;
}
.dnd-container.begin-my-day-container section.hover:before {
  opacity: 1;
}
.dnd-container.begin-my-day-container section.last-section {
  height: 120px;
  background: transparent;
  border-color: transparent;
}
.dnd-container.begin-my-day-container section li.draggable,
.dnd-container.begin-my-day-container section footer {
  position: relative;
}
.dnd-container.begin-my-day-container section li.draggable .drag-handle .preview,
.dnd-container.begin-my-day-container section footer .drag-handle .preview {
  display: none;
}
.dnd-container.begin-my-day-container section li.draggable:before,
.dnd-container.begin-my-day-container section footer:before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  height: 8px;
  border-radius: 4px;
  background: #ffe600;
  pointer-events: none;
  opacity: 0;
  will-change: opacity;
  transition: opacity 0.2s ease-out;
}
.dnd-container.begin-my-day-container section li.draggable.hover:before,
.dnd-container.begin-my-day-container section footer.hover:before {
  opacity: 1;
}

.dnd-container .vertical-container .ei.no-permission {
  color: #000018;
}
.dnd-container .vertical-container ul.hovering {
  border-color: #5bc0de;
}
.dnd-container .vertical-container ul.settings-field-group {
  background: transparent;
}
.dnd-container .vertical-container ul.settings-field-group li .content {
  border: 0 none;
  border-radius: 0;
}
.dnd-container .vertical-container ul.settings-field-group li.add-field {
  border: 0 none;
}
.dnd-container .ps > .ps__rail-y {
  background-color: transparent !important;
}
.path-desktop .dnd-container .ps__thumb-y,
.path-desktop .dnd-container .ps__rail-y:hover > .ps__thumb-y,
.path-desktop .dnd-container .ps__rail-y:focus > .ps__thumb-y,
.path-desktop .dnd-container .ps__rail-y.ps--clicking .ps__thumb-y {
  background-color: #fff;
}
.dnd-container .delete .ei {
  background-color: #fff;
  color: #DC2770;
  box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.5);
}
.dnd-container .delete.visible.hovering {
  background: rgba(0, 0, 0, 0.4);
  box-shadow: 0px 3px 5px 5px rgba(0, 0, 0, 0.4);
}
.dnd-container .delete.visible.hovering .ei {
  background-color: transparent;
  box-shadow: unset;
  color: #fff;
}

ul.preview-layer,
div.preview-layer {
  background: transparent;
}

ul.list {
  overflow: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}
ul.list.items-list {
  overflow: hidden;
  position: static;
  -webkit-overflow-scrolling: auto;
}
ul.list * {
  -webkit-overflow-scrolling: auto;
}
ul.list, ul.list li {
  display: block;
  list-style: none;
  list-style-type: none;
}
ul.list li {
  border: 1px solid #e6e6e6;
  border-width: 0 0 0 6px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  will-change: margin, background-color;
  transition: margin 0.2s ease-out, background-color 0.2s ease-out;
}
ul.list li .icon {
  text-align: center;
}
ul.list li.notification {
  will-change: opacity;
  transition: opacity 0.2s ease-out;
  opacity: 0.6;
  box-shadow: unset;
}
ul.list li.notification.unread {
  opacity: 1;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
}
ul.list li.message {
  text-align: center;
}
ul.list li.load-more {
  border: 0 none transparent;
  background: #5cb85c;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}
ul.list li.load-more .ei {
  font-size: 24px;
  margin-right: 4px;
}
ul.list li.load-more.discreet {
  background: transparent;
  color: #888;
}
.form-tab ul.list {
  position: static;
}
ul.list.has-floats li:after {
  content: "";
  display: block;
  height: 0;
  width: 100%;
  clear: both;
}
ul.list.plain li {
  border: 0 none;
  border-bottom: 1px solid #e6e6e6;
  text-align: left;
}

@media (max-width: 479px) {
  .form-tab ul.list li > .ei.icon {
    display: none;
  }
}
.display-share-changes-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 24px 24px 104px;
  overflow: auto;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

ul.display-share-changes {
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
}
ul.display-share-changes ul.display-share-changes {
  padding: 0 8px;
}
ul.display-share-changes h4 {
  padding: 4px 8px;
  margin: 0;
}
ul.display-share-changes,
ul.display-share-changes li {
  list-style-type: none;
  list-style: none;
}
ul.display-share-changes li {
  margin: 0 0 8px 0;
  padding: 0;
  border-radius: 8px;
  overflow: hidden;
}
ul.display-share-changes li > .nested,
ul.display-share-changes li > .changes {
  height: 0px;
  overflow: hidden;
  will-change: padding-top, padding-bottom;
  transition: padding-top 0.2s ease-out, padding-bottom 0.2s ease-out;
}
ul.display-share-changes li:not(.expanded) > h4 .arrow-collapse {
  display: none;
}
ul.display-share-changes li.expanded > h4 .arrow-expand {
  display: none;
}
ul.display-share-changes li.expanded > .nested {
  height: auto;
  padding-top: 8px;
  padding-bottom: 8px;
}
ul.display-share-changes li.expanded > .changes {
  height: auto;
  padding-bottom: 4px;
}
ul.display-share-changes li .single-share {
  padding: 8px 0;
  margin: 0 8px;
  border-bottom: 1px solid transparent;
}
ul.display-share-changes li .single-share:last-child {
  border-bottom: 0 none;
}
ul.display-share-changes li .single-share > .content {
  padding-left: 32px;
}
ul.display-share-changes li .single-share.added .user-icon {
  color: #5cb85c;
}
ul.display-share-changes li .single-share.removed .user-icon {
  color: #DC2770;
}
ul.display-share-changes li .single-share.modified .user-icon {
  color: #f0ad4e;
}
ul.display-share-changes li .single-permission .old-value {
  color: #888;
  font-size: 11px;
}

.habits-list-container {
  height: 100%;
}
.habits-list-container.dragging ul.list {
  overflow-y: hidden;
}
.habits-list-container ul.list {
  display: flex;
  flex-direction: column;
}
.habits-list-container ul.list li {
  display: block;
  position: relative;
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: 1;
  margin-bottom: 0;
  will-change: opacity, box-shadow;
  opacity: 1;
  transition: opacity 0.2s ease-out, box-shadow 0.2s ease-out;
}
.habits-list-container ul.list li.last-card {
  min-height: 70px;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  background: transparent;
  border-color: transparent;
  padding: 0;
  box-shadow: unset;
}
.habits-list-container ul.list li:before {
  content: "";
  position: absolute;
  top: -6px;
  left: -6px;
  right: 0;
  height: 5px;
  border-radius: 3px;
  background: #5cb85c;
  pointer-events: none;
  opacity: 0;
  will-change: opacity;
  transition: opacity 0.2s ease-out;
}
.habits-list-container ul.list li.hover:before {
  opacity: 1;
}
.habits-list-container ul.list li.dragged {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.14), 0 0 0 0 rgba(0, 0, 0, 0.12), 0 0 0 0 rgba(0, 0, 0, 0.2);
  opacity: 0.6;
}
.habits-list-container ul.preview-layer li {
  border-radius: 6px;
  background: #fff;
  border-left: 6px solid #12378F;
  padding: 5px;
}
.habits-list-container ul.preview-layer li .list-item-action {
  display: none;
}
.habits-list-container ul.preview-layer li h3 {
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  padding: 11px 5px;
  margin: 0;
}

ul.list {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
ul.list.items-list {
  padding: 3px 0;
}
ul.list.items-list li {
  margin: 5px 0;
}
ul.list, ul.list li {
  margin: 0;
  padding: 0;
}
ul.list.sublist {
  position: static;
  display: block;
  width: 100%;
  padding: 0;
}
ul.list.sublist.enable-copy-paste p {
  overflow-wrap: anywhere;
}
ul.list li {
  margin: 8px;
  border-radius: 6px;
  padding: 5px;
  min-height: 54px;
}
ul.list li.flex {
  display: flex;
  align-items: center;
  justify-content: stretch;
}
ul.list li.flex > * {
  flex-basis: 1;
}
ul.list li.flex h3 {
  flex-grow: 1;
}
ul.list li.flex .action {
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
  flex-shrink: 0;
}
ul.list li.flex .action .ei {
  font-size: 24px;
}
ul.list li.goal-projects {
  background: transparent;
  border: 0 none;
  margin: 0;
  padding: 0 28px;
  min-height: 0;
  max-height: 0;
  overflow: hidden;
  will-change: max-height;
  transition: max-height 0.2s ease-out;
  box-shadow: unset;
}
ul.list li.goal-projects .sublist li.flex {
  margin-left: 4px;
  margin-right: 4px;
}
ul.list li h3,
ul.list li h4,
ul.list li p {
  margin: 0;
}
ul.list li h3 {
  line-height: 18px;
  padding: 11px 5px;
}
ul.list li button {
  float: right;
  margin-right: 0;
  margin-left: 7px;
}
ul.list li .icon {
  float: left;
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 24px;
}
ul.list li.notification .ei.right {
  float: right;
  font-size: 24px;
  line-height: 40px;
  margin-left: 5px;
}
ul.list li.notification h3 {
  padding: 6px 6px 4px;
}
ul.list li.message {
  line-height: 50px;
}
ul.list li .float-right.list-item-action {
  margin: 6px;
  width: 32px;
  height: 32px;
  padding: 4px;
  border-radius: 16px;
}
.form-tab ul.list li {
  margin: 5px 0;
}

@media (max-width: 479px) {
  .form-tab ul.list li button {
    width: 40px;
    padding-left: 0;
    padding-right: 0;
    font-size: 0;
    text-align: center;
  }
  .form-tab ul.list li button .ei.inline {
    margin: 0;
  }
}
h3.single-line {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

ul.list {
  background: #f5f5f5;
}
ul.list.notifications {
  background: #e6e6e6;
}
ul.list.items-list, .form-tab ul.list {
  background: transparent;
}
ul.list li {
  background: #fff;
  border-color: #e6e6e6;
  border-left-color: #12378F;
}
ul.list li.notification.info {
  border-left-color: #40a3ea;
}
ul.list li.notification.success {
  border-left-color: #a473ff;
}
ul.list li.notification.warn {
  border-left-color: #ff8309;
}
ul.list li.notification.error {
  border-left-color: #DC2770;
}
ul.list li.notification .ei.right {
  color: #e6e6e6;
}
ul.list.plain li {
  border-bottom-color: #e6e6e6;
}
ul.list.color-task li {
  border-left-color: #40a3ea;
}
ul.list.color-task li button {
  background: #40a3ea;
}
ul.list.color-contact li {
  border-left-color: #ff8309;
}
ul.list.color-contact li button {
  background: #ff8309;
}
ul.list.color-image li {
  border-left-color: #ffca08;
}
ul.list.color-image li button {
  background: #ffca08;
}
ul.list.color-goal li {
  border-left-color: #a473ff;
}
ul.list.color-goal li button {
  background: #a473ff;
}
ul.list.color-project li {
  border-left-color: #a473ff;
}
ul.list.color-project li button {
  background: #a473ff;
}
ul.list.color-friend li {
  border-left-color: #ff8309;
}
ul.list.color-friend li button {
  background: #ff8309;
}
ul.list.color-feedback li {
  border-left-color: #21ac66;
}
ul.list.color-feedback li button {
  background: #21ac66;
}
ul.list.color-settings li {
  border-left-color: #000018;
}
ul.list.color-settings li button {
  background: #e6e6e6;
  color: #000018;
}

ul.list li h3,
ul.list li h4 {
  font-weight: normal;
}
ul.list li h3 {
  font-size: 16px;
}
ul.list li.notification h3 {
  font-style: italic;
}
ul.list li.notification.unread h3 {
  font-style: normal;
}
ul.list li.friend.pending h3 {
  font-weight: bold;
}

/* ----------------------------------------------
 * Generated by Animista on 2018-7-20 15:52:29
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
/**
 * ----------------------------------------
 * animation kenburns-bottom-left
 * ----------------------------------------
 */
@keyframes kenburns-bottom-left {
  0% {
    transform: scale(1) translate3d(0, 0, 0);
    transform-origin: 16% 84%;
  }
  100% {
    transform: scale(1.15) translate3d(-15px, 10px, 0);
    transform-origin: left bottom;
  }
}
.slideshow {
  position: relative;
  overflow: hidden;
  opacity: 1;
  transition: opacity 1s ease-out;
  will-change: opacity;
}
.slideshow.loading {
  opacity: 0;
  transition: none;
}
body.desktop .slideshow::-webkit-scrollbar,
body.desktop .slideshow *::-webkit-scrollbar {
  display: none;
}
.slideshow .controls,
.slideshow .control-orientation {
  text-align: center;
  opacity: 0;
  pointer-events: none;
  will-change: opacity;
  transition: opacity 0.15s ease-out;
}
.slideshow .controls.visible,
.slideshow .control-orientation.visible {
  opacity: 1;
  pointer-events: all;
}
.slideshow .controls .ei,
.slideshow .control-orientation .ei {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  will-change: background-color;
  cursor: pointer;
}
.slideshow .slide {
  opacity: 0;
  will-change: opacity, transform;
  pointer-events: none;
}
.slideshow .slide.current, .slideshow .slide.target {
  opacity: 1;
  pointer-events: all;
  will-change: opacity, transform;
  transition: opacity 0s linear;
}
.slideshow .slide.current.transitioning, .slideshow .slide.target.transitioning {
  transition: opacity 1s ease-out;
}
.slideshow .slide.current.transitioning.fast, .slideshow .slide.target.transitioning.fast {
  transition: opacity 0.3s ease-out;
}
.slideshow .slide > div.background {
  background-size: cover;
  background-position: center;
}
.slideshow .slide.animating > div.background {
  backface-visibility: hidden;
  animation: kenburns-bottom-left 11s ease-in-out infinite alternate both;
}
.slideshow .slide.size-small {
  font-size: 16px;
}
.slideshow .slide.size-normal {
  font-size: 22px;
}
.slideshow .slide.size-large {
  font-size: 26px;
}
.slideshow .slide.size-xlarge {
  font-size: 32px;
}
.slideshow .slide.size-xxlarge {
  font-size: 40px;
}
.slideshow .slide.size-xxxlarge {
  font-size: 52px;
}
.slideshow .slide.size-xxxxlarge {
  font-size: 68px;
}
.slideshow .slide a {
  text-decoration: none;
  display: inline-block;
}
.slideshow .slide > .details {
  overflow: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
.slideshow .slide ul,
.slideshow .slide ul li {
  list-style: none;
  list-style-type: none;
}
.slideshow .slide ul.actions li li {
  border: 0 none;
}

@media (orientation: landscape) {
  .slideshow .slide.slide-goal > ul.projects {
    overflow: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
}
@media (orientation: portrait) {
  .slideshow .slide.slide-goal .project-container {
    overflow: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
}
.slideshow {
  width: 100%;
  height: 100%;
}
.slideshow .controls,
.slideshow .control-orientation {
  position: absolute;
  z-index: 5;
  height: 52px;
  border-radius: 8px;
  bottom: 20px;
  left: 20px;
  width: 156px;
}
.slideshow .controls .ei,
.slideshow .control-orientation .ei {
  margin: 0;
  width: 52px;
  height: 52px;
  line-height: 52px;
  font-size: 28px;
  border-radius: 0;
}
.slideshow .controls .ei:first-child,
.slideshow .control-orientation .ei:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.slideshow .controls .ei:last-child,
.slideshow .control-orientation .ei:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.slideshow .controls .ei:only-child,
.slideshow .control-orientation .ei:only-child {
  width: 52px;
}
.slideshow .controls .ei.large,
.slideshow .control-orientation .ei.large {
  font-size: 40px;
}
.slideshow .controls.speed {
  left: auto;
  right: 20px;
  height: 52px;
  width: 52px;
  cursor: pointer;
}
.slideshow .controls.speed span {
  display: inline-flex;
  width: 28px;
  line-height: 52px;
  align-items: center;
  justify-content: center;
}
.slideshow .control-orientation {
  width: auto;
  left: auto;
  bottom: auto;
  top: 20px;
  right: 20px;
}
.slideshow .slide {
  position: absolute;
  top: 12px;
  left: 12px;
  width: calc(100% - 24px);
  height: calc(100% - 24px);
  border-radius: 16px;
  overflow: hidden;
  z-index: 2;
  padding: 4px 8px;
}
.slideshow .slide.current {
  z-index: 1;
}
.slideshow .slide.target {
  z-index: 3;
}
.slideshow .slide > h1,
.slideshow .slide > h2,
.slideshow .slide > ul,
.slideshow .slide > .caption,
.slideshow .slide > .text,
.slideshow .slide > div {
  position: relative;
  z-index: 3;
}
.slideshow .slide > div.overlay {
  position: absolute;
  inset: 0;
  z-index: 2;
}
.slideshow .slide > div.background {
  position: absolute;
  inset: 0;
  z-index: 1;
}
.slideshow .slide h1,
.slideshow .slide h2,
.slideshow .slide li,
.slideshow .slide .caption,
.slideshow .slide .text,
.slideshow .slide a {
  margin: 4px 0;
  padding: 2px 16px 8px;
  border-radius: 8px;
  float: left;
  clear: left;
}
.slideshow .slide .links {
  clear: left;
  float: left;
  max-width: 100%;
}
.slideshow .slide a {
  padding: 2px 16px 8px;
}
.slideshow .slide a .ei {
  font-size: 24px;
}
.slideshow .slide h1 {
  padding: 2px 16px 8px;
}
.slideshow .slide h2 i {
  margin-right: 5px;
}
.slideshow .slide > .details {
  max-height: 100%;
}
.slideshow .slide .project-container h1 {
  font-size: 33px;
}
.slideshow .slide .project-container h2 {
  font-size: 33px;
}
.slideshow .slide ul {
  margin: 0;
  padding: 0;
}
.slideshow .slide ul.projects {
  clear: none;
  float: right;
  width: 80%;
  max-width: 480px;
}
.slideshow .slide ul.projects li {
  float: none;
  margin: 0;
}
.slideshow .slide ul.projects > li > h4 {
  margin: -3px 0 -3px 0;
  padding: 8px 0;
}
.slideshow .slide ul.projects > li > h4 .ei {
  float: right;
  font-size: 16px;
}
.slideshow .slide .caption {
  margin-top: -4px;
}
.slideshow .slide .text-content {
  position: absolute;
  z-index: 3;
  bottom: 64px;
  left: 8px;
  right: 8px;
}
@media (max-width: 599.95px) {
  .slideshow .slide .text-content {
    bottom: 124px;
  }
}
.slideshow .slide.section-end a {
  display: inline-block;
  float: none;
}

@media (orientation: landscape) {
  .slideshow .slide.slide-goal > .details {
    width: 60%;
    float: left;
  }
  .slideshow .slide.slide-goal > ul.projects {
    max-width: none;
    width: 40%;
    max-height: 100%;
    padding-bottom: 80px;
  }
}
@media (orientation: portrait) {
  .slideshow .slide.slide-goal .project-container {
    width: 100%;
    height: 100%;
    padding-bottom: 80px;
  }
}
.slideshow {
  background-color: #12378F;
}
.slideshow .controls, .slideshow .control-orientation {
  background: rgba(0, 0, 24, 0.75);
  color: #fff;
}
.slideshow .control-orientation .ei.locked {
  background: rgba(220, 39, 112, 0.2);
  color: #fff;
}
.slideshow .slide {
  color: #fff;
}
.slideshow .slide > div.background {
  background-color: #e6e6e6;
}
.slideshow .slide h1, .slideshow .slide h2, .slideshow .slide li, .slideshow .slide .caption, .slideshow .slide .text, .slideshow .slide a {
  background-color: rgba(0, 0, 24, 0.25);
}
.slideshow .slide.color-black h1, .slideshow .slide.color-black h2, .slideshow .slide.color-black li, .slideshow .slide.color-black .caption, .slideshow .slide.color-black .text, .slideshow .slide.color-black a {
  color: black;
}
.slideshow .slide.color-white h1, .slideshow .slide.color-white h2, .slideshow .slide.color-white li, .slideshow .slide.color-white .caption, .slideshow .slide.color-white .text, .slideshow .slide.color-white a {
  color: white;
}
.slideshow .slide.color-task h1, .slideshow .slide.color-task h2, .slideshow .slide.color-task li, .slideshow .slide.color-task .caption, .slideshow .slide.color-task .text, .slideshow .slide.color-task a {
  color: #40a3ea;
}
.slideshow .slide.color-goal h1, .slideshow .slide.color-goal h2, .slideshow .slide.color-goal li, .slideshow .slide.color-goal .caption, .slideshow .slide.color-goal .text, .slideshow .slide.color-goal a {
  color: #a473ff;
}
.slideshow .slide.color-project h1, .slideshow .slide.color-project h2, .slideshow .slide.color-project li, .slideshow .slide.color-project .caption, .slideshow .slide.color-project .text, .slideshow .slide.color-project a {
  color: #a473ff;
}
.slideshow .slide.color-contact h1, .slideshow .slide.color-contact h2, .slideshow .slide.color-contact li, .slideshow .slide.color-contact .caption, .slideshow .slide.color-contact .text, .slideshow .slide.color-contact a {
  color: #ff8309;
}
.slideshow .slide.color-image h1, .slideshow .slide.color-image h2, .slideshow .slide.color-image li, .slideshow .slide.color-image .caption, .slideshow .slide.color-image .text, .slideshow .slide.color-image a {
  color: #ffca08;
}
.slideshow .slide.color-feedback h1, .slideshow .slide.color-feedback h2, .slideshow .slide.color-feedback li, .slideshow .slide.color-feedback .caption, .slideshow .slide.color-feedback .text, .slideshow .slide.color-feedback a {
  color: #21ac66;
}
.slideshow .slide.color-light-task h1, .slideshow .slide.color-light-task h2, .slideshow .slide.color-light-task li, .slideshow .slide.color-light-task .caption, .slideshow .slide.color-light-task .text, .slideshow .slide.color-light-task a {
  color: #b3ffff;
}
.slideshow .slide.color-light-goal h1, .slideshow .slide.color-light-goal h2, .slideshow .slide.color-light-goal li, .slideshow .slide.color-light-goal .caption, .slideshow .slide.color-light-goal .text, .slideshow .slide.color-light-goal a {
  color: #f0e8ff;
}
.slideshow .slide.color-light-project h1, .slideshow .slide.color-light-project h2, .slideshow .slide.color-light-project li, .slideshow .slide.color-light-project .caption, .slideshow .slide.color-light-project .text, .slideshow .slide.color-light-project a {
  color: #f0e8ff;
}
.slideshow .slide.color-light-contact h1, .slideshow .slide.color-light-contact h2, .slideshow .slide.color-light-contact li, .slideshow .slide.color-light-contact .caption, .slideshow .slide.color-light-contact .text, .slideshow .slide.color-light-contact a {
  color: #fee7ce;
}
.slideshow .slide.color-light-image h1, .slideshow .slide.color-light-image h2, .slideshow .slide.color-light-image li, .slideshow .slide.color-light-image .caption, .slideshow .slide.color-light-image .text, .slideshow .slide.color-light-image a {
  color: #ffffbd;
}
.slideshow .slide.color-light-feedback h1, .slideshow .slide.color-light-feedback h2, .slideshow .slide.color-light-feedback li, .slideshow .slide.color-light-feedback .caption, .slideshow .slide.color-light-feedback .text, .slideshow .slide.color-light-feedback a {
  color: #cfc;
}
.slideshow .slide.opacity-0 h1, .slideshow .slide.opacity-0 h2, .slideshow .slide.opacity-0 li, .slideshow .slide.opacity-0 .caption, .slideshow .slide.opacity-0 .text, .slideshow .slide.opacity-0 a {
  background: rgba(0, 0, 24, 0.25);
}
.slideshow .slide.opacity-25 h1, .slideshow .slide.opacity-25 h2, .slideshow .slide.opacity-25 li, .slideshow .slide.opacity-25 .caption, .slideshow .slide.opacity-25 .text, .slideshow .slide.opacity-25 a {
  background: rgba(0, 0, 24, 0.25);
}
.slideshow .slide.opacity-50 h1, .slideshow .slide.opacity-50 h2, .slideshow .slide.opacity-50 li, .slideshow .slide.opacity-50 .caption, .slideshow .slide.opacity-50 .text, .slideshow .slide.opacity-50 a {
  background: rgba(0, 0, 24, 0.5);
}
.slideshow .slide.opacity-75 h1, .slideshow .slide.opacity-75 h2, .slideshow .slide.opacity-75 li, .slideshow .slide.opacity-75 .caption, .slideshow .slide.opacity-75 .text, .slideshow .slide.opacity-75 a {
  background: rgba(0, 0, 24, 0.75);
}
.slideshow .slide.opacity-100 h1, .slideshow .slide.opacity-100 h2, .slideshow .slide.opacity-100 li, .slideshow .slide.opacity-100 .caption, .slideshow .slide.opacity-100 .text, .slideshow .slide.opacity-100 a {
  background: #000018;
}
.slideshow .slide ul {
  background: transparent;
}
.slideshow .slide ul.actions li {
  background: transparent;
}
.slideshow .slide ul.actions li li {
  background: rgba(230, 230, 230, 0.8);
}
.slideshow .slide .caption {
  color: #12378F;
}

.slideshow .slide.size-small,
.slideshow .slide.size-small h1,
.slideshow .slide.size-small .project-container h1,
.slideshow .slide.size-small h2,
.slideshow .slide.size-small .project-container h2,
.slideshow .slide.size-small li,
.slideshow .slide.size-small .caption,
.slideshow .slide.size-small .text,
.slideshow .slide.size-small a {
  font-size: 22px;
  line-height: 24px;
  padding: 8px 16px 12px;
}
.slideshow .slide,
.slideshow .slide h1,
.slideshow .slide .project-container h1,
.slideshow .slide h2,
.slideshow .slide .project-container h2,
.slideshow .slide li,
.slideshow .slide .caption,
.slideshow .slide .text,
.slideshow .slide a, .slideshow .slide.size-normal,
.slideshow .slide.size-normal h1,
.slideshow .slide.size-normal .project-container h1,
.slideshow .slide.size-normal h2,
.slideshow .slide.size-normal .project-container h2,
.slideshow .slide.size-normal li,
.slideshow .slide.size-normal .caption,
.slideshow .slide.size-normal .text,
.slideshow .slide.size-normal a {
  font-size: 28px;
  line-height: 30px;
  padding: 8px 16px 14px;
}
.slideshow .slide.size-large,
.slideshow .slide.size-large h1,
.slideshow .slide.size-large .project-container h1,
.slideshow .slide.size-large h2,
.slideshow .slide.size-large .project-container h2,
.slideshow .slide.size-large li,
.slideshow .slide.size-large .caption,
.slideshow .slide.size-large .text,
.slideshow .slide.size-large a {
  font-size: 32px;
  line-height: 34px;
  padding: 8px 16px 14px;
}
.slideshow .slide.size-xlarge,
.slideshow .slide.size-xlarge h1,
.slideshow .slide.size-xlarge .project-container h1,
.slideshow .slide.size-xlarge h2,
.slideshow .slide.size-xlarge .project-container h2,
.slideshow .slide.size-xlarge li,
.slideshow .slide.size-xlarge .caption,
.slideshow .slide.size-xlarge .text,
.slideshow .slide.size-xlarge a {
  font-size: 40px;
  line-height: 42px;
  padding: 8px 16px 14px;
}
.slideshow .slide.size-xxlarge,
.slideshow .slide.size-xxlarge h1,
.slideshow .slide.size-xxlarge .project-container h1,
.slideshow .slide.size-xxlarge h2,
.slideshow .slide.size-xxlarge .project-container h2,
.slideshow .slide.size-xxlarge li,
.slideshow .slide.size-xxlarge .caption,
.slideshow .slide.size-xxlarge .text,
.slideshow .slide.size-xxlarge a {
  font-size: 48px;
  line-height: 50px;
  padding: 8px 18px 14px;
}
.slideshow .slide.size-xxxlarge,
.slideshow .slide.size-xxxlarge h1,
.slideshow .slide.size-xxxlarge .project-container h1,
.slideshow .slide.size-xxxlarge h2,
.slideshow .slide.size-xxxlarge .project-container h2,
.slideshow .slide.size-xxxlarge li,
.slideshow .slide.size-xxxlarge .caption,
.slideshow .slide.size-xxxlarge .text,
.slideshow .slide.size-xxxlarge a {
  font-size: 58px;
  line-height: 60px;
  padding: 8px 20px 16px;
}
.slideshow .slide.size-xxxxlarge,
.slideshow .slide.size-xxxxlarge h1,
.slideshow .slide.size-xxxxlarge .project-container h1,
.slideshow .slide.size-xxxxlarge h2,
.slideshow .slide.size-xxxxlarge .project-container h2,
.slideshow .slide.size-xxxxlarge li,
.slideshow .slide.size-xxxxlarge .caption,
.slideshow .slide.size-xxxxlarge .text,
.slideshow .slide.size-xxxxlarge a {
  font-size: 68px;
  line-height: 70px;
  padding: 8px 22px 16px;
}
.slideshow .slide ul.projects li {
  font-size: 20px;
}
.slideshow .slide .caption {
  font-size: 14px;
  font-weight: bold;
}
.slideshow .slide .text,
.slideshow .slide ul li {
  font-weight: bold;
}

.settings-container {
  overflow: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}
.settings-container * {
  -webkit-overflow-scrolling: auto;
}
.settings-container > nav {
  border-bottom: 2px solid #071434;
}
.settings-container > nav .button {
  background: #e6e6e6;
  border-right: 2px solid #071434;
  border-bottom: 2px solid #071434;
  box-shadow: unset;
}
.settings-container.start-my-day section h4 .ei {
  pointer-events: none;
}
.settings-container.start-my-day section h4 .ei.collapse {
  display: none;
}
.settings-container.start-my-day section > .content {
  overflow: hidden;
  will-change: padding-top;
  transition: padding-top 0.2s ease-out;
}
.settings-container.start-my-day section.expanded h4 .ei.expand {
  display: none;
}
.settings-container.start-my-day section.expanded h4 .ei.collapse {
  display: block;
}
.settings-container.start-my-day section.expanded > .content {
  border-top: 1px solid transparent;
  overflow: visible;
}
.settings-container.start-my-day ul,
.settings-container.start-my-day ul li {
  list-style: none;
  list-style-type: none;
}
.settings-container.start-my-day ul li {
  border-top: 1px solid transparent;
}
.settings-container.start-my-day ul.fields-list {
  border-bottom: 1px solid transparent;
}
.settings-container.start-my-day ul.fields-list:first-child li:first-child {
  border-top: 0 none;
}
.settings-container.start-my-day .field.upload {
  border: 1px solid transparent;
}
.settings-container.start-my-day .field.upload audio {
  display: inline-block;
  vertical-align: middle;
}
.settings-container.start-my-day .field.upload.audio {
  border: 0 none;
}
.settings-container.start-my-day .field.picture-widget .preview {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  overflow: hidden;
  border: 1px solid transparent;
}
.settings-container.start-my-day .field.picture-widget .preview img {
  display: block;
  transform: translate(-50%, -50%);
}
.settings-container.start-my-day .square-button {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  border: 1px solid transparent;
  text-align: center;
  outline: 0 none;
}
.settings-container.start-my-day .square-button input {
  opacity: 0;
}
.settings-container.start-my-day label.warning {
  display: none;
}
.settings-container.start-my-day .field.text-widget .preview {
  display: inline-block;
  vertical-align: middle;
  border: 1px solid transparent;
  overflow: hidden;
  background-size: cover;
  background-position: center;
}
.settings-container.start-my-day .field.slide-editor img.hidden {
  display: none;
}
.settings-container.start-my-day .field.slide-editor .preview {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  border: 1px solid transparent;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  position: relative;
}
.settings-container.start-my-day .field.slide-editor .preview .ei {
  display: inline-block;
  opacity: 1;
}
.settings-container.start-my-day .field.slide-editor .preview span {
  display: inline-block;
  transform: translateX(-50%);
}
.settings-container.start-my-day .field.slide-editor .field {
  display: inline-block;
  vertical-align: middle;
}
.settings-container.start-my-day .field.slide-editor .field label {
  text-align: center;
}

.settings-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0;
}
.settings-container > nav {
  padding: 5px 5px 0 5px;
}
.settings-container > nav .button {
  position: static;
  padding: 0 8px;
  margin: 0 5px 0 0;
  border-radius: 8px 8px 0 0;
  margin-bottom: -2px;
}
.settings-container.logs-container header {
  position: static;
  padding: 0 10px;
  display: block;
}
.settings-container.logs-container header .field.select {
  width: auto;
  display: inline-block;
  margin-right: 8px;
}
.settings-container.logs-container header .field.select.display-inline-buttons .value-inline .option {
  border-color: #12378F;
  padding: 0 20px;
}
.settings-container.start-my-day {
  padding: 8px;
}
.settings-container.start-my-day section {
  margin-bottom: 10px;
  padding: 0;
  border-radius: 8px;
}
.settings-container.start-my-day section h4 .ei {
  float: right;
  padding: 0 8px;
}
.settings-container.start-my-day section > .content {
  height: 0;
  padding-top: 0;
  padding-left: 8px;
  padding-right: 8px;
}
.settings-container.start-my-day section.expanded > .content {
  height: auto;
  padding-top: 10px;
}
.settings-container.start-my-day h4 {
  margin: 0;
  padding: 10px 10px 12px;
}
.settings-container.start-my-day img {
  max-width: 80px;
  max-height: 80px;
}
.settings-container.start-my-day ul,
.settings-container.start-my-day ul li {
  margin: 0;
  padding: 0;
}
.settings-container.start-my-day ul li {
  padding: 10px 10px 1px;
}
.settings-container.start-my-day ul li:nth-child(1) {
  z-index: 29;
}
.settings-container.start-my-day ul li:nth-child(2) {
  z-index: 28;
}
.settings-container.start-my-day ul li:nth-child(3) {
  z-index: 27;
}
.settings-container.start-my-day ul li:nth-child(4) {
  z-index: 26;
}
.settings-container.start-my-day ul li:nth-child(5) {
  z-index: 25;
}
.settings-container.start-my-day ul li:nth-child(6) {
  z-index: 24;
}
.settings-container.start-my-day ul li:nth-child(7) {
  z-index: 23;
}
.settings-container.start-my-day ul li:nth-child(8) {
  z-index: 22;
}
.settings-container.start-my-day ul li:nth-child(9) {
  z-index: 21;
}
.settings-container.start-my-day ul li:nth-child(10) {
  z-index: 20;
}
.settings-container.start-my-day ul li:nth-child(11) {
  z-index: 19;
}
.settings-container.start-my-day ul li:nth-child(12) {
  z-index: 18;
}
.settings-container.start-my-day ul li:nth-child(13) {
  z-index: 17;
}
.settings-container.start-my-day ul li:nth-child(14) {
  z-index: 16;
}
.settings-container.start-my-day ul li:nth-child(15) {
  z-index: 15;
}
.settings-container.start-my-day ul li:nth-child(16) {
  z-index: 14;
}
.settings-container.start-my-day ul li:nth-child(17) {
  z-index: 13;
}
.settings-container.start-my-day ul li:nth-child(18) {
  z-index: 12;
}
.settings-container.start-my-day ul li:nth-child(19) {
  z-index: 11;
}
.settings-container.start-my-day ul li:nth-child(20) {
  z-index: 10;
}
.settings-container.start-my-day ul li:nth-child(21) {
  z-index: 9;
}
.settings-container.start-my-day ul li:nth-child(22) {
  z-index: 8;
}
.settings-container.start-my-day ul li:nth-child(23) {
  z-index: 7;
}
.settings-container.start-my-day ul li:nth-child(24) {
  z-index: 6;
}
.settings-container.start-my-day ul li:nth-child(25) {
  z-index: 5;
}
.settings-container.start-my-day ul li:nth-child(26) {
  z-index: 4;
}
.settings-container.start-my-day ul li:nth-child(27) {
  z-index: 3;
}
.settings-container.start-my-day ul li:nth-child(28) {
  z-index: 2;
}
.settings-container.start-my-day ul li:nth-child(29) {
  z-index: 1;
}
.settings-container.start-my-day ul.fields-list li {
  padding-right: 60px;
  position: relative;
}
.settings-container.start-my-day ul.fields-list li.upload {
  padding: 10px;
}
.settings-container.start-my-day ul.fields-list li > button {
  position: absolute;
  margin: 0;
  right: 10px;
  top: 10px;
}
.settings-container.start-my-day ul.fields-list li.draggable {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  padding-right: 0;
}
.settings-container.start-my-day ul.fields-list li.draggable > .drag-handle {
  flex-shrink: 0;
  flex-grow: 0;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #3A506B;
  cursor: ns-resize;
}
.settings-container.start-my-day ul.fields-list li.draggable > .content {
  flex-grow: 1;
  padding-right: 60px;
  position: relative;
}
.settings-container.start-my-day ul.fields-list li.draggable > .content > button {
  position: absolute;
  margin: 0;
  right: 10px;
  top: 10px;
}
.settings-container.start-my-day ul.fields-list:first-child li:first-child {
  padding-top: 0;
}
.settings-container.start-my-day ul.fields-list:first-child li:first-child > button {
  top: 0;
}
.settings-container.start-my-day .field.upload {
  margin: 0 0 10px 0;
  padding: 10px;
  border-radius: 8px;
}
.settings-container.start-my-day .field.upload.audio {
  padding: 0;
}
.settings-container.start-my-day .field.picture-widget .preview {
  width: 80px;
  height: 80px;
  border-radius: 8px;
}
.settings-container.start-my-day .field.picture-widget .preview img {
  position: absolute;
  top: 39px;
  left: 39px;
  max-width: 80px;
  max-height: 80px;
}
.settings-container.start-my-day .square-button {
  padding-top: 4px;
  margin-left: 7px;
  margin-bottom: 7px;
  width: 60px;
  height: 60px;
  border-radius: 8px;
}
.settings-container.start-my-day .square-button .ei {
  display: block;
  text-align: center;
  font-size: 32px;
}
.settings-container.start-my-day .square-button input {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}
.settings-container.start-my-day footer {
  padding: 10px 10px 2px;
}
.settings-container.start-my-day footer h5 {
  margin: 0 0 10px 0;
}
.settings-container.start-my-day label.warning {
  margin-top: 5px;
}
.settings-container.start-my-day.show-upload-warning label.warning,
.settings-container.start-my-day .show-upload-warning label.warning {
  display: block;
}
.settings-container.start-my-day .field.text-widget .preview {
  height: 60px;
  line-height: 60px;
  padding: 0 20px;
  border-radius: 8px;
}
.settings-container.start-my-day .field.slide-editor .preview {
  width: 100px;
  height: 100px;
  border-radius: 8px;
}
.settings-container.start-my-day .field.slide-editor .preview .ei {
  font-size: 24px;
  margin-top: 8px;
}
.settings-container.start-my-day .field.slide-editor .preview span {
  position: absolute;
  bottom: 5px;
  left: 50%;
  padding: 5px 10px;
  border-radius: 8px;
}

.settings-container {
  background: #e6e6e6;
}
.settings-container > nav {
  background: #12378F;
  border-bottom-color: #071434;
}
.settings-container > nav .button {
  background: #e6e6e6;
  border-right-color: #071434;
  border-bottom-color: #071434;
  color: #000018;
}
.settings-container > nav .button.active {
  border-bottom-color: #f5f5f5;
  background: #f5f5f5;
}
.settings-container.start-my-day section {
  border-bottom-color: transparent;
  border-top-color: #40a3ea;
}
.settings-container.start-my-day section.expanded > .content {
  border-top-color: transparent;
}
.settings-container.start-my-day ul li {
  border-top-color: transparent;
}
.settings-container.start-my-day ul li:nth-child(even) {
  background: rgba(18, 55, 143, 0.05);
}
.settings-container.start-my-day ul.fields-list {
  border-bottom-color: transparent;
}
.settings-container.start-my-day .field.upload {
  border-color: transparent;
}
.settings-container.start-my-day .field.picture-widget .preview {
  border-color: transparent;
  background: #fff;
}
.settings-container.start-my-day .square-button {
  border-color: transparent;
  box-shadow: 0px 2px 1px 0 rgba(0, 0, 0, 0.5);
  color: #000018;
  background: #fff;
}
.settings-container.start-my-day .square-button:focus {
  border-color: #5bc0de;
}
.settings-container.start-my-day .file-input-container {
  color: #40a3ea;
}
.settings-container.start-my-day .file-picture-chooser {
  color: #5cb85c;
}
.settings-container.start-my-day label.warning {
  color: #f0ad4e;
}
.settings-container.start-my-day .field.text-widget .preview {
  border-color: transparent;
}
.settings-container.start-my-day .field.slide-editor .preview {
  border-color: transparent;
  color: white;
}
.settings-container.start-my-day .field.slide-editor .preview .ei {
  color: #777;
}
.settings-container.start-my-day .field.slide-editor .preview span {
  text-shadow: 0 1px 0 black, 0 -1px 0 black, 1px 0 0 black, -1px 0 0 black, 1px 1px 0 black, -1px 1px 0 black, 1px -1px 0 black, -1px -1px 0 black;
  background: rgba(0, 0, 24, 0.5);
}
.settings-container.start-my-day .field.slide-editor .preview.opacity-0 span {
  background: transparent;
}
.settings-container.start-my-day .field.slide-editor .preview.opacity-25 span {
  background: rgba(0, 0, 24, 0.25);
}
.settings-container.start-my-day .field.slide-editor .preview.opacity-50 span {
  background: rgba(0, 0, 24, 0.5);
}
.settings-container.start-my-day .field.slide-editor .preview.opacity-75 span {
  background: rgba(0, 0, 24, 0.75);
}
.settings-container.start-my-day .field.slide-editor .preview.opacity-100 span {
  background: #000018;
}

.generic-text-shadow {
  text-shadow: 0 1px 0 black, 0 -1px 0 black, 1px 0 0 black, -1px 0 0 black, 1px 1px 0 black, -1px 1px 0 black, 1px -1px 0 black, -1px -1px 0 black;
}

.settings-container.start-my-day section h4 .ei {
  font-size: 16px;
}
.settings-container.start-my-day h4 {
  margin: 0;
  padding: 10px 10px 12px;
}
.settings-container.start-my-day img {
  max-width: 80px;
  max-height: 80px;
}
.settings-container.start-my-day .field.slide-editor .preview {
  font-size: 16px;
  font-weight: bold;
}
.settings-container.start-my-day .field.slide-editor .preview.size-small {
  font-size: 14px;
}
.settings-container.start-my-day .field.slide-editor .preview.size-normal {
  font-size: 16px;
}
.settings-container.start-my-day .field.slide-editor .preview.size-large {
  font-size: 20px;
}
.settings-container.start-my-day .field.slide-editor .preview.size-xlarge {
  font-size: 24px;
}
.settings-container.start-my-day .field.slide-editor .preview.size-xxlarge {
  font-size: 28px;
}
.settings-container.start-my-day .field.slide-editor .preview.size-xxxlarge {
  font-size: 32px;
}
.settings-container.start-my-day .field.slide-editor .preview.size-xxxxlarge {
  font-size: 36px;
}

* {
  outline: 0 none !important;
}

.wide-popup .panning-layer > .list-container > h3 {
  text-transform: none;
}
.wide-popup .panning-layer > .list-container > h3 .edit-filters,
.wide-popup .panning-layer > .list-container > h3 .edit-list {
  display: none;
}
.wide-popup .panning-layer .list-container > h3 .edit-filters,
.wide-popup .panning-layer .list-container > h3 .edit-list {
  display: none;
}

.drop-zone {
  display: none;
  opacity: 0;
  will-change: display, opacity;
}
.drop-zone > div {
  display: none;
  overflow: visible;
  border: 0 none;
  text-align: center;
  vertical-align: top;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  background-clip: padding-box;
}
.drop-zone > div:after {
  content: "";
  display: block;
}
.drop-zone > div span:not(.pz-title) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.6);
  background-clip: padding-box;
}
.drop-zone > div span:not(.pz-title) .ei {
  display: inline-block;
}
.drop-zone > div.visible {
  display: inline-block;
}
.settings-desktops-form .drop-zone > div span {
  border: 1px solid #fff;
  border-width: 1px 0;
}

.hovering.can-drop .drop-zone {
  display: block;
}

.desktop-drop-zone {
  display: inline-block;
  position: relative;
  overflow: hidden;
  text-align: center;
  white-space: normal;
  will-change: width, margin;
  transition: width 0.15s ease-out, margin 0.15s ease-out;
}
.desktop-drop-zone > div {
  display: inline-block !important;
  text-indent: -9999px;
  will-change: width;
  position: relative;
  opacity: 1 !important;
}
.desktop-drop-zone.visible > div {
  transition: width 0.15s ease-out;
}
.desktop-drop-zone.visible.hovering > div {
  text-indent: 0;
}
.desktop-drop-zone .drop-zone > div {
  border-bottom: 0 none;
}

ul.preview-layer,
ul.preview-layer li {
  list-style: none;
  list-style-type: none;
}
ul.preview-layer li {
  display: block;
}
ul.preview-layer li h4 span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
ul.preview-layer li h4 .field-display {
  padding-left: 0 !important;
}
ul.preview-layer li h4 .field-display label {
  display: none;
}
ul.preview-layer li h4 .field-display .value-wrapper {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
ul.preview-layer li p,
ul.preview-layer li .content > div {
  display: none !important;
}
ul.preview-layer > h3 {
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
}
ul.preview-layer > h3 .day-plan-date {
  display: none;
}
ul.preview-layer > h3 span.title {
  flex-grow: 1;
}
ul.preview-layer > h3 .hide-on-preview {
  display: none;
}

.pass-through {
  pointer-events: none;
}

.list-container {
  border: 1px solid #000018;
  border-width: 3px 0;
  will-change: opacity;
}
.list-container:not(.embedded) {
  border: 0 none !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}
.list-container:not(.embedded) > .list-wrapper > ul {
  padding: 0 23px 0 8px;
}
body.mobile .list-container:not(.embedded) > .list-wrapper > ul {
  padding: 0 18px 0 8px;
}
.list-container.desktop-chooser {
  border-width: 0;
  background: transparent;
  box-shadow: none;
}
.list-container.desktop-chooser button {
  background: #fff;
  color: #12378F;
}
.path-settings-dynamic-fields .list-container.desktop-chooser button {
  background: #12378F;
  color: #fff;
}
.list-container.no-permission {
  opacity: 0.6;
}
.list-container > h3 {
  text-transform: uppercase;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}
.list-container > h3 .drop-zone > div {
  text-transform: none;
}
.list-container > h3 .drop-zone > div .ei {
  display: inline-block;
  vertical-align: middle;
}
.list-container > h3.hovering.can-drop {
  border-bottom: 0 none;
}
.list-container > h3.hovering.can-drop .drop-zone {
  display: block;
  transform: scaleY(1);
}
.list-container > h3 .ei.preview {
  display: none;
}
.list-container > h3 .edit-list {
  float: right;
  position: relative;
  overflow: visible;
}
.list-container .quick-add-form {
  flex-shrink: 0;
  flex-basis: auto;
  height: 40px;
  max-height: 0;
  padding: 0;
  overflow: hidden;
  will-change: max-height;
  transition: max-height 0.15s ease-out;
  margin-right: -5px;
}
.list-container .quick-add-form.open {
  max-height: 40px;
}
.list-container .quick-add-form .field.text {
  margin-bottom: 0;
  margin-right: 40px;
}
.list-container .quick-add-form .field.text input {
  /* do not group these rules */
}
.list-container .quick-add-form .field.text input::-webkit-input-placeholder {
  color: #777;
}
.list-container .quick-add-form .field.text input:-moz-placeholder {
  /* FF 4-18 */
  color: #777;
}
.list-container .quick-add-form .field.text input::-moz-placeholder {
  /* FF 19+ */
  color: #777;
}
.list-container .quick-add-form .field.text input:-ms-input-placeholder {
  /* IE 10+ */
  color: #777;
}
.list-container .quick-add-form > .ei {
  float: right;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 38px;
  font-size: 24px;
  color: #12378F;
}
.list-container.list-dayplan > h3 {
  white-space: normal;
}
.list-container.list-dayplan > h3 .line {
  display: flex;
}
.list-container.list-dayplan > h3 .dayplan-list-title {
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.list-container.list-dayplan > h3 .reminders {
  position: relative;
}
.list-container.list-dayplan > h3 .reminders .badge {
  position: absolute;
  top: 4px;
  right: 2px;
  padding: 1px 4px;
  border-radius: 12px;
  line-height: 11px;
  font-size: 11px;
  color: #fff;
  background: #f0ad4e;
}
.list-container.list-dayplan .day-plan-date {
  position: relative;
  text-transform: none;
  text-align: right;
  padding-right: 3px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.list-container.list-dayplan .day-plan-date .day-name-short {
  display: none;
}
@media (max-width: 420px) {
  .list-container.list-dayplan .day-plan-date .day-name {
    display: none;
  }
  .list-container.list-dayplan .day-plan-date .day-name-short {
    display: inline;
  }
}
@media (max-width: 380px) {
  .list-container.list-dayplan .day-plan-date .special-name {
    display: none;
  }
}
.list-container.list-dayplan .day-plan-date .list-time {
  white-space: normal;
}
.list-container.list-dayplan .day-plan-track li div {
  text-align: center;
}
.list-container.list-dayplan .day-plan-track li.edge.left {
  text-align: right;
}
.list-container.list-dayplan .day-plan-track li.edge.right {
  text-align: left;
}
.list-container.list-dayplan .time-slot > ul {
  overflow-y: visible;
  overflow: visible;
}
.list-container.list-dayplan .time-slot .zone-header.month-divider, .list-container.list-dayplan .time-slot .zone-header.year-divider {
  font-size: 16px;
  color: #21ac66;
  padding: 16px 0 8px;
}
.list-container.list-dayplan .time-slot.productivity {
  position: relative;
  border: 0 none transparent;
}
.list-container.list-dayplan .time-slot.productivity.sleep.no-items {
  display: none;
}
.list-container.list-dayplan .time-slot.productivity.sleep.no-items.can-drop-hint {
  display: block;
  border: 0 none;
}
.list-container.list-dayplan .time-slot.productivity.can-drop-hint {
  border: 1px solid transparent;
}
.list-container.list-dayplan .time-slot.productivity > ul.no-items {
  overflow: visible;
}
.list-container.list-dayplan .time-slot.productivity .zone-header {
  position: relative;
  white-space: normal;
}
.list-container.list-dayplan .time-slot.productivity .zone-header .drop-zone > div {
  text-align: left;
}
.list-container.list-dayplan .time-slot.productivity .zone-header.day-divider {
  font-size: 13px;
  opacity: 0.8;
}
@media (max-width: 480px) {
  .list-container.list-dayplan .time-slot.productivity .zone-header.day-divider {
    font-size: 12px;
  }
}
@media (max-width: 480px) {
  .list-container.list-dayplan .time-slot.productivity .zone-header.day-divider {
    font-size: 12px;
  }
}
.list-container.list-dayplan .drop-clock {
  display: none;
  text-align: center;
  transform: translateZ(4px);
}
.list-container.list-dayplan .drop-clock .ei {
  vertical-align: middle;
}
.list-container.list-dayplan .drop-clock div {
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  transition: width 0.15s ease-out;
}
.list-container.list-dayplan .drop-clock div span {
  display: block;
  white-space: normal;
  text-align: center;
}
.list-container.list-dayplan .drop-clock.visible {
  display: block;
}
.list-container.list-dayplan.period-week {
  width: 90%;
  min-width: 1750px;
}
@media (orientation: landscape), (min-width: 500px) {
  .dnd-container .list-container.list-dayplan.period-week.vertical-container {
    max-width: 1750px;
  }
}
@media (max-width: 1800px) {
  .list-container.list-dayplan.period-week > h3,
  .list-container.list-dayplan.period-week > .swipe-to-open {
    max-width: 100vw;
    position: sticky;
    left: 0;
  }
  .list-container.list-dayplan.period-week > h3 {
    display: flex;
    flex-shrink: 0;
  }
  .list-container.list-dayplan.period-week > .list-wrapper {
    padding-top: 0;
    height: calc(100% - 88px);
  }
}
.list-container.list-dayplan.period-week > .list-wrapper {
  width: 100%;
  overflow: visible;
}
.list-container.list-dayplan.period-week .all-days {
  display: flex;
}
.list-container.list-dayplan.period-week .all-days .all-day-pz {
  width: 14.28%;
  flex-grow: 0;
  flex-shrink: 0;
  box-shadow: unset;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-right: 1px solid rgba(0, 0, 0, 0.2);
}
.list-container.list-dayplan.period-week .all-days .all-day-pz:last-child {
  border-right: 0 none;
}
.list-container.list-dayplan.period-week .week-container {
  width: calc(100% + 15px);
  height: calc(100% - 34px);
  padding-right: 15px;
  position: relative;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.list-container.list-dayplan.period-week .week-container .timeblocks-pz.list-container.embedded:not(.collapsed) {
  left: 42px;
  right: 0 !important;
}
.list-container.list-dayplan.period-week .week-container > ul,
.list-container.list-dayplan.period-week .week-container > .shining-boxes {
  height: auto;
  width: 14.28%;
  display: block;
  float: left;
  padding-right: 0;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  overflow: visible;
}
.list-container.list-dayplan.period-week .week-container > ul:last-of-type,
.list-container.list-dayplan.period-week .week-container > .shining-boxes:last-of-type {
  border-right: 0 none;
}
.list-container.list-dayplan.period-week .week-container > ul li.time-slots,
.list-container.list-dayplan.period-week .week-container > .shining-boxes li.time-slots {
  margin: 0;
}
.list-container.list-dayplan.period-week .week-container > ul.view-pz,
.list-container.list-dayplan.period-week .week-container > .shining-boxes.view-pz {
  padding: 0 4px;
  border-right: 0 none;
  overflow: visible;
}
.list-container.list-dayplan.period-week .week-container > .shining-boxes {
  padding-right: 8px;
}
body.mobile .list-container.list-dayplan.period-week.drag-started .week-container {
  overflow: hidden;
  -webkit-overflow-scrolling: unset;
}
.list-container .action-container {
  border: 0 solid transparent;
  border-width: 3px 1px 1px;
  position: relative;
}
.list-container .action-container.habits {
  border-width: 2px 0 0;
}
.list-container .action-container > h4 {
  overflow: hidden;
  text-overflow: ellipsis;
}
.list-container .action-container > h4 .ei {
  float: right;
  position: relative;
  pointer-events: none;
}
.list-container .action-container > h4 .ei.collapse {
  display: none;
}
.list-container .action-container ul {
  display: none;
}
.list-container .action-container.expanded > h4 .ei.collapse {
  display: block;
}
.list-container .action-container.expanded > h4 .ei.expand {
  display: none;
}
.list-container .action-container.expanded ul {
  display: block;
}
.list-container .action-container .header-drop-zone {
  overflow: hidden;
}
.list-container .action-container .header-drop-zone.hovering .drop-zone {
  display: block;
  transform: scaleY(1);
}
.list-container .action-container .header-drop-zone .drop-zone > div .ei {
  display: inline-block;
  vertical-align: middle;
}

.shadow {
  pointer-events: none;
  transform: translateZ(4px);
  backface-visibility: hidden;
}

.list-wrapper {
  position: relative;
  overflow: hidden;
}

.list-container.embedded {
  border-width: 1px 2px;
  border-color: rgba(0, 0, 0, 0.2);
}
.list-container.embedded > h3 {
  display: flex;
}
.list-container.embedded > h3 span {
  flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.actions-container {
  overflow: visible;
  transform: translateZ(5px) scale(1);
  transition: transform 0.15s ease-out;
  backface-visibility: hidden;
}
.actions-container > .ei {
  text-align: center;
}
.actions-container .backdrop {
  display: none;
  background: rgba(0, 0, 0, 0.1);
}
.actions-container.visible > .ei.open {
  display: none;
}
.actions-container.visible > .ei.close,
.actions-container.visible .backdrop {
  display: block;
}

.list-container.embedded {
  background-clip: padding-box;
}
.list-container.embedded h3 > .actions-container {
  display: none;
}
.list-container.embedded .actions-container > .ei {
  box-shadow: unset;
  border: 1px solid #e6e6e6;
  border-width: 1px 1px 2px 1px;
}
.list-container.embedded.collapsed > .actions-container {
  display: none;
}
.list-container.embedded.collapsed h3 > .actions-container {
  display: block;
}
.list-container.embedded.collapsed > .list-wrapper {
  display: none;
}

.list-container.just-inserted {
  opacity: 0;
}
.list-container.deleted {
  border: 0 none;
}
.list-container.animate {
  will-change: width, opacity;
  transition: width 0.2s ease-out, opacity 0.2s ease-out;
}
.list-container.animate > .actions-container {
  transition: none;
  transform: translateZ(5px) scale(0);
}

.list-context-menu {
  display: flex;
}
@media (max-width: 460px) {
  .list-context-menu {
    display: block;
  }
}
.list-context-menu button {
  display: block;
  text-align: left;
}
.list-context-menu button .ei {
  vertical-align: middle;
  position: relative;
}

.swipe-to-open {
  position: absolute;
  top: 44px;
  left: 0;
  right: 0;
  z-index: 2;
  overflow: visible;
  will-change: height;
}
.swipe-to-open > .content {
  overflow: hidden;
  height: 0;
  will-change: height;
}
.swipe-to-open .arrow-container {
  display: block;
  backface-visibility: hidden;
  width: 100%;
  height: 24px;
  text-align: center;
}
.swipe-to-open .arrow-container .arrow {
  backface-visibility: visible;
  will-change: transform;
  font-size: 20px;
  line-height: 16px;
  display: inline-block;
}
.swipe-to-open .arrow-container.large {
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: stretch;
}
.swipe-to-open .arrow-container.large > div {
  flex-grow: 1;
}
.swipe-to-open .arrow-container.large > div.left, .swipe-to-open .arrow-container.large > div.right {
  position: relative;
  z-index: 3;
}
.swipe-to-open .arrow-container.large > div.left .ei, .swipe-to-open .arrow-container.large > div.right .ei {
  display: inline-block;
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 44px;
  font-size: 24px;
}
.swipe-to-open .shadow.top {
  top: auto;
  bottom: -3px;
}
.swipe-to-open > .overlay {
  position: absolute;
  top: -4px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}

.shining-boxes {
  width: 100%;
}
.shining-boxes div,
.shining-boxes span {
  width: 100%;
  padding: 8px 8px 0;
  margin-top: 8px;
  transform: translate3d(0, 0, 0);
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.03);
}
.shining-boxes span {
  display: block;
  width: 40%;
  height: 24px;
}
.shining-boxes div div {
  opacity: 0.03;
  background-color: black;
  height: 90px;
  margin-bottom: 8px;
  will-change: opacity;
  animation: pulse 1.8s ease-in-out infinite;
}
.shining-boxes div div + div {
  animation-delay: 0.3s;
}

.new-shining-boxes {
  width: 100%;
}
.new-shining-boxes div {
  width: 100%;
  transform: translate3d(0, 0, 0);
  overflow: hidden;
  background-color: rgba(18, 55, 143, 0.25);
  opacity: 0.03;
  height: 70px;
  margin-top: 16px;
  will-change: opacity;
  animation: pulse-card 1.8s ease-in-out infinite;
}
.new-shining-boxes div + div {
  animation-delay: 0.3s;
}

@keyframes pulse {
  0% {
    opacity: 0.03;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0.03;
  }
}
@keyframes pulse-card {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 0.3;
  }
}
.drop-zone {
  height: 100%;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  right: -1px;
}
.drop-zone > div {
  width: 100%;
  height: 8px;
  border-radius: 8px;
}
.drop-zone > div:after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.drop-zone > div span:not(.pz-title) {
  border-radius: 0 0 8px 8px;
  padding: 3px 8px 1px;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -37px;
}
.drop-zone > div span:not(.pz-title) .ei {
  font-size: 16px;
  margin-right: 5px;
}
.settings-desktops-form .drop-zone > div {
  width: calc(50% - 4px);
}
.settings-desktops-form .drop-zone > div:after {
  right: 50%;
}
.settings-desktops-form .drop-zone > div:first-child {
  margin-right: 8px;
}
.settings-desktops-form .drop-zone > div.full-width {
  width: 100% !important;
  margin-right: 0;
}
.settings-desktops-form .drop-zone > div span:not(.pz-title) {
  border-radius: 12px;
  padding: 5px 10px;
  position: absolute;
  top: -11px;
  left: 25%;
  transform: translateX(-50%);
}
.settings-desktops-form .drop-zone > div.full-width span:not(.pz-title) {
  left: 50%;
}
.settings-desktops-form .drop-zone > div.clone:after {
  right: 0;
  left: 50%;
}
.settings-desktops-form .drop-zone > div.clone span:not(.pz-title) {
  left: 75%;
}

.desktop-drop-zone {
  z-index: 2;
  top: 0;
  height: 100%;
  width: 0;
}
.desktop-drop-zone > div {
  width: 0;
  height: 100%;
  padding-top: 150px;
  border-radius: 8px;
}
.desktop-drop-zone .ei {
  font-size: 24px;
}
.desktop-drop-zone.visible {
  width: 32px;
  margin: 0 -16px;
}
.desktop-drop-zone.visible > div {
  width: 4px;
}
.desktop-drop-zone.visible:first-child > div {
  left: 5px;
}
.desktop-drop-zone.visible.hovering > div {
  width: 16px;
}
.desktop-drop-zone.visible.hovering:first-child {
  width: 99px;
}
.desktop-drop-zone.visible.hovering:last-child {
  margin-right: -16px;
}

.desktop-page.dnd-container .all-day-pz .timeblocks-pz.list-container.embedded .list-wrapper {
  max-height: 70vh;
  overflow-y: auto;
}

.dnd-container .vertical-container.list-dayplan ul.time-block {
  padding-left: 4px;
  padding-right: 4px;
  padding-bottom: 4px;
  overflow: visible;
  min-height: 24px;
  position: absolute;
  right: auto;
}
.dnd-container .vertical-container.list-dayplan ul.time-block.sublist {
  transition: left 0.15s ease-out, right 0.15s ease-out;
}
.dnd-container .vertical-container.list-dayplan ul.time-block.sublist:not(.collapsed) {
  width: auto !important;
  height: auto !important;
  left: 0 !important;
  right: -4px !important;
  z-index: 2;
}
.dnd-container .vertical-container.list-dayplan .timeblocks-pz {
  box-shadow: unset;
}
.dnd-container .vertical-container.list-dayplan .timeblocks-pz > .list-wrapper {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  border-radius: 0 0 8px 8px;
}
.dnd-container .vertical-container.list-dayplan .timeblocks-pz > h3 {
  z-index: 2;
}
.dnd-container .vertical-container.list-dayplan .timeblocks-pz.list-container .action-container.habits {
  margin-top: 0;
  margin-bottom: 8px;
}
.dnd-container .vertical-container.list-dayplan .timeblocks-pz.list-container.embedded.drag-started {
  z-index: 65535;
}
.dnd-container .vertical-container.list-dayplan .timeblocks-pz.list-container.embedded:not(.collapsed) {
  right: 8px !important;
  z-index: 65535;
}
.dnd-container .vertical-container.list-dayplan .timeblocks-pz.list-container.embedded:not(.collapsed) > .list-wrapper > ul {
  padding-bottom: 2px;
}
.dnd-container .vertical-container.list-dayplan .timeblocks-pz.list-container.embedded:not(.collapsed) > h3 {
  margin-left: -42px;
  margin-right: -8px;
  background-color: inherit;
  border-radius: 0;
  transition: unset;
}
.dnd-container .vertical-container.list-dayplan .timeblocks-pz.list-container.embedded {
  position: absolute;
  width: auto;
  margin: 0;
}
.dnd-container .vertical-container.list-dayplan .timeblocks-pz.list-container.embedded.collapsed {
  height: 30px !important;
  border: 0 none !important;
  left: 0 !important;
  right: 0 !important;
  box-shadow: unset;
  border-radius: 0;
}
.dnd-container .vertical-container.list-dayplan .timeblocks-pz.list-container.embedded > h3 {
  height: 30px;
  line-height: 30px;
  background: transparent;
  padding: 0 4px;
}
.dnd-container .vertical-container.list-dayplan .all-day-pz {
  display: block;
  position: relative;
  z-index: 2;
  height: 34px;
  padding: 2px 0 0;
  width: calc(100% - 15px);
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
}
.dnd-container .vertical-container.list-dayplan .all-day-pz + ul {
  height: calc(100% - 34px);
}
.dnd-container .vertical-container.list-dayplan .all-day-pz .timeblocks-pz.list-container.embedded {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
}
.dnd-container .vertical-container.list-dayplan .all-day-pz .timeblocks-pz.list-container.embedded > h3 {
  height: 32px;
  line-height: 32px;
}
.dnd-container .vertical-container.list-dayplan .all-day-pz .timeblocks-pz.list-container.embedded.collapsed {
  background: transparent;
  height: 32px !important;
}
.dnd-container .vertical-container.list-dayplan .all-day-pz .timeblocks-pz.list-container.embedded:not(.collapsed) {
  width: calc(100% - 50px);
  left: 42px;
}
.dnd-container .vertical-container.list-dayplan .all-day-pz h3 {
  overflow: visible;
}
.dnd-container .vertical-container.list-dayplan .all-day-pz h3 .habits {
  flex-shrink: 0;
  flex-grow: 0;
  font-size: 11px;
  color: #000018;
  text-transform: none;
  padding: 0 8px;
}
.dnd-container .vertical-container.list-dayplan .all-day-pz h3 > ul {
  flex-grow: 0;
  flex-shrink: 1;
  min-height: auto;
  padding: 0;
  width: auto;
  min-width: 74px;
  overflow: hidden;
  border-radius: 5px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
}
@media (max-width: 480px) {
  .dnd-container .vertical-container.list-dayplan .all-day-pz h3 > ul {
    width: 74px;
    flex-shrink: 0;
  }
  .dnd-container .vertical-container.list-dayplan .all-day-pz h3 > ul > li .content {
    white-space: nowrap;
  }
  .dnd-container .vertical-container.list-dayplan .all-day-pz h3 > ul > li .content .float-right {
    display: none;
  }
}
.dnd-container .vertical-container.list-dayplan .all-day-pz h3 > ul > li {
  font-size: 11px;
  color: #000018;
  text-transform: none;
}
.dnd-container .vertical-container.list-dayplan .all-day-pz h3 > ul > li:before {
  display: none;
}
.dnd-container .vertical-container.list-dayplan .all-day-pz h3 > ul > li .content {
  padding: 0 4px;
  box-shadow: unset;
  overflow: hidden;
}
.dnd-container .vertical-container.list-dayplan .all-day-pz h3 > ul > li .content .float-right {
  margin-top: 3px;
}
.dnd-container .vertical-container.list-dayplan li.embedded-list-item.new-layout.timeblocks-sublist {
  height: 100%;
  padding-bottom: 0;
}
.dnd-container .vertical-container.list-dayplan li.embedded-list-item.new-layout.timeblocks-sublist .list-container.embedded {
  margin-top: 0;
  height: 100%;
}
.dnd-container .vertical-container.list-dayplan li.embedded-list-item.new-layout.timeblocks-sublist .list-container.embedded > h3 {
  padding: 0 4px 0 8px;
}
.dnd-container .vertical-container.list-dayplan li.embedded-list-item.new-layout.timeblocks-sublist .list-container.embedded.collapsed {
  background: #fff;
  border-width: 0;
  border-left-width: 4px;
  overflow: hidden;
}
.dnd-container .vertical-container.list-dayplan li.embedded-list-item.new-layout.timeblocks-sublist .list-container.embedded.collapsed > h3 {
  margin-bottom: 0;
  padding-left: 4px;
}
.dnd-container .vertical-container.list-dayplan li.embedded-list-item.new-layout.timeblocks-sublist .list-container.embedded.collapsed > h3 span {
  font-weight: normal;
}
.dnd-container .vertical-container.list-dayplan li.embedded-list-item.new-layout.timeblocks-sublist .list-container.embedded.collapsed > h3 .add-default-item {
  color: inherit;
}
.dnd-container .vertical-container.list-dayplan li.embedded-list-item.new-layout.timeblocks-sublist .list-container.embedded .timeblocks-message {
  padding: 6px;
  border-top: 1px solid transparent;
  position: relative;
  height: calc(100% - 36px);
}
.dnd-container .vertical-container.list-dayplan li.embedded-list-item.new-layout.timeblocks-sublist .list-container.embedded .timeblocks-message .drop-zone {
  height: 100%;
}
.dnd-container .vertical-container.list-dayplan .timeblock-is-medium li.embedded-list-item.new-layout.timeblocks-sublist .list-container.embedded > h3 {
  height: 100%;
}
.dnd-container .vertical-container.list-dayplan .time-slots {
  margin: 0 -8px;
  background-color: #fff;
}
.dnd-container .vertical-container.list-dayplan .time-slots > ul {
  position: relative;
  padding: 0;
  margin: 0;
  width: auto;
  height: auto;
  overflow: visible;
}
.dnd-container .vertical-container.list-dayplan .time-slots > ul li.first-in-zone > .drop-zone {
  margin-top: 30px;
}
.dnd-container .vertical-container.list-dayplan .time-slots span.dnd-label {
  left: 37px;
}
.dnd-container .vertical-container.list-dayplan .time-slots .items-container {
  position: absolute;
  right: 4px;
  top: 0;
}
.dnd-container .vertical-container.list-dayplan .time-slots .items-container.has-expanded-sublist {
  z-index: 60000;
}
.dnd-container .vertical-container.list-dayplan .time-slots .items-container.has-expanded-sublist > .time-block.sublist:not(.collapsed) {
  z-index: 10000 !important;
}
.dnd-container .vertical-container.list-dayplan .time-slots .timeslot-header {
  display: inline-flex;
  flex-direction: column;
  line-height: 14px;
  vertical-align: top;
}
.dnd-container .vertical-container.list-dayplan .time-slots .timeslot-header span {
  display: inline-block;
  color: #fff;
  border-radius: 12px;
  padding: 0 5px;
  line-height: 16px;
  align-self: center;
}

.list-item-goals {
  padding: 4px 8px;
  font-size: 11px;
}
.list-item-goals svg {
  margin: -3px 8px 0 0;
}

.field-display.text .actions-line,
.field-display.text p.actions-line {
  display: flex;
  padding-top: 2px;
  flex-direction: row;
  align-items: center;
  justify-content: stretch;
  margin: 0;
  padding: 0 8px 4px;
}
.field-display.text .actions-line a,
.field-display.text p.actions-line a {
  text-decoration: none;
}

ul.preview-layer li h4 {
  margin: 0;
  padding: 3px 3px 3px 8px;
}
ul.preview-layer > h3 {
  margin: 0;
  padding: 5px;
  border-radius: 8px;
  max-height: 36px;
}
ul.preview-layer > h3 .actions-container2 {
  display: none;
}
ul.preview-layer > h3 > span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.pre-list-drop-zone {
  position: relative;
  z-index: 2;
  height: 16px;
  margin-bottom: -16px;
}
.pre-list-drop-zone.hovering.can-drop .drop-zone {
  display: block;
}

.list-container {
  border-radius: 8px;
}
.list-container.desktop-chooser > .content {
  padding: 38px 15px;
}
.list-container.desktop-chooser > .content > button {
  width: 100%;
  margin-top: 15px;
}
.list-container.desktop-chooser:before {
  display: none;
}
.list-container > h3 {
  line-height: 34px;
  margin: 0;
  padding: 0 8px 0 12px;
  height: 36px;
  border-radius: 8px 8px 0 0;
  display: flex;
  align-items: center;
}
.list-container > h3 .drop-zone {
  padding-top: 0;
  right: 0;
}
.list-container > h3 .drop-zone > div {
  height: 36px;
  padding: 0;
  border-radius: 4px;
}
.list-container > h3 .drop-zone > div .ei {
  font-size: 24px;
  margin-right: 10px;
}
.list-container > h3 span.title,
.list-container > h3 span.title small {
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.list-container > h3 div.progress {
  flex-grow: 0;
  flex-shrink: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 11px;
  padding-right: 8px;
}
.list-container.timeblocks-pz.embedded > h3 {
  border-radius: 0;
}
.list-container.timeblocks-pz.embedded > h3 .pz-items-hint {
  margin: 0 -4px;
  padding: 0;
  flex-grow: 0;
  flex-shrink: 0;
  overflow: visible;
}
.list-container.timeblocks-pz.embedded > h3 .pz-items-hint .embedded-list-arrow {
  margin: 0;
}
.list-container.timeblocks-pz.embedded > h3 .pz-items-hint small.badge.items-count {
  padding: 4px 8px;
  border-radius: 20px;
  align-self: center;
  text-align: center;
  margin: 0 8px 0 0;
  font-size: 11px;
  line-height: 11px;
  display: inline-block;
  transform-origin: center center;
  transform: "scale(1)";
  will-change: transform;
  transition: transform 0.2s ease-out;
}
.list-container.timeblocks-pz.embedded > h3 .pz-items-hint small.badge.items-count.shadow {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
}
.list-container.timeblocks-pz.embedded > h3 .pz-items-hint small.badge.items-count.gte10 {
  padding: 4px 3px;
}
.list-container:not(.embedded) > h3 {
  height: 44px;
  line-height: 44px;
}
.list-container:not(.embedded) > h3 .ei.clone {
  padding-top: 12px;
}
.list-container:not(.embedded) > h3 .actions-container2 {
  height: 44px;
}
.list-container.collapsed > h3 {
  border-radius: 6px;
}
.list-container:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.list-container.list-dayplan {
  display: inline-flex;
  flex-direction: column;
}
.list-container.list-dayplan > h3 {
  line-height: 44px;
}
.list-container.list-dayplan > h3 .edit-filters {
  padding-top: 6px;
}
.list-container.list-dayplan > h3 .collapse-icon.inline {
  margin-top: 0;
  padding-top: 14px;
}
.list-container.list-dayplan > h3 .collapse-icon.inline:after {
  bottom: 0;
  right: -10px;
}
.list-container.list-dayplan .day-plan-date {
  padding-top: 3px;
}
.list-container.list-dayplan .day-plan-date > div {
  text-align: center;
}
.list-container.list-dayplan .day-plan-date.show-timezone div {
  line-height: 14px;
}
.list-container.list-dayplan > .list-wrapper {
  padding-top: 44px;
  padding-bottom: 0 !important;
  flex-grow: 1;
  will-change: padding-top;
}
.list-container.list-dayplan > .list-wrapper.infinite-view {
  padding-top: 0;
}
.list-container.list-dayplan > .list-wrapper.infinite-view li.day-header {
  margin-top: 8px;
  padding: 4px 8px;
  background: rgba(0, 0, 0, 0.03);
  border-radius: 6px;
}
.list-container.list-dayplan > .list-wrapper.infinite-view li.navigation {
  display: flex;
  margin: 0 -8px;
  background: rgba(0, 0, 0, 0.03);
}
.list-container.list-dayplan > .list-wrapper.infinite-view li.navigation:last-of-type {
  margin-top: 8px;
}
.list-container.list-dayplan > .list-wrapper.infinite-view li.navigation span {
  flex-grow: 1;
  text-align: center;
  display: inline-block;
  padding: 8px 0;
}
.list-container.list-dayplan > .list-wrapper.infinite-view li.navigation div {
  padding: 8px 5%;
}
@media (max-width: 419px) {
  .list-container.list-dayplan > .list-wrapper.infinite-view li.navigation div {
    padding: 8px 3%;
  }
}
.list-container.list-dayplan .track.horizontal.day-plan-track ul {
  display: block;
  height: 48px;
  padding-top: 0;
}
.list-container.list-dayplan .track.horizontal.day-plan-track ul li {
  display: inline-block;
  vertical-align: middle;
  width: 56px;
  height: 100%;
  padding-top: 2px;
}
.list-container.list-dayplan .track.horizontal.day-plan-track ul li:before {
  display: none;
}
.list-container.list-dayplan .track.horizontal.day-plan-track ul li div.bullets {
  line-height: 8px;
  padding: 2px 0 6px 0;
}
.list-container.list-dayplan .track.horizontal.day-plan-track ul li div.bullets span {
  display: inline-block;
  margin: 0 1px;
  width: 6px;
  height: 6px;
  border-radius: 6px;
}
.list-container.list-dayplan .track.horizontal.day-plan-track ul li.edge {
  padding: 0 8px;
}
.list-container.list-dayplan .track.horizontal.day-plan-track + nav {
  height: 32px;
  background: #fff;
  text-align: right;
}
.list-container.list-dayplan .track.horizontal.day-plan-track + nav .ei,
.list-container.list-dayplan .track.horizontal.day-plan-track + nav span {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  height: 32px;
  font-size: 24px;
  line-height: 32px;
  padding: 0 20px;
  margin-right: 0;
}
.list-container.list-dayplan .track.horizontal.day-plan-track + nav .ei:after,
.list-container.list-dayplan .track.horizontal.day-plan-track + nav span:after {
  content: "";
  display: block;
  position: absolute;
  top: -8px;
  bottom: 0;
  left: 0;
  right: 0;
}
.list-container.list-dayplan .track.horizontal.day-plan-track.week ul li {
  width: 64px;
}
.list-container.list-dayplan .track.horizontal.day-plan-track.week ul li .weekday span,
.list-container.list-dayplan .track.horizontal.day-plan-track.week ul li .date span {
  display: inline-block;
  width: 29px;
}
.list-container.list-dayplan .track.horizontal.day-plan-track.week ul li .weekday span:first-child,
.list-container.list-dayplan .track.horizontal.day-plan-track.week ul li .date span:first-child {
  padding-right: 3px;
  text-align: right;
}
.list-container.list-dayplan .track.horizontal.day-plan-track.week ul li .weekday span:last-child,
.list-container.list-dayplan .track.horizontal.day-plan-track.week ul li .date span:last-child {
  padding-left: 3px;
  text-align: left;
}
.list-container.list-dayplan .track.horizontal.day-plan-track.week ul li .weekday span:only-child,
.list-container.list-dayplan .track.horizontal.day-plan-track.week ul li .date span:only-child {
  width: 100%;
  text-align: center;
}
.list-container.list-dayplan .time-slot.begin-end-my-day {
  padding: 0 8px 8px;
  overflow: visible;
}
.list-container.list-dayplan .time-slot > ul {
  padding: 0;
}
.list-container.list-dayplan .time-slot.productivity {
  padding: 5px;
  margin: 8px 0 0;
  border-radius: 8px;
}
.list-container.list-dayplan .time-slot.productivity.sleep.no-items.can-drop-hint {
  padding: 5px;
}
.list-container.list-dayplan .time-slot.productivity.can-drop-hint {
  padding: 4px;
}
.list-container.list-dayplan .time-slot.productivity .zone-header {
  padding: 5px 0;
}
.list-container.list-dayplan .time-slot.productivity .zone-header .drop-zone > div {
  height: 100%;
  padding: 5px;
}
.list-container.list-dayplan .time-slot.productivity > ul li:not(.zone-header) {
  display: none;
}
.list-container.list-dayplan .time-slot.productivity > ul.expanded li:not(.zone-header) {
  display: block;
}
.list-container.list-dayplan .time-slot.productivity .badge {
  display: inline-block;
  border-radius: 12px;
  font-size: 10px;
  min-width: 14px;
  height: 14px;
  line-height: 14px;
  text-align: center;
  margin: 0 0 0 2px;
  padding: 0 4px;
}
.list-container.list-dayplan .drop-clock {
  position: absolute;
  right: 15px;
  top: 50%;
  height: 100px;
  padding: 30px 10px 30px 30px;
  margin-top: -50px;
  border-radius: 100px 0 0 100px;
  z-index: 3;
}
body.mobile .list-container.list-dayplan .drop-clock {
  right: 10px;
}
.list-container.list-dayplan .drop-clock .ei {
  font-size: 38px;
}
.list-container.list-dayplan .drop-clock div {
  width: 0;
  height: 38px;
}
.list-container.list-dayplan .drop-clock div span {
  width: 100px;
  height: 38px;
}
.list-container.list-dayplan .drop-clock.visible.hovering div {
  width: 100px;
}
.list-container.list-dayplan > .list-wrapper > ul.day-plan-fade-exit {
  opacity: 1;
  transition: opacity 300ms;
}
.list-container.list-dayplan > .list-wrapper > ul.day-plan-fade-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}
.list-container.list-dayplan > .list-wrapper > ul + ul {
  position: absolute;
  top: 44px;
  right: 15px;
  left: 0;
  width: auto;
  height: calc(100% - 44px);
  overflow: hidden;
  padding-right: 8px;
  z-index: 2;
  background: #dbf3e6;
}
.list-container .action-container {
  padding: 1px 5px;
  margin-bottom: 8px;
  border-radius: 6px;
}
.list-container .action-container > h4 {
  margin: 0;
  padding: 7px 6px;
}
.list-container .action-container > h4 .ei {
  font-size: 16px;
  padding: 0 8px;
}
.list-container .action-container ul {
  height: auto;
  padding-bottom: 8px;
  margin: 0 0 8px 0;
}
.list-container .action-container.habits {
  margin-top: 8px;
}
.list-container .action-container.habits ul {
  padding: 0 8px 8px;
}
.list-container .action-container.habits.expanded ul {
  margin-bottom: 0;
}
.list-container .action-container.goal-details {
  margin-top: 8px;
}
.list-container .action-container.goal-details > h4 span .ei.no-float {
  padding: 0;
}
.list-container .action-container.goal-details ul.details {
  padding: 0 8px;
  margin-bottom: 0;
}
.list-container .action-container.goal-details ul.details li {
  margin: 4px 0;
}
.list-container .action-container .header-drop-zone {
  height: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
}
.list-container .action-container .header-drop-zone.expanded {
  height: 36px;
}
.list-container .action-container .header-drop-zone .drop-zone {
  padding-top: 0;
}
.list-container .action-container .header-drop-zone .drop-zone > div {
  height: 36px;
  padding: 4px;
  border-radius: 4px 4px 6px 6px;
}
.list-container .action-container .header-drop-zone .drop-zone > div .ei {
  font-size: 24px;
  margin-right: 10px;
}
.list-container ul li.footer {
  align-self: flex-start;
  flex-shrink: 1;
  max-width: 50%;
  height: 22px;
  padding: 2px 8px 0;
  background: #fff;
  border-radius: 0 8px;
  position: relative;
  bottom: -2px;
  left: -8px;
  display: flex;
}
.list-container ul li.footer .ei {
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 16px;
  line-height: 28px;
  vertical-align: middle;
}
.list-container ul li.footer span {
  color: #000018;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
}
.list-container.embedded .list-wrapper ul {
  overflow: visible;
}

.list-wrapper {
  z-index: 1;
  width: calc(100% + 15px);
  height: 100%;
  margin: 0 -15px 0 0;
  padding: 0 0 35px 0;
}
.tree-view .list-wrapper {
  width: 100%;
  margin: 0;
}
.list-wrapper > .shadow {
  right: 15px;
}
.list-wrapper > .shadow.bottom.new-layout {
  right: 18px;
}
body.mobile .list-wrapper {
  width: calc(100% + 10px);
  margin-right: -10px;
}
body.mobile .list-wrapper > .shadow {
  right: 10px;
}
body.mobile .list-wrapper > .shadow.bottom.new-layout {
  right: 13px;
}

.list-container.embedded > h3 .edit-filters,
.list-container.embedded > h3 .collapse-arrow {
  padding-top: 8px;
}
.list-container.embedded > h3 .collapse-arrow {
  width: 22px;
}
.list-container.embedded > h3 .collapse-arrow.ei-angle-right {
  padding-left: 7px;
}
.list-container.embedded > h3 .embedded-list-arrow {
  width: 20px;
  margin-left: -8px;
  margin-right: -6px;
}
.list-container.embedded > h3 .embedded-list-icon {
  width: 20px;
}
.list-container.embedded > h3 .embedded-list-icon.left {
  margin-left: -8px;
}
.list-container.embedded .list-wrapper {
  padding-bottom: 8px;
  height: calc(100% - 36px);
}
body.desktop .list-container.embedded .list-wrapper {
  width: 100%;
}
.list-container.embedded .list-wrapper .shadow.bottom {
  bottom: 0;
}

.dnd-container .vertical-container ul li.embedded-list-item.static-list {
  padding-bottom: 8px;
}
.dnd-container .vertical-container ul li.embedded-list-item.static-list .list-container.embedded {
  margin: 8px 0 0;
  width: 100%;
}
.dnd-container .vertical-container ul li.embedded-list-item.static-list .list-container.embedded .list-wrapper {
  border-radius: 0 0 8px 8px;
  padding: 0 5px 5px;
  width: 100%;
}

.actions-container {
  position: absolute;
  z-index: 5;
  bottom: -30px;
  right: 10px;
  width: auto;
  height: 56px;
  padding: 0;
}
.actions-container > .ei {
  display: inline-block;
  vertical-align: middle;
  width: 42px;
  height: 42px;
  margin-right: 14px;
  border-radius: 28px;
  font-size: 24px;
  line-height: 42px;
}
.actions-container > .ei.close {
  display: none;
}
.actions-container > .ei.add-item {
  margin-right: 0;
  width: 56px;
  height: 56px;
  line-height: 56px;
}
.actions-container .backdrop {
  position: absolute;
  z-index: 1;
}

.list-container.embedded .actions-container {
  bottom: -26px;
  height: 42px;
}
.list-container.embedded .actions-container > .ei {
  width: 36px;
  height: 36px;
  line-height: 32px;
  margin-right: 0;
}
.list-container.embedded.collapsed {
  margin-bottom: -8px !important;
  height: 38px;
}
.list-container.embedded.collapsed .actions-container {
  position: static;
  width: auto;
  height: auto;
  padding: 0 7px 0 0;
}
.list-container.embedded.collapsed .actions-container > .ei {
  position: relative;
  border: 0 none;
  overflow: visible;
}
.list-container.embedded.collapsed .actions-container > .ei:after {
  content: "";
  display: block;
  position: absolute;
  top: -8px;
  left: -6px;
  right: -8px;
  bottom: -6px;
  z-index: 2;
  pointer-events: all;
}

.list-container.deleted {
  width: 0;
  margin: 0;
}
@media (orientation: landscape), (min-width: 500px) {
  .list-container.deleted {
    max-width: 400px;
  }
}
.list-container.deleted.just-deleted {
  width: 0;
}

.root {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.list-context-menu button {
  height: 48px;
  line-height: 30px;
  width: 100%;
  margin-right: 0;
  margin-top: 6px;
}
.list-context-menu button .ei {
  font-size: 24px;
  margin-right: 10px;
  top: -2px;
}
.list-context-menu button.add-custom-item-action {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@media (min-width: 421px) {
  .list-context-menu .left,
  .list-context-menu .right {
    width: 50%;
  }
  .list-context-menu .left {
    padding-right: 3px;
  }
  .list-context-menu .right {
    padding-left: 3px;
  }
  .list-context-menu.single-column .left,
  .list-context-menu.single-column .right {
    width: 100%;
    padding: 0;
  }
}

.panning-layer {
  background: #e6e6e6;
}
.path-desktop .panning-layer {
  background: transparent;
}

.drop-zone > div {
  background: #5bc0de;
  color: #fff;
  border-bottom-color: rgba(0, 0, 0, 0.3);
}
.drop-zone > div span:not(.pz-title) {
  background: #5cb85c;
  border-bottom-color: rgba(0, 0, 0, 0.6);
}
.drop-zone > div span.pz-title {
  color: #fff !important;
}
.drop-zone > div.move, .drop-zone > div.clone, .drop-zone > div.hovering {
  background: #5cb85c;
  color: #fff;
}
.desktop-drop-zone .drop-zone > div.move, .desktop-drop-zone .drop-zone > div.clone, .desktop-drop-zone .drop-zone > div.hovering {
  background-color: transparent;
}
.drop-zone > div.clone {
  background: #f0ad4e;
  color: #fff;
}
.drop-zone > div.clone span {
  background: #f0ad4e;
}
.settings-desktops-form .drop-zone > div.hovering,
.settings-desktops-form .drop-zone > div.hovering span:not(.pz-title) {
  background: #80c780;
}
.settings-desktops-form .drop-zone > div.hovering.clone,
.settings-desktops-form .drop-zone > div.hovering.clone span {
  background: #f4c37d;
}
.settings-desktops-form .drop-zone > div.full-width.hovering,
.settings-desktops-form .drop-zone > div.full-width.hovering span {
  background: #5cb85c;
}
.settings-desktops-form .drop-zone > div span:not(.pz-title) {
  background: #5cb85c;
  border-color: #fff;
}
.settings-desktops-form .drop-zone > div.clone span:not(.pz-title) {
  background: #f0ad4e;
}

.desktop-drop-zone.move > div {
  background: #FFE862;
  color: #fff;
}
.desktop-drop-zone.clone > div {
  background: #f0ad4e;
  color: #fff;
}

ul.preview-layer li {
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
}
ul.preview-layer li h4 {
  background: #fff;
}
ul.preview-layer.no-drop li {
  border-color: #DC2770;
}
ul.preview-layer.no-drop li .content {
  color: #DC2770 !important;
}
ul.preview-layer.hovering-action-delete li {
  box-shadow: 0 8px 10px 1px rgba(255, 0, 0, 0.14), 0 3px 14px 2px rgba(255, 0, 0, 0.12), 0 5px 5px -3px rgba(255, 0, 0, 0.2);
}
ul.preview-layer.hovering-action-delete li .content {
  color: #DC2770 !important;
  border-left-color: #DC2770 !important;
}
ul.preview-layer > h3 {
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
  background: #fff;
  border-color: #000018;
}
ul.preview-layer > h3.list-task {
  border-color: #40a3ea;
  color: #40a3ea;
}
ul.preview-layer > h3.list-contact {
  border-color: #ff8309;
  color: #ff8309;
}
ul.preview-layer > h3.list-image {
  border-color: #ffca08;
  color: #ffca08;
}
ul.preview-layer > h3.list-project {
  border-color: #a473ff;
  color: #a473ff;
}
ul.preview-layer > h3.list-goal {
  border-color: #a473ff;
  color: #a473ff;
}
ul.preview-layer > h3.list-dayplan {
  border-color: #21ac66;
  color: #21ac66;
}

.list-container {
  background: #f5f5f5;
  border-color: #000018;
}
.list-container > h3 {
  color: #000018;
  border-bottom-color: transparent;
  transition: background-color 0.4s linear;
}
.list-container > h3 .edit-filters.highlight,
.list-container > h3 .collapse-arrow.highlight {
  color: #fff;
  background: #000018;
}
.list-container.autofocus > h3 {
  background-color: #5cb85c !important;
}
.list-container.list-task {
  border-color: #40a3ea;
}
.list-container.list-task.embedded {
  border-color: #40a3ea rgba(0, 0, 0, 0.2);
}
.list-container.list-task > h3 {
  color: #40a3ea;
}
.list-container.list-task > h3 .edit-filters.highlight {
  background: #40a3ea;
}
.list-container.list-contact {
  border-color: #ff8309;
}
.list-container.list-contact.embedded {
  border-color: #ff8309 rgba(0, 0, 0, 0.2);
}
.list-container.list-contact.embedded.collapsed {
  border-bottom-color: #ff8309;
}
.list-container.list-contact > h3 {
  color: #ff8309;
}
.list-container.list-contact > h3 .edit-filters.highlight {
  background: #ff8309;
}
.list-container.list-image {
  border-color: #ffca08;
}
.list-container.list-image.embedded {
  border-color: #ffca08 rgba(0, 0, 0, 0.2);
}
.list-container.list-image.embedded.collapsed {
  border-bottom-color: #ffca08;
}
.list-container.list-image > h3 {
  color: #ffca08;
}
.list-container.list-image > h3 .edit-filters.highlight {
  background: #ffca08;
}
.list-container.list-project {
  border-color: #a473ff;
}
.list-container.list-project.embedded {
  border-color: #a473ff;
}
.list-container.list-project.embedded.collapsed {
  border-bottom-color: #a473ff;
}
.list-container.list-project > h3 {
  color: #a473ff;
}
.list-container.list-project > h3 .edit-filters.highlight {
  background: #a473ff;
}
.list-container.list-goal {
  border-color: #a473ff;
}
.list-container.list-goal.embedded {
  border-color: #a473ff;
}
.list-container.list-goal.embedded.collapsed {
  border-bottom-color: #a473ff;
}
.list-container.list-goal > h3 {
  color: #a473ff;
}
.list-container.list-goal > h3 .edit-filters.highlight {
  background: #a473ff;
}
.list-container.list-dayplan {
  border-color: #21ac66;
  background: #dbf3e6;
}
.list-container.list-dayplan.embedded {
  border-color: #21ac66;
}
.list-container.list-dayplan.embedded.collapsed {
  border-bottom-color: #21ac66;
}
.list-container.list-dayplan > h3 {
  color: #21ac66;
  background: #f5f5f5;
}
.list-container.list-dayplan > h3 .edit-filters.highlight {
  background: #21ac66;
}
.list-container.list-dayplan .track.day-plan-track {
  background: #f5f5f5;
  color: #000018;
}
.list-container.list-dayplan .track.day-plan-track:before {
  background: #f5f5f5;
  background: -moz-linear-gradient(left, #f5f5f5 0%, rgba(245, 245, 245, 0) 100%);
  background: -webkit-linear-gradient(left, #f5f5f5 0%, rgba(245, 245, 245, 0) 100%);
  background: linear-gradient(to right, #f5f5f5 0%, rgba(245, 245, 245, 0) 100%);
}
.list-container.list-dayplan .track.day-plan-track:after {
  background: #f5f5f5;
  background: -moz-linear-gradient(right, #f5f5f5 0%, rgba(245, 245, 245, 0) 100%);
  background: -webkit-linear-gradient(right, #f5f5f5 0%, rgba(245, 245, 245, 0) 100%);
  background: linear-gradient(to left, #f5f5f5 0%, rgba(245, 245, 245, 0) 100%);
}
.list-container.list-dayplan .track.day-plan-track li .bullets .primary {
  background: #12378F;
}
.list-container.list-dayplan .track.day-plan-track + nav .ei {
  color: #777;
}
.list-container.list-dayplan .day-plan-date {
  background: transparent;
}
.list-container.list-dayplan .day-plan-date .list-time {
  color: #5bc0de;
}
.list-container.list-dayplan .time-slot.productivity {
  background: rgba(0, 0, 0, 0.03);
}
.list-container.list-dayplan .time-slot.productivity.sleep.no-items.can-drop-hint {
  background: rgba(0, 0, 0, 0.03);
}
.list-container.list-dayplan .time-slot.productivity.can-drop-hint {
  border-color: transparent;
  background: transparent;
}
.list-container.list-dayplan .time-slot.productivity .zone-header .drop-zone > div {
  color: #fff !important;
}
.list-container.list-dayplan .time-slot.productivity .badge {
  background: #f0ad4e;
  color: #fff;
}
.list-container.list-dayplan .time-slot.productivity .badge.important {
  background: #DC2770;
  color: #fff;
}
.list-container.list-dayplan .drop-clock {
  background: #40a3ea;
  box-shadow: -2px 2px 2px 0 rgba(0, 0, 0, 0.14), -2px 3px 1px -2px rgba(0, 0, 0, 0.12), -2px 1px 5px 0 rgba(0, 0, 0, 0.2);
}
.list-container.list-dayplan .drop-clock .ei {
  color: #fff;
}
.list-container.list-dayplan .drop-clock div span {
  color: #fff;
}
.list-container.list-dayplan .drop-clock.visible.hovering {
  background: #6eb9ef;
}
.list-container .action-container {
  background: #fff;
  border-color: #a473ff #e6e6e6 #e6e6e6;
}
.list-container .action-container ul {
  background: transparent;
}
.list-container .action-container.habits {
  border-top-color: #21ac66;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
}

.shadow.top {
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
.shadow.bottom {
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.embedded-list-item.static-list .list-container.embedded {
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.1) !important;
}
.embedded-list-item.static-list .list-container.embedded .list-wrapper {
  background: #f5f5f5;
}

.list-container.embedded {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.actions-container > .ei {
  color: #fff;
  background: #000018;
  box-shadow: 1px 1px 8px #000018;
}
.actions-container > .ei.close {
  color: #000018;
}
.actions-container.actions-task > .ei {
  background: #40a3ea;
}
.actions-container.actions-contact > .ei {
  background: #ff8309;
}
.actions-container.actions-image > .ei {
  background: #ffca08;
}
.actions-container.actions-project > .ei {
  background: #a473ff;
}
.actions-container.actions-dayplan > .ei {
  background: #21ac66;
}

.list-container.embedded .actions-container > .ei {
  background: #fff;
  color: #000018;
  border-color: #e6e6e6;
}
.list-container.embedded .actions-container.actions-task > .ei,
.list-container.embedded .actions-container.actions-task .extra button {
  border-color: #40a3ea;
  color: #40a3ea;
}
.list-container.embedded .actions-container.actions-contact > .ei,
.list-container.embedded .actions-container.actions-contact .extra button {
  border-color: #ff8309;
  color: #ff8309;
}
.list-container.embedded .actions-container.actions-image > .ei,
.list-container.embedded .actions-container.actions-image .extra button {
  border-color: #ffca08;
  color: #ffca08;
}
.list-container.embedded .actions-container.actions-project > .ei,
.list-container.embedded .actions-container.actions-project .extra button {
  border-color: #a473ff;
  color: #a473ff;
}
.list-container.embedded .actions-container.actions-dayplan > .ei,
.list-container.embedded .actions-container.actions-dayplan .extra button {
  border-color: #21ac66;
  color: #21ac66;
}
.list-container.embedded .actions-container.actions-task > .ei {
  color: #40a3ea;
  border-color: #40a3ea;
}
.list-container.embedded .actions-container.actions-contact > .ei {
  color: #ff8309;
  border-color: #ff8309;
}
.list-container.embedded .actions-container.actions-image > .ei {
  color: #ffca08;
  border-color: #ffca08;
}
.list-container.embedded .actions-container.actions-project > .ei {
  color: #a473ff;
  border-color: #a473ff;
}
.list-container.embedded .actions-container.actions-dayplan > .ei {
  color: #21ac66;
  border-color: #21ac66;
}

.list-container.deleted {
  background: transparent;
}

.list-context-menu button {
  color: #000018;
  background: #daf1f8;
}
.list-context-menu button.add-action {
  color: #fff;
  background: #000018;
}
.list-context-menu button.delete-action {
  color: #DC2770;
  background: #f1abc7;
}
.list-context-menu button.color-task {
  background: #40a3ea;
}
.list-context-menu button.color-contact {
  background: #ff8309;
}
.list-context-menu button.color-image {
  background: #ffca08;
}
.list-context-menu.actions-task button.add-action {
  background: #40a3ea;
}
.list-context-menu.actions-contact button.add-action {
  background: #ff8309;
}
.list-context-menu.actions-image button.add-action {
  background: #ffca08;
}
.list-context-menu.actions-project button.add-action {
  background: #a473ff;
}
.list-context-menu.actions-dayplan button.add-action {
  background: #21ac66;
}

.list-container.embedded > h3 {
  background: #fff;
}
.list-container.embedded > h3 span {
  color: #000018;
}

.swipe-to-open .arrow-container {
  background: #f5f5f5;
  box-shadow: 0 2px 1px 0 rgba(128, 128, 128, 0.14), 0 3px 1px -1px rgba(128, 128, 128, 0.12), 0 2px 3px 0 rgba(128, 128, 128, 0.2);
}
.swipe-to-open .arrow-container .arrow {
  color: #777;
}

body.desktop .path-desktop .dnd-container .panning-layer {
  padding-bottom: 10px;
}
body.desktop .path-desktop .dnd-container .panning-layer.ps--active-x {
  padding-bottom: 18px;
}
body.desktop .path-desktop .dnd-container .panning-layer > .ps__rail-x,
body.desktop .path-desktop .dnd-container .panning-layer .list-wrapper .ps__rail-x {
  background: transparent;
}
body.desktop .path-desktop .dnd-container .panning-layer > .ps__rail-x .ps__thumb-x,
body.desktop .path-desktop .dnd-container .panning-layer .list-wrapper .ps__rail-x .ps__thumb-x {
  background-color: #fff;
}

ul.preview-layer > h3 {
  font-size: 16px;
  font-weight: normal;
}

.list-container > h3 {
  font-size: 16px;
  font-weight: normal;
}
.list-container > h3 .edit-filters.highlight span,
.list-container > h3 .collapse-arrow.highlight span {
  font-size: 11px;
}
.list-container.desktop-chooser .field.select .value span {
  font-size: 16px;
  font-weight: normal;
}
.list-container.list-dayplan .day-plan-date {
  font-size: 13px;
}
.list-container.list-dayplan .day-plan-track div {
  line-height: 14px;
}
.list-container.list-dayplan .day-plan-track .weekday {
  font-size: 12px;
}
.list-container.list-dayplan .day-plan-track .date {
  font-size: 13px;
}
.list-container.list-dayplan .day-plan-track li.active {
  font-weight: bold;
}
.list-container.list-dayplan .time-slot.productivity .zone-header {
  font-size: 11px;
}

.list-container.embedded > h3 span {
  font-size: 12px;
  font-weight: bold;
  text-transform: none;
}

.root span {
  font-size: 40px;
}

.landing-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.landing-container .btn {
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
}
.landing-container .btn .ei {
  display: block;
}
.landing-container .btn.login {
  border-bottom: 2px solid #071434;
}
.landing-container .btn.register {
  border-bottom: 2px solid #c77c11;
}

.landing-container {
  width: 100%;
  height: 100%;
}
.landing-container h1 {
  margin: 0;
  padding: 0;
}
.landing-container .btn {
  width: 40%;
  min-width: 240px;
  height: 80px;
  line-height: 20px;
  border-radius: 40px;
  margin-top: 20px;
  padding: 11px 0;
}
.landing-container .btn .ei {
  font-size: 32px;
  margin-bottom: 5px;
}

section.main.public-route {
  overflow-x: hidden;
}

.landing-container h1 {
  color: #12378F;
}
.landing-container .btn {
  color: #fff;
}
.landing-container .btn.login {
  background: #12378F;
  border-bottom-color: #071434;
}
.landing-container .btn.register {
  background: #f0ad4e;
  border-bottom-color: #c77c11;
}

body.public-route.mobile {
  background-size: initial;
  background-position: top center;
  background-repeat: repeat;
}

.background-logo-image, body.theme-dark .background-logo, body.theme-dark .before-with-logo:before, .background-logo, .before-with-logo:before, #mobileRoot .popup-content header:not(.day-picker):before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='499.426' height='583.669' viewBox='0 0 499.426 583.669'%3E%3Cg id='Group_487' data-name='Group 487' transform='translate(-3.422 92.776)'%3E%3Cg id='Group_485' data-name='Group 485' transform='translate(3.422 -92.776)'%3E%3Cpath id='Path_70' data-name='Path 70' d='M2.354,48.928A44.859,44.859,0,0,1,8.941,46.6L61.412,32.541a105.4,105.4,0,0,1,79.97,10.528C201.194,77.6,352.257,228.54,403.677,279.3c19.892,19.637,42.182,38.163,53.059,78.761l12.558,46.865S387.9,101.147,358.946-6.9a38.91,38.91,0,0,0-47.655-27.513L2.214,48.4Z' transform='translate(25.733 35.749)' fill='rgba(255,255,255,0.06)' fill-rule='evenodd'/%3E%3Cpath id='Path_71' data-name='Path 71' d='M162.928,483.488a38.911,38.911,0,0,1-47.655-27.515C90.471,363.413,26.664,125.28,1.862,32.719A38.912,38.912,0,0,1,29.377-14.936l56.8-15.221A45.2,45.2,0,0,1,141.537,1.8L226.4,318.522l201.641-54.028A45.2,45.2,0,0,1,483.4,296.453c4.89,18.251,10.516,39.252,15.221,56.8A38.912,38.912,0,0,1,471.1,400.912C397.748,420.567,236.284,463.831,162.928,483.488Z' transform='translate(-0.527 98.846)' fill='rgba(255,255,255,0.06)' fill-rule='evenodd'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.background-logo-image-dark {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='499.426' height='583.669' viewBox='0 0 499.426 583.669'%3E%3Cg id='Group_487' data-name='Group 487' transform='translate(-3.422 92.776)'%3E%3Cg id='Group_485' data-name='Group 485' transform='translate(3.422 -92.776)'%3E%3Cpath id='Path_70' data-name='Path 70' d='M2.354,48.928A44.859,44.859,0,0,1,8.941,46.6L61.412,32.541a105.4,105.4,0,0,1,79.97,10.528C201.194,77.6,352.257,228.54,403.677,279.3c19.892,19.637,42.182,38.163,53.059,78.761l12.558,46.865S387.9,101.147,358.946-6.9a38.91,38.91,0,0,0-47.655-27.513L2.214,48.4Z' transform='translate(25.733 35.749)' fill='rgba(18,55,143,0.04)' fill-rule='evenodd'/%3E%3Cpath id='Path_71' data-name='Path 71' d='M162.928,483.488a38.911,38.911,0,0,1-47.655-27.515C90.471,363.413,26.664,125.28,1.862,32.719A38.912,38.912,0,0,1,29.377-14.936l56.8-15.221A45.2,45.2,0,0,1,141.537,1.8L226.4,318.522l201.641-54.028A45.2,45.2,0,0,1,483.4,296.453c4.89,18.251,10.516,39.252,15.221,56.8A38.912,38.912,0,0,1,471.1,400.912C397.748,420.567,236.284,463.831,162.928,483.488Z' transform='translate(-0.527 98.846)' fill='rgba(18,55,143,0.04)' fill-rule='evenodd'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.background-logo-image-primary {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='499.426' height='583.669' viewBox='0 0 499.426 583.669'%3E%3Cg id='Group_487' data-name='Group 487' transform='translate(-3.422 92.776)'%3E%3Cg id='Group_485' data-name='Group 485' transform='translate(3.422 -92.776)'%3E%3Cpath id='Path_70' data-name='Path 70' d='M2.354,48.928A44.859,44.859,0,0,1,8.941,46.6L61.412,32.541a105.4,105.4,0,0,1,79.97,10.528C201.194,77.6,352.257,228.54,403.677,279.3c19.892,19.637,42.182,38.163,53.059,78.761l12.558,46.865S387.9,101.147,358.946-6.9a38.91,38.91,0,0,0-47.655-27.513L2.214,48.4Z' transform='translate(25.733 35.749)' fill='rgba(255,230,0,1)' fill-rule='evenodd'/%3E%3Cpath id='Path_71' data-name='Path 71' d='M162.928,483.488a38.911,38.911,0,0,1-47.655-27.515C90.471,363.413,26.664,125.28,1.862,32.719A38.912,38.912,0,0,1,29.377-14.936l56.8-15.221A45.2,45.2,0,0,1,141.537,1.8L226.4,318.522l201.641-54.028A45.2,45.2,0,0,1,483.4,296.453c4.89,18.251,10.516,39.252,15.221,56.8A38.912,38.912,0,0,1,471.1,400.912C397.748,420.567,236.284,463.831,162.928,483.488Z' transform='translate(-0.527 98.846)' fill='rgba(18,55,143,1)' fill-rule='evenodd'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.before-with-logo, #mobileRoot .popup-content header:not(.day-picker) {
  overflow: hidden;
}
.before-with-logo:before, #mobileRoot .popup-content header:not(.day-picker):before {
  content: "";
  display: block;
  width: 500px;
  height: 584px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100%;
  position: absolute;
  z-index: 0;
  left: 50%;
  top: -250px;
  transform-origin: center center;
  transform: scale(0.6) translateX(-400px) rotate(-23deg);
}

.background-logo {
  background-repeat: no-repeat;
  background-position: center 100px;
  background-size: 165%;
}
@media (min-width: 769px) {
  .background-logo {
    background-size: 130%;
    background-position: left 90px;
  }
}
@media (min-width: 1400px) {
  .background-logo {
    background-size: 130%;
    background-position: left 0px;
  }
}

section.main.path-introx {
  background: #e6e6e6;
}

.landing-container h1 {
  font-size: 36px;
  font-weight: normal;
}
.landing-container .btn {
  font-size: 16px;
}

.drop-expand-toast-wrapper {
  position: fixed;
  z-index: 1000;
  opacity: 0;
  top: 0;
  left: 50%;
  right: auto;
  transform: translateX(-50%);
  display: none;
}

.drop-expand-toast-super {
  position: relative;
}

.drop-expand-toast-body {
  position: relative;
  transform: translateY(64px);
  margin: 0 auto;
  width: 64px;
  overflow: hidden;
  height: 64px;
  line-height: 24px;
  text-align: center;
  border-radius: 32px;
  background-color: #5cb85c;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 40%, rgba(255, 255, 255, 0.2) 60%, rgba(255, 255, 255, 0) 100%);
  background-repeat: no-repeat;
  background-size: 50% 100%;
  background-position: -100%;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.drop-expand-toast-body .drop-expand-toast-text {
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  opacity: 0;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 36px;
}

.drop-expand-toast-body .drop-expand-toast-subtext {
  color: #fff;
  font-size: 14px;
  opacity: 0;
  margin: 0;
  padding-left: 36px;
}
.drop-expand-toast-body .drop-expand-toast-subtext .ei {
  font-size: 16px;
}

.drop-expand-toast-title {
  background: rgba(255, 255, 255, 0);
  width: 64px;
  height: 64px;
  border-radius: 32px;
  text-align: center;
  line-height: 64px;
  color: #fff;
  margin: 0 auto;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 10px;
}
.drop-expand-toast-title .ei {
  font-size: 32px;
}

.drop-expand-toast-wrapper.animation {
  opacity: 1;
  display: block;
  animation: slideDown 750ms cubic-bezier(0.75, -0.5, 0, 1.75) 0s 1 forwards, slideUp 750ms cubic-bezier(0.75, -0.5, 0, 1.75) 5450ms 1 forwards;
}

.drop-expand-toast-wrapper.animation .drop-expand-toast-title {
  animation: slideLeft 750ms ease 650ms 1 forwards, setColor 750ms ease 650ms 1 forwards, ripple 500ms ease 1200ms 1, slideRight 750ms ease 4750ms 1 forwards, removeColor 750ms ease 4750ms 1 forwards;
}

.drop-expand-toast-wrapper.animation .drop-expand-toast-body {
  animation: expand 750ms ease 650ms 1 forwards, contract 750ms ease 4750ms 1 forwards, shimmer 1500ms linear 1400ms 2;
}

.drop-expand-toast-wrapper.animation .drop-expand-toast-text {
  animation: fadeInUp 550ms ease 1250ms 1 forwards, fadeOutUp 550ms ease 4250ms 1 forwards;
}

.drop-expand-toast-wrapper.animation .drop-expand-toast-subtext {
  animation: fadeInUp 550ms ease 1350ms 1 forwards, fadeOutUp 550ms ease 4350ms 1 forwards;
}

@keyframes slideUp {
  0% {
    transform: translate(-50%, 0);
  }
  100% {
    transform: translate(-50%, -135px);
  }
}
@keyframes slideDown {
  100% {
    transform: translate(-50%, 0);
  }
  0% {
    transform: translate(-50%, -135px);
  }
}
@keyframes setColor {
  0% {
    background: rgba(255, 255, 255, 0);
  }
  100% {
    background: rgba(255, 255, 255, 0.2);
  }
}
@keyframes removeColor {
  100% {
    background: rgba(255, 255, 255, 0);
  }
  0% {
    background: rgba(255, 255, 255, 0.2);
  }
}
@keyframes slideLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translate(calc(-49vw + 32px));
  }
}
@keyframes slideRight {
  100% {
    transform: translateX(0);
  }
  0% {
    transform: translate(calc(-49vw + 32px));
  }
}
@keyframes expand {
  0% {
    width: 64px;
  }
  100% {
    width: 98vw;
    padding: 8px 40px;
  }
}
@keyframes contract {
  100% {
    width: 64px;
  }
  0% {
    width: 98vw;
    padding: 8px 40px;
  }
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fadeOutUp {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }
  100% {
    opacity: 0;
    transform: translateY(-10px);
  }
}
@keyframes ripple {
  100% {
    box-shadow: inset 0 0 5px 0 rgba(0, 0, 0, 0.08);
  }
  0% {
    box-shadow: inset 0 0 5px 50px rgba(0, 0, 0, 0);
  }
}
@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}
@media only screen and (min-width: 480px) {
  @keyframes slideLeft {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translate(-180px);
    }
  }
  @keyframes slideRight {
    100% {
      transform: translateX(0);
    }
    0% {
      transform: translate(-180px);
    }
  }
  @keyframes expand {
    0% {
      width: 64px;
    }
    100% {
      width: 424px;
      padding: 8px 40px;
    }
  }
  @keyframes contract {
    100% {
      width: 64px;
    }
    0% {
      width: 424px;
      padding: 8px 40px;
    }
  }
}
.field {
  text-align: left;
  white-space: normal;
}
.field label:not(.MuiFormControlLabel-root) {
  display: block;
}
.field label:not(.MuiFormControlLabel-root).editable input {
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
  border: 0 none;
  padding: 0;
  font-size: 13px;
  min-width: 80px;
  max-width: 220px;
  height: 16px;
}
.field label:not(.MuiFormControlLabel-root).editable span {
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
}
.field label:not(.MuiFormControlLabel-root).editable > .ei {
  display: inline-block;
  width: 32px;
  height: 16px;
  font-size: 16px;
  vertical-align: bottom;
  text-align: center;
  line-height: 16px;
  position: relative;
  overflow: visible;
}
.field label:not(.MuiFormControlLabel-root).editable > .ei:after {
  content: "";
  display: block;
  position: absolute;
  top: -16px;
  right: -8px;
  bottom: -4px;
  left: -8px;
}
.field label:not(.MuiFormControlLabel-root).editable > .ei.ei-check2:after {
  right: 0;
  left: -16px;
}
.field label:not(.MuiFormControlLabel-root).editable > .ei.ei-close2:after {
  left: 0;
  right: -16px;
}
.field.floating-label label:not(.MuiFormControlLabel-root) {
  display: inline-block;
  position: relative;
  transform: translate3d(10px, 28px, 0) perspective(1px) scale(1);
  backface-visibility: hidden;
  will-change: transform;
  transition: transform 0.15s ease-out;
  pointer-events: none;
}
body.desktop .field.floating-label label:not(.MuiFormControlLabel-root) {
  transform: translate3d(10px, 28px, 0);
  will-change: transform, font-size;
  transition: all 0.15s ease-out;
}
.field.floating-label.has-value label, .field.floating-label.focus label,
.field.floating-label label.editable {
  transform: translate3d(0, 8px, 0) perspective(1px) scale(0.9285714286);
  pointer-events: all;
}
body.desktop .field.floating-label.has-value label, body.desktop .field.floating-label.focus label,
body.desktop .field.floating-label label.editable {
  transform: translate3d(0, 8px, 0);
}
.field.floating-label.textarea label.editable {
  transform: translate3d(0, 0, 0) perspective(1px) scale(0.9285714286);
  pointer-events: all;
}
body.desktop .field.floating-label.textarea label.editable {
  transform: translate3d(0, 0, 0);
}
.field .error:not(.field) {
  display: block;
  opacity: 0;
  transition: opacity 0.2s ease-in;
}
.field .error:not(.field) .ei {
  vertical-align: text-bottom;
}
.field .error:not(.field) span {
  vertical-align: middle;
}
.field.touched.error .error:not(.field) {
  opacity: 1;
}
.field.disabled input,
.field.disabled .value,
.field.disabled .value-inline,
.field.disabled label,
.field.disabled textarea {
  cursor: not-allowed;
}
.field.disabled label .required,
.field.disabled .error:not(.field) {
  display: none;
}

span.tag {
  display: inline-block;
}
.field-display span.tag:before {
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
}
.field-display span.tag.compact {
  overflow: hidden;
  font-size: 1px;
  text-indent: -9999px;
  border: 0 none;
}
.field-display span.tag.compact:before {
  display: none;
}

.field-lang {
  display: flex;
}
.field-lang > div.field {
  flex-grow: 1;
  min-width: 0;
}
.field-lang > .field.visibility {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.get-link {
  border: 1px solid transparent;
  overflow: hidden;
}

.field.text {
  position: relative;
}
.field.text .auto-width {
  position: absolute;
  opacity: 0;
}
.field.text input, .field.text.mobile .value {
  display: block;
  border: 1px solid transparent;
  border-width: 0 0 1px 0;
  outline: 0 none !important;
}
.field.text .field-wrapper {
  display: flex;
  align-items: stretch;
}
.field.text .field-wrapper .input-wrapper {
  position: relative;
  flex-grow: 1;
}
.field.text .field-wrapper > a {
  text-decoration: none;
  text-align: center;
}
.field.text.inline {
  display: inline-block;
  vertical-align: middle;
}
.field.text.inline input, .field.text.inline.mobile .value {
  display: inline-block;
}
.field.text.mobile .value {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.field.text.mobile.multiline .value {
  white-space: pre-wrap;
}
.field.text.mobile.multiline .value span {
  display: inline-block;
  word-break: keep-all;
}
.field.text.clear .input-wrapper > .ei {
  text-align: center;
}
.field.text.touched.error input,
.field.text.touched.error .value {
  border-bottom-width: 2px;
}
.field.text.disabled.clear .input-wrapper > .ei {
  display: none;
}
.field.text.has-action a {
  text-decoration: none;
}
.field.text.text-select {
  position: relative;
  z-index: 3;
}
.field.text.text-select .value input {
  border-width: 0;
}
.field.text.text-select .value.has-options {
  overflow: visible;
  position: relative;
}
.field.text.text-select .value.has-options > .ei {
  text-align: center;
}
.field.text.text-select .value.has-options > .ei.close {
  display: none;
}
.field.text.text-select .value.has-options ul {
  will-change: margin;
  transition: margin 0.2s ease-out;
  overflow: hidden;
  border: 1px solid transparent;
  border-top: 0 none;
  opacity: 0;
  display: none;
}
.field.text.text-select .value.has-options ul li {
  border-bottom: 0;
  border-top: 1px solid transparent;
  background: transparent;
  white-space: normal;
  overflow-wrap: anywhere;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}
.field.text.text-select.text-select-expanded .value.has-options > .ei.close {
  display: block;
}
.field.text.text-select.text-select-expanded .value.has-options > .ei.open {
  display: none;
}
.field.text.text-select.text-select-expanded .value.has-options ul {
  opacity: 1;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  display: block;
}

.field.textarea {
  position: relative;
}
.field.textarea textarea {
  display: block;
  resize: vertical;
  border: 1px solid transparent;
  border-width: 0 0 1px 0;
  outline: 0 none !important;
}
.field.textarea.multiline-grow textarea {
  overflow: hidden;
}
.field.textarea.disabled textarea {
  resize: none;
}
.field.textarea.floating-label label {
  transform: translate3d(10px, 20px, 0) perspective(1px) scale(1);
}
body.desktop .field.textarea.floating-label label {
  transform: translate3d(10px, 20px, 0);
}
.field.textarea.floating-label.has-value label, .field.textarea.floating-label.focus label {
  transform: translate3d(0, 0, 0) perspective(1px) scale(0.9285714286);
}
body.desktop .field.textarea.floating-label.has-value label, body.desktop .field.textarea.floating-label.focus label {
  transform: translate3d(0, 0, 0);
}

.row-count-helper {
  display: block;
  opacity: 0;
  pointer-events: none;
  white-space: pre-wrap;
  height: auto;
}

.multiline-trimmed {
  position: absolute;
  background: aqua;
  inset: -16px 0 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.field.checkbox {
  display: block;
}
.field-lang .field.checkbox {
  flex-grow: 1;
}
.field-lang .field.checkbox.visibility {
  flex-grow: 0;
}

.field.radio .option {
  position: relative;
  display: flex;
  align-items: center;
}
.field.radio .option > .ei.radio {
  flex-shrink: 0;
}
.field.radio .option.checked .overlay {
  display: none;
}
.field.radio .option > span {
  flex-grow: 1;
  display: flex;
  align-items: center;
}
.field.radio .option .field.text input[type=number] {
  text-align: center;
}

.field.color-picker .value {
  display: block;
  border: 1px solid transparent;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}
.field.color-picker .value div {
  text-align: center;
}
.field.color-picker .value div .ei {
  display: inline-block;
  border: 1px solid #999;
  vertical-align: middle;
}
.field.color-picker.inline {
  display: inline-block;
  vertical-align: middle;
  outline: 0 none;
  flex-grow: 0;
}

.color-picker-popup {
  opacity: 0;
  will-change: opacity, transform;
  transform: translate3d(0, 100%, 0);
  transition: transform 0.15s ease-out, opacity 0.15s ease-out;
}
.color-picker-popup > * {
  transform: none;
}
.color-picker-popup .picker-container {
  position: relative;
  z-index: 3;
}
.color-picker-popup .picker-container > .undo {
  float: right;
  text-align: center;
  display: none;
}
.color-picker-popup.open {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
.color-picker-popup.open .picker-container > .undo {
  display: block;
}
.color-picker-popup .hue-picker div {
  text-align: center;
}
.color-picker-popup .hue-picker div .ei {
  display: none;
}
.color-picker-popup .hue-picker div .ei.visible {
  display: block;
}
.color-picker-popup .hue-slider {
  position: relative;
  clear: both;
  pointer-events: all;
}
.color-picker-popup .hue-slider .cursor {
  border: 1px solid #000;
  pointer-events: none;
}
.color-picker-popup .colors > div {
  float: left;
  text-align: center;
}
.color-picker-popup .colors > div .ei {
  display: none;
}
.color-picker-popup .colors > div .ei.visible {
  display: inline;
}

.color-picker-in-popup .picker-container {
  position: relative;
  z-index: 3;
  overflow: hidden;
}
.color-picker-in-popup .picker-container .preview label {
  font-size: 11px;
}
.color-picker-in-popup .hue-picker div {
  text-align: center;
}
.color-picker-in-popup .hue-picker div .ei {
  display: none;
}
.color-picker-in-popup .hue-picker div .ei.visible {
  display: block;
}
.color-picker-in-popup .hue-slider {
  position: relative;
  clear: both;
  pointer-events: all;
}
.color-picker-in-popup .hue-slider .cursor {
  border: 1px solid #000;
  pointer-events: none;
}
.color-picker-in-popup .colors > div {
  float: left;
  text-align: center;
}
.color-picker-in-popup .colors > div .ei {
  display: none;
}
.color-picker-in-popup .colors > div .ei.visible {
  display: inline;
}

.field.select .value {
  display: block;
  border: 1px solid transparent;
  border-width: 0 0 1px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}
.field.select .value > span {
  position: relative;
  z-index: 2;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
}
.field.select.inline {
  display: inline-block;
  vertical-align: middle;
}
.field.select.disabled .value > .ei {
  display: none;
}
.field.select.tags.floating-label label {
  transform: translate3d(10px, 26px, 0) perspective(1px) scale(1);
}
body.desktop .field.select.tags.floating-label label {
  transform: translate3d(10px, 26px, 0);
}
.field.select.tags.floating-label.has-value label, .field.select.tags.floating-label.focus label {
  transform: translate3d(0, 6px, 0) perspective(1px) scale(0.9285714286);
}
body.desktop .field.select.tags.floating-label.has-value label, body.desktop .field.select.tags.floating-label.focus label {
  transform: translate3d(0, 6px, 0);
}
.field.select.display-inline-buttons .value-inline {
  width: 100%;
  padding-top: 4px;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  min-height: 42px;
}
.field.select.display-inline-buttons .value-inline .option {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 1px solid transparent;
  margin-right: -1px;
  padding: 8px 2px;
  flex-grow: 1;
}
.field.select.display-inline-buttons .value-inline .option:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.field.select.display-inline-buttons .value-inline .option:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.field.select.display-inline-buttons .value-inline .option .ei.right {
  font-size: 24px;
  margin-left: 5px;
}
.field.select.display-inline-buttons .value-inline .option.selected {
  border-color: #12378F;
  background: #12378F;
  color: #fff;
}
.field.select.display-inline-buttons .value-inline .option.selected.in-progress {
  border-color: #f0ad4e;
  background: #f0ad4e;
}
.field.select.display-inline-buttons .value-inline .option.selected.done {
  border-color: #5cb85c;
  background: #5cb85c;
}
.field.select.display-inline-buttons.suffix .value-inline {
  width: auto;
  display: inline-block;
  margin: 0 8px;
}
.field.select.display-inline-buttons.suffix .value-inline .option {
  padding: 8px 4px;
}
.field.select.slim.display-inline-buttons .value-inline {
  min-height: 32px;
  padding-top: 8px;
}

.field.tags-select .field-wrapper {
  padding-top: 8px;
}
.field.tags-select span.tag {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 0;
  width: auto;
  vertical-align: middle;
  margin: 0 0 8px;
  height: 32px;
  line-height: 31px;
  border-radius: 16px;
  padding: 0;
  will-change: max-width, opacity, padding-right, margin-right, box-shadow, color, background-color;
  transition: all 0.1s ease-out;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
}
.field.tags-select span.tag:not(.selected) {
  color: #000018 !important;
}
.field.tags-select span.tag.selected {
  box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.2);
}
.field.tags-select span.tag.visible {
  max-width: 100%;
  padding: 0 12px;
  margin-right: 8px;
}
.field.tags-select span.expand {
  display: inline-block;
  vertical-align: middle;
  height: 34px;
  line-height: 33px;
  background: transparent;
  border: 1px solid transparent;
  border-radius: 16px;
  margin: 0 0 8px;
  padding: 0 16px 0 8px;
}
.field.tags-select span.expand .ei {
  font-size: 16px;
  margin-right: 5px;
  vertical-align: middle;
  position: relative;
  top: -2px;
}

.field.datetime {
  position: relative;
}
.field.datetime .value {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border: 1px solid transparent;
  border-width: 0 0 1px 0;
  outline: 0 none !important;
  position: relative;
}
.field.datetime .value > .ei.clear {
  display: none;
}
.field.datetime .value span.action {
  display: inline-block;
  vertical-align: bottom;
  position: relative;
}
.field.datetime .value span.action:before {
  content: "";
  display: block;
  position: absolute;
  left: -8px;
  top: -4px;
  right: -8px;
  bottom: -10px;
}
.field.datetime.clearable.has-value .value > .ei.clear {
  display: block;
}
.field.datetime.disabled .value > .ei {
  display: none;
}

#mobileRoot .datetime-popup {
  display: none;
}
#mobileRoot .datetime-popup.animating {
  opacity: 0;
}
#mobileRoot .datetime-popup.open {
  display: block;
}
#mobileRoot .datetime-popup.open > section {
  overflow: auto;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
#mobileRoot .datetime-popup.open > section * {
  -webkit-overflow-scrolling: auto;
}
#mobileRoot .datetime-popup .datetime-popup-content {
  position: relative;
  overflow: hidden;
  border: 0 solid rgba(0, 0, 0, 0.2);
  border-width: 0 1px 2px 0;
  background-clip: padding-box;
}
#mobileRoot .datetime-popup .datetime-popup-content > header {
  border-bottom: 1px solid #e6e6e6;
}
#mobileRoot .datetime-popup .datetime-popup-content > section {
  overflow: hidden;
  overflow-x: hidden;
  overflow-y: auto;
}
#mobileRoot .datetime-popup.open {
  display: flex;
  align-items: center;
  justify-content: center;
}
#mobileRoot .datetime-popup section > .display {
  position: relative;
  z-index: 3;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  text-align: center;
}

.track {
  overflow: hidden;
  position: relative;
}
.track:before, .track:after {
  content: "";
  display: block;
  pointer-events: none;
}
.track ul {
  display: block;
  overflow: auto;
}
body.desktop .track ul::-webkit-scrollbar {
  display: none;
  -webkit-appearance: none;
  height: 0;
}
.track ul,
.track ul li {
  list-style: none;
  list-style-type: none;
  transform: translate3d(0, 0, 0);
}
.track ul li {
  display: inline-block;
  opacity: 0.6;
}
.track ul li:before {
  content: "";
  display: block;
  border-style: solid;
  border-width: 0 8px 4px;
  opacity: 0;
}
.track ul li.active {
  opacity: 1;
}
.track ul li.active:before {
  opacity: 1;
}
.track.horizontal {
  border-bottom: 1px solid #e6e6e6;
}
.track.horizontal ul {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
}
.track.vertical ul {
  overflow-x: hidden;
  overflow-y: scroll;
}

.datetime-popup.open .track ul {
  -webkit-overflow-scrolling: touch;
}
.datetime-popup.open .track ul * {
  -webkit-overflow-scrolling: auto;
}

.day-picker {
  position: relative;
  overflow: visible;
  transform: translate3d(0, 0, 0);
  will-change: transform;
}
.day-picker.header {
  text-align: center;
  display: block;
}
.day-picker.header div {
  display: inline-block;
  border-bottom: 1px solid transparent;
}
.day-picker.header.left {
  text-align: left;
}
.day-picker:before {
  content: "";
  display: block;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.35s ease-out;
  will-change: opacity;
}
.day-picker.edge:before {
  opacity: 0.6;
}
.day-picker section.calendar {
  text-align: center;
}
.day-picker section.calendar .week {
  border-bottom: 1px solid transparent;
}
.day-picker section.calendar .week .day {
  display: inline-block;
  border-right: 1px solid transparent;
  position: relative;
}
.day-picker section.calendar .week .day:last-child {
  border-right: 0 none;
}

.slider.horizontal {
  position: relative;
}
.slider.horizontal .display, .datetime-popup .slider.horizontal .display {
  border: 0 none;
  transition: color 0.15s linear;
  text-align: center;
}
.slider.horizontal .ei {
  text-align: center;
  transition: color 0.15s linear;
}
.slider.horizontal .track-line {
  position: relative;
}
.slider.horizontal .track-line:before {
  content: "";
  display: block;
  transition: background-color 0.15s linear;
}
.slider.horizontal .track-line .min,
.slider.horizontal .track-line .max {
  display: inline-block;
  text-align: center;
  border: 1px solid transparent;
  transform: translate(-130%, -50%);
  transition: color 0.15s linear, border-color 0.15s linear;
}
.slider.horizontal .track-line .max {
  transform: translate(130%, -50%);
}
.slider.horizontal .track-line .value {
  display: inline-block;
  border: 1px solid #fff;
  text-align: center;
  transform: translate3d(-50%, -50%, 0);
  will-change: left;
}

.datetime-popup {
  background: transparent;
}
.datetime-popup.only-time .datetime-popup-content {
  width: 360px;
}
@media (max-width: 370px) {
  .datetime-popup.only-time .datetime-popup-content {
    width: 320px;
  }
}

.field.notifications-field .notification-line {
  border-top: 1px solid transparent;
  position: relative;
}
.field.notifications-field .notification-line:last-of-type {
  border-bottom: 1px solid transparent;
}
.field.notifications-field .notification-line .field.inline input {
  text-align: center;
}
.field.notifications-field .notification-line .field.inline.before input {
  text-align: right;
}
.field.notifications-field .notification-line span.label {
  vertical-align: middle;
}

.field.dynamic-fields-list ul,
.field.dynamic-fields-list li {
  list-style: none;
  list-style-type: none;
}
.field.dynamic-fields-list li {
  position: relative;
}
.field.dynamic-fields-list li .field.dynamic-fields-list {
  border-left: 1px solid transparent;
}
.field.dynamic-fields-list li.field-type-image .progress-bar {
  right: -24px;
}

.field.array-list-field ul,
.field.array-list-field li {
  list-style: none;
  list-style-type: none;
}
.field.array-list-field li {
  position: relative;
}

.field.file-upload .container {
  display: flex;
  align-items: stretch;
  position: relative;
}
.field.file-upload .buttons {
  display: flex;
  flex-direction: row;
  align-items: stretch;
}
.field.file-upload .buttons button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 0 none;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
.field.file-upload .buttons button:first-child {
  border-left: 0;
}
.field.file-upload .file-input-container {
  cursor: pointer;
  flex-grow: 1;
  position: relative;
  text-align: center;
  border: 1px dashed transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.field.file-upload .file-input-container input {
  display: block;
  opacity: 0;
}
.field.file-upload .ei.clear {
  cursor: pointer;
}

input.over-button {
  display: block;
  position: absolute;
  inset: 0;
  z-index: 2;
  opacity: 0;
}

.image-preview,
.image-thumbnail {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
  width: 120px;
  height: 96px;
}
.image-preview img,
.image-thumbnail img {
  display: block;
}
.image-preview img.hidden,
.image-thumbnail img.hidden {
  display: none;
}
.image-preview.no-image,
.image-thumbnail.no-image {
  cursor: default;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.image-preview svg,
.image-thumbnail svg {
  display: inline-block;
  opacity: 1;
}
.image-preview.circle,
.image-thumbnail.circle {
  margin-left: -3px;
  width: 96px;
  background-size: cover;
  border-radius: 48px;
  border: 3px solid #fff;
}

.history-list-container {
  display: flex;
  flex-direction: column;
}
.history-list-container h3 .show-history {
  float: right;
  font-weight: normal;
}
.history-list-container ul.comments-list {
  flex-grow: 1;
  overflow: hidden;
  overflow-x: hidden;
  overflow-y: auto;
  transform: translate3d(0, 0, 0);
  -webkit-overflow-scrolling: touch;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.history-list-container ul.comments-list,
.history-list-container ul.comments-list li {
  list-style: none;
  list-style-type: none;
}
.history-list-container ul.comments-list li {
  will-change: background-color;
  transition: background-color 0.3s ease-out;
}
.history-list-container ul.comments-list li .author {
  font-weight: bold;
}
.history-list-container ul.comments-list li .text::first-letter {
  text-transform: lowercase;
}
.history-list-container ul.comments-list li .speech-bubble {
  display: block;
  position: relative;
}
.history-list-container ul.comments-list li .speech-bubble:before {
  content: "";
  display: block;
  border: 0px solid transparent;
  border-width: 0 8px 8px 0;
  background-clip: padding-box;
}
.history-list-container ul.comments-list li .speech-bubble .button {
  float: right;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
}
.history-list-container .field.textarea textarea[id] {
  resize: none;
  will-change: background-color;
  transition: background-color linear 0.3s;
}

.habits-history-list-item {
  margin: 8px 0;
  -webkit-margin-collapse: collapse;
  border-top: 1px solid transparent;
  padding: 8px 0 0;
  display: flex;
}
.habits-history-list-item > div {
  flex-grow: 1;
  padding-right: 8px;
}
.habits-history-list-item > button {
  margin: 0;
}

.recurrence-edit span {
  display: inline-block;
  vertical-align: middle;
}
.recurrence-edit .weekdays {
  display: inline-block;
}
.recurrence-edit .weekdays span {
  border: 1px solid transparent;
}

.form-tabs-container {
  overflow: hidden;
}
.form-tabs-container > nav {
  text-align: right;
  background: transparent;
  overflow: visible;
  overflow-y: auto;
}
.form-tabs-container > nav button {
  text-align: center;
  border-left: 2px solid silver;
  border-bottom: 2px solid silver;
  display: block;
  will-change: background-color;
  transition: background-color 0.35s ease;
  position: static;
}
.form-tabs-container > nav button .ei {
  display: block;
  will-change: color;
  transition: color 0.35s ease;
}
.form-tabs-container > nav button.active {
  border-bottom: 2px solid silver;
}
.form-tabs-container .form-tab {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  opacity: 0;
  will-change: opacity;
  transition: opacity 0.35s ease;
  pointer-events: none;
}
.form-tabs-container .form-tab * {
  -webkit-overflow-scrolling: auto;
}
.form-tabs-container .form-tab.active {
  pointer-events: all;
  opacity: 1;
}
.form-tabs-container.has-tabs .form-tab {
  border-right-width: 2px;
  border-right-style: solid;
}

.field-display.text {
  flex-grow: 1;
  display: flex;
  align-items: flex-start;
}
.field-display.text.vertical-align-middle {
  align-items: center;
}
.field-display.text.empty {
  display: none;
}
.field-display.text > .ei {
  text-align: center;
  display: block;
}
.field-display.text > label,
.field-display.text .value-wrapper {
  white-space: normal;
  overflow-wrap: anywhere;
  word-wrap: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}
.field-display.text > label {
  flex-basis: 1;
}
.field-display.text .value-wrapper {
  flex-grow: 1;
}
.field-display.status {
  flex-shrink: 0;
}
.field-display.tags p {
  white-space: normal;
  word-wrap: break-word;
}

.show-icon {
  display: block;
}

table.summary th {
  text-align: right;
  font-weight: normal;
}
table.summary td a {
  text-decoration: none;
}
table.summary td button.det-sms {
  border: 0px;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.progress-bar .loading-bar,
.progress-bar .solid-bar {
  border: 1px solid #777;
}
.progress-bar button {
  border: 0 none;
}
.progress-bar button .ei {
  vertical-align: text-bottom;
}

.progress-loading-bar {
  border: 1px solid #777;
}

.popup-container.progress-bar .popup-content {
  height: auto;
  width: 50%;
  max-width: 360px;
}
.popup-container.progress-bar .popup-content .progress-bar {
  background: #fff;
  border-radius: 8px;
}

@-webkit-keyframes autofill {
  to {
    color: #000018;
    background: transparent;
  }
}
*:-webkit-autofill {
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}

.field {
  margin: 0 0 4px 0;
}
.field label:not(.MuiFormControlLabel-root) {
  margin: 0;
  padding: 0;
}
.field label:not(.MuiFormControlLabel-root) .required {
  line-height: 12px;
}
.field label:not(.MuiFormControlLabel-root).inline {
  display: inline-block;
}
.field.floating-label label:not(.MuiFormControlLabel-root) {
  z-index: 2;
  line-height: 12px;
}
.field .error:not(.field) {
  height: 14px;
  font-size: 14px;
  line-height: 14px;
  margin: 2px 0 0 0;
}
.field .error:not(.field) .ei {
  margin-right: 3px;
}
.field.disabled input,
.field.disabled .value,
.field.disabled textarea {
  padding: 0 !important;
}
.field.display.summary {
  padding-top: 4px;
}
.field ul {
  padding: 0;
  list-style: none;
  list-style-type: none;
}
.field.inline {
  display: inline-block;
  margin-right: 8px;
}

.space-above {
  position: relative;
  top: 8px;
}

form.settings-desktops-form {
  height: 100%;
  padding: 0;
}

.captcha {
  margin-top: 8px;
  max-width: 100%;
  overflow: hidden;
  display: inline-flex;
  width: 80%;
  max-width: 304px;
}

span.tag {
  padding: 0 8px;
  border-radius: 12px;
  height: 24px;
  line-height: 24px;
  margin-right: 5px;
}
.field-display span.tag {
  line-height: 18px;
  height: 18px;
}
.field-display span.tag.compact {
  height: 3px;
  line-height: 3px;
  width: 32px;
}

.field-lang > div.field {
  max-width: 100%;
}
.field-lang > .field.visibility {
  width: 42px;
  margin-right: -8px;
  padding: 20px 0 0;
}
.field-lang > .field.visibility i.ei {
  margin-right: 0;
}
.field-lang.show-in-slideshow {
  float: right;
  margin-top: -8px;
  margin-right: -50px;
  margin-bottom: -8px;
}
.field-lang.show-in-slideshow.no-right {
  margin-right: 6px;
}
.field-lang .label + button {
  margin: 17px 0 0 5px;
}

.get-link {
  width: 100%;
  height: 42px;
  margin: 0;
  padding: 0 5px 0 10px;
  border-radius: 8px;
  line-height: 42px;
}

.field.text .auto-width {
  top: -10000px;
  left: -10000px;
  padding: 0 5px 0 10px;
  margin: 0;
  min-width: 60px;
  max-width: 180px;
}
@media (min-width: 400px) {
  .field.text .auto-width {
    max-width: 260px;
  }
}
@media (min-width: 480px) {
  .field.text .auto-width {
    max-width: 340px;
  }
}
@media (min-width: 560px) {
  .field.text .auto-width {
    max-width: 400px;
  }
}
.field.text.forgot-password input {
  padding-right: 70px;
}
.field.text.forgot-password .forgot-btn {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  height: 35px;
  padding: 0 10px;
  line-height: 34px;
}
.field.text input, .field.text.mobile .value {
  width: 100%;
  height: 36px;
  margin: 0;
  padding: 0 5px 0 10px;
  border-radius: 0;
}
.field.text .field-wrapper {
  width: 100%;
}
.field.text .field-wrapper > a {
  width: 42px;
  line-height: 36px;
}
.field.text .description {
  padding-top: 8px;
}
.field.text.inline {
  margin-bottom: 0;
}
.field.text.inline input, .field.text.inline.mobile .value {
  width: auto;
}
.field.text.mobile .value {
  line-height: 22px;
  padding: 10px;
  min-height: 36px;
}
.field.text.mobile.multiline .value {
  height: auto;
}
.field.text.mobile.multiline .value span {
  margin-left: 5px;
}
.field.text.clear input,
.field.text.clear .value {
  padding-right: 36px;
}
.field.text.clear .input-wrapper > .ei {
  font-size: 24px;
  position: absolute;
  top: 0;
  right: 0;
  line-height: 36px;
  width: 36px;
  margin-right: 0;
}
.field.text.clear .input-wrapper.has-label > .ei {
  top: 17px;
}
.field.text.text-select .value {
  padding: 0;
}
.field.text.text-select .value input {
  line-height: 40px;
}
.field.text.text-select .value.has-options input {
  padding-right: 42px;
  height: 40px;
}
.field.text.text-select .value.has-options > .ei {
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
  height: 40px;
  padding: 8px 0;
  z-index: 2;
  font-size: 24px;
}
.field.text.text-select .value.has-options ul {
  margin: -16px 0 0 0;
  padding: 16px 0 0 0;
  height: 0;
  position: absolute;
  top: 36px;
  left: 0;
  right: 0;
  max-height: 130px;
  border-radius: 0 0 8px 8px;
}
.field.text.text-select .value.has-options ul li {
  padding: 5px 10px;
}
.field.text.text-select.text-select-expanded .value.has-options ul {
  height: auto;
  margin: -8px 0 0 0;
}
.field.text button.map-button {
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0 0 0 5px;
  padding: 7px;
  vertical-align: middle;
}
.field.text button.map-button .ei {
  font-size: 24px;
}

.field.text > .field.multiple-lines,
.field.textarea > .field.multiple-lines {
  float: right;
  padding: 10px 0 0 5px;
  margin: -10px 0 0;
}

.field.textarea textarea {
  width: 100%;
  line-height: 22px;
  margin: 0;
  padding: 0 5px 0 10px;
  border-radius: 0;
}
.field.textarea.floating-label {
  padding-top: 8px;
}

.row-count-helper {
  position: absolute;
  top: -100%;
  height: auto;
  z-index: -1;
  width: 100%;
  line-height: 22px;
  margin: 0;
  padding: 0;
}

.field.checkbox {
  padding: 10px 0;
}
.field.checkbox .ei {
  font-size: 16px;
  line-height: 18px;
  margin-right: 6px;
}
.field.checkbox label.editable {
  display: inline-block;
  font-size: 14px;
}
.field.checkbox label.editable input {
  font-size: 14px;
}

.field.color-picker .value {
  display: block;
  width: 100%;
  height: 42px;
  margin: 0;
  padding: 3px;
  border-radius: 8px;
  line-height: 42px;
}
.field.color-picker .value div {
  border-radius: 6px;
  width: 100%;
  height: 100%;
}
.field.color-picker .value div .ei {
  font-size: 16px;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  padding: 4px 0;
  margin-top: -10px;
}
.field.color-picker.inline {
  width: auto;
  min-width: 48px;
  margin-bottom: 0;
  margin-right: 5px;
}
.field.color-picker.inline .value {
  width: 100%;
}
.field.color-picker.inline:last-child {
  margin-right: 0;
}

.color-picker-popup {
  position: absolute;
  z-index: 12;
  left: 0;
  right: 0;
  bottom: 0;
}
.color-picker-popup .picker-container > .undo {
  margin-top: -50px;
  padding-left: 0;
  padding-right: 0;
  width: 40px;
}
.color-picker-popup .hue-picker {
  height: 40px;
}
.color-picker-popup .hue-picker div {
  float: left;
  width: 7.1428%;
  height: 100%;
  padding-top: 24px;
}
.color-picker-popup .hue-picker div.black, .color-picker-popup .hue-picker div.white {
  padding-top: 0;
}
.color-picker-popup .hue-picker div.black .ei, .color-picker-popup .hue-picker div.white .ei {
  font-size: 24px;
  line-height: 40px;
}
.color-picker-popup .hue-slider {
  width: 100%;
  height: 40px;
}
.color-picker-popup .hue-slider .cursor {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 4px;
  margin-left: -2px;
}
.color-picker-popup .hue-slider .cursor.active {
  top: -4px;
  bottom: -4px;
  width: 8px;
  margin-left: -4px;
}
.color-picker-popup .colors > div {
  height: 40px;
  width: 12.5%;
}
@media (min-width: 480px) {
  .color-picker-popup .colors > div {
    width: 8.333%;
  }
}
@media (min-width: 640px) {
  .color-picker-popup .colors > div {
    width: 6.25%;
  }
}
.color-picker-popup .colors > div .ei {
  font-size: 24px;
  line-height: 40px;
}

.popups-container .popup-container.color-picker-popup-container .popup-content {
  height: 434px;
}
@media (min-width: 480px) {
  .popups-container .popup-container.color-picker-popup-container .popup-content {
    height: 354px;
  }
}
@media (min-width: 640px) {
  .popups-container .popup-container.color-picker-popup-container .popup-content {
    height: 314px;
  }
}

.color-picker-in-popup {
  position: absolute;
  z-index: 12;
  left: 0;
  right: 0;
  bottom: 0;
}
.color-picker-in-popup .preview {
  height: 72px;
  padding: 10px 6px;
}
.color-picker-in-popup .preview label {
  display: block;
  height: 18px;
}
.color-picker-in-popup .preview > div {
  height: 52px;
  width: 100%;
  border-radius: 8px;
}
.color-picker-in-popup .hue-picker {
  height: 40px;
}
.color-picker-in-popup .hue-picker div {
  float: left;
  width: 7.1428%;
  height: 100%;
  padding-top: 24px;
}
.color-picker-in-popup .hue-picker div.black, .color-picker-in-popup .hue-picker div.white {
  padding-top: 0;
}
.color-picker-in-popup .hue-picker div.black .ei, .color-picker-in-popup .hue-picker div.white .ei {
  font-size: 24px;
  line-height: 40px;
}
.color-picker-in-popup .hue-slider {
  width: 100%;
  height: 40px;
}
.color-picker-in-popup .hue-slider .cursor {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 4px;
  margin-left: -2px;
}
.color-picker-in-popup .hue-slider .cursor.active {
  top: -4px;
  bottom: -4px;
  width: 8px;
  margin-left: -4px;
}
.color-picker-in-popup .colors > div {
  height: 40px;
  width: 12.5%;
}
@media (min-width: 480px) {
  .color-picker-in-popup .colors > div {
    width: 8.333%;
  }
}
@media (min-width: 640px) {
  .color-picker-in-popup .colors > div {
    width: 6.25%;
  }
}
.color-picker-in-popup .colors > div .ei {
  font-size: 24px;
  line-height: 40px;
}

.field.radio .option .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}
.field.radio .option > .ei.radio {
  font-size: 24px;
  padding-right: 8px;
}
.field.radio .option .field.text input[type=number] {
  width: 70px;
}

.field.select {
  width: 100%;
}
.field.select .value {
  width: 100%;
  height: 36px;
  margin: 0;
  padding: 0 32px 0 10px;
  line-height: 36px;
}
.field.select .value > .ei.right {
  font-size: 24px;
  position: absolute;
  right: 14px;
  top: 6px;
}
.field.select .value > span {
  max-width: 100%;
}
@media (max-width: 600px) {
  .field.select .value {
    padding: 0 26px 0 10px;
  }
  .field.select .value > .ei.right {
    right: 8px;
  }
}
.field.select.inline {
  width: auto;
  margin-bottom: 0;
}
.field.select.inline .value {
  width: auto;
}
.field.select.disabled .value {
  height: 24px;
  line-height: 24px;
  border-radius: 0;
}
.field.select.tags.floating-label {
  padding-top: 2px;
}

.field.datetime .value {
  width: 100%;
  height: 36px;
  line-height: 22px;
  margin: 0;
  padding: 10px 24px 10px 10px;
}
.field.datetime .value > .ei.arrow,
.field.datetime .value > .ei.clear {
  font-size: 24px;
  position: absolute;
  right: 12px;
  top: 6px;
}
.field.datetime .value > .ei.clear {
  padding: 6px 12px 10px;
  top: 0;
  right: 0;
}
.field.datetime.clearable.has-value .value {
  padding-right: 62px;
}
.field.datetime.clearable.has-value .value > .ei.arrow {
  right: 48px;
}
.field.datetime.disabled .value {
  height: 24px;
}
.field.datetime.disabled.floating-label .value {
  height: 36px;
}
.end-my-day-picker .field.datetime .value {
  padding: 0;
}

.datetime-popup {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
}
.datetime-popup .datetime-popup-content {
  height: 100%;
  max-height: 460px;
  width: 90%;
  border-radius: 8px;
  border: 0 none;
  padding: 44px 0 0 0;
}
.datetime-popup .datetime-popup-content:before {
  content: "";
  display: block;
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.datetime-popup .datetime-popup-content > section {
  position: absolute;
  top: 44px;
  left: 0;
  right: 0;
  bottom: 0;
}
.datetime-popup.only-time .datetime-popup-content header.open {
  border-bottom: 0 none;
}
.datetime-popup.only-time .datetime-popup-content > section {
  position: static;
  overflow: hidden;
}
@media (min-width: 640px) {
  .datetime-popup.open .datetime-popup-content {
    width: 500px;
  }
}
.datetime-popup.open.only-time .datetime-popup-content {
  height: auto;
  width: 320px;
}
.datetime-popup section > .display {
  padding: 10px 0;
}
.datetime-popup section > label {
  padding-left: 8px;
}

.track {
  width: 100%;
}
.track:before, .track:after {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 2;
  width: 20px;
  margin: 0;
}
.track:before {
  left: 0;
}
.track:after {
  right: 0;
}
.track ul {
  line-height: 36px;
}
.track ul,
.track ul li {
  margin: 0;
  padding: 0;
}
.track ul li {
  padding: 0 5px;
}
.track ul li:before {
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 0;
  height: 0;
  margin-left: -8px;
  z-index: 2;
}
.track.horizontal ul {
  width: 100%;
  height: 36px;
}
.track.vertical ul {
  width: 36px;
  height: 100%;
}

.day-picker {
  width: 100%;
  height: 221px;
}
.day-picker.header {
  width: 100%;
  height: 22px;
  margin: 0;
  padding: 0;
}
.day-picker.header div {
  width: 14.285%;
  max-width: 14.285%;
  padding: 2px 5px;
}
.day-picker:before {
  position: absolute;
  z-index: 2;
  top: 0;
  bottom: 0;
  width: 5px;
}
.day-picker.left:before {
  left: 0;
}
.day-picker.right:before {
  right: 0;
  width: 7px;
}
.day-picker section.calendar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.day-picker section.calendar.prev {
  left: -100%;
}
.day-picker section.calendar.next {
  left: 100%;
}
.day-picker section.calendar .week .day {
  width: 14.285%;
  max-width: 14.285%;
  height: 36px;
  line-height: 36px;
}
.day-picker section.calendar .week .day.today:before {
  content: "";
  position: absolute;
  right: 1px;
  bottom: 1px;
  width: 0;
  height: 0;
}

.slider.horizontal {
  padding: 0 0 10px;
  display: flex;
}
.slider.horizontal .ei {
  display: block;
  z-index: 2;
  width: 32px;
  font-size: 24px;
  line-height: 40px;
  margin: 0;
  flex-shrink: 0;
}
.slider.horizontal .track-line {
  margin: 0 60px;
  height: 42px;
  flex-grow: 1;
}
.slider.horizontal .track-line:before {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 1px;
  z-index: 1;
}
.slider.horizontal .track-line .min,
.slider.horizontal .track-line .max {
  height: 25px;
  min-width: 25px;
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 0;
  margin: 0;
  padding: 0 7px;
  line-height: 21px;
  border-radius: 10px;
}
.slider.horizontal .track-line .max {
  left: auto;
  right: 0;
}
.slider.horizontal .track-line .value {
  position: absolute;
  z-index: 3;
  top: 50%;
  border-radius: 13px;
  height: 26px;
  min-width: 26px;
  line-height: 24px;
}

.day-picker-inline-container {
  margin: 4px 0 0;
  padding: 4px 0;
}

.field.notifications-field .notification-line {
  padding: 4px 55px 4px 0;
}
.field.notifications-field .notification-line > button {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.field.notifications-field .notification-line .field.inline {
  padding: 4px 0;
}
.field.notifications-field .notification-line .field.inline .value, .field.notifications-field .notification-line .field.inline input {
  margin: 0 4px;
}
.field.notifications-field .notification-line .field.inline .value {
  padding: 0 18px 0 5px;
  line-height: 40px;
}
.field.notifications-field .notification-line .field.inline.unit .value {
  margin-left: -4px;
}
.field.notifications-field .notification-line .field.inline input {
  padding: 0 10px 3px;
  min-width: 40px;
}
.field.notifications-field .notification-line .field.inline.select .value > .ei.right {
  right: 5px;
}
.field.notifications-field .notification-line .field.inline.select .value > span {
  line-height: 36px;
}
.field.notifications-field .notification-line .field.inline.before input {
  width: 70px;
  padding-bottom: 0;
}
.field.notifications-field .notification-line span.label {
  line-height: 36px;
}
.field.notifications-field > button {
  margin-top: 10px;
}
.field.notifications-field > button .ei {
  margin-right: 5px;
}

.field.dynamic-fields-list ul,
.field.dynamic-fields-list li {
  margin: 0;
  padding: 8px 0 0;
}
.field.dynamic-fields-list button.add {
  margin-top: 10px;
}
.field.dynamic-fields-list li {
  display: flex;
  align-items: stretch;
  justify-content: stretch;
}
.field.dynamic-fields-list li .field-actions {
  display: flex;
  padding-top: 15px;
  flex-direction: row;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
}
.field.dynamic-fields-list li .field-actions .ei {
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  margin: 0;
  padding: 0;
  font-size: 16px;
}
.field.dynamic-fields-list li .field-action-button {
  margin: 0;
  padding: 0 0 10px 10px;
}
.field.dynamic-fields-list li .field-action-button button.add {
  margin: 0;
}
@media (max-width: 479px) {
  .field.dynamic-fields-list li.field-type-image .field-actions {
    flex-direction: column;
  }
}
.field.dynamic-fields-list li > .field {
  flex-grow: 1;
  min-width: 0;
}
.field.dynamic-fields-list li .field:last-of-type {
  margin-bottom: 0;
}

.dynamic-fields-summary {
  padding-top: 5px;
  margin-left: -8px;
}
.dynamic-fields-summary .timestamp:only-child {
  display: inline-block;
  padding-top: 5px;
  font-size: 14px;
}
.dynamic-fields-summary .summary-placeholder {
  padding-left: 8px;
}

.dynamic-card-title {
  margin: 0 auto;
  color: #12378F;
}
.dynamic-card-title .ei {
  display: inline-block;
  font-size: 16px;
  vertical-align: middle;
  padding: 0 5px;
  color: #bbb;
  position: relative;
}
.dynamic-card-title .ei:after {
  content: "";
  display: block;
  position: absolute;
  top: -12px;
  bottom: -6px;
  left: -8px;
  right: 0;
}
.dynamic-card-title .ei + .ei:after {
  left: 0;
  right: -8px;
}
.dynamic-card-title .ei:empty {
  width: 24px;
}
.dynamic-card-title span {
  position: relative;
}
.dynamic-card-title span:after {
  content: "";
  display: block;
  position: absolute;
  top: -12px;
  bottom: -6px;
  left: 0;
  right: -20px;
}
.dynamic-card-title span .ei:after {
  display: none;
}

.field.array-list-field ul,
.field.array-list-field li {
  margin: 0;
  padding: 0;
}
.field.array-list-field ul li {
  padding-bottom: 0;
  display: flex;
  align-items: center;
}
.field.array-list-field ul li.array-list-item {
  display: inline-block;
  vertical-align: middle;
}
.field.array-list-field ul li .field-wrapper {
  flex-grow: 1;
}
.field.array-list-field ul li .field-actions {
  flex-grow: 0;
  flex-shrink: 0;
}
.field.array-list-field ul li .field-actions .ei {
  flex-grow: 0;
  flex-shrink: 0;
  display: block;
  width: 32px;
  height: 32px;
  margin: 0;
  padding: 0;
  font-size: 16px;
  text-align: center;
  line-height: 32px;
}
.field.array-list-field.zebra ul li {
  padding: 0 8px 5px;
}

.field.file-upload .container {
  min-height: 100px;
  padding-top: 4px;
}
.field.file-upload .container > * {
  margin-right: 8px;
}
.field.file-upload .container > *:last-child {
  margin-right: 0;
}
.field.file-upload .container > *.no-margin {
  margin-right: 0;
}
.field.file-upload .container .picture-operations {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
}
.field.file-upload .container .picture-operations > div {
  flex-grow: 1;
  text-align: center;
  cursor: pointer;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  padding: 3px 6px;
  margin-bottom: 2px;
  border-radius: 6px;
  font-size: 11px;
  max-height: 50%;
}
.field.file-upload .container .picture-operations > div:last-child {
  margin-bottom: 0;
}
.field.file-upload .buttons {
  border-radius: 8px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  margin-right: 7px !important;
}
.field.file-upload .buttons button {
  margin: 0;
  padding: 0 20px;
  height: auto;
  border-radius: 0;
  box-shadow: none;
}
@media (max-width: 479px) {
  .field.file-upload .buttons button {
    padding: 0 10px;
  }
}
@media (max-width: 429px) {
  .field.file-upload .buttons {
    flex-direction: column;
  }
  .field.file-upload .buttons button {
    padding: 0 4px;
    flex-direction: row;
    justify-content: flex-start;
    flex-grow: 1;
    border-left: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
  .field.file-upload .buttons button:first-child {
    border-top: 0;
  }
  .field.file-upload .buttons button svg {
    margin-right: 4px;
  }
}
@media (max-width: 379px) {
  .field.file-upload .buttons {
    margin-right: 0;
  }
  .field.file-upload .buttons button {
    font-size: 12px;
  }
  .field.file-upload .buttons button svg {
    margin-right: 2px;
  }
}
.field.file-upload .file-input-container input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}
.field.file-upload .file-input-container .ei {
  font-size: 32px;
  margin: 0 8px;
}
.field.file-upload .file-input-container .ei.small {
  font-size: 18px;
}
.field.file-upload .ei.clear {
  height: 32px;
  font-size: 24px;
  padding: 0 10px;
}

.image-preview,
.image-thumbnail {
  margin: 0;
  padding: 0;
  border-radius: 8px;
  overflow: hidden;
}
.image-preview .ei.overlay,
.image-thumbnail .ei.overlay {
  display: block;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  overflow: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -16px 0 0 -16px;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  font-size: 24px;
  text-align: center;
  line-height: 32px;
}
.image-preview .ei.overlay.ei-playlist_play:before,
.image-thumbnail .ei.overlay.ei-playlist_play:before {
  position: relative;
  top: 1px;
  left: 2px;
}
.image-preview.video,
.image-thumbnail.video {
  width: 113px;
  height: 64px;
  background: #000;
}
.image-preview.video img,
.image-thumbnail.video img {
  width: 113px;
}

.reset-image {
  display: flex;
  align-items: center;
}
.reset-image .ei {
  display: flex;
  align-items: center;
}

.history-list-container {
  height: 100%;
  width: 100%;
}
.card-summary .history-list-container {
  padding: 6px 8px 6px 0;
}
.history-list-container h3 {
  margin: 0;
  padding: 0 0 5px 0;
}
.history-list-container h3 .show-history {
  margin: -9px 0;
  padding: 10px 0 10px 5px;
}
.history-list-container ul.comments-list, .history-list-container ul.comments-list li {
  padding: 0;
  margin: 0;
}
.history-list-container ul.comments-list li {
  padding: 3px 0;
  border-radius: 4px;
}
.history-list-container ul.comments-list li .speech-bubble {
  margin-top: 7px;
  border-radius: 0 8px 8px;
  padding: 8px;
  min-height: 42px;
}
.history-list-container ul.comments-list li .speech-bubble:before {
  position: absolute;
  top: -8px;
  left: 0;
  width: 0;
  height: 0;
}
.history-list-container ul.comments-list li .speech-bubble .comment {
  padding: 8px 8px 10px;
}
.history-list-container ul.comments-list li .speech-bubble .button {
  margin: 0;
  padding: 0;
  width: 42px;
  height: 42px;
  margin: -8px -8px 0 0;
}
.history-list-container ul.comments-list li .speech-bubble .button i {
  font-size: 20px;
  margin-top: -2px;
}
.history-list-container .field.textarea {
  padding-top: 10px;
}

.recurrence-edit > span {
  padding-right: 5px;
}
.recurrence-edit .weekdays {
  padding-bottom: 0;
}
.recurrence-edit .weekdays span {
  height: 36px;
  line-height: 36px;
  padding: 0 9px;
  margin-left: -1px;
}
.recurrence-edit .weekdays span:first-child {
  margin-left: 0;
  border-radius: 8px 0 0 8px;
}
.recurrence-edit .weekdays span:last-child {
  border-radius: 0 8px 8px 0;
}

.form-tabs-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}
.form-tabs-container > nav {
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  z-index: 4;
  width: 51px;
}
.form-tabs-container > nav button {
  padding: 5px 0;
  line-height: auto;
  font-size: 30px;
  width: 48px;
  height: auto;
  border-radius: 0 12px 12px 0;
  margin: 3px 0;
}
.form-tabs-container > nav button:first-child {
  margin-top: 0;
}
.form-tabs-container > nav button .ei {
  font-size: 30px;
  margin: 20px 0;
}
.form-tabs-container > nav button.active {
  width: 50px;
  margin-left: -2px;
}
.form-tabs-container .form-tab {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 10px;
}
.form-tabs-container .form-tab.active {
  z-index: 3;
}
.form-tabs-container.has-tabs .form-tab {
  right: 49px;
}

.field-display.text {
  padding: 4px 0;
}
.MuiGrid-root .field-display.text {
  padding: 0;
  float: right;
}
.MuiGrid-root .field-display.text .value-wrapper {
  padding: 0;
}
.field-display.text > .ei {
  font-size: 16px;
  width: 18px;
  margin-right: 6px;
}
.field-display.text.summary-placeholder {
  padding: 6px 0;
}
.field-display.text .value-wrapper {
  padding-right: 8px;
}
.field-display.text.touched.error .ei.ei-error {
  margin-right: 4px;
}
.field-display.text a {
  display: inline-block;
  overflow-wrap: anywhere;
  text-decoration: none;
}
.field-display.text > div {
  padding-right: 8px;
  overflow-wrap: anywhere;
}
.field-display.text > div div.link {
  display: inline-block;
}
.field-display.text.icon-on-right > div {
  padding-right: 4px;
}
.field-display.text.icon-on-right > div div.link {
  display: flex;
  align-items: flex-start;
}
.field-display.text.icon-on-right > div div.link span {
  order: 1;
  flex-grow: 1;
  overflow-wrap: anywhere;
}
.field-display.text.icon-on-right > div div.link .float-right {
  order: 2;
  flex-shrink: 0;
  margin: 2px 0 0 8px;
  position: relative;
}
.field-display.text.icon-on-right > div div.link .float-right:before {
  content: "";
  display: block;
  position: absolute;
  top: -8px;
  left: -8px;
  bottom: -4px;
  right: -4px;
}
.field-display.text.location .value-wrapper {
  padding-right: 4px;
}
.field-display.status {
  border-radius: 5px;
  margin: 4px 8px 0 4px;
  padding: 2px 6px;
}
.field-display.tags {
  padding: 4px 0px 4px 0px;
}
.field-display.tags label {
  margin: 0 0 4px 8px;
}
.field-display.tags p {
  padding: 4px 8px 0 6px;
}
.field-display.tags p.tags-list.compact {
  line-height: 3px;
  margin: 0;
}
.field-display.layout-vertical {
  display: block;
  padding-left: 16px !important;
  line-height: 20px;
}
.field-display.layout-vertical label {
  font-size: 11px;
  margin-left: -8px !important;
  text-transform: capitalize;
  flex-shrink: 0;
}
.field-display.flex {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
}
.field-display.flex label {
  flex-grow: 0;
}
.field-display.flex label,
.field-display.flex .value-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.field-display.has-actions .value-wrapper button {
  display: inline-block;
  margin: 0 0 0 8px;
  vertical-align: middle;
}
.field-display.image {
  display: block;
  padding: 4px 0 6px;
}

.show-icon {
  font-size: 20px;
  padding: 4px 4px 5px 5px;
  width: 29px;
  height: 29px;
  margin: 0 6px;
}
.show-icon.active {
  border-radius: 20px;
}

.display-field-padding {
  width: 100%;
  padding: 0 6px;
}

table.summary th {
  padding-right: 5px;
  min-width: 110px;
}
table.summary td button.det-sms {
  padding-left: 0px;
  padding-right: 0px;
}
table.summary.auto-title-width th {
  min-width: auto;
}
table.summary.title-width-80 th {
  min-width: 80px;
}

ul.summary-list {
  margin: 0;
  padding: 0;
}
ul.summary-list,
ul.summary-list li {
  list-style: none;
  list-style-type: none;
}
ul.summary-list.text li:before {
  content: "- ";
}

.progress-bar {
  position: absolute;
  z-index: 10;
  margin-right: 0 !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.progress-bar .loading-bar,
.progress-bar .solid-bar {
  height: 8px;
  width: 50%;
  border-radius: 4px;
  margin-bottom: 8px;
}
.progress-bar .loading-bar div,
.progress-bar .solid-bar div {
  width: 0;
  height: 100%;
  border-radius: 4px;
}
.progress-bar.tall .loading-bar,
.progress-bar.tall .solid-bar {
  height: 16px;
}
.progress-bar.tall .loading-bar,
.progress-bar.tall .loading-bar div,
.progress-bar.tall .solid-bar,
.progress-bar.tall .solid-bar div {
  border-radius: 8px;
}
.progress-bar.overlaid .loading-bar,
.progress-bar.overlaid .solid-bar {
  position: relative;
}
.progress-bar.overlaid .loading-bar div,
.progress-bar.overlaid .solid-bar div {
  position: absolute;
  width: auto;
  height: auto;
  top: -3px;
  bottom: -3px;
  left: -3px;
  margin-right: -3px;
  border-radius: 12px;
}
.progress-bar.inline {
  position: static;
  padding: 12px 0 16px;
}
.progress-bar.inline .loading-bar,
.progress-bar.inline .solid-bar {
  width: 90%;
}
.progress-bar.full-width {
  padding: 8px 0;
}
.progress-bar.full-width .loading-bar,
.progress-bar.full-width .solid-bar {
  width: 100%;
}
.progress-bar button .ei {
  margin-right: 5px;
}

.progress-loading-bar {
  height: 8px;
  width: 50%;
  border-radius: 4px;
}
.progress-loading-bar div {
  width: 0;
  height: 100%;
  border-radius: 4px;
}

@-webkit-keyframes autofill {
  to {
    color: #000018;
    background: transparent;
  }
}
@keyframes autofill {
  to {
    color: #000018;
    background: transparent;
  }
}
*:-webkit-autofill {
  -webkit-animation-name: autofill;
  animation-name: autofill;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.field.touched label .required {
  color: #5cb85c;
}
.field.touched input,
.field.touched .value {
  border-color: #5cb85c;
}
.field.touched.error label .required {
  color: #DC2770;
}
.field.touched.error input,
.field.touched.error .value {
  border-color: #DC2770;
}
.field .error:not(.field) {
  color: #DC2770;
}
.field.disabled input,
.field.disabled .value,
.field.disabled textarea {
  background: transparent !important;
}
.field.disabled label {
  color: #888;
}
.field .details {
  color: #777;
}

label.small {
  font-size: 13px;
}

/* do not group these rules */
span.tag:before {
  background: white;
}
.field-display span.tag:before {
  background: white;
  border-top-color: #ccc;
  border-left-color: #ccc;
}
.field-display span.tag.compact {
  color: transparent;
}

.field-lang i.ei.ei-visibility_off {
  color: #888;
}

.get-link {
  border-color: transparent;
  background: transparent;
  color: #000018;
}

label.color-primary {
  color: #12378F;
}

.field.text input, .field.text.mobile .value {
  border-color: transparent;
  background: transparent;
  color: #000018;
}
.field.text input:focus, .field.text.mobile .value:focus {
  border-color: #5bc0de !important;
}
.field.text.forgot-password .forgot-btn {
  color: #888;
}
.field.text input:-webkit-autofill,
.field.text input:-webkit-autofill:hover,
.field.text input:-webkit-autofill:focus,
.field.text textarea:-webkit-autofill,
.field.text textarea:-webkit-autofill:hover,
.field.text textarea:-webkit-autofill:focus,
.field.text select:-webkit-autofill,
.field.text select:-webkit-autofill:hover,
.field.text select:-webkit-autofill:focus {
  border: 1px solid #fff !important;
  border-bottom: 1px solid transparent !important;
  outline: 1px solid #fff !important;
  -webkit-text-fill-color: #000018 !important;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset !important;
  transition: background-color 5000s ease-in-out 0s !important;
  font-size: 14px !important;
}
.field.text input:-webkit-autofill:focus,
.field.text textarea:-webkit-autofill:focus,
.field.text select:-webkit-autofill:focus {
  border-bottom: 1px solid #5bc0de !important;
}
.field.text .field-wrapper > a {
  color: #5cb85c;
}
.field.text.mobile.placeholder .value {
  color: #3A506B;
}
.field.text.mobile.multiline .value span {
  color: #12378F;
}
.field.text.mobile.clear.label .input-wrapper > .ei {
  color: #888;
}
.field.text.clear .input-wrapper > .ei {
  color: #888;
}
.field.text.touched.error input,
.field.text.touched.error .value {
  border-color: #DC2770;
}
.field.text.disabled input,
.field.text.disabled .value {
  background: transparent;
}
.field.text.has-action {
  color: #12378F;
}
.field.text.has-action a {
  color: #12378F;
}
.field.text.text-select .value input {
  background: transparent;
}
.field.text.text-select .value.has-options ul {
  background: #fff;
  border-color: transparent;
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.3);
}
.field.text.text-select .value.has-options ul li {
  border-top-color: transparent;
}

.field.textarea textarea {
  border-color: transparent;
  background: #fff !important;
  color: #000018;
}
.field.textarea textarea:focus {
  border-color: #12378F !important;
}
.field.textarea.touched textarea {
  border-color: #5cb85c;
}
.field.textarea.touched.error textarea {
  border-color: #DC2770;
}

.field.checkbox {
  color: #3A506B;
}

.field.color-picker .value {
  border-color: transparent;
  background: #fff;
  color: #000018;
}
.field.color-picker .value div .ei {
  background: rgba(255, 255, 255, 0.7);
  border-color: #999;
}
.field.color-picker:focus .value {
  border-bottom-color: #5bc0de;
  border-right-color: #5bc0de;
}
.field.color-picker.touched .value {
  border-color: #5cb85c;
}
.field.color-picker.touched.error .value {
  border-color: #DC2770;
}

.color-picker-popup .picker-container > .undo {
  background: #fff;
  color: #DC2770;
}
.color-picker-popup .hue-picker div.black .ei {
  color: #fff;
}
.color-picker-popup .hue-slider {
  background: -moz-linear-gradient(right, #ff0000 0%, #ffff00 17%, #00ff00 33%, #00ffff 50%, #0000ff 67%, #ff00ff 83%, #ff0000 100%);
  background: -ms-linear-gradient(right, #ff0000 0%, #ffff00 17%, #00ff00 33%, #00ffff 50%, #0000ff 67%, #ff00ff 83%, #ff0000 100%);
  background: -o-linear-gradient(right, #ff0000 0%, #ffff00 17%, #00ff00 33%, #00ffff 50%, #0000ff 67%, #ff00ff 83%, #ff0000 100%);
  background: -webkit-gradient(linear, left top, right top, from(#ff0000), color-stop(0.17, #ffff00), color-stop(0.33, #00ff00), color-stop(0.5, #00ffff), color-stop(0.67, #0000ff), color-stop(0.83, #ff00ff), to(#ff0000));
  background: -webkit-linear-gradient(right, #ff0000 0%, #ffff00 17%, #00ff00 33%, #00ffff 50%, #0000ff 67%, #ff00ff 83%, #ff0000 100%);
  background: linear-gradient(to right, #ff0000 0%, #ffff00 17%, #00ff00 33%, #00ffff 50%, #0000ff 67%, #ff00ff 83%, #ff0000 100%);
}
.color-picker-popup .hue-slider .cursor {
  border-color: #000;
  background: #fff;
}
.color-picker-popup .colors > div .ei {
  color: #000018;
}
.color-picker-popup .colors > div:nth-last-child(-n+25) .ei {
  color: #fff;
}

.color-picker-in-popup .picker-container .preview label {
  color: #fff;
}
.color-picker-in-popup .picker-container .preview > div {
  border: 8px solid #fff;
}
.color-picker-in-popup .hue-picker div.black .ei {
  color: #fff;
}
.color-picker-in-popup .hue-slider {
  background: -moz-linear-gradient(right, #ff0000 0%, #ffff00 17%, #00ff00 33%, #00ffff 50%, #0000ff 67%, #ff00ff 83%, #ff0000 100%);
  background: -ms-linear-gradient(right, #ff0000 0%, #ffff00 17%, #00ff00 33%, #00ffff 50%, #0000ff 67%, #ff00ff 83%, #ff0000 100%);
  background: -o-linear-gradient(right, #ff0000 0%, #ffff00 17%, #00ff00 33%, #00ffff 50%, #0000ff 67%, #ff00ff 83%, #ff0000 100%);
  background: -webkit-gradient(linear, left top, right top, from(#ff0000), color-stop(0.17, #ffff00), color-stop(0.33, #00ff00), color-stop(0.5, #00ffff), color-stop(0.67, #0000ff), color-stop(0.83, #ff00ff), to(#ff0000));
  background: -webkit-linear-gradient(right, #ff0000 0%, #ffff00 17%, #00ff00 33%, #00ffff 50%, #0000ff 67%, #ff00ff 83%, #ff0000 100%);
  background: linear-gradient(to right, #ff0000 0%, #ffff00 17%, #00ff00 33%, #00ffff 50%, #0000ff 67%, #ff00ff 83%, #ff0000 100%);
}
.color-picker-in-popup .hue-slider .cursor {
  border-color: #000;
  background: #fff;
}
.color-picker-in-popup .colors > div .ei {
  color: #000018;
}
.color-picker-in-popup .colors > div:nth-last-child(-n+25) .ei {
  color: #fff;
}

.field.radio .option .overlay {
  background: rgba(255, 255, 255, 0.5);
}
.field.radio .option.checked > .ei.radio {
  color: #12378F;
}
.field.radio.color-primary .option .overlay {
  background: transparent;
}
.field.radio.color-primary .option.checked {
  color: #12378F;
}

.field.select .value {
  border-color: transparent;
  background: transparent;
  color: #000018;
}
.field.select .value > .ei.right {
  color: #777;
}
.field.select.none .value {
  color: #3A506B;
}
.field.select.primary.none .value {
  color: #12378F;
}
.field.select.primary.none .value .ei {
  color: #12378F;
}
.field.select.touched .value {
  border-color: #5cb85c;
}
.field.select.touched.error .value {
  border-color: #DC2770;
}
.field.select .more {
  color: #12378F;
}

.field.datetime .value {
  border-color: transparent;
  background: transparent !important;
  color: #000018;
}
.field.datetime .value:focus {
  border-color: #12378F !important;
}
.field.datetime .value > .ei.arrow,
.field.datetime .value > .ei.clear {
  color: #777;
}
.field.datetime .value > .ei.clear {
  color: #888;
}
.field.datetime .value .action.disabled {
  color: #888;
}
.field.datetime.placeholder .value {
  color: #3A506B;
}
.field.datetime.disabled .value {
  background: transparent !important;
}

#mobileRoot .datetime-popup .datetime-popup-content {
  background: #40a3ea;
  border-color: rgba(0, 0, 0, 0.2);
}
#mobileRoot .datetime-popup .datetime-popup-content > header {
  border-bottom-color: #e6e6e6;
  background: transparent;
  color: #fff;
}
#mobileRoot .datetime-popup .datetime-popup-content > section {
  background: transparent;
}
#mobileRoot .datetime-popup section > .display {
  border-top-color: transparent;
  border-bottom-color: transparent;
  color: #000018;
  background: #fff;
}
#mobileRoot .datetime-popup section > .display.timezone {
  border-top: 0 none;
  overflow: hidden;
  max-height: 0;
  padding: 0;
  will-change: max-height, padding;
  transition: all 0.3s ease-out;
}
#mobileRoot .datetime-popup section > .display.timezone.visible {
  max-height: 60px;
  padding: 10px 0;
}
#mobileRoot .datetime-popup section > label {
  color: #fff;
}

.track {
  background: transparent;
  color: #fff;
}
.track:before {
  background: #12378F;
  background: -moz-linear-gradient(left, #12378F 0%, rgba(18, 55, 143, 0) 100%);
  background: -webkit-linear-gradient(left, #12378F 0%, rgba(18, 55, 143, 0) 100%);
  background: linear-gradient(to right, #12378F 0%, rgba(18, 55, 143, 0) 100%);
}
.track:after {
  background: #12378F;
  background: -moz-linear-gradient(right, #12378F 0%, rgba(18, 55, 143, 0) 100%);
  background: -webkit-linear-gradient(right, #12378F 0%, rgba(18, 55, 143, 0) 100%);
  background: linear-gradient(to left, #12378F 0%, rgba(18, 55, 143, 0) 100%);
}
.track ul li:before {
  border-color: transparent transparent #e6e6e6;
}
.track.horizontal {
  border-bottom-color: #e6e6e6;
}
#mobileRoot .datetime-popup .track.horizontal .ps__rail-x {
  height: 12px;
}
#mobileRoot .datetime-popup .track.horizontal .ps__rail-x:hover > .ps__thumb-x,
#mobileRoot .datetime-popup .track.horizontal .ps__rail-x:focus > .ps__thumb-x,
#mobileRoot .datetime-popup .track.horizontal .ps__rail-x.ps--clicking .ps__thumb-x {
  height: 8px;
}
#mobileRoot .datetime-popup .track.horizontal:not(.day-plan-track) .ps .ps__rail-x:hover,
#mobileRoot .datetime-popup .track.horizontal:not(.day-plan-track) .ps .ps__rail-y:hover,
#mobileRoot .datetime-popup .track.horizontal:not(.day-plan-track) .ps .ps__rail-x:focus,
#mobileRoot .datetime-popup .track.horizontal:not(.day-plan-track) .ps .ps__rail-y:focus,
#mobileRoot .datetime-popup .track.horizontal:not(.day-plan-track) .ps .ps__rail-x.ps--clicking,
#mobileRoot .datetime-popup .track.horizontal:not(.day-plan-track) .ps .ps__rail-y.ps--clicking {
  background-color: rgba(255, 255, 255, 0.1);
}
#mobileRoot .datetime-popup .track.horizontal:not(.day-plan-track) .ps__thumb-x,
#mobileRoot .datetime-popup .track.horizontal:not(.day-plan-track) .ps__rail-x:hover > .ps__thumb-x,
#mobileRoot .datetime-popup .track.horizontal:not(.day-plan-track) .ps__rail-x:focus > .ps__thumb-x,
#mobileRoot .datetime-popup .track.horizontal:not(.day-plan-track) .ps__rail-x.ps--clicking .ps__thumb-x {
  background-color: #fff;
}

.day-picker.header {
  color: #fff;
  background: transparent;
}
.day-picker.header div {
  border-bottom-color: transparent;
}
.day-picker-inline-container .day-picker.header {
  color: #12378F;
}
.day-picker:before {
  background: #DC2770;
}
.day-picker section.calendar {
  background: #fff;
}
.day-picker section.calendar .week {
  border-bottom-color: transparent;
}
.day-picker section.calendar .week .day {
  border-right-color: transparent;
}
.day-picker section.calendar .week .day.today:before {
  border: 4px solid #12378F;
  border-color: transparent #12378F #12378F transparent;
}
.day-picker section.calendar .week .day.saturday, .day-picker section.calendar .week .day.sunday {
  color: #12378F;
}
.day-picker section.calendar .week .day.active {
  background: #12378F;
  color: #fff;
}
.day-picker section.calendar .week .day.active.today:before {
  border-color: transparent #e6e6e6 #e6e6e6 transparent;
}
.day-picker section.calendar .week .day.prev, .day-picker section.calendar .week .day.next {
  color: rgba(0, 0, 24, 0.4);
}
.day-picker section.calendar .week .day.prev.saturday, .day-picker section.calendar .week .day.prev.sunday, .day-picker section.calendar .week .day.next.saturday, .day-picker section.calendar .week .day.next.sunday {
  color: rgba(18, 55, 143, 0.4);
}
.day-picker section.calendar .week .day.disabled {
  background-color: rgba(255, 255, 255, 0.3);
  color: rgba(0, 0, 24, 0.3);
}
.day-picker section.calendar .week .day.disabled.saturday, .day-picker section.calendar .week .day.disabled.sunday {
  color: rgba(18, 55, 143, 0.3);
}
.day-picker section.calendar .week .day span {
  display: inline-block;
  position: relative;
  z-index: 2;
  line-height: 14px;
  padding: 2px 5px;
  border-radius: 20px;
  background: #fff;
}
.day-picker section.calendar .week .day.streak-start:after, .day-picker section.calendar .week .day.streak-end:after, .day-picker section.calendar .week .day.in-streak:after {
  content: "";
  display: block;
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  top: 50%;
  bottom: auto;
  height: 8px;
  margin-top: -4px;
}
.day-picker section.calendar .week .day.streak-start:after {
  left: 50%;
}
.day-picker section.calendar .week .day.streak-end:after {
  right: 50%;
}

.day-picker-inline-container {
  overflow: hidden;
  border-top: 1px solid #12378F;
}

.slider.horizontal .display, .datetime-popup .slider.horizontal .display {
  color: #fff;
}
.slider.horizontal .ei {
  color: #fff;
}
.slider.horizontal .track-line:before {
  background: transparent;
}
.slider.horizontal .track-line .min,
.slider.horizontal .track-line .max {
  color: #fff;
  background: #12378F;
  border-color: transparent;
}
.slider.horizontal .track-line .value {
  border-color: #fff;
  background: #12378F;
  color: #fff;
}
.slider.horizontal.dragging .track-line .value {
  background: #1848bc;
}

.field.notifications-field .notification-line {
  border-top-color: transparent;
}
.field.notifications-field .notification-line:last-of-type {
  border-bottom-color: transparent;
}

.field.dynamic-fields-list li {
  border-top-color: transparent;
}

.field.array-list-field.zebra ul li:nth-child(odd) {
  background: #f5f5f5;
}

.field.tasks-list .list li.completed {
  background: #f8fff8;
}
.field.tasks-list .list li.completed .ei.completed {
  color: #5cb85c;
}
.field.tasks-list .list li.completed h3 span {
  color: #3A506B;
}

.field.file-upload .buttons {
  border-color: rgba(0, 0, 0, 0.3);
}
.field.file-upload .buttons button {
  color: #12378F;
  background: transparent;
  border-left-color: rgba(0, 0, 0, 0.3);
}
.field.file-upload .buttons button .ei {
  color: #777;
}
.field.file-upload .file-input-container {
  color: #40a3ea;
  border-color: 1px dashed transparent;
}
.field.file-upload .file-input-container .ei {
  color: #777;
}
.field.file-upload .ei.clear {
  color: #777;
}

.image-preview,
.image-thumbnail {
  background-position: center;
  background-size: cover;
  background-color: #f5f5f5;
  background-repeat: no-repeat;
}
.image-preview.no-image,
.image-thumbnail.no-image {
  color: #777;
}
.image-preview .ei.open,
.image-thumbnail .ei.open {
  background: rgba(0, 0, 0, 0.35);
}
.image-preview .ei.open:before,
.image-thumbnail .ei.open:before {
  color: #fff;
}
.image-preview .ei.spinning,
.image-preview .ei.error,
.image-thumbnail .ei.spinning,
.image-thumbnail .ei.error {
  color: #777;
}
.image-preview.video .ei.spinning,
.image-thumbnail.video .ei.spinning {
  opacity: 0.3;
}

.history-list-container ul.comments-list li {
  background-color: transparent;
}
.history-list-container ul.comments-list li.autofocus {
  background-color: #5cb85c;
}
.history-list-container ul.comments-list li .speech-bubble {
  background: rgba(0, 0, 0, 0.1);
}
.history-list-container ul.comments-list li .speech-bubble:before {
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
}
.history-list-container .field.textarea textarea[id].highlight {
  background-color: #5cb85c !important;
}

.recurrence-edit .weekdays span {
  border-color: transparent;
  background: #fff;
}
.recurrence-edit .weekdays span.selected {
  background: #12378F;
  color: #fff;
}

.form-tabs-container {
  background: #12378F;
}
.color-task .form-tabs-container {
  background: #40a3ea;
}
.color-contact .form-tabs-container {
  background: #ff8309;
}
.color-image .form-tabs-container {
  background: #ffca08;
}
.color-project .form-tabs-container {
  background: #a473ff;
}
.color-goal .form-tabs-container {
  background: #a473ff;
}
.color-friend .form-tabs-container {
  background: #ff8309;
}
.color-feedback .form-tabs-container {
  background: #21ac66;
}
.color-settings .form-tabs-container {
  background: #e6e6e6;
}
.form-tabs-container > nav button {
  background: #e6e6e6;
  border-left-color: silver;
  border-bottom-color: silver;
  box-shadow: unset;
}
.color-task .form-tabs-container > nav button {
  border-color: #11619a;
}
.color-contact .form-tabs-container > nav button {
  border-color: #d56a00;
}
.color-image .form-tabs-container > nav button {
  border-color: #d4a700;
}
.color-project .form-tabs-container > nav button {
  border-color: #8340ff;
}
.color-goal .form-tabs-container > nav button {
  border-color: #8340ff;
}
.color-friend .form-tabs-container > nav button {
  border-color: #d56a00;
}
.color-feedback .form-tabs-container > nav button {
  border-color: #19814d;
}
.color-settings .form-tabs-container > nav button {
  border-color: #cdcdcd;
}
.form-tabs-container > nav button .ei {
  color: #3A506B;
}
.form-tabs-container > nav button.active {
  background: #f7ffff;
  border-color: transparent;
  border-bottom-color: silver;
}
.color-task .form-tabs-container > nav button.active {
  border-bottom-color: #11619a;
  background: #f7ffff;
}
.color-contact .form-tabs-container > nav button.active {
  border-bottom-color: #d56a00;
  background: #fffcf9;
}
.color-image .form-tabs-container > nav button.active {
  border-bottom-color: #d4a700;
  background: #fffff7;
}
.color-project .form-tabs-container > nav button.active {
  border-bottom-color: #8340ff;
  background: #fdfbff;
}
.color-goal .form-tabs-container > nav button.active {
  border-bottom-color: #8340ff;
  background: #fdfbff;
}
.color-friend .form-tabs-container > nav button.active {
  border-bottom-color: #d56a00;
  background: #fffcf9;
}
.color-feedback .form-tabs-container > nav button.active {
  border-bottom-color: #19814d;
  background: #f7fff7;
}
.color-settings .form-tabs-container > nav button.active {
  border-bottom-color: #cdcdcd;
  background: #e6e6e6;
}
.form-tabs-container > nav button.active .ei {
  color: #000018;
}
.form-tabs-container .form-tab {
  background: #f7ffff;
}
.color-task .form-tabs-container .form-tab {
  background: #f7ffff;
  border-color: #11619a;
}
.color-contact .form-tabs-container .form-tab {
  background: #fffcf9;
  border-color: #d56a00;
}
.color-image .form-tabs-container .form-tab {
  background: #fffff7;
  border-color: #d4a700;
}
.color-project .form-tabs-container .form-tab {
  background: #fdfbff;
  border-color: #8340ff;
}
.color-goal .form-tabs-container .form-tab {
  background: #fdfbff;
  border-color: #8340ff;
}
.color-friend .form-tabs-container .form-tab {
  background: #fffcf9;
  border-color: #d56a00;
}
.color-feedback .form-tabs-container .form-tab {
  background: #f7fff7;
  border-color: #19814d;
}
.color-settings .form-tabs-container .form-tab {
  background: #f6f6f6;
  border-color: #cdcdcd;
}

.field-display.text.touched.error .value-wrapper {
  color: #DC2770;
}
.field-display.status {
  background: #12378F;
  color: #fff;
}
.field-display.status.progress {
  background: #fceedb;
  color: #f0ad4e;
}
.field-display.status.done {
  background: #c7e6c7;
  color: #5cb85c;
}

.timestamp {
  color: #888;
}

.show-icon {
  color: #888;
}
.show-icon.active {
  color: #5cb85c;
  background: white;
}

table.summary th {
  color: #777;
}
table.summary td a {
  color: #12378F;
}
table.summary td button.det-sms {
  background: transparent;
}

@keyframes moveGradient {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: -500% 0%;
  }
}
.progress-bar {
  background: rgba(255, 255, 255, 0.8);
}
.progress-bar .loading-bar {
  border-color: #777;
}
.progress-bar .loading-bar div {
  background-size: 500% !important;
  animation: moveGradient 10s linear infinite;
  transition: width 0.15s ease-out;
  background: linear-gradient(150deg, #12378F 0%, #12378F 22%, #fff 25%, #12378F 28%, #12378F 72%, #fff 75%, #12378F 78%, #12378F 100%);
}
.progress-bar .solid-bar {
  border-color: #777;
}
.progress-bar .solid-bar div {
  background-color: #5bc0de;
}
.progress-bar.overlaid .loading-bar,
.progress-bar.overlaid .solid-bar {
  border: 0 none;
}
.progress-bar.overlaid .loading-bar div,
.progress-bar.overlaid .solid-bar div {
  border: 3px solid #fff;
}

.progress-loading-bar {
  border-color: #12378F;
  background: rgba(255, 255, 255, 0.8);
}
.progress-loading-bar div {
  background-size: 500% !important;
  animation: moveGradient 10s linear infinite;
  transition: width 0.15s ease-out;
  background: linear-gradient(150deg, #12378F 0%, #12378F 22%, #fff 25%, #12378F 28%, #12378F 72%, #fff 75%, #12378F 78%, #12378F 100%);
}

.field label:not(.MuiFormControlLabel-root) {
  font-size: 11px;
}
.field label:not(.MuiFormControlLabel-root) .required {
  font-size: 16px;
}
.field.floating-label label:not(.MuiFormControlLabel-root) {
  font-size: 12px;
}
.field.floating-label.has-value label, body.desktop .field.floating-label.has-value label, .field.floating-label.focus label, body.desktop .field.floating-label.focus label,
.field.floating-label label.editable,
body.desktop .field.floating-label label.editable {
  font-size: 13px;
}
.field .error:not(.field) span {
  font-size: 11px;
}
.field .details {
  font-size: 11px;
}

label.small {
  font-size: 13px;
}

.field-display span.tag {
  font-size: 11px;
}

.field.text .field-wrapper > a {
  font-size: 24px;
}
.field.text .description {
  font-size: 12px;
}

.field.select .more {
  font-size: 11px;
}
.field.select .more span {
  font-weight: bold;
}

#mobileRoot .datetime-popup section > label {
  font-size: 11px;
}

.slider.horizontal .track-line .min, .slider.horizontal .track-line .max {
  font-size: 10px;
}
.slider.horizontal .track-line .value {
  font-size: 11px;
}

.field-display.status {
  font-size: 11px;
}

.timestamp {
  font-size: 11px;
}

table.summary th {
  font-size: 11px;
}

.progress-bar .number {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}

/*
 * Container style
 */
.ps {
  overflow: hidden !important;
  overflow-anchor: none;
  -ms-overflow-style: none;
  touch-action: auto;
  -ms-touch-action: auto;
}

/*
   * Scrollbar rail styles
   */
.ps__rail-x {
  display: none;
  opacity: 0;
  transition: background-color 0.2s linear, opacity 0.2s linear;
  -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
  height: 14px;
  /* there must be 'bottom' or 'top' for ps__rail-x */
  bottom: 1px;
  /* please don't change 'position' */
  position: absolute;
  z-index: 65535;
}

.ps__rail-y {
  display: none;
  opacity: 0;
  transition: background-color 0.2s linear, opacity 0.2s linear;
  -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
  width: 15px;
  /* there must be 'right' or 'left' for ps__rail-y */
  right: 0;
  /* please don't change 'position' */
  position: absolute;
  z-index: 65535;
}

.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y {
  display: block;
  background-color: transparent;
}

.ps:hover > .ps__rail-x,
.ps:hover > .ps__rail-y,
.ps--focus > .ps__rail-x,
.ps--focus > .ps__rail-y,
.ps--scrolling-x > .ps__rail-x,
.ps--scrolling-y > .ps__rail-y {
  opacity: 0.6;
}

.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
  background-color: transparent;
}

/*
   * Scrollbar thumb styles
   */
.ps__thumb-x {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 6px;
  transition: background-color 0.2s linear, height 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s linear, height 0.2s ease-in-out;
  height: 3px;
  /* there must be 'bottom' for ps__thumb-x */
  bottom: 50%;
  /* please don't change 'position' */
  position: absolute;
  transform: translateY(50%);
}
.ps-color-dark .ps__thumb-x, .toasts-container .ps__thumb-x {
  background-color: rgba(0, 0, 0, 0.1);
}

.ps__thumb-y {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 6px;
  transition: background-color 0.2s linear, width 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s linear, width 0.2s ease-in-out;
  width: 3px;
  /* there must be 'right' for ps__thumb-y */
  right: 2px;
  /* please don't change 'position' */
  position: absolute;
}
.ps-color-dark .ps__thumb-y, .toasts-container .ps__thumb-y {
  background-color: rgba(0, 0, 0, 0.1);
}

.ps__rail-x:hover > .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x,
.ps__rail-x.ps--clicking .ps__thumb-x {
  background-color: rgba(255, 255, 255, 0.7);
  height: 12px;
}
.ps-color-dark .ps__rail-x:hover > .ps__thumb-x, .toasts-container .ps__rail-x:hover > .ps__thumb-x,
.ps-color-dark .ps__rail-x:focus > .ps__thumb-x,
.toasts-container .ps__rail-x:focus > .ps__thumb-x,
.ps-color-dark .ps__rail-x.ps--clicking .ps__thumb-x,
.toasts-container .ps__rail-x.ps--clicking .ps__thumb-x {
  background-color: rgba(0, 0, 0, 0.2);
}

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
  background-color: rgba(255, 255, 255, 0.7);
  width: 12px;
}
.ps-color-dark .ps__rail-y:hover > .ps__thumb-y, .toasts-container .ps__rail-y:hover > .ps__thumb-y,
.ps-color-dark .ps__rail-y:focus > .ps__thumb-y,
.toasts-container .ps__rail-y:focus > .ps__thumb-y,
.ps-color-dark .ps__rail-y.ps--clicking .ps__thumb-y,
.toasts-container .ps__rail-y.ps--clicking .ps__thumb-y {
  background-color: rgba(0, 0, 0, 0.2);
}

/* MS supports */
@supports (-ms-overflow-style: none) {
  .ps {
    overflow: auto !important;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ps {
    overflow: auto !important;
  }
}
.ps-always-visible > .ps__rail-x, .toasts-container > .ps__rail-x,
.ps-always-visible > .ps__rail-y,
.toasts-container > .ps__rail-y {
  opacity: 0.3;
}

.ps-always-fully-visible > .ps__rail-x,
.ps-always-fully-visible > .ps__rail-y {
  opacity: 0.8;
}

body.mobile.android .ps-always-fully-visible {
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.15) rgba(0, 0, 0, 0.05);
}
body.mobile.android .ps-always-fully-visible::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  background-color: transparent;
}
body.mobile.android .ps-always-fully-visible::-webkit-scrollbar-track {
  background-color: transparent;
  margin: 20px;
}
body.mobile.android .ps-always-fully-visible::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}

.popups-container {
  display: none;
  will-change: display;
}
.popups-container.visible {
  display: block;
}

.popup-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(18, 55, 143, 0.5);
  opacity: 0;
  will-change: opacity;
  transition: opacity 0.15s ease-out;
  backdrop-filter: blur(20px);
}
.popup-container.no-animation {
  transition: none;
}
.popup-container + .popup-container {
  backdrop-filter: unset;
}
.popup-container .close-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}
.popup-container.full-screen .popup-content,
.popup-container.full-screen .popup-header, .popup-container.wide .popup-content,
.popup-container.wide .popup-header {
  max-width: none;
}
.popup-container.state-loading, .popup-container.state-exiting {
  opacity: 0;
}
.popup-container.state-loading .popup-content,
.popup-container.state-loading .popup-header,
.popup-container.state-loading .popup-inner-content, .popup-container.state-exiting .popup-content,
.popup-container.state-exiting .popup-header,
.popup-container.state-exiting .popup-inner-content {
  transform: translate3d(0, 30px, 0);
}
.popup-container.state-exiting .popup-content,
.popup-container.state-exiting .popup-header {
  transform: translate3d(0, 120px, 0);
}
.popup-container.state-exiting .popup-inner-content {
  transform: translate3d(0, 120px, 0);
}
.popup-container.state-entering, .popup-container.state-displayed, .popup-container.state-confirming {
  opacity: 1;
}
.popup-container.state-displayed, .popup-container.state-confirming, .popup-container.state-exiting {
  transition-duration: 0.1s;
}
.popup-container.state-displayed .popup-content,
.popup-container.state-displayed .popup-header,
.popup-container.state-displayed .popup-inner-content, .popup-container.state-confirming .popup-content,
.popup-container.state-confirming .popup-header,
.popup-container.state-confirming .popup-inner-content, .popup-container.state-exiting .popup-content,
.popup-container.state-exiting .popup-header,
.popup-container.state-exiting .popup-inner-content {
  transition-duration: 0.1s;
}
.popup-container .popup-content,
.popup-container .popup-header,
.popup-container .popup-inner-content {
  transition: transform 0.15s ease-out;
}
.popup-container.no-animation .popup-content,
.popup-container.no-animation .popup-header,
.popup-container.no-animation .popup-inner-content {
  transition: none;
}
.popup-container.state-entering .popup-content, .popup-container.state-displayed .popup-content, .popup-container.state-confirming .popup-content {
  transition-delay: 0.04s;
}
.popup-container.state-entering .popup-inner-content, .popup-container.state-displayed .popup-inner-content, .popup-container.state-confirming .popup-inner-content {
  transition-delay: 0.075s;
}

.popup-container.has-scroll .popup-content > section,
.popup-content.has-scroll > section {
  overflow: auto;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.popup-container.has-scroll .popup-content > section *,
.popup-content.has-scroll > section * {
  -webkit-overflow-scrolling: auto;
}
body.scroll-block .popup-container.has-scroll .popup-content > section,
body.scroll-block .popup-content.has-scroll > section {
  overflow: hidden;
  overflow-y: hidden;
}

#mobileRoot .popup-content header:not(.day-picker) {
  background-color: #012883;
  color: white;
  height: 90px;
  padding: 2px 20px 2px 24px;
  border-radius: 24px 24px 0 0;
  overflow: hidden;
  z-index: 1;
}
#mobileRoot .popup-content header:not(.day-picker) h1 {
  margin: 0;
  font-family: Segoe UI;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.334;
  color: white;
  font-weight: 700;
  padding: 15px 0 0;
  height: 48px;
}
#mobileRoot .popup-content header:not(.day-picker) .action {
  margin-top: 10px;
}

.popup-content {
  position: relative;
  z-index: 2;
  max-width: 560px;
  background: #f0f5ff;
  border-radius: 24px;
  will-change: transform;
  transition: transform 0.15s ease-out;
}
.settings-dynamic-fields .popup-content, .no-animation .popup-content {
  transform: none;
  transition: none;
}
.popup-content section div.search {
  top: 0;
}
.popup-content section .simple-list {
  position: absolute;
  top: 36px;
  left: 0;
  right: 0;
  bottom: 0;
}
.popup-content > header + section {
  position: absolute;
  top: 68px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  border-radius: 24px;
  padding-top: 24px;
}

.image-preview-form .popup-content {
  overflow: hidden;
  max-width: 960px;
  height: 92% !important;
}

.image-preview-popup {
  height: 100%;
  text-align: center;
  overflow: visible;
}
.image-preview-popup img.hidden {
  display: none;
}
.image-preview-popup .image-scroll-container {
  overflow: visible;
}
.image-preview-popup .image-scroll-container.hidden {
  opacity: 0;
}
.image-preview-popup .current {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  height: 100%;
  display: "flex";
  align-items: "flex-start";
}
.image-preview-popup .current img {
  max-width: 100%;
}
.image-preview-popup .ei,
.image-preview-popup svg {
  font-size: 32px;
  display: inline-block;
}
.image-preview-popup .image-scroll-container {
  height: calc(100% + 52px);
  width: 100%;
  position: absolute;
  z-index: 2;
  top: -52px;
}
.image-preview-popup .image-scroll-container > div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 52px;
  height: auto;
  bottom: 0;
  background: #f0f5ff;
}
.image-preview-popup .image-scroll-container > div.prev, .image-preview-popup .image-scroll-container > div.next {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.image-preview-popup .image-scroll-container > div.prev .ei, .image-preview-popup .image-scroll-container > div.next .ei {
  font-size: 20px;
  top: 3px;
  padding: 0 5px;
}
.image-preview-popup .image-scroll-container > div.prev:before, .image-preview-popup .image-scroll-container > div.next:before {
  content: "";
  display: block;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.image-preview-popup .image-scroll-container > div.prev .content, .image-preview-popup .image-scroll-container > div.next .content {
  position: relative;
  z-index: 2;
}
.image-preview-popup .image-scroll-container > div.prev {
  left: -100%;
}
.image-preview-popup .image-scroll-container > div.prev.no-image {
  top: 52px;
  justify-content: flex-end;
}
.image-preview-popup .image-scroll-container > div.next {
  left: auto;
  right: -100%;
}
.image-preview-popup .image-scroll-container > div.next.no-image {
  top: 52px;
  justify-content: flex-start;
}

.simple-list {
  display: block;
  margin: 0;
  padding: 0;
}
.simple-list li {
  display: block;
  margin: 0;
  background: #fff;
  border-bottom: 1px solid transparent;
  padding: 13px 10px;
}
.simple-list li .ei {
  font-size: 24px;
  margin-right: 5px;
  vertical-align: middle;
  position: relative;
  top: -2px;
}

.popup-container.overdue-tasks .popup-content,
.popup-container.overdue-tasks .popup-content section {
  overflow: visible;
}
.popup-container.overdue-tasks .popup-content section {
  height: calc(100% - 52px);
}
body.mobile .popup-container.overdue-tasks .popup-content .list-wrapper.infinite-view > ul {
  overflow: auto;
}
.popup-container .list-container.list-dayplan {
  border-radius: 0;
  box-shadow: unset;
  background: transparent;
  height: 100%;
  width: 100%;
}
.popup-container .list-container.list-dayplan .list-wrapper {
  height: 100%;
}
.popup-container .list-container.list-dayplan .list-wrapper > ul {
  position: relative;
  height: 100%;
  margin: 0;
}
.popup-container .list-container.list-dayplan li {
  list-style: none;
  list-style-type: none;
}

.popups-container {
  z-index: 9;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
.popups-container form.edit-container {
  height: 100%;
  padding: 0;
}

.popup-container {
  width: 100%;
  height: 100%;
  padding: 0 8px;
}
.popup-container.wide-popup .popup-content {
  overflow: hidden;
}
body.desktop .popup-container.wide-popup .popup-content {
  max-width: 1024px;
}
body.mobile .popup-container.wide-popup .popup-content {
  max-width: 100%;
}
.popup-container.auto-height .popup-content {
  height: auto;
  max-height: 100%;
  min-height: 50%;
}
@media (min-height: 700px) {
  .popup-container.auto-height .popup-content {
    min-height: 550px;
  }
}

.popup-content {
  width: 100%;
  height: 90%;
}
.popup-content .swipe-handle {
  width: 32px;
  margin-left: -16px;
  height: 4px;
  border-radius: 3px;
  position: absolute;
  top: -10px;
  left: 50%;
}
.popup-content.loading {
  padding-top: 62px;
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.popup-content.fill-parent {
  width: 100%;
  height: 100%;
  border: 0 none;
}
.popup-content > section {
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  bottom: 0;
}
.popup-content > section.no-header {
  top: 0;
}

.settings-dynamic-fields .dnd-container .vertical-container {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}
.settings-dynamic-fields .dnd-container .vertical-container.popup-content {
  padding-top: 60px;
}
.settings-dynamic-fields .dnd-container .vertical-container ul.settings-field-group li {
  border: 0 none;
  box-shadow: unset;
}
.settings-dynamic-fields .dnd-container .vertical-container ul.settings-field-group li:first-child {
  margin-top: 18px;
}
.settings-dynamic-fields .dnd-container .vertical-container ul.settings-field-group li .content {
  border-left: 6px solid #12378F !important;
  border-radius: 6px;
}
.settings-dynamic-fields .drop-zone {
  height: auto;
  top: -10px;
  bottom: 0;
}

.popup-content {
  background: #f0f5ff;
}
.popup-content.hidden {
  opacity: 0;
}
.popup-content.loading {
  color: rgba(255, 255, 255, 0.8);
}
.popup-content .swipe-handle {
  background: rgba(255, 255, 255, 0.35);
}

@media (min-width: 640px) {
  .popup-container.open {
    background: rgba(0, 0, 0, 0.6);
  }
  .popup-container.open .popup-content {
    border: 0 solid rgba(0, 0, 0, 0.2);
  }
}
.image-preview-popup .ei {
  color: #fff;
}
.image-preview-popup .image-scroll-container > div.no-image {
  background-color: #5bc0de;
  color: #fff;
}

@media (min-width: 641px) {
  .path-introx {
    background: #12378F;
    background: -moz-linear-gradient(top, #12378F 0%, white 80%);
    background: -webkit-linear-gradient(top, #12378F 0%, white 80%);
    background: linear-gradient(to bottom, #12378F 0%, white 80%);
  }
}
.path-introx .intro-page-container {
  display: block;
  position: absolute;
  z-index: 2;
  top: 36px;
  bottom: 48px;
  left: 0;
  right: 0;
}
.path-introx .intro-page-container .center-content {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  width: 95%;
  max-width: 520px;
  transform: translateX(-50%);
  overflow: hidden;
  background: #fff;
}
@media (min-width: 641px) {
  .path-introx .intro-page-container .center-content {
    top: 10px;
    bottom: 20px;
    border-radius: 8px;
    box-shadow: 0 3px 3px -3px rgba(0, 0, 0, 0.2), 0 6px 8px 1px rgba(0, 0, 0, 0.14), 0 1px 12px 2px rgba(0, 0, 0, 0.12);
  }
}
.path-introx .intro-page-container .center-content .step-container {
  float: left;
  margin: 0;
  padding: 10px 20px;
  width: 100%;
  height: 100%;
  overflow: auto;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}
.path-introx .intro-page-container .center-content .step-container * {
  -webkit-overflow-scrolling: auto;
}
.path-introx .intro-page-container .center-content .step-container .show-on-small-screen {
  display: none;
}
.path-introx .intro-page-container .center-content .step-container .hide-on-small-screen {
  display: block;
}
.path-introx .intro-page-container .center-content .step-container .step-content {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  font-size: 16px;
}
.path-introx .intro-page-container .center-content .step-container p {
  width: 100%;
  margin: 10px 0;
}
.path-introx .intro-page-container .center-content .step-container p + ul {
  width: 100%;
  margin-top: 0;
}
.path-introx .intro-page-container .center-content .step-container p + ul li {
  padding: 5px 0;
}
@media (max-width: 640px), (max-height: 620px) {
  .path-introx .intro-page-container .center-content .step-container {
    padding: 10px;
  }
  .path-introx .intro-page-container .center-content .step-container p {
    margin: 5px 0;
  }
  .path-introx .intro-page-container .center-content .step-container p + ul {
    margin: 0;
    padding-left: 20px;
  }
  .path-introx .intro-page-container .center-content .step-container p + ul li {
    padding: 2px 0;
  }
  .path-introx .intro-page-container .center-content .step-container .show-on-small-screen {
    display: block;
  }
  .path-introx .intro-page-container .center-content .step-container .hide-on-small-screen {
    display: none;
  }
}
@media (max-width: 480px) {
  .path-introx .intro-page-container .center-content .step-container {
    padding: 5px;
  }
  .path-introx .intro-page-container .center-content .step-container p {
    margin: 3px 0;
  }
  .path-introx .intro-page-container .center-content .step-container p + ul {
    margin: 0;
    padding-left: 0;
    list-style: none;
    list-style-type: none;
  }
  .path-introx .intro-page-container .center-content .step-container p + ul li {
    list-style: none;
    list-style-type: none;
    padding: 3px 0;
  }
  .path-introx .intro-page-container .center-content .step-container p + ul li:before {
    content: "- ";
  }
}
.path-introx .intro-page-container .center-content .steps-horizontal-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.path-introx .intro-page-container .center-content .steps-horizontal-container.steps-4 {
  width: 400%;
}
.path-introx .intro-page-container .center-content .steps-horizontal-container.steps-4 .step-container {
  width: 25%;
}
.path-introx .intro-page-container .center-content .steps-horizontal-container.steps-5 {
  width: 500%;
}
.path-introx .intro-page-container .center-content .steps-horizontal-container.steps-5 .step-container {
  width: 20%;
}
.path-introx .intro-page-container .center-content .steps-horizontal-container.steps-6 {
  width: 600%;
}
.path-introx .intro-page-container .center-content .steps-horizontal-container.steps-6 .step-container {
  width: 16.666%;
}
.path-introx .intro-page-container .center-content .steps-horizontal-container.steps-7 {
  width: 700%;
}
.path-introx .intro-page-container .center-content .steps-horizontal-container.steps-7 .step-container {
  width: 14.285%;
}
.path-introx header, #mobileRoot .path-introx header {
  background: transparent;
}
.path-introx header h1 span {
  color: #000018;
}
.path-introx header .action {
  color: #fff;
  padding: 0 10px;
  width: auto;
}
@media (max-width: 640px) {
  .path-introx header .action {
    color: #777;
  }
}

.phone-frame-container {
  text-align: center;
}
.phone-frame-container .phone-frame {
  display: inline-block;
  vertical-align: middle;
  overflow: visible;
  position: relative;
  box-sizing: content-box;
  border: 4px solid transparent;
  border-width: 8px 5px;
  border-radius: 24px;
}
.phone-frame-container .phone-frame .screen {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border: 4px solid #2d2d41;
  border-width: 8px 5px;
  border-radius: 24px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.phone-frame-container .phone-frame .screen img {
  display: block;
  margin-left: -1px;
}
@media (max-height: 780px) {
  .phone-frame-container .phone-frame .screen img {
    max-height: 480px;
  }
}
@media (max-height: 660px) {
  .phone-frame-container .phone-frame .screen img {
    max-height: 420px;
  }
}
@media (max-height: 605px) {
  .phone-frame-container .phone-frame .screen img {
    max-height: 340px;
  }
}
@media (max-height: 525px) {
  .phone-frame-container .phone-frame .screen img {
    max-height: 280px;
  }
}
@media (max-width: 480px) {
  .phone-frame-container .phone-frame .screen img {
    max-width: 320px;
    height: auto;
  }
}
@media (max-width: 380px) {
  .phone-frame-container .phone-frame .screen img {
    max-width: 280px;
    height: auto;
  }
}
@media (max-width: 320px) {
  .phone-frame-container .phone-frame .screen img {
    max-width: 260px;
    height: auto;
  }
}
.phone-frame-container .phone-frame .notch {
  position: absolute;
  top: 0;
  left: 50%;
  margin: 0 0 0 -60px;
  width: 120px;
  height: 20px;
  background: #2d2d41;
  border-radius: 0 0 10px 10px;
}
.phone-frame-container .phone-frame .notch:before {
  content: "";
  position: absolute;
  top: 8px;
  left: 16px;
  width: 60px;
  height: 4px;
  border-radius: 3px;
  background: #3d3d51;
}
.phone-frame-container .phone-frame .notch:after {
  content: "";
  position: absolute;
  top: 5px;
  right: 16px;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background: #3d3d51;
}
.phone-frame-container .phone-frame:before {
  content: "";
  position: absolute;
  top: 80px;
  left: -3px;
  width: 6px;
  height: 120px;
  border-radius: 6px 0 0 6px;
  background: #2d2d41;
}
.phone-frame-container .phone-frame:after {
  content: "";
  position: absolute;
  top: 180px;
  right: -3px;
  width: 6px;
  height: 56px;
  border-radius: 0 6px 6px 0;
  background: #2d2d41;
}

.chart {
  display: flex;
  align-items: center;
  justify-content: center;
}
.chart .radio-current-date {
  stroke: blue;
  font-size: 13px;
  text-anchor: left;
}
.chart .radio-replay {
  stroke: green;
  font-size: 14px;
  cursor: pointer;
  text-anchor: left;
}

.chartHelp {
  font-size: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.layout-accordion {
  display: flex;
  width: 100%;
  height: 36px;
  align-items: stretch;
}
.layout-accordion > .component {
  max-width: 36px;
  height: 36px;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  vertical-align: middle;
}
.layout-accordion > .component > .ei {
  display: inline-block;
  height: 100%;
  width: 36px;
  text-align: center;
  line-height: 36px;
  font-size: 24px;
  color: #fff;
  overflow: hidden;
  opacity: 1;
  will-change: width, opacity;
  transition: width 0.18s ease-out, opacity 0.18s ease-out;
}
.layout-accordion > .component > .content {
  width: 100%;
  max-width: 0;
  opacity: 0;
  overflow: hidden;
  white-space: nowrap;
  will-change: max-width, opacity;
  transition: max-width 0.18s ease-out, opacity 0.18s ease-out;
}
.layout-accordion > .component > .content > * {
  position: relative;
}
.layout-accordion > .component.active {
  max-width: 100%;
}
.layout-accordion > .component.active > .ei {
  width: 0;
  opacity: 0;
}
.layout-accordion > .component.active > .content {
  max-width: 100%;
  opacity: 1;
}
.layout-accordion > .component.active.entered > .content {
  white-space: normal;
}

body.theme-dark {
  color: #ddd;
  background: #444;
  /* do not group these rules */
}
body.theme-dark table,
body.theme-dark textarea,
body.theme-dark input {
  color: #ddd;
}
body.theme-dark body {
  background: #12378F;
}
body.theme-dark body.white-background {
  background: #666;
}
body.theme-dark body.theme-dark.desktop:not(textarea),
body.theme-dark body.theme-dark.desktop *:not(textarea), body.theme-dark body.theme-dark.mobile-testing-on-web:not(textarea),
body.theme-dark body.theme-dark.mobile-testing-on-web *:not(textarea) {
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 255, 255, 0.15) rgba(255, 255, 255, 0.05);
}
body.theme-dark body.theme-dark.desktop:not(textarea)::-webkit-scrollbar,
body.theme-dark body.theme-dark.desktop *:not(textarea)::-webkit-scrollbar, body.theme-dark body.theme-dark.mobile-testing-on-web:not(textarea)::-webkit-scrollbar,
body.theme-dark body.theme-dark.mobile-testing-on-web *:not(textarea)::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  background-color: rgba(255, 255, 255, 0.05);
}
body.theme-dark body.theme-dark.desktop:not(textarea)::-webkit-scrollbar-track,
body.theme-dark body.theme-dark.desktop *:not(textarea)::-webkit-scrollbar-track, body.theme-dark body.theme-dark.mobile-testing-on-web:not(textarea)::-webkit-scrollbar-track,
body.theme-dark body.theme-dark.mobile-testing-on-web *:not(textarea)::-webkit-scrollbar-track {
  background-color: transparent;
}
body.theme-dark body.theme-dark.desktop:not(textarea)::-webkit-scrollbar-thumb,
body.theme-dark body.theme-dark.desktop *:not(textarea)::-webkit-scrollbar-thumb, body.theme-dark body.theme-dark.mobile-testing-on-web:not(textarea)::-webkit-scrollbar-thumb,
body.theme-dark body.theme-dark.mobile-testing-on-web *:not(textarea)::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.15);
}
body.theme-dark .warning-message,
body.theme-dark .error-message {
  background: #666;
  border: 1px solid #f0ad4e;
  color: #f0ad4e;
}
body.theme-dark .warning-message .ei,
body.theme-dark .error-message .ei {
  color: #f0ad4e;
}
body.theme-dark .warning-message button .ei,
body.theme-dark .error-message button .ei {
  color: inherit;
}
body.theme-dark .warning-message.no-border,
body.theme-dark .error-message.no-border {
  border: 0 none !important;
}
body.theme-dark .error-message {
  border-color: #d9534f;
  color: #d9534f;
}
body.theme-dark .error-message .ei {
  color: #d9534f;
}
body.theme-dark .error-message button .ei {
  color: inherit;
}
body.theme-dark fieldset {
  border: 0px solid #888;
  border-radius: 6px;
}
body.theme-dark fieldset.field-margins {
  margin: 10px 0 0;
  padding: 10px 10px 0;
}
body.theme-dark fieldset.has-border {
  border-width: 1px;
}
body.theme-dark fieldset.has-border.collapsed {
  border-radius: 0;
  border-width: 1px 0 0 0;
}
body.theme-dark .elevation-0 {
  /* Shadow 0dp */
  box-shadow: none;
}
body.theme-dark .elevation-1 {
  /* Shadow 1dp */
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
}
body.theme-dark .elevation-2 {
  /* Shadow 2dp */
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}
body.theme-dark .elevation-3 {
  /* Shadow 3dp */
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.12), 0 1px 8px 0 rgba(0, 0, 0, 0.2);
}
body.theme-dark .elevation-4 {
  /* Shadow 4dp */
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
}
body.theme-dark .elevation-6 {
  /* Shadow 6dp */
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
}
body.theme-dark .elevation-8 {
  /* Shadow 8dp */
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
}
body.theme-dark .elevation-9 {
  /* Shadow 9dp */
  box-shadow: 0 9px 12px 1px rgba(0, 0, 0, 0.14), 0 3px 16px 2px rgba(0, 0, 0, 0.12), 0 5px 6px -3px rgba(0, 0, 0, 0.2);
}
body.theme-dark .elevation-12 {
  /* Shadow 12dp */
  box-shadow: 0 12px 17px 2px rgba(0, 0, 0, 0.14), 0 5px 22px 4px rgba(0, 0, 0, 0.12), 0 7px 8px -4px rgba(0, 0, 0, 0.2);
}
body.theme-dark .elevation-16 {
  /* Shadow 16dp */
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}
body.theme-dark .elevation-24 {
  /* Shadow 24dp */
  box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 11px 15px -7px rgba(0, 0, 0, 0.2);
}
body.theme-dark .dialog-container {
  backdrop-filter: blur(20px);
}
body.theme-dark .dialog-container .overlay {
  background: rgba(18, 55, 143, 0.7);
}
body.theme-dark .dialog-container .popup {
  background: #666;
  color: #eee;
}
body.theme-dark .dialog-container .buttons > button {
  background: #F0F5FF !important;
  color: #60c3ff !important;
  box-shadow: unset !important;
}
body.theme-dark .dialog-container .buttons > button:before {
  background: rgba(0, 0, 0, 0.07) !important;
}
body.theme-dark .dialog-container .buttons > button.btn-delete, body.theme-dark .dialog-container .buttons > button.btn-deleteall, body.theme-dark .dialog-container .buttons > button.btn-deletezone, body.theme-dark .dialog-container .buttons > button.btn-deletelist, body.theme-dark .dialog-container .buttons > button.btn-deleteentry, body.theme-dark .dialog-container .buttons > button.btn-deleteoption, body.theme-dark .dialog-container .buttons > button.btn-deletegoal, body.theme-dark .dialog-container .buttons > button.btn-deletehabit, body.theme-dark .dialog-container .buttons > button.btn-deletedesktop, body.theme-dark .dialog-container .buttons > button.btn-unshareanddelete, body.theme-dark .dialog-container .buttons > button.btn-remove, body.theme-dark .dialog-container .buttons > button.btn-removelist, body.theme-dark .dialog-container .buttons > button.btn-overwrite, body.theme-dark .dialog-container .buttons > button.btn-logout {
  background: #d9534f !important;
  color: #666 !important;
}
body.theme-dark .dialog-container .buttons > button.btn-delete:before, body.theme-dark .dialog-container .buttons > button.btn-deleteall:before, body.theme-dark .dialog-container .buttons > button.btn-deletezone:before, body.theme-dark .dialog-container .buttons > button.btn-deletelist:before, body.theme-dark .dialog-container .buttons > button.btn-deleteentry:before, body.theme-dark .dialog-container .buttons > button.btn-deleteoption:before, body.theme-dark .dialog-container .buttons > button.btn-deletegoal:before, body.theme-dark .dialog-container .buttons > button.btn-deletehabit:before, body.theme-dark .dialog-container .buttons > button.btn-deletedesktop:before, body.theme-dark .dialog-container .buttons > button.btn-unshareanddelete:before, body.theme-dark .dialog-container .buttons > button.btn-remove:before, body.theme-dark .dialog-container .buttons > button.btn-removelist:before, body.theme-dark .dialog-container .buttons > button.btn-overwrite:before, body.theme-dark .dialog-container .buttons > button.btn-logout:before {
  background: rgba(255, 255, 255, 0.2) !important;
}
body.theme-dark .dialog-container .buttons > button.btn-ok, body.theme-dark .dialog-container .buttons > button.btn-yes, body.theme-dark .dialog-container .buttons > button.btn-accept, body.theme-dark .dialog-container .buttons > button.btn-confirm, body.theme-dark .dialog-container .buttons > button.btn-proceed, body.theme-dark .dialog-container .buttons > button.btn-open, body.theme-dark .dialog-container .buttons > button.btn-review, body.theme-dark .dialog-container .buttons > button.btn-reviewfields, body.theme-dark .dialog-container .buttons > button.btn-now, body.theme-dark .dialog-container .buttons > button.btn-savewithoutnotifications, body.theme-dark .dialog-container .buttons > button.btn-invite, body.theme-dark .dialog-container .buttons > button.btn-closeandnavigate {
  background: #60c3ff !important;
  color: #666 !important;
}
body.theme-dark .dialog-container .buttons > button.btn-ok:before, body.theme-dark .dialog-container .buttons > button.btn-yes:before, body.theme-dark .dialog-container .buttons > button.btn-accept:before, body.theme-dark .dialog-container .buttons > button.btn-confirm:before, body.theme-dark .dialog-container .buttons > button.btn-proceed:before, body.theme-dark .dialog-container .buttons > button.btn-open:before, body.theme-dark .dialog-container .buttons > button.btn-review:before, body.theme-dark .dialog-container .buttons > button.btn-reviewfields:before, body.theme-dark .dialog-container .buttons > button.btn-now:before, body.theme-dark .dialog-container .buttons > button.btn-savewithoutnotifications:before, body.theme-dark .dialog-container .buttons > button.btn-invite:before, body.theme-dark .dialog-container .buttons > button.btn-closeandnavigate:before {
  background: rgba(255, 255, 255, 0.2) !important;
}
body.theme-dark .dialog-container .options {
  color: #eee;
}
body.theme-dark .dialog-container .options > div {
  background: #F0F5FF;
  border-bottom: 1px solid #666;
}
body.theme-dark .dialog-container .options > div:last-child {
  border-bottom: 0 none;
}
body.theme-dark .dialog-container .options > div .ei {
  color: #60c3ff;
}
body.theme-dark .dialog-container .options > div.selected {
  background: #F0F5FF;
}
body.theme-dark .dialog-container .options > div.selected span {
  color: #60c3ff;
}
body.theme-dark .dnd-container .vertical-container .ei.no-permission {
  color: #ddd;
}
body.theme-dark .dnd-container .vertical-container ul.hovering {
  border-color: #5bc0de;
}
body.theme-dark .dnd-container .vertical-container ul.settings-field-group {
  background: transparent;
}
body.theme-dark .dnd-container .vertical-container ul.settings-field-group li .content {
  border: 0 none;
  border-radius: 0;
}
body.theme-dark .dnd-container .vertical-container ul.settings-field-group li.add-field {
  border: 0 none;
}
body.theme-dark .dnd-container .ps > .ps__rail-y {
  background-color: transparent !important;
}
.path-desktop body.theme-dark .dnd-container .ps__thumb-y,
.path-desktop body.theme-dark .dnd-container .ps__rail-y:hover > .ps__thumb-y,
.path-desktop body.theme-dark .dnd-container .ps__rail-y:focus > .ps__thumb-y,
.path-desktop body.theme-dark .dnd-container .ps__rail-y.ps--clicking .ps__thumb-y {
  background-color: #666;
}
body.theme-dark .dnd-container .delete .ei {
  background-color: #666;
  color: #d9534f;
  box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.5);
}
body.theme-dark .dnd-container .delete.visible.hovering {
  background: rgba(0, 0, 0, 0.4);
  box-shadow: 0px 3px 5px 5px rgba(0, 0, 0, 0.4);
}
body.theme-dark .dnd-container .delete.visible.hovering .ei {
  background-color: transparent;
  box-shadow: unset;
  color: #fff;
}
body.theme-dark ul.preview-layer,
body.theme-dark div.preview-layer {
  background: transparent;
}
body.theme-dark ul.list {
  background: #555;
}
body.theme-dark ul.list.notifications {
  background: #666;
}
body.theme-dark ul.list.items-list, .form-tab body.theme-dark ul.list {
  background: transparent;
}
body.theme-dark ul.list li {
  background: #666;
  border-color: #444;
  border-left-color: #60c3ff;
}
body.theme-dark ul.list li.notification.info {
  border-left-color: #60c3ff;
}
body.theme-dark ul.list li.notification.success {
  border-left-color: #a473ff;
}
body.theme-dark ul.list li.notification.warn {
  border-left-color: #ff8309;
}
body.theme-dark ul.list li.notification.error {
  border-left-color: #d9534f;
}
body.theme-dark ul.list li.notification .ei.right {
  color: #444;
}
body.theme-dark ul.list.plain li {
  border-bottom-color: #444;
}
body.theme-dark ul.list.color-task li {
  border-left-color: #60c3ff;
}
body.theme-dark ul.list.color-task li button {
  background: #60c3ff;
}
body.theme-dark ul.list.color-contact li {
  border-left-color: #ff8309;
}
body.theme-dark ul.list.color-contact li button {
  background: #ff8309;
}
body.theme-dark ul.list.color-image li {
  border-left-color: #ffca08;
}
body.theme-dark ul.list.color-image li button {
  background: #ffca08;
}
body.theme-dark ul.list.color-goal li {
  border-left-color: #a473ff;
}
body.theme-dark ul.list.color-goal li button {
  background: #a473ff;
}
body.theme-dark ul.list.color-project li {
  border-left-color: #a473ff;
}
body.theme-dark ul.list.color-project li button {
  background: #a473ff;
}
body.theme-dark ul.list.color-friend li {
  border-left-color: #ff8309;
}
body.theme-dark ul.list.color-friend li button {
  background: #ff8309;
}
body.theme-dark ul.list.color-feedback li {
  border-left-color: #21ac66;
}
body.theme-dark ul.list.color-feedback li button {
  background: #21ac66;
}
body.theme-dark ul.list.color-settings li {
  border-left-color: #ddd;
}
body.theme-dark ul.list.color-settings li button {
  background: #666;
  color: #ddd;
}
body.theme-dark .slideshow {
  background-color: #60c3ff;
}
body.theme-dark .slideshow .controls, body.theme-dark .slideshow .control-orientation {
  background: rgba(0, 0, 24, 0.75);
  color: #666;
}
body.theme-dark .slideshow .control-orientation .ei.locked {
  background: rgba(217, 83, 79, 0.2);
  color: #fff;
}
body.theme-dark .slideshow .slide {
  color: #fff;
}
body.theme-dark .slideshow .slide > div.background {
  background-color: #444;
}
body.theme-dark .slideshow .slide h1, body.theme-dark .slideshow .slide h2, body.theme-dark .slideshow .slide li, body.theme-dark .slideshow .slide .caption, body.theme-dark .slideshow .slide .text, body.theme-dark .slideshow .slide a {
  background-color: rgba(221, 221, 221, 0.25);
}
body.theme-dark .slideshow .slide.color-black h1, body.theme-dark .slideshow .slide.color-black h2, body.theme-dark .slideshow .slide.color-black li, body.theme-dark .slideshow .slide.color-black .caption, body.theme-dark .slideshow .slide.color-black .text, body.theme-dark .slideshow .slide.color-black a {
  color: black;
}
body.theme-dark .slideshow .slide.color-white h1, body.theme-dark .slideshow .slide.color-white h2, body.theme-dark .slideshow .slide.color-white li, body.theme-dark .slideshow .slide.color-white .caption, body.theme-dark .slideshow .slide.color-white .text, body.theme-dark .slideshow .slide.color-white a {
  color: white;
}
body.theme-dark .slideshow .slide.color-task h1, body.theme-dark .slideshow .slide.color-task h2, body.theme-dark .slideshow .slide.color-task li, body.theme-dark .slideshow .slide.color-task .caption, body.theme-dark .slideshow .slide.color-task .text, body.theme-dark .slideshow .slide.color-task a {
  color: #60c3ff;
}
body.theme-dark .slideshow .slide.color-goal h1, body.theme-dark .slideshow .slide.color-goal h2, body.theme-dark .slideshow .slide.color-goal li, body.theme-dark .slideshow .slide.color-goal .caption, body.theme-dark .slideshow .slide.color-goal .text, body.theme-dark .slideshow .slide.color-goal a {
  color: #a473ff;
}
body.theme-dark .slideshow .slide.color-project h1, body.theme-dark .slideshow .slide.color-project h2, body.theme-dark .slideshow .slide.color-project li, body.theme-dark .slideshow .slide.color-project .caption, body.theme-dark .slideshow .slide.color-project .text, body.theme-dark .slideshow .slide.color-project a {
  color: #a473ff;
}
body.theme-dark .slideshow .slide.color-contact h1, body.theme-dark .slideshow .slide.color-contact h2, body.theme-dark .slideshow .slide.color-contact li, body.theme-dark .slideshow .slide.color-contact .caption, body.theme-dark .slideshow .slide.color-contact .text, body.theme-dark .slideshow .slide.color-contact a {
  color: #ff8309;
}
body.theme-dark .slideshow .slide.color-image h1, body.theme-dark .slideshow .slide.color-image h2, body.theme-dark .slideshow .slide.color-image li, body.theme-dark .slideshow .slide.color-image .caption, body.theme-dark .slideshow .slide.color-image .text, body.theme-dark .slideshow .slide.color-image a {
  color: #ffca08;
}
body.theme-dark .slideshow .slide.color-feedback h1, body.theme-dark .slideshow .slide.color-feedback h2, body.theme-dark .slideshow .slide.color-feedback li, body.theme-dark .slideshow .slide.color-feedback .caption, body.theme-dark .slideshow .slide.color-feedback .text, body.theme-dark .slideshow .slide.color-feedback a {
  color: #21ac66;
}
body.theme-dark .slideshow .slide.color-light-task h1, body.theme-dark .slideshow .slide.color-light-task h2, body.theme-dark .slideshow .slide.color-light-task li, body.theme-dark .slideshow .slide.color-light-task .caption, body.theme-dark .slideshow .slide.color-light-task .text, body.theme-dark .slideshow .slide.color-light-task a {
  color: #00438a;
}
body.theme-dark .slideshow .slide.color-light-goal h1, body.theme-dark .slideshow .slide.color-light-goal h2, body.theme-dark .slideshow .slide.color-light-goal li, body.theme-dark .slideshow .slide.color-light-goal .caption, body.theme-dark .slideshow .slide.color-light-goal .text, body.theme-dark .slideshow .slide.color-light-goal a {
  color: #f0e8ff;
}
body.theme-dark .slideshow .slide.color-light-project h1, body.theme-dark .slideshow .slide.color-light-project h2, body.theme-dark .slideshow .slide.color-light-project li, body.theme-dark .slideshow .slide.color-light-project .caption, body.theme-dark .slideshow .slide.color-light-project .text, body.theme-dark .slideshow .slide.color-light-project a {
  color: #f0e8ff;
}
body.theme-dark .slideshow .slide.color-light-contact h1, body.theme-dark .slideshow .slide.color-light-contact h2, body.theme-dark .slideshow .slide.color-light-contact li, body.theme-dark .slideshow .slide.color-light-contact .caption, body.theme-dark .slideshow .slide.color-light-contact .text, body.theme-dark .slideshow .slide.color-light-contact a {
  color: #fee7ce;
}
body.theme-dark .slideshow .slide.color-light-image h1, body.theme-dark .slideshow .slide.color-light-image h2, body.theme-dark .slideshow .slide.color-light-image li, body.theme-dark .slideshow .slide.color-light-image .caption, body.theme-dark .slideshow .slide.color-light-image .text, body.theme-dark .slideshow .slide.color-light-image a {
  color: #ffffbd;
}
body.theme-dark .slideshow .slide.color-light-feedback h1, body.theme-dark .slideshow .slide.color-light-feedback h2, body.theme-dark .slideshow .slide.color-light-feedback li, body.theme-dark .slideshow .slide.color-light-feedback .caption, body.theme-dark .slideshow .slide.color-light-feedback .text, body.theme-dark .slideshow .slide.color-light-feedback a {
  color: #252;
}
body.theme-dark .slideshow .slide.opacity-0 h1, body.theme-dark .slideshow .slide.opacity-0 h2, body.theme-dark .slideshow .slide.opacity-0 li, body.theme-dark .slideshow .slide.opacity-0 .caption, body.theme-dark .slideshow .slide.opacity-0 .text, body.theme-dark .slideshow .slide.opacity-0 a {
  background: rgba(221, 221, 221, 0.25);
}
body.theme-dark .slideshow .slide.opacity-25 h1, body.theme-dark .slideshow .slide.opacity-25 h2, body.theme-dark .slideshow .slide.opacity-25 li, body.theme-dark .slideshow .slide.opacity-25 .caption, body.theme-dark .slideshow .slide.opacity-25 .text, body.theme-dark .slideshow .slide.opacity-25 a {
  background: rgba(221, 221, 221, 0.25);
}
body.theme-dark .slideshow .slide.opacity-50 h1, body.theme-dark .slideshow .slide.opacity-50 h2, body.theme-dark .slideshow .slide.opacity-50 li, body.theme-dark .slideshow .slide.opacity-50 .caption, body.theme-dark .slideshow .slide.opacity-50 .text, body.theme-dark .slideshow .slide.opacity-50 a {
  background: rgba(221, 221, 221, 0.5);
}
body.theme-dark .slideshow .slide.opacity-75 h1, body.theme-dark .slideshow .slide.opacity-75 h2, body.theme-dark .slideshow .slide.opacity-75 li, body.theme-dark .slideshow .slide.opacity-75 .caption, body.theme-dark .slideshow .slide.opacity-75 .text, body.theme-dark .slideshow .slide.opacity-75 a {
  background: rgba(221, 221, 221, 0.75);
}
body.theme-dark .slideshow .slide.opacity-100 h1, body.theme-dark .slideshow .slide.opacity-100 h2, body.theme-dark .slideshow .slide.opacity-100 li, body.theme-dark .slideshow .slide.opacity-100 .caption, body.theme-dark .slideshow .slide.opacity-100 .text, body.theme-dark .slideshow .slide.opacity-100 a {
  background: #ddd;
}
body.theme-dark .slideshow .slide ul {
  background: transparent;
}
body.theme-dark .slideshow .slide ul.actions li {
  background: transparent;
}
body.theme-dark .slideshow .slide ul.actions li li {
  background: rgba(68, 68, 68, 0.8);
}
body.theme-dark .slideshow .slide .caption {
  color: #60c3ff;
}
body.theme-dark .settings-container {
  background: #666;
}
body.theme-dark .settings-container > nav {
  background: #60c3ff;
  border-bottom-color: #009bf9;
}
body.theme-dark .settings-container > nav .button {
  background: #444;
  border-right-color: #009bf9;
  border-bottom-color: #009bf9;
  color: #ddd;
}
body.theme-dark .settings-container > nav .button.active {
  border-bottom-color: #555;
  background: #555;
}
body.theme-dark .settings-container.start-my-day section {
  border-bottom-color: #888;
  border-top-color: #60c3ff;
}
body.theme-dark .settings-container.start-my-day section.expanded > .content {
  border-top-color: #888;
}
body.theme-dark .settings-container.start-my-day ul li {
  border-top-color: #888;
}
body.theme-dark .settings-container.start-my-day ul li:nth-child(even) {
  background: rgba(96, 195, 255, 0.05);
}
body.theme-dark .settings-container.start-my-day ul.fields-list {
  border-bottom-color: #888;
}
body.theme-dark .settings-container.start-my-day .field.upload {
  border-color: #888;
}
body.theme-dark .settings-container.start-my-day .field.picture-widget .preview {
  border-color: #888;
  background: #666;
}
body.theme-dark .settings-container.start-my-day .square-button {
  border-color: #888;
  box-shadow: 0px 2px 1px 0 rgba(0, 0, 0, 0.5);
  color: #ddd;
  background: #666;
}
body.theme-dark .settings-container.start-my-day .square-button:focus {
  border-color: #5bc0de;
}
body.theme-dark .settings-container.start-my-day .file-input-container {
  color: #60c3ff;
}
body.theme-dark .settings-container.start-my-day .file-picture-chooser {
  color: #5cb85c;
}
body.theme-dark .settings-container.start-my-day label.warning {
  color: #f0ad4e;
}
body.theme-dark .settings-container.start-my-day .field.text-widget .preview {
  border-color: #888;
}
body.theme-dark .settings-container.start-my-day .field.slide-editor .preview {
  border-color: #888;
  color: white;
}
body.theme-dark .settings-container.start-my-day .field.slide-editor .preview .ei {
  color: #aaa;
}
body.theme-dark .settings-container.start-my-day .field.slide-editor .preview span {
  text-shadow: 0 1px 0 black, 0 -1px 0 black, 1px 0 0 black, -1px 0 0 black, 1px 1px 0 black, -1px 1px 0 black, 1px -1px 0 black, -1px -1px 0 black;
  background: rgba(221, 221, 221, 0.5);
}
body.theme-dark .settings-container.start-my-day .field.slide-editor .preview.opacity-0 span {
  background: transparent;
}
body.theme-dark .settings-container.start-my-day .field.slide-editor .preview.opacity-25 span {
  background: rgba(221, 221, 221, 0.25);
}
body.theme-dark .settings-container.start-my-day .field.slide-editor .preview.opacity-50 span {
  background: rgba(221, 221, 221, 0.5);
}
body.theme-dark .settings-container.start-my-day .field.slide-editor .preview.opacity-75 span {
  background: rgba(221, 221, 221, 0.75);
}
body.theme-dark .settings-container.start-my-day .field.slide-editor .preview.opacity-100 span {
  background: #ddd;
}
body.theme-dark .generic-text-shadow {
  text-shadow: 0 1px 0 black, 0 -1px 0 black, 1px 0 0 black, -1px 0 0 black, 1px 1px 0 black, -1px 1px 0 black, 1px -1px 0 black, -1px -1px 0 black;
}
body.theme-dark .popup-content {
  background: #f0f5ff;
}
body.theme-dark .popup-content.hidden {
  opacity: 0;
}
body.theme-dark .popup-content.loading {
  color: rgba(255, 255, 255, 0.8);
}
body.theme-dark .popup-content .swipe-handle {
  background: rgba(255, 255, 255, 0.35);
}
@media (min-width: 640px) {
  body.theme-dark .popup-container.open {
    background: rgba(0, 0, 0, 0.6);
  }
  body.theme-dark .popup-container.open .popup-content {
    border: 0 solid rgba(255, 255, 255, 0.2);
  }
}
body.theme-dark .image-preview-popup .ei {
  color: #fff;
}
body.theme-dark .image-preview-popup .image-scroll-container > div.no-image {
  background-color: #5bc0de;
  color: #fff;
}
body.theme-dark .panning-layer {
  background: #666;
}
.path-desktop body.theme-dark .panning-layer {
  background: transparent;
}
body.theme-dark .drop-zone > div {
  background: #5bc0de;
  color: #fff;
  border-bottom-color: rgba(0, 0, 0, 0.3);
}
body.theme-dark .drop-zone > div span:not(.pz-title) {
  background: #5cb85c;
  border-bottom-color: rgba(0, 0, 0, 0.6);
}
body.theme-dark .drop-zone > div span.pz-title {
  color: #fff !important;
}
body.theme-dark .drop-zone > div.move, body.theme-dark .drop-zone > div.clone, body.theme-dark .drop-zone > div.hovering {
  background: #5cb85c;
  color: #fff;
}
.desktop-drop-zone body.theme-dark .drop-zone > div.move, .desktop-drop-zone body.theme-dark .drop-zone > div.clone, .desktop-drop-zone body.theme-dark .drop-zone > div.hovering {
  background-color: transparent;
}
body.theme-dark .drop-zone > div.clone {
  background: #f0ad4e;
  color: #fff;
}
body.theme-dark .drop-zone > div.clone span {
  background: #f0ad4e;
}
.settings-desktops-form body.theme-dark .drop-zone > div.hovering,
.settings-desktops-form body.theme-dark .drop-zone > div.hovering span:not(.pz-title) {
  background: #80c780;
}
.settings-desktops-form body.theme-dark .drop-zone > div.hovering.clone,
.settings-desktops-form body.theme-dark .drop-zone > div.hovering.clone span {
  background: #f4c37d;
}
.settings-desktops-form body.theme-dark .drop-zone > div.full-width.hovering,
.settings-desktops-form body.theme-dark .drop-zone > div.full-width.hovering span {
  background: #5cb85c;
}
.settings-desktops-form body.theme-dark .drop-zone > div span:not(.pz-title) {
  background: #5cb85c;
  border-color: #fff;
}
.settings-desktops-form body.theme-dark .drop-zone > div.clone span:not(.pz-title) {
  background: #f0ad4e;
}
body.theme-dark .desktop-drop-zone.move > div {
  background: #FFE862;
  color: #fff;
}
body.theme-dark .desktop-drop-zone.clone > div {
  background: #f0ad4e;
  color: #fff;
}
body.theme-dark ul.preview-layer li {
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
}
body.theme-dark ul.preview-layer li h4 {
  background: #666;
}
body.theme-dark ul.preview-layer.no-drop li {
  border-color: #d9534f;
}
body.theme-dark ul.preview-layer.no-drop li .content {
  color: #d9534f !important;
}
body.theme-dark ul.preview-layer.hovering-action-delete li {
  box-shadow: 0 8px 10px 1px rgba(255, 0, 0, 0.14), 0 3px 14px 2px rgba(255, 0, 0, 0.12), 0 5px 5px -3px rgba(255, 0, 0, 0.2);
}
body.theme-dark ul.preview-layer.hovering-action-delete li .content {
  color: #d9534f !important;
  border-left-color: #d9534f !important;
}
body.theme-dark ul.preview-layer > h3 {
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
  background: #666;
  border-color: #ddd;
}
body.theme-dark ul.preview-layer > h3.list-task {
  border-color: #60c3ff;
  color: #60c3ff;
}
body.theme-dark ul.preview-layer > h3.list-contact {
  border-color: #ff8309;
  color: #ff8309;
}
body.theme-dark ul.preview-layer > h3.list-image {
  border-color: #ffca08;
  color: #ffca08;
}
body.theme-dark ul.preview-layer > h3.list-project {
  border-color: #a473ff;
  color: #a473ff;
}
body.theme-dark ul.preview-layer > h3.list-goal {
  border-color: #a473ff;
  color: #a473ff;
}
body.theme-dark ul.preview-layer > h3.list-dayplan {
  border-color: #21ac66;
  color: #21ac66;
}
body.theme-dark .list-container {
  background: #555;
  border-color: #ddd;
}
body.theme-dark .list-container > h3 {
  color: #ddd;
  border-bottom-color: transparent;
  transition: background-color 0.4s linear;
}
body.theme-dark .list-container > h3 .edit-filters.highlight,
body.theme-dark .list-container > h3 .collapse-arrow.highlight {
  color: #fff;
  background: #ddd;
}
body.theme-dark .list-container.autofocus > h3 {
  background-color: #5cb85c !important;
}
body.theme-dark .list-container.list-task {
  border-color: #60c3ff;
}
body.theme-dark .list-container.list-task.embedded {
  border-color: #60c3ff rgba(255, 255, 255, 0.2);
}
body.theme-dark .list-container.list-task > h3 {
  color: #60c3ff;
}
body.theme-dark .list-container.list-task > h3 .edit-filters.highlight {
  background: #60c3ff;
}
body.theme-dark .list-container.list-contact {
  border-color: #ff8309;
}
body.theme-dark .list-container.list-contact.embedded {
  border-color: #ff8309 rgba(255, 255, 255, 0.2);
}
body.theme-dark .list-container.list-contact.embedded.collapsed {
  border-bottom-color: #ff8309;
}
body.theme-dark .list-container.list-contact > h3 {
  color: #ff8309;
}
body.theme-dark .list-container.list-contact > h3 .edit-filters.highlight {
  background: #ff8309;
}
body.theme-dark .list-container.list-image {
  border-color: #ffca08;
}
body.theme-dark .list-container.list-image.embedded {
  border-color: #ffca08 rgba(255, 255, 255, 0.2);
}
body.theme-dark .list-container.list-image.embedded.collapsed {
  border-bottom-color: #ffca08;
}
body.theme-dark .list-container.list-image > h3 {
  color: #ffca08;
}
body.theme-dark .list-container.list-image > h3 .edit-filters.highlight {
  background: #ffca08;
}
body.theme-dark .list-container.list-project {
  border-color: #a473ff;
}
body.theme-dark .list-container.list-project.embedded {
  border-color: #a473ff;
}
body.theme-dark .list-container.list-project.embedded.collapsed {
  border-bottom-color: #a473ff;
}
body.theme-dark .list-container.list-project > h3 {
  color: #a473ff;
}
body.theme-dark .list-container.list-project > h3 .edit-filters.highlight {
  background: #a473ff;
}
body.theme-dark .list-container.list-goal {
  border-color: #a473ff;
}
body.theme-dark .list-container.list-goal.embedded {
  border-color: #a473ff;
}
body.theme-dark .list-container.list-goal.embedded.collapsed {
  border-bottom-color: #a473ff;
}
body.theme-dark .list-container.list-goal > h3 {
  color: #a473ff;
}
body.theme-dark .list-container.list-goal > h3 .edit-filters.highlight {
  background: #a473ff;
}
body.theme-dark .list-container.list-dayplan {
  border-color: #21ac66;
  background: #252;
}
body.theme-dark .list-container.list-dayplan.embedded {
  border-color: #21ac66;
}
body.theme-dark .list-container.list-dayplan.embedded.collapsed {
  border-bottom-color: #21ac66;
}
body.theme-dark .list-container.list-dayplan > h3 {
  color: #21ac66;
  background: #555;
}
body.theme-dark .list-container.list-dayplan > h3 .edit-filters.highlight {
  background: #21ac66;
}
body.theme-dark .list-container.list-dayplan .track.day-plan-track {
  background: #555;
  color: #ddd;
}
body.theme-dark .list-container.list-dayplan .track.day-plan-track:before {
  background: #555;
  background: -moz-linear-gradient(left, #555 0%, rgba(85, 85, 85, 0) 100%);
  background: -webkit-linear-gradient(left, #555 0%, rgba(85, 85, 85, 0) 100%);
  background: linear-gradient(to right, #555 0%, rgba(85, 85, 85, 0) 100%);
}
body.theme-dark .list-container.list-dayplan .track.day-plan-track:after {
  background: #555;
  background: -moz-linear-gradient(right, #555 0%, rgba(85, 85, 85, 0) 100%);
  background: -webkit-linear-gradient(right, #555 0%, rgba(85, 85, 85, 0) 100%);
  background: linear-gradient(to left, #555 0%, rgba(85, 85, 85, 0) 100%);
}
body.theme-dark .list-container.list-dayplan .track.day-plan-track li .bullets .primary {
  background: #60c3ff;
}
body.theme-dark .list-container.list-dayplan .track.day-plan-track + nav .ei {
  color: #aaa;
}
body.theme-dark .list-container.list-dayplan .day-plan-date {
  background: transparent;
}
body.theme-dark .list-container.list-dayplan .day-plan-date .list-time {
  color: #5bc0de;
}
body.theme-dark .list-container.list-dayplan .time-slot.productivity {
  background: rgba(0, 0, 0, 0.03);
}
body.theme-dark .list-container.list-dayplan .time-slot.productivity.sleep.no-items.can-drop-hint {
  background: rgba(0, 0, 0, 0.03);
}
body.theme-dark .list-container.list-dayplan .time-slot.productivity.can-drop-hint {
  border-color: transparent;
  background: transparent;
}
body.theme-dark .list-container.list-dayplan .time-slot.productivity .zone-header .drop-zone > div {
  color: #fff !important;
}
body.theme-dark .list-container.list-dayplan .time-slot.productivity .badge {
  background: #f0ad4e;
  color: #fff;
}
body.theme-dark .list-container.list-dayplan .time-slot.productivity .badge.important {
  background: #d9534f;
  color: #fff;
}
body.theme-dark .list-container.list-dayplan .drop-clock {
  background: #60c3ff;
  box-shadow: -2px 2px 2px 0 rgba(0, 0, 0, 0.14), -2px 3px 1px -2px rgba(0, 0, 0, 0.12), -2px 1px 5px 0 rgba(0, 0, 0, 0.2);
}
body.theme-dark .list-container.list-dayplan .drop-clock .ei {
  color: #fff;
}
body.theme-dark .list-container.list-dayplan .drop-clock div span {
  color: #fff;
}
body.theme-dark .list-container.list-dayplan .drop-clock.visible.hovering {
  background: #93d6ff;
}
body.theme-dark .list-container .action-container {
  background: #666;
  border-color: #a473ff #444 #444;
}
body.theme-dark .list-container .action-container ul {
  background: transparent;
}
body.theme-dark .list-container .action-container.habits {
  border-top-color: #21ac66;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
}
body.theme-dark .shadow.top {
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 0.2) 0%, rgba(0, 0, 0, 0) 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.2) 0%, rgba(0, 0, 0, 0) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.2) 0%, rgba(0, 0, 0, 0) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
body.theme-dark .shadow.bottom {
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 0.2) 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 0.2) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 0.2) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
body.theme-dark .embedded-list-item.static-list .list-container.embedded {
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.1) !important;
}
body.theme-dark .embedded-list-item.static-list .list-container.embedded .list-wrapper {
  background: #555;
}
body.theme-dark .list-container.embedded {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}
body.theme-dark .actions-container > .ei {
  color: #fff;
  background: #ddd;
  box-shadow: 1px 1px 8px #ddd;
}
body.theme-dark .actions-container > .ei.close {
  color: #ddd;
}
body.theme-dark .actions-container.actions-task > .ei {
  background: #60c3ff;
}
body.theme-dark .actions-container.actions-contact > .ei {
  background: #ff8309;
}
body.theme-dark .actions-container.actions-image > .ei {
  background: #ffca08;
}
body.theme-dark .actions-container.actions-project > .ei {
  background: #a473ff;
}
body.theme-dark .actions-container.actions-dayplan > .ei {
  background: #21ac66;
}
body.theme-dark .list-container.embedded .actions-container > .ei {
  background: #666;
  color: #ddd;
  border-color: #444;
}
body.theme-dark .list-container.embedded .actions-container.actions-task > .ei,
body.theme-dark .list-container.embedded .actions-container.actions-task .extra button {
  border-color: #60c3ff;
  color: #60c3ff;
}
body.theme-dark .list-container.embedded .actions-container.actions-contact > .ei,
body.theme-dark .list-container.embedded .actions-container.actions-contact .extra button {
  border-color: #ff8309;
  color: #ff8309;
}
body.theme-dark .list-container.embedded .actions-container.actions-image > .ei,
body.theme-dark .list-container.embedded .actions-container.actions-image .extra button {
  border-color: #ffca08;
  color: #ffca08;
}
body.theme-dark .list-container.embedded .actions-container.actions-project > .ei,
body.theme-dark .list-container.embedded .actions-container.actions-project .extra button {
  border-color: #a473ff;
  color: #a473ff;
}
body.theme-dark .list-container.embedded .actions-container.actions-dayplan > .ei,
body.theme-dark .list-container.embedded .actions-container.actions-dayplan .extra button {
  border-color: #21ac66;
  color: #21ac66;
}
body.theme-dark .list-container.embedded .actions-container.actions-task > .ei {
  color: #60c3ff;
  border-color: #60c3ff;
}
body.theme-dark .list-container.embedded .actions-container.actions-contact > .ei {
  color: #ff8309;
  border-color: #ff8309;
}
body.theme-dark .list-container.embedded .actions-container.actions-image > .ei {
  color: #ffca08;
  border-color: #ffca08;
}
body.theme-dark .list-container.embedded .actions-container.actions-project > .ei {
  color: #a473ff;
  border-color: #a473ff;
}
body.theme-dark .list-container.embedded .actions-container.actions-dayplan > .ei {
  color: #21ac66;
  border-color: #21ac66;
}
body.theme-dark .list-container.deleted {
  background: transparent;
}
body.theme-dark .list-context-menu button {
  color: #ddd;
  background: #daf1f8;
}
body.theme-dark .list-context-menu button.add-action {
  color: #fff;
  background: #ddd;
}
body.theme-dark .list-context-menu button.delete-action {
  color: #d9534f;
  background: #f4cecd;
}
body.theme-dark .list-context-menu button.color-task {
  background: #60c3ff;
}
body.theme-dark .list-context-menu button.color-contact {
  background: #ff8309;
}
body.theme-dark .list-context-menu button.color-image {
  background: #ffca08;
}
body.theme-dark .list-context-menu.actions-task button.add-action {
  background: #60c3ff;
}
body.theme-dark .list-context-menu.actions-contact button.add-action {
  background: #ff8309;
}
body.theme-dark .list-context-menu.actions-image button.add-action {
  background: #ffca08;
}
body.theme-dark .list-context-menu.actions-project button.add-action {
  background: #a473ff;
}
body.theme-dark .list-context-menu.actions-dayplan button.add-action {
  background: #21ac66;
}
body.theme-dark .list-container.embedded > h3 {
  background: #666;
}
body.theme-dark .list-container.embedded > h3 span {
  color: #ddd;
}
body.theme-dark .swipe-to-open .arrow-container {
  background: #555;
  box-shadow: 0 2px 1px 0 rgba(128, 128, 128, 0.14), 0 3px 1px -1px rgba(128, 128, 128, 0.12), 0 2px 3px 0 rgba(128, 128, 128, 0.2);
}
body.theme-dark .swipe-to-open .arrow-container .arrow {
  color: #aaa;
}
body.theme-dark body.desktop .path-desktop .dnd-container .panning-layer {
  padding-bottom: 10px;
}
body.theme-dark body.desktop .path-desktop .dnd-container .panning-layer.ps--active-x {
  padding-bottom: 18px;
}
body.theme-dark body.desktop .path-desktop .dnd-container .panning-layer > .ps__rail-x,
body.theme-dark body.desktop .path-desktop .dnd-container .panning-layer .list-wrapper .ps__rail-x {
  background: transparent;
}
body.theme-dark body.desktop .path-desktop .dnd-container .panning-layer > .ps__rail-x .ps__thumb-x,
body.theme-dark body.desktop .path-desktop .dnd-container .panning-layer .list-wrapper .ps__rail-x .ps__thumb-x {
  background-color: #666;
}
body.theme-dark .landing-container h1 {
  color: #60c3ff;
}
body.theme-dark .landing-container .btn {
  color: #fff;
}
body.theme-dark .landing-container .btn.login {
  background: #60c3ff;
  border-bottom-color: #009bf9;
}
body.theme-dark .landing-container .btn.register {
  background: #f0ad4e;
  border-bottom-color: #c77c11;
}
body.theme-dark body.public-route.mobile {
  background-size: initial;
  background-position: top center;
  background-repeat: repeat;
}
body.theme-dark .background-logo-image, body.theme-dark .background-logo, body.theme-dark .before-with-logo:before, body.theme-dark #mobileRoot .popup-content header:not(.day-picker):before, #mobileRoot .popup-content body.theme-dark header:not(.day-picker):before, body.theme-dark .background-logo {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='499.426' height='583.669' viewBox='0 0 499.426 583.669'%3E%3Cg id='Group_487' data-name='Group 487' transform='translate(-3.422 92.776)'%3E%3Cg id='Group_485' data-name='Group 485' transform='translate(3.422 -92.776)'%3E%3Cpath id='Path_70' data-name='Path 70' d='M2.354,48.928A44.859,44.859,0,0,1,8.941,46.6L61.412,32.541a105.4,105.4,0,0,1,79.97,10.528C201.194,77.6,352.257,228.54,403.677,279.3c19.892,19.637,42.182,38.163,53.059,78.761l12.558,46.865S387.9,101.147,358.946-6.9a38.91,38.91,0,0,0-47.655-27.513L2.214,48.4Z' transform='translate(25.733 35.749)' fill='rgba(255,255,255,0.06)' fill-rule='evenodd'/%3E%3Cpath id='Path_71' data-name='Path 71' d='M162.928,483.488a38.911,38.911,0,0,1-47.655-27.515C90.471,363.413,26.664,125.28,1.862,32.719A38.912,38.912,0,0,1,29.377-14.936l56.8-15.221A45.2,45.2,0,0,1,141.537,1.8L226.4,318.522l201.641-54.028A45.2,45.2,0,0,1,483.4,296.453c4.89,18.251,10.516,39.252,15.221,56.8A38.912,38.912,0,0,1,471.1,400.912C397.748,420.567,236.284,463.831,162.928,483.488Z' transform='translate(-0.527 98.846)' fill='rgba(255,255,255,0.06)' fill-rule='evenodd'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
body.theme-dark .background-logo-image-dark {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='499.426' height='583.669' viewBox='0 0 499.426 583.669'%3E%3Cg id='Group_487' data-name='Group 487' transform='translate(-3.422 92.776)'%3E%3Cg id='Group_485' data-name='Group 485' transform='translate(3.422 -92.776)'%3E%3Cpath id='Path_70' data-name='Path 70' d='M2.354,48.928A44.859,44.859,0,0,1,8.941,46.6L61.412,32.541a105.4,105.4,0,0,1,79.97,10.528C201.194,77.6,352.257,228.54,403.677,279.3c19.892,19.637,42.182,38.163,53.059,78.761l12.558,46.865S387.9,101.147,358.946-6.9a38.91,38.91,0,0,0-47.655-27.513L2.214,48.4Z' transform='translate(25.733 35.749)' fill='rgba(18,55,143,0.04)' fill-rule='evenodd'/%3E%3Cpath id='Path_71' data-name='Path 71' d='M162.928,483.488a38.911,38.911,0,0,1-47.655-27.515C90.471,363.413,26.664,125.28,1.862,32.719A38.912,38.912,0,0,1,29.377-14.936l56.8-15.221A45.2,45.2,0,0,1,141.537,1.8L226.4,318.522l201.641-54.028A45.2,45.2,0,0,1,483.4,296.453c4.89,18.251,10.516,39.252,15.221,56.8A38.912,38.912,0,0,1,471.1,400.912C397.748,420.567,236.284,463.831,162.928,483.488Z' transform='translate(-0.527 98.846)' fill='rgba(18,55,143,0.04)' fill-rule='evenodd'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
body.theme-dark .background-logo-image-primary {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='499.426' height='583.669' viewBox='0 0 499.426 583.669'%3E%3Cg id='Group_487' data-name='Group 487' transform='translate(-3.422 92.776)'%3E%3Cg id='Group_485' data-name='Group 485' transform='translate(3.422 -92.776)'%3E%3Cpath id='Path_70' data-name='Path 70' d='M2.354,48.928A44.859,44.859,0,0,1,8.941,46.6L61.412,32.541a105.4,105.4,0,0,1,79.97,10.528C201.194,77.6,352.257,228.54,403.677,279.3c19.892,19.637,42.182,38.163,53.059,78.761l12.558,46.865S387.9,101.147,358.946-6.9a38.91,38.91,0,0,0-47.655-27.513L2.214,48.4Z' transform='translate(25.733 35.749)' fill='rgba(255,230,0,1)' fill-rule='evenodd'/%3E%3Cpath id='Path_71' data-name='Path 71' d='M162.928,483.488a38.911,38.911,0,0,1-47.655-27.515C90.471,363.413,26.664,125.28,1.862,32.719A38.912,38.912,0,0,1,29.377-14.936l56.8-15.221A45.2,45.2,0,0,1,141.537,1.8L226.4,318.522l201.641-54.028A45.2,45.2,0,0,1,483.4,296.453c4.89,18.251,10.516,39.252,15.221,56.8A38.912,38.912,0,0,1,471.1,400.912C397.748,420.567,236.284,463.831,162.928,483.488Z' transform='translate(-0.527 98.846)' fill='rgba(18,55,143,1)' fill-rule='evenodd'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
body.theme-dark .before-with-logo, body.theme-dark #mobileRoot .popup-content header:not(.day-picker), #mobileRoot .popup-content body.theme-dark header:not(.day-picker) {
  overflow: hidden;
}
body.theme-dark .before-with-logo:before, body.theme-dark #mobileRoot .popup-content header:not(.day-picker):before, #mobileRoot .popup-content body.theme-dark header:not(.day-picker):before {
  content: "";
  display: block;
  width: 500px;
  height: 584px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100%;
  position: absolute;
  z-index: 0;
  left: 50%;
  top: -250px;
  transform-origin: center center;
  transform: scale(0.6) translateX(-400px) rotate(-23deg);
}
body.theme-dark .background-logo {
  background-repeat: no-repeat;
  background-position: center 100px;
  background-size: 165%;
}
@media (min-width: 769px) {
  body.theme-dark .background-logo {
    background-size: 130%;
    background-position: left 90px;
  }
}
@media (min-width: 1400px) {
  body.theme-dark .background-logo {
    background-size: 130%;
    background-position: left 0px;
  }
}
body.theme-dark section.main.path-introx {
  background: #444;
}
@-webkit-keyframes autofill {
  to {
    color: #ddd;
    background: transparent;
  }
}
@keyframes autofill {
  to {
    color: #ddd;
    background: transparent;
  }
}
body.theme-dark *:-webkit-autofill {
  -webkit-animation-name: autofill;
  animation-name: autofill;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
body.theme-dark .field.touched label .required {
  color: #5cb85c;
}
body.theme-dark .field.touched input,
body.theme-dark .field.touched .value {
  border-color: #5cb85c;
}
body.theme-dark .field.touched.error label .required {
  color: #d9534f;
}
body.theme-dark .field.touched.error input,
body.theme-dark .field.touched.error .value {
  border-color: #d9534f;
}
body.theme-dark .field .error:not(.field) {
  color: #d9534f;
}
body.theme-dark .field.disabled input,
body.theme-dark .field.disabled .value,
body.theme-dark .field.disabled textarea {
  background: transparent !important;
}
body.theme-dark .field.disabled label {
  color: #999;
}
body.theme-dark .field .details {
  color: #aaa;
}
body.theme-dark label.small {
  font-size: 13px;
}
body.theme-dark span.tag:before {
  background: white;
}
.field-display body.theme-dark span.tag:before {
  background: white;
  border-top-color: #ccc;
  border-left-color: #ccc;
}
.field-display body.theme-dark span.tag.compact {
  color: transparent;
}
body.theme-dark .field-lang i.ei.ei-visibility_off {
  color: #999;
}
body.theme-dark .get-link {
  border-color: #888;
  background: transparent;
  color: #ddd;
}
body.theme-dark label.color-primary {
  color: #60c3ff;
}
body.theme-dark .field.text input, body.theme-dark .field.text.mobile .value {
  border-color: #888;
  background: transparent;
  color: #ddd;
}
body.theme-dark .field.text input:focus, body.theme-dark .field.text.mobile .value:focus {
  border-color: #5bc0de !important;
}
body.theme-dark .field.text.forgot-password .forgot-btn {
  color: #999;
}
body.theme-dark .field.text input:-webkit-autofill,
body.theme-dark .field.text input:-webkit-autofill:hover,
body.theme-dark .field.text input:-webkit-autofill:focus,
body.theme-dark .field.text textarea:-webkit-autofill,
body.theme-dark .field.text textarea:-webkit-autofill:hover,
body.theme-dark .field.text textarea:-webkit-autofill:focus,
body.theme-dark .field.text select:-webkit-autofill,
body.theme-dark .field.text select:-webkit-autofill:hover,
body.theme-dark .field.text select:-webkit-autofill:focus {
  border: 1px solid #fff !important;
  border-bottom: 1px solid #888 !important;
  outline: 1px solid #fff !important;
  -webkit-text-fill-color: #ddd !important;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset !important;
  transition: background-color 5000s ease-in-out 0s !important;
  font-size: 14px !important;
}
body.theme-dark .field.text input:-webkit-autofill:focus,
body.theme-dark .field.text textarea:-webkit-autofill:focus,
body.theme-dark .field.text select:-webkit-autofill:focus {
  border-bottom: 1px solid #5bc0de !important;
}
body.theme-dark .field.text .field-wrapper > a {
  color: #5cb85c;
}
body.theme-dark .field.text.mobile.placeholder .value {
  color: #eee;
}
body.theme-dark .field.text.mobile.multiline .value span {
  color: #60c3ff;
}
body.theme-dark .field.text.mobile.clear.label .input-wrapper > .ei {
  color: #999;
}
body.theme-dark .field.text.clear .input-wrapper > .ei {
  color: #999;
}
body.theme-dark .field.text.touched.error input,
body.theme-dark .field.text.touched.error .value {
  border-color: #d9534f;
}
body.theme-dark .field.text.disabled input,
body.theme-dark .field.text.disabled .value {
  background: transparent;
}
body.theme-dark .field.text.has-action {
  color: #60c3ff;
}
body.theme-dark .field.text.has-action a {
  color: #60c3ff;
}
body.theme-dark .field.text.text-select .value input {
  background: transparent;
}
body.theme-dark .field.text.text-select .value.has-options ul {
  background: #666;
  border-color: #888;
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.3);
}
body.theme-dark .field.text.text-select .value.has-options ul li {
  border-top-color: #888;
}
body.theme-dark .field.textarea textarea {
  border-color: #888;
  background: #666 !important;
  color: #ddd;
}
body.theme-dark .field.textarea textarea:focus {
  border-color: #60c3ff !important;
}
body.theme-dark .field.textarea.touched textarea {
  border-color: #5cb85c;
}
body.theme-dark .field.textarea.touched.error textarea {
  border-color: #d9534f;
}
body.theme-dark .field.checkbox {
  color: #eee;
}
body.theme-dark .field.color-picker .value {
  border-color: #888;
  background: #666;
  color: #ddd;
}
body.theme-dark .field.color-picker .value div .ei {
  background: rgba(255, 255, 255, 0.7);
  border-color: #999;
}
body.theme-dark .field.color-picker:focus .value {
  border-bottom-color: #5bc0de;
  border-right-color: #5bc0de;
}
body.theme-dark .field.color-picker.touched .value {
  border-color: #5cb85c;
}
body.theme-dark .field.color-picker.touched.error .value {
  border-color: #d9534f;
}
body.theme-dark .color-picker-popup .picker-container > .undo {
  background: #fff;
  color: #d9534f;
}
body.theme-dark .color-picker-popup .hue-picker div.black .ei {
  color: #fff;
}
body.theme-dark .color-picker-popup .hue-slider {
  background: -moz-linear-gradient(right, #ff0000 0%, #ffff00 17%, #00ff00 33%, #00ffff 50%, #0000ff 67%, #ff00ff 83%, #ff0000 100%);
  background: -ms-linear-gradient(right, #ff0000 0%, #ffff00 17%, #00ff00 33%, #00ffff 50%, #0000ff 67%, #ff00ff 83%, #ff0000 100%);
  background: -o-linear-gradient(right, #ff0000 0%, #ffff00 17%, #00ff00 33%, #00ffff 50%, #0000ff 67%, #ff00ff 83%, #ff0000 100%);
  background: -webkit-gradient(linear, left top, right top, from(#ff0000), color-stop(0.17, #ffff00), color-stop(0.33, #00ff00), color-stop(0.5, #00ffff), color-stop(0.67, #0000ff), color-stop(0.83, #ff00ff), to(#ff0000));
  background: -webkit-linear-gradient(right, #ff0000 0%, #ffff00 17%, #00ff00 33%, #00ffff 50%, #0000ff 67%, #ff00ff 83%, #ff0000 100%);
  background: linear-gradient(to right, #ff0000 0%, #ffff00 17%, #00ff00 33%, #00ffff 50%, #0000ff 67%, #ff00ff 83%, #ff0000 100%);
}
body.theme-dark .color-picker-popup .hue-slider .cursor {
  border-color: #000;
  background: #fff;
}
body.theme-dark .color-picker-popup .colors > div .ei {
  color: #ddd;
}
body.theme-dark .color-picker-popup .colors > div:nth-last-child(-n+25) .ei {
  color: #fff;
}
body.theme-dark .color-picker-in-popup .picker-container .preview label {
  color: #fff;
}
body.theme-dark .color-picker-in-popup .picker-container .preview > div {
  border: 8px solid #666;
}
body.theme-dark .color-picker-in-popup .hue-picker div.black .ei {
  color: #fff;
}
body.theme-dark .color-picker-in-popup .hue-slider {
  background: -moz-linear-gradient(right, #ff0000 0%, #ffff00 17%, #00ff00 33%, #00ffff 50%, #0000ff 67%, #ff00ff 83%, #ff0000 100%);
  background: -ms-linear-gradient(right, #ff0000 0%, #ffff00 17%, #00ff00 33%, #00ffff 50%, #0000ff 67%, #ff00ff 83%, #ff0000 100%);
  background: -o-linear-gradient(right, #ff0000 0%, #ffff00 17%, #00ff00 33%, #00ffff 50%, #0000ff 67%, #ff00ff 83%, #ff0000 100%);
  background: -webkit-gradient(linear, left top, right top, from(#ff0000), color-stop(0.17, #ffff00), color-stop(0.33, #00ff00), color-stop(0.5, #00ffff), color-stop(0.67, #0000ff), color-stop(0.83, #ff00ff), to(#ff0000));
  background: -webkit-linear-gradient(right, #ff0000 0%, #ffff00 17%, #00ff00 33%, #00ffff 50%, #0000ff 67%, #ff00ff 83%, #ff0000 100%);
  background: linear-gradient(to right, #ff0000 0%, #ffff00 17%, #00ff00 33%, #00ffff 50%, #0000ff 67%, #ff00ff 83%, #ff0000 100%);
}
body.theme-dark .color-picker-in-popup .hue-slider .cursor {
  border-color: #000;
  background: #fff;
}
body.theme-dark .color-picker-in-popup .colors > div .ei {
  color: #ddd;
}
body.theme-dark .color-picker-in-popup .colors > div:nth-last-child(-n+25) .ei {
  color: #fff;
}
body.theme-dark .field.radio .option .overlay {
  background: rgba(255, 255, 255, 0.5);
}
body.theme-dark .field.radio .option.checked > .ei.radio {
  color: #60c3ff;
}
body.theme-dark .field.radio.color-primary .option .overlay {
  background: transparent;
}
body.theme-dark .field.radio.color-primary .option.checked {
  color: #60c3ff;
}
body.theme-dark .field.select .value {
  border-color: #888;
  background: transparent;
  color: #ddd;
}
body.theme-dark .field.select .value > .ei.right {
  color: #aaa;
}
body.theme-dark .field.select.none .value {
  color: #eee;
}
body.theme-dark .field.select.primary.none .value {
  color: #60c3ff;
}
body.theme-dark .field.select.primary.none .value .ei {
  color: #60c3ff;
}
body.theme-dark .field.select.touched .value {
  border-color: #5cb85c;
}
body.theme-dark .field.select.touched.error .value {
  border-color: #d9534f;
}
body.theme-dark .field.select .more {
  color: #60c3ff;
}
body.theme-dark .field.datetime .value {
  border-color: #888;
  background: transparent !important;
  color: #ddd;
}
body.theme-dark .field.datetime .value:focus {
  border-color: #60c3ff !important;
}
body.theme-dark .field.datetime .value > .ei.arrow,
body.theme-dark .field.datetime .value > .ei.clear {
  color: #aaa;
}
body.theme-dark .field.datetime .value > .ei.clear {
  color: #999;
}
body.theme-dark .field.datetime .value .action.disabled {
  color: #999;
}
body.theme-dark .field.datetime.placeholder .value {
  color: #eee;
}
body.theme-dark .field.datetime.disabled .value {
  background: transparent !important;
}
body.theme-dark #mobileRoot .datetime-popup .datetime-popup-content {
  background: #60c3ff;
  border-color: rgba(255, 255, 255, 0.2);
}
body.theme-dark #mobileRoot .datetime-popup .datetime-popup-content > header {
  border-bottom-color: #444;
  background: transparent;
  color: #fff;
}
body.theme-dark #mobileRoot .datetime-popup .datetime-popup-content > section {
  background: transparent;
}
body.theme-dark #mobileRoot .datetime-popup section > .display {
  border-top-color: #888;
  border-bottom-color: #888;
  color: #ddd;
  background: #666;
}
body.theme-dark #mobileRoot .datetime-popup section > .display.timezone {
  border-top: 0 none;
  overflow: hidden;
  max-height: 0;
  padding: 0;
  will-change: max-height, padding;
  transition: all 0.3s ease-out;
}
body.theme-dark #mobileRoot .datetime-popup section > .display.timezone.visible {
  max-height: 60px;
  padding: 10px 0;
}
body.theme-dark #mobileRoot .datetime-popup section > label {
  color: #fff;
}
body.theme-dark .track {
  background: transparent;
  color: #fff;
}
body.theme-dark .track:before {
  background: #60c3ff;
  background: -moz-linear-gradient(left, #60c3ff 0%, rgba(96, 195, 255, 0) 100%);
  background: -webkit-linear-gradient(left, #60c3ff 0%, rgba(96, 195, 255, 0) 100%);
  background: linear-gradient(to right, #60c3ff 0%, rgba(96, 195, 255, 0) 100%);
}
body.theme-dark .track:after {
  background: #60c3ff;
  background: -moz-linear-gradient(right, #60c3ff 0%, rgba(96, 195, 255, 0) 100%);
  background: -webkit-linear-gradient(right, #60c3ff 0%, rgba(96, 195, 255, 0) 100%);
  background: linear-gradient(to left, #60c3ff 0%, rgba(96, 195, 255, 0) 100%);
}
body.theme-dark .track ul li:before {
  border-color: transparent transparent #444;
}
body.theme-dark .track.horizontal {
  border-bottom-color: #444;
}
#mobileRoot .datetime-popup body.theme-dark .track.horizontal .ps__rail-x {
  height: 12px;
}
#mobileRoot .datetime-popup body.theme-dark .track.horizontal .ps__rail-x:hover > .ps__thumb-x,
#mobileRoot .datetime-popup body.theme-dark .track.horizontal .ps__rail-x:focus > .ps__thumb-x,
#mobileRoot .datetime-popup body.theme-dark .track.horizontal .ps__rail-x.ps--clicking .ps__thumb-x {
  height: 8px;
}
#mobileRoot .datetime-popup body.theme-dark .track.horizontal:not(.day-plan-track) .ps .ps__rail-x:hover,
#mobileRoot .datetime-popup body.theme-dark .track.horizontal:not(.day-plan-track) .ps .ps__rail-y:hover,
#mobileRoot .datetime-popup body.theme-dark .track.horizontal:not(.day-plan-track) .ps .ps__rail-x:focus,
#mobileRoot .datetime-popup body.theme-dark .track.horizontal:not(.day-plan-track) .ps .ps__rail-y:focus,
#mobileRoot .datetime-popup body.theme-dark .track.horizontal:not(.day-plan-track) .ps .ps__rail-x.ps--clicking,
#mobileRoot .datetime-popup body.theme-dark .track.horizontal:not(.day-plan-track) .ps .ps__rail-y.ps--clicking {
  background-color: rgba(255, 255, 255, 0.1);
}
#mobileRoot .datetime-popup body.theme-dark .track.horizontal:not(.day-plan-track) .ps__thumb-x,
#mobileRoot .datetime-popup body.theme-dark .track.horizontal:not(.day-plan-track) .ps__rail-x:hover > .ps__thumb-x,
#mobileRoot .datetime-popup body.theme-dark .track.horizontal:not(.day-plan-track) .ps__rail-x:focus > .ps__thumb-x,
#mobileRoot .datetime-popup body.theme-dark .track.horizontal:not(.day-plan-track) .ps__rail-x.ps--clicking .ps__thumb-x {
  background-color: #666;
}
body.theme-dark .day-picker.header {
  color: #fff;
  background: transparent;
}
body.theme-dark .day-picker.header div {
  border-bottom-color: #888;
}
.day-picker-inline-container body.theme-dark .day-picker.header {
  color: #60c3ff;
}
body.theme-dark .day-picker:before {
  background: #d9534f;
}
body.theme-dark .day-picker section.calendar {
  background: #666;
}
body.theme-dark .day-picker section.calendar .week {
  border-bottom-color: #888;
}
body.theme-dark .day-picker section.calendar .week .day {
  border-right-color: #888;
}
body.theme-dark .day-picker section.calendar .week .day.today:before {
  border: 4px solid #60c3ff;
  border-color: transparent #60c3ff #60c3ff transparent;
}
body.theme-dark .day-picker section.calendar .week .day.saturday, body.theme-dark .day-picker section.calendar .week .day.sunday {
  color: #60c3ff;
}
body.theme-dark .day-picker section.calendar .week .day.active {
  background: #60c3ff;
  color: #fff;
}
body.theme-dark .day-picker section.calendar .week .day.active.today:before {
  border-color: transparent #444 #444 transparent;
}
body.theme-dark .day-picker section.calendar .week .day.prev, body.theme-dark .day-picker section.calendar .week .day.next {
  color: rgba(221, 221, 221, 0.4);
}
body.theme-dark .day-picker section.calendar .week .day.prev.saturday, body.theme-dark .day-picker section.calendar .week .day.prev.sunday, body.theme-dark .day-picker section.calendar .week .day.next.saturday, body.theme-dark .day-picker section.calendar .week .day.next.sunday {
  color: rgba(96, 195, 255, 0.4);
}
body.theme-dark .day-picker section.calendar .week .day.disabled {
  background-color: rgba(102, 102, 102, 0.3);
  color: rgba(221, 221, 221, 0.3);
}
body.theme-dark .day-picker section.calendar .week .day.disabled.saturday, body.theme-dark .day-picker section.calendar .week .day.disabled.sunday {
  color: rgba(96, 195, 255, 0.3);
}
body.theme-dark .day-picker section.calendar .week .day span {
  display: inline-block;
  position: relative;
  z-index: 2;
  line-height: 14px;
  padding: 2px 5px;
  border-radius: 20px;
  background: #666;
}
body.theme-dark .day-picker section.calendar .week .day.streak-start:after, body.theme-dark .day-picker section.calendar .week .day.streak-end:after, body.theme-dark .day-picker section.calendar .week .day.in-streak:after {
  content: "";
  display: block;
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  top: 50%;
  bottom: auto;
  height: 8px;
  margin-top: -4px;
}
body.theme-dark .day-picker section.calendar .week .day.streak-start:after {
  left: 50%;
}
body.theme-dark .day-picker section.calendar .week .day.streak-end:after {
  right: 50%;
}
body.theme-dark .day-picker-inline-container {
  overflow: hidden;
  border-top: 1px solid #60c3ff;
}
body.theme-dark .slider.horizontal .display, .datetime-popup body.theme-dark .slider.horizontal .display {
  color: #fff;
}
body.theme-dark .slider.horizontal .ei {
  color: #fff;
}
body.theme-dark .slider.horizontal .track-line:before {
  background: #888;
}
body.theme-dark .slider.horizontal .track-line .min,
body.theme-dark .slider.horizontal .track-line .max {
  color: #fff;
  background: #60c3ff;
  border-color: #888;
}
body.theme-dark .slider.horizontal .track-line .value {
  border-color: #666;
  background: #60c3ff;
  color: #fff;
}
body.theme-dark .slider.horizontal.dragging .track-line .value {
  background: #93d6ff;
}
body.theme-dark .field.notifications-field .notification-line {
  border-top-color: #888;
}
body.theme-dark .field.notifications-field .notification-line:last-of-type {
  border-bottom-color: #888;
}
body.theme-dark .field.dynamic-fields-list li {
  border-top-color: #888;
}
body.theme-dark .field.array-list-field.zebra ul li:nth-child(odd) {
  background: #555;
}
body.theme-dark .field.tasks-list .list li.completed {
  background: #869f86;
}
body.theme-dark .field.tasks-list .list li.completed .ei.completed {
  color: #5cb85c;
}
body.theme-dark .field.tasks-list .list li.completed h3 span {
  color: #eee;
}
body.theme-dark .field.file-upload .buttons {
  border-color: rgba(0, 0, 0, 0.3);
}
body.theme-dark .field.file-upload .buttons button {
  color: #60c3ff;
  background: transparent;
  border-left-color: rgba(0, 0, 0, 0.3);
}
body.theme-dark .field.file-upload .buttons button .ei {
  color: #aaa;
}
body.theme-dark .field.file-upload .file-input-container {
  color: #60c3ff;
  border-color: 1px dashed #888;
}
body.theme-dark .field.file-upload .file-input-container .ei {
  color: #aaa;
}
body.theme-dark .field.file-upload .ei.clear {
  color: #aaa;
}
body.theme-dark .image-preview,
body.theme-dark .image-thumbnail {
  background-position: center;
  background-size: cover;
  background-color: #555;
  background-repeat: no-repeat;
}
body.theme-dark .image-preview.no-image,
body.theme-dark .image-thumbnail.no-image {
  color: #aaa;
}
body.theme-dark .image-preview .ei.open,
body.theme-dark .image-thumbnail .ei.open {
  background: rgba(0, 0, 0, 0.35);
}
body.theme-dark .image-preview .ei.open:before,
body.theme-dark .image-thumbnail .ei.open:before {
  color: #fff;
}
body.theme-dark .image-preview .ei.spinning,
body.theme-dark .image-preview .ei.error,
body.theme-dark .image-thumbnail .ei.spinning,
body.theme-dark .image-thumbnail .ei.error {
  color: #aaa;
}
body.theme-dark .image-preview.video .ei.spinning,
body.theme-dark .image-thumbnail.video .ei.spinning {
  opacity: 0.3;
}
body.theme-dark .history-list-container ul.comments-list li {
  background-color: transparent;
}
body.theme-dark .history-list-container ul.comments-list li.autofocus {
  background-color: #5cb85c;
}
body.theme-dark .history-list-container ul.comments-list li .speech-bubble {
  background: rgba(0, 0, 0, 0.1);
}
body.theme-dark .history-list-container ul.comments-list li .speech-bubble:before {
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
}
body.theme-dark .history-list-container .field.textarea textarea[id].highlight {
  background-color: #5cb85c !important;
}
body.theme-dark .recurrence-edit .weekdays span {
  border-color: #888;
  background: #666;
}
body.theme-dark .recurrence-edit .weekdays span.selected {
  background: #60c3ff;
  color: #fff;
}
body.theme-dark .form-tabs-container {
  background: #60c3ff;
}
.color-task body.theme-dark .form-tabs-container {
  background: #60c3ff;
}
.color-contact body.theme-dark .form-tabs-container {
  background: #ff8309;
}
.color-image body.theme-dark .form-tabs-container {
  background: #ffca08;
}
.color-project body.theme-dark .form-tabs-container {
  background: #a473ff;
}
.color-goal body.theme-dark .form-tabs-container {
  background: #a473ff;
}
.color-friend body.theme-dark .form-tabs-container {
  background: #ff8309;
}
.color-feedback body.theme-dark .form-tabs-container {
  background: #21ac66;
}
.color-settings body.theme-dark .form-tabs-container {
  background: #666;
}
body.theme-dark .form-tabs-container > nav button {
  background: #444;
  border-left-color: #0a1008;
  border-bottom-color: #0a1008;
  box-shadow: unset;
}
.color-task body.theme-dark .form-tabs-container > nav button {
  border-color: #008be0;
}
.color-contact body.theme-dark .form-tabs-container > nav button {
  border-color: #d56a00;
}
.color-image body.theme-dark .form-tabs-container > nav button {
  border-color: #d4a700;
}
.color-project body.theme-dark .form-tabs-container > nav button {
  border-color: #8340ff;
}
.color-goal body.theme-dark .form-tabs-container > nav button {
  border-color: #8340ff;
}
.color-friend body.theme-dark .form-tabs-container > nav button {
  border-color: #d56a00;
}
.color-feedback body.theme-dark .form-tabs-container > nav button {
  border-color: #19814d;
}
.color-settings body.theme-dark .form-tabs-container > nav button {
  border-color: #4d4d4d;
}
body.theme-dark .form-tabs-container > nav button .ei {
  color: #eee;
}
body.theme-dark .form-tabs-container > nav button.active {
  background: #666;
  border-color: transparent;
  border-bottom-color: #0a1008;
}
.color-task body.theme-dark .form-tabs-container > nav button.active {
  border-bottom-color: #008be0;
  background: #666;
}
.color-contact body.theme-dark .form-tabs-container > nav button.active {
  border-bottom-color: #d56a00;
  background: #fffcf9;
}
.color-image body.theme-dark .form-tabs-container > nav button.active {
  border-bottom-color: #d4a700;
  background: #fffff7;
}
.color-project body.theme-dark .form-tabs-container > nav button.active {
  border-bottom-color: #8340ff;
  background: #fdfbff;
}
.color-goal body.theme-dark .form-tabs-container > nav button.active {
  border-bottom-color: #8340ff;
  background: #fdfbff;
}
.color-friend body.theme-dark .form-tabs-container > nav button.active {
  border-bottom-color: #d56a00;
  background: #fffcf9;
}
.color-feedback body.theme-dark .form-tabs-container > nav button.active {
  border-bottom-color: #19814d;
  background: #f7fff7;
}
.color-settings body.theme-dark .form-tabs-container > nav button.active {
  border-bottom-color: #4d4d4d;
  background: #666;
}
body.theme-dark .form-tabs-container > nav button.active .ei {
  color: #ddd;
}
body.theme-dark .form-tabs-container .form-tab {
  background: #666;
}
.color-task body.theme-dark .form-tabs-container .form-tab {
  background: #666;
  border-color: #008be0;
}
.color-contact body.theme-dark .form-tabs-container .form-tab {
  background: #fffcf9;
  border-color: #d56a00;
}
.color-image body.theme-dark .form-tabs-container .form-tab {
  background: #fffff7;
  border-color: #d4a700;
}
.color-project body.theme-dark .form-tabs-container .form-tab {
  background: #fdfbff;
  border-color: #8340ff;
}
.color-goal body.theme-dark .form-tabs-container .form-tab {
  background: #fdfbff;
  border-color: #8340ff;
}
.color-friend body.theme-dark .form-tabs-container .form-tab {
  background: #fffcf9;
  border-color: #d56a00;
}
.color-feedback body.theme-dark .form-tabs-container .form-tab {
  background: #f7fff7;
  border-color: #19814d;
}
.color-settings body.theme-dark .form-tabs-container .form-tab {
  background: #777;
  border-color: #4d4d4d;
}
body.theme-dark .field-display.text.touched.error .value-wrapper {
  color: #d9534f;
}
body.theme-dark .field-display.status {
  background: #60c3ff;
  color: #fff;
}
body.theme-dark .field-display.status.progress {
  background: #fceedb;
  color: #f0ad4e;
}
body.theme-dark .field-display.status.done {
  background: #c7e6c7;
  color: #5cb85c;
}
body.theme-dark .timestamp {
  color: #999;
}
body.theme-dark .show-icon {
  color: #999;
}
body.theme-dark .show-icon.active {
  color: #5cb85c;
  background: white;
}
body.theme-dark table.summary th {
  color: #aaa;
}
body.theme-dark table.summary td a {
  color: #60c3ff;
}
body.theme-dark table.summary td button.det-sms {
  background: transparent;
}
@keyframes moveGradient {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: -500% 0%;
  }
}
body.theme-dark .progress-bar {
  background: rgba(255, 255, 255, 0.8);
}
body.theme-dark .progress-bar .loading-bar {
  border-color: #aaa;
}
body.theme-dark .progress-bar .loading-bar div {
  background-size: 500% !important;
  animation: moveGradient 10s linear infinite;
  transition: width 0.15s ease-out;
  background: linear-gradient(150deg, #60c3ff 0%, #60c3ff 22%, #666 25%, #60c3ff 28%, #60c3ff 72%, #666 75%, #60c3ff 78%, #60c3ff 100%);
}
body.theme-dark .progress-bar .solid-bar {
  border-color: #aaa;
}
body.theme-dark .progress-bar .solid-bar div {
  background-color: #5bc0de;
}
body.theme-dark .progress-bar.overlaid .loading-bar,
body.theme-dark .progress-bar.overlaid .solid-bar {
  border: 0 none;
}
body.theme-dark .progress-bar.overlaid .loading-bar div,
body.theme-dark .progress-bar.overlaid .solid-bar div {
  border: 3px solid #666;
}
body.theme-dark .progress-loading-bar {
  border-color: #60c3ff;
  background: rgba(255, 255, 255, 0.8);
}
body.theme-dark .progress-loading-bar div {
  background-size: 500% !important;
  animation: moveGradient 10s linear infinite;
  transition: width 0.15s ease-out;
  background: linear-gradient(150deg, #60c3ff 0%, #60c3ff 22%, #666 25%, #60c3ff 28%, #60c3ff 72%, #666 75%, #60c3ff 78%, #60c3ff 100%);
}
body.theme-dark #mobileRoot .popup-content header {
  background: transparent;
}
body.theme-dark .actions-container > .ei {
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.6);
}
body.theme-dark .path-settings-desktops .actions-container > .ei {
  color: #555;
}
body.theme-dark .list-container.list-dayplan .time-slot.productivity.sleep .zone-header {
  color: #aaa !important;
}
body.theme-dark .field.radio .option .overlay {
  background: rgba(102, 102, 102, 0.5);
}
body.theme-dark .field.radio .option.checked > .ei.radio {
  color: #eee;
}
body.theme-dark .desktop-picker div:hover {
  background: #666;
}
body.theme-dark .desktop-picker div.selected span,
body.theme-dark .desktop-picker div.selected .ei {
  color: #eee !important;
}
body.theme-dark .track:before {
  left: 0;
  background: #595959;
  background: -moz-linear-gradient(left, #595959 0%, rgba(94, 94, 94, 0) 100%);
  background: -webkit-linear-gradient(left, #595959 0%, rgba(94, 94, 94, 0) 100%);
  background: linear-gradient(to right, #595959 0%, rgba(94, 94, 94, 0) 100%);
}
body.theme-dark .track:after {
  right: 0;
  background: #666;
  background: -moz-linear-gradient(right, #666 0%, rgba(102, 102, 102, 0) 100%);
  background: -webkit-linear-gradient(right, #666 0%, rgba(102, 102, 102, 0) 100%);
  background: linear-gradient(to left, #666 0%, rgba(102, 102, 102, 0) 100%);
}
body.theme-dark button.danger-text {
  background: rgba(255, 255, 255, 0.9) padding-box !important;
}
body.theme-dark .dnd-container .vertical-container.embedded.tree-view {
  background: transparent !important;
}
body.theme-dark .popups-container .popup-container.color-task .popup-content {
  background: #555;
}
body.theme-dark .popups-container .popup-container.color-contact .popup-content {
  background: #555;
}
body.theme-dark .popups-container .popup-container.color-image .popup-content {
  background: #555;
}
body.theme-dark .popups-container .popup-container.color-project .popup-content {
  background: #555;
}
body.theme-dark .popups-container .popup-container.color-goal .popup-content {
  background: #555;
}
body.theme-dark .popups-container .popup-container.color-friend .popup-content {
  background: #555;
}
body.theme-dark .popups-container .popup-container.color-feedback .popup-content {
  background: #f7fff7;
}
body.theme-dark .form-tabs-container.settings {
  background: #555;
}
body.theme-dark .form-tabs-container .form-tab {
  background: #555;
  border-right-color: #111;
}
body.theme-dark .form-tabs-container > nav button.active {
  background: #555;
}
body.theme-dark #mobileRoot .datetime-popup .datetime-popup-content {
  background: #666;
}
body.theme-dark .list-context-menu button {
  color: #555;
}
body.theme-dark .list-context-menu button.add-action {
  color: #000;
}
body.theme-dark .list-container.list-task.embedded > h3 .ei.collapse-arrow {
  color: #ddd;
}

body.font-size-large {
  font-size: 16px;
}
body.font-size-large .dialog-container .message {
  font-size: 20px;
  color: #24294B !important;
}
body.font-size-large .dialog-container .buttons > button {
  font-size: 16px !important;
  font-family: "Segoe UI";
}
body.font-size-large .dialog-container .options {
  font-size: 16px;
}
body.font-size-large .dialog-container .options > div .ei {
  font-size: 20px;
}
body.font-size-large ul.list li h3,
body.font-size-large ul.list li h4 {
  font-weight: normal;
}
body.font-size-large ul.list li h3 {
  font-size: 16px;
}
body.font-size-large ul.list li.notification h3 {
  font-style: italic;
}
body.font-size-large ul.list li.notification.unread h3 {
  font-style: normal;
}
body.font-size-large ul.list li.friend.pending h3 {
  font-weight: bold;
}
body.font-size-large .slideshow .slide.size-small,
body.font-size-large .slideshow .slide.size-small h1,
body.font-size-large .slideshow .slide.size-small .project-container h1,
body.font-size-large .slideshow .slide.size-small h2,
body.font-size-large .slideshow .slide.size-small .project-container h2,
body.font-size-large .slideshow .slide.size-small li,
body.font-size-large .slideshow .slide.size-small .caption,
body.font-size-large .slideshow .slide.size-small .text,
body.font-size-large .slideshow .slide.size-small a {
  font-size: 22px;
  line-height: 24px;
  padding: 8px 16px 12px;
}
body.font-size-large .slideshow .slide,
body.font-size-large .slideshow .slide h1,
body.font-size-large .slideshow .slide .project-container h1,
body.font-size-large .slideshow .slide h2,
body.font-size-large .slideshow .slide .project-container h2,
body.font-size-large .slideshow .slide li,
body.font-size-large .slideshow .slide .caption,
body.font-size-large .slideshow .slide .text,
body.font-size-large .slideshow .slide a, body.font-size-large .slideshow .slide.size-normal,
body.font-size-large .slideshow .slide.size-normal h1,
body.font-size-large .slideshow .slide.size-normal .project-container h1,
body.font-size-large .slideshow .slide.size-normal h2,
body.font-size-large .slideshow .slide.size-normal .project-container h2,
body.font-size-large .slideshow .slide.size-normal li,
body.font-size-large .slideshow .slide.size-normal .caption,
body.font-size-large .slideshow .slide.size-normal .text,
body.font-size-large .slideshow .slide.size-normal a {
  font-size: 28px;
  line-height: 30px;
  padding: 8px 16px 14px;
}
body.font-size-large .slideshow .slide.size-large,
body.font-size-large .slideshow .slide.size-large h1,
body.font-size-large .slideshow .slide.size-large .project-container h1,
body.font-size-large .slideshow .slide.size-large h2,
body.font-size-large .slideshow .slide.size-large .project-container h2,
body.font-size-large .slideshow .slide.size-large li,
body.font-size-large .slideshow .slide.size-large .caption,
body.font-size-large .slideshow .slide.size-large .text,
body.font-size-large .slideshow .slide.size-large a {
  font-size: 32px;
  line-height: 34px;
  padding: 8px 16px 14px;
}
body.font-size-large .slideshow .slide.size-xlarge,
body.font-size-large .slideshow .slide.size-xlarge h1,
body.font-size-large .slideshow .slide.size-xlarge .project-container h1,
body.font-size-large .slideshow .slide.size-xlarge h2,
body.font-size-large .slideshow .slide.size-xlarge .project-container h2,
body.font-size-large .slideshow .slide.size-xlarge li,
body.font-size-large .slideshow .slide.size-xlarge .caption,
body.font-size-large .slideshow .slide.size-xlarge .text,
body.font-size-large .slideshow .slide.size-xlarge a {
  font-size: 40px;
  line-height: 42px;
  padding: 8px 16px 14px;
}
body.font-size-large .slideshow .slide.size-xxlarge,
body.font-size-large .slideshow .slide.size-xxlarge h1,
body.font-size-large .slideshow .slide.size-xxlarge .project-container h1,
body.font-size-large .slideshow .slide.size-xxlarge h2,
body.font-size-large .slideshow .slide.size-xxlarge .project-container h2,
body.font-size-large .slideshow .slide.size-xxlarge li,
body.font-size-large .slideshow .slide.size-xxlarge .caption,
body.font-size-large .slideshow .slide.size-xxlarge .text,
body.font-size-large .slideshow .slide.size-xxlarge a {
  font-size: 48px;
  line-height: 50px;
  padding: 8px 18px 14px;
}
body.font-size-large .slideshow .slide.size-xxxlarge,
body.font-size-large .slideshow .slide.size-xxxlarge h1,
body.font-size-large .slideshow .slide.size-xxxlarge .project-container h1,
body.font-size-large .slideshow .slide.size-xxxlarge h2,
body.font-size-large .slideshow .slide.size-xxxlarge .project-container h2,
body.font-size-large .slideshow .slide.size-xxxlarge li,
body.font-size-large .slideshow .slide.size-xxxlarge .caption,
body.font-size-large .slideshow .slide.size-xxxlarge .text,
body.font-size-large .slideshow .slide.size-xxxlarge a {
  font-size: 58px;
  line-height: 60px;
  padding: 8px 20px 16px;
}
body.font-size-large .slideshow .slide.size-xxxxlarge,
body.font-size-large .slideshow .slide.size-xxxxlarge h1,
body.font-size-large .slideshow .slide.size-xxxxlarge .project-container h1,
body.font-size-large .slideshow .slide.size-xxxxlarge h2,
body.font-size-large .slideshow .slide.size-xxxxlarge .project-container h2,
body.font-size-large .slideshow .slide.size-xxxxlarge li,
body.font-size-large .slideshow .slide.size-xxxxlarge .caption,
body.font-size-large .slideshow .slide.size-xxxxlarge .text,
body.font-size-large .slideshow .slide.size-xxxxlarge a {
  font-size: 68px;
  line-height: 70px;
  padding: 8px 22px 16px;
}
body.font-size-large .slideshow .slide ul.projects li {
  font-size: 20px;
}
body.font-size-large .slideshow .slide .caption {
  font-size: 14px;
  font-weight: bold;
}
body.font-size-large .slideshow .slide .text,
body.font-size-large .slideshow .slide ul li {
  font-weight: bold;
}
body.font-size-large .settings-container.start-my-day section h4 .ei {
  font-size: 20px;
}
body.font-size-large .settings-container.start-my-day h4 {
  margin: 0;
  padding: 10px 10px 12px;
}
body.font-size-large .settings-container.start-my-day img {
  max-width: 80px;
  max-height: 80px;
}
body.font-size-large .settings-container.start-my-day .field.slide-editor .preview {
  font-size: 16px;
  font-weight: bold;
}
body.font-size-large .settings-container.start-my-day .field.slide-editor .preview.size-small {
  font-size: 14px;
}
body.font-size-large .settings-container.start-my-day .field.slide-editor .preview.size-normal {
  font-size: 16px;
}
body.font-size-large .settings-container.start-my-day .field.slide-editor .preview.size-large {
  font-size: 20px;
}
body.font-size-large .settings-container.start-my-day .field.slide-editor .preview.size-xlarge {
  font-size: 24px;
}
body.font-size-large .settings-container.start-my-day .field.slide-editor .preview.size-xxlarge {
  font-size: 28px;
}
body.font-size-large .settings-container.start-my-day .field.slide-editor .preview.size-xxxlarge {
  font-size: 32px;
}
body.font-size-large .settings-container.start-my-day .field.slide-editor .preview.size-xxxxlarge {
  font-size: 36px;
}
body.font-size-large ul.preview-layer > h3 {
  font-size: 20px;
  font-weight: normal;
}
body.font-size-large .list-container > h3 {
  font-size: 20px;
  font-weight: normal;
}
body.font-size-large .list-container > h3 .edit-filters.highlight span,
body.font-size-large .list-container > h3 .collapse-arrow.highlight span {
  font-size: 14px;
}
body.font-size-large .list-container.desktop-chooser .field.select .value span {
  font-size: 20px;
  font-weight: normal;
}
body.font-size-large .list-container.list-dayplan .day-plan-date {
  font-size: 13px;
}
body.font-size-large .list-container.list-dayplan .day-plan-track div {
  line-height: 14px;
}
body.font-size-large .list-container.list-dayplan .day-plan-track .weekday {
  font-size: 12px;
}
body.font-size-large .list-container.list-dayplan .day-plan-track .date {
  font-size: 13px;
}
body.font-size-large .list-container.list-dayplan .day-plan-track li.active {
  font-weight: bold;
}
body.font-size-large .list-container.list-dayplan .time-slot.productivity .zone-header {
  font-size: 14px;
}
body.font-size-large .list-container.embedded > h3 span {
  font-size: 16px;
  font-weight: bold;
  text-transform: none;
}
body.font-size-large .root span {
  font-size: 40px;
}
body.font-size-large .landing-container h1 {
  font-size: 36px;
  font-weight: normal;
}
body.font-size-large .landing-container .btn {
  font-size: 20px;
}
body.font-size-large .field label:not(.MuiFormControlLabel-root) {
  font-size: 14px;
}
body.font-size-large .field label:not(.MuiFormControlLabel-root) .required {
  font-size: 16px;
}
body.font-size-large .field.floating-label label:not(.MuiFormControlLabel-root) {
  font-size: 16px;
}
body.font-size-large .field.floating-label.has-value label, body.desktop body.font-size-large .field.floating-label.has-value label, body.font-size-large .field.floating-label.focus label, body.desktop body.font-size-large .field.floating-label.focus label,
body.font-size-large .field.floating-label label.editable,
body.desktop body.font-size-large .field.floating-label label.editable {
  font-size: 13px;
}
body.font-size-large .field .error:not(.field) span {
  font-size: 14px;
}
body.font-size-large .field .details {
  font-size: 14px;
}
body.font-size-large label.small {
  font-size: 13px;
}
.field-display body.font-size-large span.tag {
  font-size: 14px;
}
body.font-size-large .field.text .field-wrapper > a {
  font-size: 24px;
}
body.font-size-large .field.text .description {
  font-size: 12px;
}
body.font-size-large .field.select .more {
  font-size: 14px;
}
body.font-size-large .field.select .more span {
  font-weight: bold;
}
body.font-size-large #mobileRoot .datetime-popup section > label {
  font-size: 14px;
}
body.font-size-large .slider.horizontal .track-line .min, body.font-size-large .slider.horizontal .track-line .max {
  font-size: 10px;
}
body.font-size-large .slider.horizontal .track-line .value {
  font-size: 14px;
}
body.font-size-large .field-display.status {
  font-size: 14px;
}
body.font-size-large .timestamp {
  font-size: 14px;
}
body.font-size-large table.summary th {
  font-size: 14px;
}
body.font-size-large .progress-bar .number {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}
body.font-size-large .list-container.list-dayplan .day-plan-date .date {
  font-size: 15px;
}

body.layout-cozy section.main {
  height: 100%;
}
body.mobile body.layout-cozy section.main.path-feedback ul.list, body.mobile body.layout-cozy section.main.path-goals ul.list, body.mobile body.layout-cozy section.main.path-habits ul.list, body.mobile body.layout-cozy section.main.path-friends ul.list {
  padding-bottom: 90px;
}
body.layout-cozy #mobileRoot {
  height: 100%;
}
body.layout-cozy section.main.tasks {
  top: 40px;
}
body.layout-cozy h1 {
  margin: 0;
  padding: 15px 0;
}
body.layout-cozy hr {
  margin: -1px 0;
  position: relative;
  z-index: 2;
}
body.layout-cozy hr:first-child {
  margin-top: 0;
}
body.layout-cozy .no-break {
  white-space: nowrap;
}
body.layout-cozy .warning-message,
body.layout-cozy .error-message {
  margin: 0;
  padding: 10px;
}
body.layout-cozy .warning-message.inline,
body.layout-cozy .error-message.inline {
  border-radius: 8px;
}
body.layout-cozy .has-feature-icon {
  display: flex;
  align-items: center;
  justify-content: stretch;
}
body.layout-cozy .has-feature-icon .ei.feature {
  font-size: 64px;
  margin-right: 16px;
  flex-basis: 1;
}
body.layout-cozy .has-feature-icon span {
  flex-grow: 1;
}
body.layout-cozy .spacer.h10 {
  height: 10px;
}
body.layout-cozy .ei.tap-area-10 {
  position: relative;
}
body.layout-cozy .ei.tap-area-10:after {
  content: "";
  display: block;
  position: absolute;
  top: -10px;
  right: -10px;
  bottom: -10px;
  left: -10px;
}
@keyframes cloud-status-rotating {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
@-webkit-keyframes cloud-status-rotating {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
body.layout-cozy .spin {
  transform-origin: center center;
  animation: cloud-status-rotating 1.5s ease infinite;
}
body.layout-cozy .float-right {
  float: right;
}
body.layout-cozy .float-left {
  float: left;
}
body.layout-cozy .lc-stacked-icon {
  position: relative;
}
body.layout-cozy .lc-stacked-icon .lc-icon.position-top-left {
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: top left;
  transform: scale(0.5);
}
body.layout-cozy .lc-stacked-icon .lc-icon.position-top-right {
  position: absolute;
  top: 0;
  right: 0;
  transform-origin: top right;
  transform: scale(0.5);
}
body.layout-cozy .lc-stacked-icon .lc-icon.position-bottom-left {
  position: absolute;
  bottom: 0;
  left: 0;
  transform-origin: bottom left;
  transform: scale(0.5);
}
body.layout-cozy .lc-stacked-icon .lc-icon.position-bottom-right {
  position: absolute;
  bottom: 0;
  right: 0;
  transform-origin: bottom right;
  transform: scale(0.5);
}
body.layout-cozy .dialog-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 65515;
}
body.layout-cozy .dialog-container .overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
body.layout-cozy .dialog-container .popup {
  width: 80%;
  min-width: 220px;
  max-width: 480px;
  z-index: 2;
  border-radius: 16px;
  position: relative;
  top: 20%;
  padding: 8px;
}
body.layout-cozy .dialog-container .message {
  padding: 16px;
  text-align: center;
  overflow-wrap: anywhere;
}
body.layout-cozy .dialog-container .buttons {
  text-align: center;
  padding: 0 8px 8px 8px;
}
body.layout-cozy .dialog-container .buttons > button {
  height: 36px !important;
  line-height: 36px !important;
  padding: 0 16px !important;
  margin: 0 0 0 8px !important;
}
body.layout-cozy .dialog-container .buttons > button:first-child {
  margin-left: 0 !important;
}
body.layout-cozy .dialog-container .options {
  padding: 0 8px 16px;
}
body.layout-cozy .dialog-container .options > div {
  padding: 8px 16px;
}
body.layout-cozy .dialog-container .options > div > .ei {
  padding: 0 0 0 5px;
}
body.layout-cozy .dialog-container .options > div div.color-warning {
  padding-top: 5px;
}
body.layout-cozy .dialog-container .options > div:first-child {
  border-radius: 16px 16px 0 0;
}
body.layout-cozy .dialog-container .options > div:last-child {
  border-radius: 0 0 16px 16px;
}
body.layout-cozy .dialog-container .options > div:first-child:last-child {
  border-radius: 16px;
}
body.android body.layout-cozy .dnd-container.dragging .panning-layer {
  touch-action: none;
  overflow-x: hidden;
}
body.android body.layout-cozy .dnd-container.dragging .vertical-container ul {
  touch-action: none;
  overflow-y: hidden;
}
body.layout-cozy .dnd-container:before, body.layout-cozy .dnd-container:after {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 2;
  width: 10px;
}
body.layout-cozy .dnd-container .panning-layer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0 22px 8px 0;
}
body.desktop body.layout-cozy .dnd-container .panning-layer {
  padding-bottom: 16px;
}
.wide-popup body.layout-cozy .dnd-container .panning-layer {
  padding: 0 2px 8px;
}
.path-settings-desktops .settings-desktops-form body.layout-cozy .dnd-container .panning-layer {
  padding-bottom: 36px;
}
.path-desktop body.layout-cozy .dnd-container .panning-layer > .vertical-container {
  border-bottom: 0 none !important;
}
.wide-popup body.layout-cozy .dnd-container > .panning-layer > .vertical-container {
  height: calc(100% - 24px);
  width: calc(100% - 20px);
  max-width: 400px;
}
body.layout-cozy .dnd-container > .panning-layer > .vertical-container.tree-view {
  height: 100%;
  width: auto;
  max-width: none;
  padding-right: 10px;
}
body.layout-cozy .dnd-container > .panning-layer > .vertical-container.tree-view > .list-wrapper {
  padding: 0 8px 36px;
}
body.layout-cozy .dnd-container .vertical-container {
  position: relative;
  width: 80%;
  height: 100%;
  margin: 0 10px 0 10px;
  padding: 0 0 0 0;
}
body.layout-cozy .dnd-container .vertical-container .ei.no-permission {
  position: absolute;
  top: 70px;
  left: 0;
  right: 0;
  margin: 0;
  padding: 0;
  z-index: 100;
  font-size: 128px;
}
body.layout-cozy .dnd-container .vertical-container.tree-view {
  width: auto;
}
.settings-desktops-form body.layout-cozy .dnd-container .vertical-container {
  margin-top: 0;
}
@media (orientation: landscape), (min-width: 500px) {
  body.layout-cozy .dnd-container .vertical-container {
    max-width: 400px;
  }
  body.layout-cozy .dnd-container .vertical-container.tree-view {
    max-width: none;
  }
}
body.layout-cozy .dnd-container .vertical-container:last-child {
  margin-right: 10px;
}
body.layout-cozy .dnd-container .vertical-container ul {
  width: 100%;
  height: 100%;
  z-index: 1;
  padding: 2px 15px 2px 0;
  margin: 0 0 0 0;
}
body.layout-cozy .dnd-container .vertical-container ul.ps > .ps__rail-y > .ps__thumb-y {
  right: auto;
  left: 3px;
}
body.layout-cozy .dnd-container .vertical-container ul li {
  margin: 0;
  padding: 0;
}
body.layout-cozy .dnd-container .vertical-container ul li:last-child {
  margin-bottom: 0;
}
body.layout-cozy .dnd-container .vertical-container ul li.highlight {
  max-height: 0;
}
body.layout-cozy .dnd-container .vertical-container ul li.highlight.appear {
  max-height: 500px;
}
body.layout-cozy .dnd-container .vertical-container ul li.embedded-list-item {
  padding-bottom: 28px;
}
body.layout-cozy .dnd-container .vertical-container ul li.embedded-list-item .embedded > h3 .actions-container {
  display: none;
}
body.layout-cozy .dnd-container .vertical-container ul li.embedded-list-item.collapsed {
  padding-bottom: 8px;
}
body.layout-cozy .dnd-container .vertical-container ul li.embedded-list-item.collapsed .embedded.collapsed > h3 {
  margin-bottom: -8px;
}
body.layout-cozy .dnd-container .vertical-container ul li.embedded-list-item.collapsed .embedded.collapsed > h3 > .actions-container {
  display: block;
}
body.layout-cozy .dnd-container .vertical-container ul li.embedded-list-item:empty {
  display: none;
}
body.layout-cozy .dnd-container .vertical-container ul.settings-field-group {
  padding: 0;
  margin: 0 0 -56px 0;
  position: relative;
  z-index: 2;
}
body.layout-cozy .dnd-container .vertical-container ul.settings-field-group[data-group-id=default_contact_before-tags], body.layout-cozy .dnd-container .vertical-container ul.settings-field-group[data-group-id=default_image_before-tags], body.layout-cozy .dnd-container .vertical-container ul.settings-field-group[data-group-id=default_image_before-image], body.layout-cozy .dnd-container .vertical-container ul.settings-field-group[data-group-id=default_task_before-tags], body.layout-cozy .dnd-container .vertical-container ul.settings-field-group[data-group-id=default_contact_before-status], body.layout-cozy .dnd-container .vertical-container ul.settings-field-group[data-group-id=default_image_before-status], body.layout-cozy .dnd-container .vertical-container ul.settings-field-group[data-group-id=default_task_before-status], body.layout-cozy .dnd-container .vertical-container ul.settings-field-group[data-group-id=default_image_before-name], body.layout-cozy .dnd-container .vertical-container ul.settings-field-group[data-group-id^=default_dynamic_before-status_], body.layout-cozy .dnd-container .vertical-container ul.settings-field-group[data-group-id^=default_dynamic_before-tags_] {
  margin-bottom: -36px;
}
body.layout-cozy .dnd-container .vertical-container ul.settings-field-group:last-child {
  margin-bottom: 0;
}
body.layout-cozy .dnd-container .vertical-container ul.settings-field-group li {
  margin-top: 9px;
  min-height: 42px;
}
body.layout-cozy .dnd-container .vertical-container ul.settings-field-group li:before {
  display: none;
}
body.layout-cozy .dnd-container .vertical-container ul.settings-field-group li .content {
  padding-top: 4px;
  font-weight: bold;
}
body.layout-cozy .dnd-container .vertical-container ul.settings-field-group li .content h4 {
  white-space: normal;
}
body.layout-cozy .dnd-container .vertical-container ul.settings-field-group li .content h4 span {
  text-transform: none;
}
body.layout-cozy .dnd-container .vertical-container ul.settings-field-group li .content h4 span .ei {
  float: right;
  margin: 0 8px;
}
body.layout-cozy .dnd-container .vertical-container ul.settings-field-group li .content .ei {
  font-size: 16px;
}
body.layout-cozy .dnd-container .vertical-container ul.settings-field-group li .drop-zone > div span {
  line-height: 12px;
  transform: none;
}
body.layout-cozy .dnd-container .vertical-container ul.settings-field-group li.placeholder {
  border: 0 none;
  background: transparent;
}
body.layout-cozy .dnd-container .vertical-container.list-dayplan ul {
  height: 100%;
  padding-bottom: 8px;
}
body.layout-cozy .dnd-container .vertical-container.list-dayplan ul .habits ul {
  height: calc(100% - 33px);
}
body.layout-cozy .dnd-container .vertical-container.embedded {
  width: 100%;
  max-width: none;
  margin: 8px 0 0;
  height: auto;
  overflow: visible;
}
body.layout-cozy .dnd-container .vertical-container.embedded .vertical-container.embedded {
  width: 100%;
  margin: 8px 0 0 0;
}
body.layout-cozy .dnd-container .vertical-container.embedded .list-wrapper {
  padding: 0 8px;
  overflow: visible;
}
body.layout-cozy .dnd-container .vertical-container.embedded ul {
  min-height: 90px;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 2px;
}
body.mobile body.layout-cozy .dnd-container .vertical-container.embedded ul {
  padding-right: 10px;
}
.wide-popup body.layout-cozy .dnd-container .vertical-container.embedded ul {
  min-height: 60px;
}
body.layout-cozy .dnd-container .delete {
  position: absolute;
  z-index: 3;
  top: 0;
  left: 50%;
  height: 100px;
  width: 200px;
  margin-left: -100px;
}
body.layout-cozy .dnd-container .delete .ei {
  margin-right: 0;
  height: 42px;
  width: 42px;
  border-radius: 21px;
  line-height: 38px;
  font-size: 24px;
  position: absolute;
  bottom: 10px;
  left: 79px;
}
body.layout-cozy .dnd-container .delete.visible.hovering .ei {
  font-size: 32px;
}
body.layout-cozy ul.preview-layer,
body.layout-cozy div.preview-layer {
  position: absolute;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  z-index: 10000;
}
body.layout-cozy .dnd-container .vertical-container ul li.embedded-list-item.new-layout {
  padding-bottom: 8px;
}
body.layout-cozy .dnd-container .vertical-container ul li.embedded-list-item.new-layout .list-container.embedded > .list-wrapper {
  padding-bottom: 0;
  height: calc(100% - 36px);
}
body.layout-cozy .dnd-container .vertical-container.new-layout > .list-wrapper {
  padding-bottom: 0;
  height: calc(100% - 44px);
}
body.layout-cozy .dnd-container .vertical-container.new-layout.tree-view > .list-wrapper {
  height: calc(100% - 8px);
}
body.layout-cozy .actions-container2 {
  display: flex;
  align-items: center;
  justify-content: stretch;
  height: 36px;
  border-radius: 0 0 8px 8px;
  position: relative;
  z-index: 1;
}
body.layout-cozy .actions-container2 > * {
  height: 100%;
  line-height: 36px;
  text-align: center;
}
body.layout-cozy .actions-container2 .ei {
  font-size: 16px;
}
body.layout-cozy .actions-container2 > .ei {
  flex-shrink: 0;
  width: 36px;
  position: relative;
  overflow: visible;
  font-size: 24px;
  margin: 0;
}
body.layout-cozy .actions-container2 > .ei:after {
  content: "";
  display: block;
  position: absolute;
  top: -8px;
  left: -8px;
  right: -2px;
  bottom: -8px;
}
body.layout-cozy .list-container h3 .actions-container2 > .ei {
  top: -2px;
}
body.layout-cozy .list-container h3 .actions-container2 > .ei.ei-add {
  top: -1px;
  background-color: currentColor;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 12px;
  font-size: 19px;
}
body.layout-cozy .list-container h3 .actions-container2 > .ei.ei-add:before {
  color: #fff;
}
body.layout-cozy .embedded-list-item.collapsed .list-container > .actions-container2 {
  display: none;
}
body.layout-cozy .dnd-container.begin-my-day-container {
  height: 100%;
  position: relative;
}
body.layout-cozy .dnd-container.begin-my-day-container .preview-layer {
  position: absolute;
  z-index: 65535;
}
body.layout-cozy .dnd-container.begin-my-day-container .preview-layer h4 {
  padding: 10px 10px 12px;
  margin: 0;
  border-radius: 8px;
  max-width: 320px;
  overflow: hidden;
}
body.layout-cozy .dnd-container.begin-my-day-container .preview-layer .drag-handle {
  border-radius: 6px;
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
  padding: 6px;
}
body.layout-cozy .dnd-container.begin-my-day-container .preview-layer .drag-handle svg {
  display: inline-block;
}
body.layout-cozy .dnd-container.begin-my-day-container .preview-layer .drag-handle .preview {
  display: inline-block;
}
body.layout-cozy .dnd-container.begin-my-day-container .preview-layer .drag-handle .preview span {
  display: inline-block;
  vertical-align: middle;
  max-width: 220px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
body.layout-cozy .dnd-container.begin-my-day-container section {
  position: relative;
}
body.layout-cozy .dnd-container.begin-my-day-container section:before {
  content: "";
  position: absolute;
  top: -9px;
  left: 0;
  right: 0;
  height: 8px;
  border-radius: 4px;
  background: #ffe600;
  pointer-events: none;
  opacity: 0;
  will-change: opacity;
  transition: opacity 0.2s ease-out;
}
body.layout-cozy .dnd-container.begin-my-day-container section.hover:before {
  opacity: 1;
}
body.layout-cozy .dnd-container.begin-my-day-container section.last-section {
  height: 120px;
  background: transparent;
  border-color: transparent;
}
body.layout-cozy .dnd-container.begin-my-day-container section li.draggable,
body.layout-cozy .dnd-container.begin-my-day-container section footer {
  position: relative;
}
body.layout-cozy .dnd-container.begin-my-day-container section li.draggable .drag-handle .preview,
body.layout-cozy .dnd-container.begin-my-day-container section footer .drag-handle .preview {
  display: none;
}
body.layout-cozy .dnd-container.begin-my-day-container section li.draggable:before,
body.layout-cozy .dnd-container.begin-my-day-container section footer:before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  height: 8px;
  border-radius: 4px;
  background: #ffe600;
  pointer-events: none;
  opacity: 0;
  will-change: opacity;
  transition: opacity 0.2s ease-out;
}
body.layout-cozy .dnd-container.begin-my-day-container section li.draggable.hover:before,
body.layout-cozy .dnd-container.begin-my-day-container section footer.hover:before {
  opacity: 1;
}
body.layout-cozy ul.list {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
body.layout-cozy ul.list.items-list {
  padding: 3px 0;
}
body.layout-cozy ul.list.items-list li {
  margin: 5px 0;
}
body.layout-cozy ul.list, body.layout-cozy ul.list li {
  margin: 0;
  padding: 0;
}
body.layout-cozy ul.list.sublist {
  position: static;
  display: block;
  width: 100%;
  padding: 0;
}
body.layout-cozy ul.list.sublist.enable-copy-paste p {
  overflow-wrap: anywhere;
}
body.layout-cozy ul.list li {
  margin: 8px;
  border-radius: 6px;
  padding: 5px;
  min-height: 54px;
}
body.layout-cozy ul.list li.flex {
  display: flex;
  align-items: center;
  justify-content: stretch;
}
body.layout-cozy ul.list li.flex > * {
  flex-basis: 1;
}
body.layout-cozy ul.list li.flex h3 {
  flex-grow: 1;
}
body.layout-cozy ul.list li.flex .action {
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
  flex-shrink: 0;
}
body.layout-cozy ul.list li.flex .action .ei {
  font-size: 24px;
}
body.layout-cozy ul.list li.goal-projects {
  background: transparent;
  border: 0 none;
  margin: 0;
  padding: 0 28px;
  min-height: 0;
  max-height: 0;
  overflow: hidden;
  will-change: max-height;
  transition: max-height 0.2s ease-out;
  box-shadow: unset;
}
body.layout-cozy ul.list li.goal-projects .sublist li.flex {
  margin-left: 4px;
  margin-right: 4px;
}
body.layout-cozy ul.list li h3,
body.layout-cozy ul.list li h4,
body.layout-cozy ul.list li p {
  margin: 0;
}
body.layout-cozy ul.list li h3 {
  line-height: 18px;
  padding: 11px 5px;
}
body.layout-cozy ul.list li button {
  float: right;
  margin-right: 0;
  margin-left: 7px;
}
body.layout-cozy ul.list li .icon {
  float: left;
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 24px;
}
body.layout-cozy ul.list li.notification .ei.right {
  float: right;
  font-size: 24px;
  line-height: 40px;
  margin-left: 5px;
}
body.layout-cozy ul.list li.notification h3 {
  padding: 6px 6px 4px;
}
body.layout-cozy ul.list li.message {
  line-height: 50px;
}
body.layout-cozy ul.list li .float-right.list-item-action {
  margin: 6px;
  width: 32px;
  height: 32px;
  padding: 4px;
  border-radius: 16px;
}
.form-tab body.layout-cozy ul.list li {
  margin: 5px 0;
}
@media (max-width: 479px) {
  body.layout-cozy .form-tab ul.list li button {
    width: 40px;
    padding-left: 0;
    padding-right: 0;
    font-size: 0;
    text-align: center;
  }
  body.layout-cozy .form-tab ul.list li button .ei.inline {
    margin: 0;
  }
}
body.layout-cozy h3.single-line {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
body.layout-cozy .slideshow {
  width: 100%;
  height: 100%;
}
body.layout-cozy .slideshow .controls,
body.layout-cozy .slideshow .control-orientation {
  position: absolute;
  z-index: 5;
  height: 52px;
  border-radius: 8px;
  bottom: 20px;
  left: 20px;
  width: 156px;
}
body.layout-cozy .slideshow .controls .ei,
body.layout-cozy .slideshow .control-orientation .ei {
  margin: 0;
  width: 52px;
  height: 52px;
  line-height: 52px;
  font-size: 28px;
  border-radius: 0;
}
body.layout-cozy .slideshow .controls .ei:first-child,
body.layout-cozy .slideshow .control-orientation .ei:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
body.layout-cozy .slideshow .controls .ei:last-child,
body.layout-cozy .slideshow .control-orientation .ei:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
body.layout-cozy .slideshow .controls .ei:only-child,
body.layout-cozy .slideshow .control-orientation .ei:only-child {
  width: 52px;
}
body.layout-cozy .slideshow .controls .ei.large,
body.layout-cozy .slideshow .control-orientation .ei.large {
  font-size: 40px;
}
body.layout-cozy .slideshow .controls.speed {
  left: auto;
  right: 20px;
  height: 52px;
  width: 52px;
  cursor: pointer;
}
body.layout-cozy .slideshow .controls.speed span {
  display: inline-flex;
  width: 28px;
  line-height: 52px;
  align-items: center;
  justify-content: center;
}
body.layout-cozy .slideshow .control-orientation {
  width: auto;
  left: auto;
  bottom: auto;
  top: 20px;
  right: 20px;
}
body.layout-cozy .slideshow .slide {
  position: absolute;
  top: 12px;
  left: 12px;
  width: calc(100% - 24px);
  height: calc(100% - 24px);
  border-radius: 16px;
  overflow: hidden;
  z-index: 2;
  padding: 4px 8px;
}
body.layout-cozy .slideshow .slide.current {
  z-index: 1;
}
body.layout-cozy .slideshow .slide.target {
  z-index: 3;
}
body.layout-cozy .slideshow .slide > h1,
body.layout-cozy .slideshow .slide > h2,
body.layout-cozy .slideshow .slide > ul,
body.layout-cozy .slideshow .slide > .caption,
body.layout-cozy .slideshow .slide > .text,
body.layout-cozy .slideshow .slide > div {
  position: relative;
  z-index: 3;
}
body.layout-cozy .slideshow .slide > div.overlay {
  position: absolute;
  inset: 0;
  z-index: 2;
}
body.layout-cozy .slideshow .slide > div.background {
  position: absolute;
  inset: 0;
  z-index: 1;
}
body.layout-cozy .slideshow .slide h1,
body.layout-cozy .slideshow .slide h2,
body.layout-cozy .slideshow .slide li,
body.layout-cozy .slideshow .slide .caption,
body.layout-cozy .slideshow .slide .text,
body.layout-cozy .slideshow .slide a {
  margin: 4px 0;
  padding: 2px 16px 8px;
  border-radius: 8px;
  float: left;
  clear: left;
}
body.layout-cozy .slideshow .slide .links {
  clear: left;
  float: left;
  max-width: 100%;
}
body.layout-cozy .slideshow .slide a {
  padding: 2px 16px 8px;
}
body.layout-cozy .slideshow .slide a .ei {
  font-size: 24px;
}
body.layout-cozy .slideshow .slide h1 {
  padding: 2px 16px 8px;
}
body.layout-cozy .slideshow .slide h2 i {
  margin-right: 5px;
}
body.layout-cozy .slideshow .slide > .details {
  max-height: 100%;
}
body.layout-cozy .slideshow .slide .project-container h1 {
  font-size: 33px;
}
body.layout-cozy .slideshow .slide .project-container h2 {
  font-size: 33px;
}
body.layout-cozy .slideshow .slide ul {
  margin: 0;
  padding: 0;
}
body.layout-cozy .slideshow .slide ul.projects {
  clear: none;
  float: right;
  width: 80%;
  max-width: 480px;
}
body.layout-cozy .slideshow .slide ul.projects li {
  float: none;
  margin: 0;
}
body.layout-cozy .slideshow .slide ul.projects > li > h4 {
  margin: -3px 0 -3px 0;
  padding: 8px 0;
}
body.layout-cozy .slideshow .slide ul.projects > li > h4 .ei {
  float: right;
  font-size: 16px;
}
body.layout-cozy .slideshow .slide .caption {
  margin-top: -4px;
}
body.layout-cozy .slideshow .slide .text-content {
  position: absolute;
  z-index: 3;
  bottom: 64px;
  left: 8px;
  right: 8px;
}
@media (max-width: 599.95px) {
  body.layout-cozy .slideshow .slide .text-content {
    bottom: 124px;
  }
}
body.layout-cozy .slideshow .slide.section-end a {
  display: inline-block;
  float: none;
}
@media (orientation: landscape) {
  body.layout-cozy .slideshow .slide.slide-goal > .details {
    width: 60%;
    float: left;
  }
  body.layout-cozy .slideshow .slide.slide-goal > ul.projects {
    max-width: none;
    width: 40%;
    max-height: 100%;
    padding-bottom: 80px;
  }
}
@media (orientation: portrait) {
  body.layout-cozy .slideshow .slide.slide-goal .project-container {
    width: 100%;
    height: 100%;
    padding-bottom: 80px;
  }
}
body.layout-cozy .settings-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0;
}
body.layout-cozy .settings-container > nav {
  padding: 5px 5px 0 5px;
}
body.layout-cozy .settings-container > nav .button {
  position: static;
  padding: 0 8px;
  margin: 0 5px 0 0;
  border-radius: 8px 8px 0 0;
  margin-bottom: -2px;
}
body.layout-cozy .settings-container.logs-container header {
  position: static;
  padding: 0 10px;
  display: block;
}
body.layout-cozy .settings-container.logs-container header .field.select {
  width: auto;
  display: inline-block;
  margin-right: 8px;
}
body.layout-cozy .settings-container.logs-container header .field.select.display-inline-buttons .value-inline .option {
  border-color: #12378F;
  padding: 0 20px;
}
body.layout-cozy .settings-container.start-my-day {
  padding: 8px;
}
body.layout-cozy .settings-container.start-my-day section {
  margin-bottom: 10px;
  padding: 0;
  border-radius: 8px;
}
body.layout-cozy .settings-container.start-my-day section h4 .ei {
  float: right;
  padding: 0 8px;
}
body.layout-cozy .settings-container.start-my-day section > .content {
  height: 0;
  padding-top: 0;
  padding-left: 8px;
  padding-right: 8px;
}
body.layout-cozy .settings-container.start-my-day section.expanded > .content {
  height: auto;
  padding-top: 10px;
}
body.layout-cozy .settings-container.start-my-day h4 {
  margin: 0;
  padding: 10px 10px 12px;
}
body.layout-cozy .settings-container.start-my-day img {
  max-width: 80px;
  max-height: 80px;
}
body.layout-cozy .settings-container.start-my-day ul,
body.layout-cozy .settings-container.start-my-day ul li {
  margin: 0;
  padding: 0;
}
body.layout-cozy .settings-container.start-my-day ul li {
  padding: 10px 10px 1px;
}
body.layout-cozy .settings-container.start-my-day ul li:nth-child(1) {
  z-index: 29;
}
body.layout-cozy .settings-container.start-my-day ul li:nth-child(2) {
  z-index: 28;
}
body.layout-cozy .settings-container.start-my-day ul li:nth-child(3) {
  z-index: 27;
}
body.layout-cozy .settings-container.start-my-day ul li:nth-child(4) {
  z-index: 26;
}
body.layout-cozy .settings-container.start-my-day ul li:nth-child(5) {
  z-index: 25;
}
body.layout-cozy .settings-container.start-my-day ul li:nth-child(6) {
  z-index: 24;
}
body.layout-cozy .settings-container.start-my-day ul li:nth-child(7) {
  z-index: 23;
}
body.layout-cozy .settings-container.start-my-day ul li:nth-child(8) {
  z-index: 22;
}
body.layout-cozy .settings-container.start-my-day ul li:nth-child(9) {
  z-index: 21;
}
body.layout-cozy .settings-container.start-my-day ul li:nth-child(10) {
  z-index: 20;
}
body.layout-cozy .settings-container.start-my-day ul li:nth-child(11) {
  z-index: 19;
}
body.layout-cozy .settings-container.start-my-day ul li:nth-child(12) {
  z-index: 18;
}
body.layout-cozy .settings-container.start-my-day ul li:nth-child(13) {
  z-index: 17;
}
body.layout-cozy .settings-container.start-my-day ul li:nth-child(14) {
  z-index: 16;
}
body.layout-cozy .settings-container.start-my-day ul li:nth-child(15) {
  z-index: 15;
}
body.layout-cozy .settings-container.start-my-day ul li:nth-child(16) {
  z-index: 14;
}
body.layout-cozy .settings-container.start-my-day ul li:nth-child(17) {
  z-index: 13;
}
body.layout-cozy .settings-container.start-my-day ul li:nth-child(18) {
  z-index: 12;
}
body.layout-cozy .settings-container.start-my-day ul li:nth-child(19) {
  z-index: 11;
}
body.layout-cozy .settings-container.start-my-day ul li:nth-child(20) {
  z-index: 10;
}
body.layout-cozy .settings-container.start-my-day ul li:nth-child(21) {
  z-index: 9;
}
body.layout-cozy .settings-container.start-my-day ul li:nth-child(22) {
  z-index: 8;
}
body.layout-cozy .settings-container.start-my-day ul li:nth-child(23) {
  z-index: 7;
}
body.layout-cozy .settings-container.start-my-day ul li:nth-child(24) {
  z-index: 6;
}
body.layout-cozy .settings-container.start-my-day ul li:nth-child(25) {
  z-index: 5;
}
body.layout-cozy .settings-container.start-my-day ul li:nth-child(26) {
  z-index: 4;
}
body.layout-cozy .settings-container.start-my-day ul li:nth-child(27) {
  z-index: 3;
}
body.layout-cozy .settings-container.start-my-day ul li:nth-child(28) {
  z-index: 2;
}
body.layout-cozy .settings-container.start-my-day ul li:nth-child(29) {
  z-index: 1;
}
body.layout-cozy .settings-container.start-my-day ul.fields-list li {
  padding-right: 60px;
  position: relative;
}
body.layout-cozy .settings-container.start-my-day ul.fields-list li.upload {
  padding: 10px;
}
body.layout-cozy .settings-container.start-my-day ul.fields-list li > button {
  position: absolute;
  margin: 0;
  right: 10px;
  top: 10px;
}
body.layout-cozy .settings-container.start-my-day ul.fields-list li.draggable {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  padding-right: 0;
}
body.layout-cozy .settings-container.start-my-day ul.fields-list li.draggable > .drag-handle {
  flex-shrink: 0;
  flex-grow: 0;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #3A506B;
  cursor: ns-resize;
}
body.layout-cozy .settings-container.start-my-day ul.fields-list li.draggable > .content {
  flex-grow: 1;
  padding-right: 60px;
  position: relative;
}
body.layout-cozy .settings-container.start-my-day ul.fields-list li.draggable > .content > button {
  position: absolute;
  margin: 0;
  right: 10px;
  top: 10px;
}
body.layout-cozy .settings-container.start-my-day ul.fields-list:first-child li:first-child {
  padding-top: 0;
}
body.layout-cozy .settings-container.start-my-day ul.fields-list:first-child li:first-child > button {
  top: 0;
}
body.layout-cozy .settings-container.start-my-day .field.upload {
  margin: 0 0 10px 0;
  padding: 10px;
  border-radius: 8px;
}
body.layout-cozy .settings-container.start-my-day .field.upload.audio {
  padding: 0;
}
body.layout-cozy .settings-container.start-my-day .field.picture-widget .preview {
  width: 80px;
  height: 80px;
  border-radius: 8px;
}
body.layout-cozy .settings-container.start-my-day .field.picture-widget .preview img {
  position: absolute;
  top: 39px;
  left: 39px;
  max-width: 80px;
  max-height: 80px;
}
body.layout-cozy .settings-container.start-my-day .square-button {
  padding-top: 4px;
  margin-left: 7px;
  margin-bottom: 7px;
  width: 60px;
  height: 60px;
  border-radius: 8px;
}
body.layout-cozy .settings-container.start-my-day .square-button .ei {
  display: block;
  text-align: center;
  font-size: 32px;
}
body.layout-cozy .settings-container.start-my-day .square-button input {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}
body.layout-cozy .settings-container.start-my-day footer {
  padding: 10px 10px 2px;
}
body.layout-cozy .settings-container.start-my-day footer h5 {
  margin: 0 0 10px 0;
}
body.layout-cozy .settings-container.start-my-day label.warning {
  margin-top: 5px;
}
body.layout-cozy .settings-container.start-my-day.show-upload-warning label.warning,
body.layout-cozy .settings-container.start-my-day .show-upload-warning label.warning {
  display: block;
}
body.layout-cozy .settings-container.start-my-day .field.text-widget .preview {
  height: 60px;
  line-height: 60px;
  padding: 0 20px;
  border-radius: 8px;
}
body.layout-cozy .settings-container.start-my-day .field.slide-editor .preview {
  width: 100px;
  height: 100px;
  border-radius: 8px;
}
body.layout-cozy .settings-container.start-my-day .field.slide-editor .preview .ei {
  font-size: 24px;
  margin-top: 8px;
}
body.layout-cozy .settings-container.start-my-day .field.slide-editor .preview span {
  position: absolute;
  bottom: 5px;
  left: 50%;
  padding: 5px 10px;
  border-radius: 8px;
}
body.layout-cozy .popups-container {
  z-index: 9;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
body.layout-cozy .popups-container form.edit-container {
  height: 100%;
  padding: 0;
}
body.layout-cozy .popup-container {
  width: 100%;
  height: 100%;
  padding: 0 8px;
}
body.layout-cozy .popup-container.wide-popup .popup-content {
  overflow: hidden;
}
body.desktop body.layout-cozy .popup-container.wide-popup .popup-content {
  max-width: 1024px;
}
body.mobile body.layout-cozy .popup-container.wide-popup .popup-content {
  max-width: 100%;
}
body.layout-cozy .popup-container.auto-height .popup-content {
  height: auto;
  max-height: 100%;
  min-height: 50%;
}
@media (min-height: 700px) {
  body.layout-cozy .popup-container.auto-height .popup-content {
    min-height: 550px;
  }
}
body.layout-cozy .popup-content {
  width: 100%;
  height: 90%;
}
body.layout-cozy .popup-content .swipe-handle {
  width: 32px;
  margin-left: -16px;
  height: 4px;
  border-radius: 3px;
  position: absolute;
  top: -10px;
  left: 50%;
}
body.layout-cozy .popup-content.loading {
  padding-top: 62px;
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
body.layout-cozy .popup-content.fill-parent {
  width: 100%;
  height: 100%;
  border: 0 none;
}
body.layout-cozy .popup-content > section {
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  bottom: 0;
}
body.layout-cozy .popup-content > section.no-header {
  top: 0;
}
body.layout-cozy .settings-dynamic-fields .dnd-container .vertical-container {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}
body.layout-cozy .settings-dynamic-fields .dnd-container .vertical-container.popup-content {
  padding-top: 60px;
}
body.layout-cozy .settings-dynamic-fields .dnd-container .vertical-container ul.settings-field-group li {
  border: 0 none;
  box-shadow: unset;
}
body.layout-cozy .settings-dynamic-fields .dnd-container .vertical-container ul.settings-field-group li:first-child {
  margin-top: 18px;
}
body.layout-cozy .settings-dynamic-fields .dnd-container .vertical-container ul.settings-field-group li .content {
  border-left: 6px solid #12378F !important;
  border-radius: 6px;
}
body.layout-cozy .settings-dynamic-fields .drop-zone {
  height: auto;
  top: -10px;
  bottom: 0;
}
body.layout-cozy .drop-zone {
  height: 100%;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  right: -1px;
}
body.layout-cozy .drop-zone > div {
  width: 100%;
  height: 8px;
  border-radius: 8px;
}
body.layout-cozy .drop-zone > div:after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
body.layout-cozy .drop-zone > div span:not(.pz-title) {
  border-radius: 0 0 8px 8px;
  padding: 3px 8px 1px;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -37px;
}
body.layout-cozy .drop-zone > div span:not(.pz-title) .ei {
  font-size: 16px;
  margin-right: 5px;
}
.settings-desktops-form body.layout-cozy .drop-zone > div {
  width: calc(50% - 4px);
}
.settings-desktops-form body.layout-cozy .drop-zone > div:after {
  right: 50%;
}
.settings-desktops-form body.layout-cozy .drop-zone > div:first-child {
  margin-right: 8px;
}
.settings-desktops-form body.layout-cozy .drop-zone > div.full-width {
  width: 100% !important;
  margin-right: 0;
}
.settings-desktops-form body.layout-cozy .drop-zone > div span:not(.pz-title) {
  border-radius: 12px;
  padding: 5px 10px;
  position: absolute;
  top: -11px;
  left: 25%;
  transform: translateX(-50%);
}
.settings-desktops-form body.layout-cozy .drop-zone > div.full-width span:not(.pz-title) {
  left: 50%;
}
.settings-desktops-form body.layout-cozy .drop-zone > div.clone:after {
  right: 0;
  left: 50%;
}
.settings-desktops-form body.layout-cozy .drop-zone > div.clone span:not(.pz-title) {
  left: 75%;
}
body.layout-cozy .desktop-drop-zone {
  z-index: 2;
  top: 0;
  height: 100%;
  width: 0;
}
body.layout-cozy .desktop-drop-zone > div {
  width: 0;
  height: 100%;
  padding-top: 150px;
  border-radius: 8px;
}
body.layout-cozy .desktop-drop-zone .ei {
  font-size: 24px;
}
body.layout-cozy .desktop-drop-zone.visible {
  width: 32px;
  margin: 0 -16px;
}
body.layout-cozy .desktop-drop-zone.visible > div {
  width: 4px;
}
body.layout-cozy .desktop-drop-zone.visible:first-child > div {
  left: 5px;
}
body.layout-cozy .desktop-drop-zone.visible.hovering > div {
  width: 16px;
}
body.layout-cozy .desktop-drop-zone.visible.hovering:first-child {
  width: 99px;
}
body.layout-cozy .desktop-drop-zone.visible.hovering:last-child {
  margin-right: -16px;
}
body.layout-cozy .desktop-page.dnd-container .all-day-pz .timeblocks-pz.list-container.embedded .list-wrapper {
  max-height: 70vh;
  overflow-y: auto;
}
body.layout-cozy .dnd-container .vertical-container.list-dayplan ul.time-block {
  padding-left: 4px;
  padding-right: 4px;
  padding-bottom: 4px;
  overflow: visible;
  min-height: 24px;
  position: absolute;
  right: auto;
}
body.layout-cozy .dnd-container .vertical-container.list-dayplan ul.time-block.sublist {
  transition: left 0.15s ease-out, right 0.15s ease-out;
}
body.layout-cozy .dnd-container .vertical-container.list-dayplan ul.time-block.sublist:not(.collapsed) {
  width: auto !important;
  height: auto !important;
  left: 0 !important;
  right: -4px !important;
  z-index: 2;
}
body.layout-cozy .dnd-container .vertical-container.list-dayplan .timeblocks-pz {
  box-shadow: unset;
}
body.layout-cozy .dnd-container .vertical-container.list-dayplan .timeblocks-pz > .list-wrapper {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  border-radius: 0 0 8px 8px;
}
body.layout-cozy .dnd-container .vertical-container.list-dayplan .timeblocks-pz > h3 {
  z-index: 2;
}
body.layout-cozy .dnd-container .vertical-container.list-dayplan .timeblocks-pz.list-container .action-container.habits {
  margin-top: 0;
  margin-bottom: 8px;
}
body.layout-cozy .dnd-container .vertical-container.list-dayplan .timeblocks-pz.list-container.embedded.drag-started {
  z-index: 65535;
}
body.layout-cozy .dnd-container .vertical-container.list-dayplan .timeblocks-pz.list-container.embedded:not(.collapsed) {
  right: 8px !important;
  z-index: 65535;
}
body.layout-cozy .dnd-container .vertical-container.list-dayplan .timeblocks-pz.list-container.embedded:not(.collapsed) > .list-wrapper > ul {
  padding-bottom: 2px;
}
body.layout-cozy .dnd-container .vertical-container.list-dayplan .timeblocks-pz.list-container.embedded:not(.collapsed) > h3 {
  margin-left: -42px;
  margin-right: -8px;
  background-color: inherit;
  border-radius: 0;
  transition: unset;
}
body.layout-cozy .dnd-container .vertical-container.list-dayplan .timeblocks-pz.list-container.embedded {
  position: absolute;
  width: auto;
  margin: 0;
}
body.layout-cozy .dnd-container .vertical-container.list-dayplan .timeblocks-pz.list-container.embedded.collapsed {
  height: 30px !important;
  border: 0 none !important;
  left: 0 !important;
  right: 0 !important;
  box-shadow: unset;
  border-radius: 0;
}
body.layout-cozy .dnd-container .vertical-container.list-dayplan .timeblocks-pz.list-container.embedded > h3 {
  height: 30px;
  line-height: 30px;
  background: transparent;
  padding: 0 4px;
}
body.layout-cozy .dnd-container .vertical-container.list-dayplan .all-day-pz {
  display: block;
  position: relative;
  z-index: 2;
  height: 34px;
  padding: 2px 0 0;
  width: calc(100% - 15px);
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
}
body.layout-cozy .dnd-container .vertical-container.list-dayplan .all-day-pz + ul {
  height: calc(100% - 34px);
}
body.layout-cozy .dnd-container .vertical-container.list-dayplan .all-day-pz .timeblocks-pz.list-container.embedded {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
}
body.layout-cozy .dnd-container .vertical-container.list-dayplan .all-day-pz .timeblocks-pz.list-container.embedded > h3 {
  height: 32px;
  line-height: 32px;
}
body.layout-cozy .dnd-container .vertical-container.list-dayplan .all-day-pz .timeblocks-pz.list-container.embedded.collapsed {
  background: transparent;
  height: 32px !important;
}
body.layout-cozy .dnd-container .vertical-container.list-dayplan .all-day-pz .timeblocks-pz.list-container.embedded:not(.collapsed) {
  width: calc(100% - 50px);
  left: 42px;
}
body.layout-cozy .dnd-container .vertical-container.list-dayplan .all-day-pz h3 {
  overflow: visible;
}
body.layout-cozy .dnd-container .vertical-container.list-dayplan .all-day-pz h3 .habits {
  flex-shrink: 0;
  flex-grow: 0;
  font-size: 11px;
  color: #000018;
  text-transform: none;
  padding: 0 8px;
}
body.layout-cozy .dnd-container .vertical-container.list-dayplan .all-day-pz h3 > ul {
  flex-grow: 0;
  flex-shrink: 1;
  min-height: auto;
  padding: 0;
  width: auto;
  min-width: 74px;
  overflow: hidden;
  border-radius: 5px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
}
@media (max-width: 480px) {
  body.layout-cozy .dnd-container .vertical-container.list-dayplan .all-day-pz h3 > ul {
    width: 74px;
    flex-shrink: 0;
  }
  body.layout-cozy .dnd-container .vertical-container.list-dayplan .all-day-pz h3 > ul > li .content {
    white-space: nowrap;
  }
  body.layout-cozy .dnd-container .vertical-container.list-dayplan .all-day-pz h3 > ul > li .content .float-right {
    display: none;
  }
}
body.layout-cozy .dnd-container .vertical-container.list-dayplan .all-day-pz h3 > ul > li {
  font-size: 11px;
  color: #000018;
  text-transform: none;
}
body.layout-cozy .dnd-container .vertical-container.list-dayplan .all-day-pz h3 > ul > li:before {
  display: none;
}
body.layout-cozy .dnd-container .vertical-container.list-dayplan .all-day-pz h3 > ul > li .content {
  padding: 0 4px;
  box-shadow: unset;
  overflow: hidden;
}
body.layout-cozy .dnd-container .vertical-container.list-dayplan .all-day-pz h3 > ul > li .content .float-right {
  margin-top: 3px;
}
body.layout-cozy .dnd-container .vertical-container.list-dayplan li.embedded-list-item.new-layout.timeblocks-sublist {
  height: 100%;
  padding-bottom: 0;
}
body.layout-cozy .dnd-container .vertical-container.list-dayplan li.embedded-list-item.new-layout.timeblocks-sublist .list-container.embedded {
  margin-top: 0;
  height: 100%;
}
body.layout-cozy .dnd-container .vertical-container.list-dayplan li.embedded-list-item.new-layout.timeblocks-sublist .list-container.embedded > h3 {
  padding: 0 4px 0 8px;
}
body.layout-cozy .dnd-container .vertical-container.list-dayplan li.embedded-list-item.new-layout.timeblocks-sublist .list-container.embedded.collapsed {
  background: #fff;
  border-width: 0;
  border-left-width: 4px;
  overflow: hidden;
}
body.layout-cozy .dnd-container .vertical-container.list-dayplan li.embedded-list-item.new-layout.timeblocks-sublist .list-container.embedded.collapsed > h3 {
  margin-bottom: 0;
  padding-left: 4px;
}
body.layout-cozy .dnd-container .vertical-container.list-dayplan li.embedded-list-item.new-layout.timeblocks-sublist .list-container.embedded.collapsed > h3 span {
  font-weight: normal;
}
body.layout-cozy .dnd-container .vertical-container.list-dayplan li.embedded-list-item.new-layout.timeblocks-sublist .list-container.embedded.collapsed > h3 .add-default-item {
  color: inherit;
}
body.layout-cozy .dnd-container .vertical-container.list-dayplan li.embedded-list-item.new-layout.timeblocks-sublist .list-container.embedded .timeblocks-message {
  padding: 6px;
  border-top: 1px solid transparent;
  position: relative;
  height: calc(100% - 36px);
}
body.layout-cozy .dnd-container .vertical-container.list-dayplan li.embedded-list-item.new-layout.timeblocks-sublist .list-container.embedded .timeblocks-message .drop-zone {
  height: 100%;
}
body.layout-cozy .dnd-container .vertical-container.list-dayplan .timeblock-is-medium li.embedded-list-item.new-layout.timeblocks-sublist .list-container.embedded > h3 {
  height: 100%;
}
body.layout-cozy .dnd-container .vertical-container.list-dayplan .time-slots {
  margin: 0 -8px;
  background-color: #fff;
}
body.layout-cozy .dnd-container .vertical-container.list-dayplan .time-slots > ul {
  position: relative;
  padding: 0;
  margin: 0;
  width: auto;
  height: auto;
  overflow: visible;
}
body.layout-cozy .dnd-container .vertical-container.list-dayplan .time-slots > ul li.first-in-zone > .drop-zone {
  margin-top: 30px;
}
body.layout-cozy .dnd-container .vertical-container.list-dayplan .time-slots span.dnd-label {
  left: 37px;
}
body.layout-cozy .dnd-container .vertical-container.list-dayplan .time-slots .items-container {
  position: absolute;
  right: 4px;
  top: 0;
}
body.layout-cozy .dnd-container .vertical-container.list-dayplan .time-slots .items-container.has-expanded-sublist {
  z-index: 60000;
}
body.layout-cozy .dnd-container .vertical-container.list-dayplan .time-slots .items-container.has-expanded-sublist > .time-block.sublist:not(.collapsed) {
  z-index: 10000 !important;
}
body.layout-cozy .dnd-container .vertical-container.list-dayplan .time-slots .timeslot-header {
  display: inline-flex;
  flex-direction: column;
  line-height: 14px;
  vertical-align: top;
}
body.layout-cozy .dnd-container .vertical-container.list-dayplan .time-slots .timeslot-header span {
  display: inline-block;
  color: #fff;
  border-radius: 12px;
  padding: 0 5px;
  line-height: 16px;
  align-self: center;
}
body.layout-cozy .list-item-goals {
  padding: 4px 8px;
  font-size: 11px;
}
body.layout-cozy .list-item-goals svg {
  margin: -3px 8px 0 0;
}
body.layout-cozy .field-display.text .actions-line,
body.layout-cozy .field-display.text p.actions-line {
  display: flex;
  padding-top: 2px;
  flex-direction: row;
  align-items: center;
  justify-content: stretch;
  margin: 0;
  padding: 0 8px 4px;
}
body.layout-cozy .field-display.text .actions-line a,
body.layout-cozy .field-display.text p.actions-line a {
  text-decoration: none;
}
body.layout-cozy ul.preview-layer li h4 {
  margin: 0;
  padding: 3px 3px 3px 8px;
}
body.layout-cozy ul.preview-layer > h3 {
  margin: 0;
  padding: 5px;
  border-radius: 8px;
  max-height: 36px;
}
body.layout-cozy ul.preview-layer > h3 .actions-container2 {
  display: none;
}
body.layout-cozy ul.preview-layer > h3 > span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
body.layout-cozy .pre-list-drop-zone {
  position: relative;
  z-index: 2;
  height: 16px;
  margin-bottom: -16px;
}
body.layout-cozy .pre-list-drop-zone.hovering.can-drop .drop-zone {
  display: block;
}
body.layout-cozy .list-container {
  border-radius: 8px;
}
body.layout-cozy .list-container.desktop-chooser > .content {
  padding: 38px 15px;
}
body.layout-cozy .list-container.desktop-chooser > .content > button {
  width: 100%;
  margin-top: 15px;
}
body.layout-cozy .list-container.desktop-chooser:before {
  display: none;
}
body.layout-cozy .list-container > h3 {
  line-height: 34px;
  margin: 0;
  padding: 0 8px 0 12px;
  height: 36px;
  border-radius: 8px 8px 0 0;
  display: flex;
  align-items: center;
}
body.layout-cozy .list-container > h3 .drop-zone {
  padding-top: 0;
  right: 0;
}
body.layout-cozy .list-container > h3 .drop-zone > div {
  height: 36px;
  padding: 0;
  border-radius: 4px;
}
body.layout-cozy .list-container > h3 .drop-zone > div .ei {
  font-size: 24px;
  margin-right: 10px;
}
body.layout-cozy .list-container > h3 span.title,
body.layout-cozy .list-container > h3 span.title small {
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
body.layout-cozy .list-container > h3 div.progress {
  flex-grow: 0;
  flex-shrink: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 11px;
  padding-right: 8px;
}
body.layout-cozy .list-container.timeblocks-pz.embedded > h3 {
  border-radius: 0;
}
body.layout-cozy .list-container.timeblocks-pz.embedded > h3 .pz-items-hint {
  margin: 0 -4px;
  padding: 0;
  flex-grow: 0;
  flex-shrink: 0;
  overflow: visible;
}
body.layout-cozy .list-container.timeblocks-pz.embedded > h3 .pz-items-hint .embedded-list-arrow {
  margin: 0;
}
body.layout-cozy .list-container.timeblocks-pz.embedded > h3 .pz-items-hint small.badge.items-count {
  padding: 4px 8px;
  border-radius: 20px;
  align-self: center;
  text-align: center;
  margin: 0 8px 0 0;
  font-size: 11px;
  line-height: 11px;
  display: inline-block;
  transform-origin: center center;
  transform: "scale(1)";
  will-change: transform;
  transition: transform 0.2s ease-out;
}
body.layout-cozy .list-container.timeblocks-pz.embedded > h3 .pz-items-hint small.badge.items-count.shadow {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
}
body.layout-cozy .list-container.timeblocks-pz.embedded > h3 .pz-items-hint small.badge.items-count.gte10 {
  padding: 4px 3px;
}
body.layout-cozy .list-container:not(.embedded) > h3 {
  height: 44px;
  line-height: 44px;
}
body.layout-cozy .list-container:not(.embedded) > h3 .ei.clone {
  padding-top: 12px;
}
body.layout-cozy .list-container:not(.embedded) > h3 .actions-container2 {
  height: 44px;
}
body.layout-cozy .list-container.collapsed > h3 {
  border-radius: 6px;
}
body.layout-cozy .list-container:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
body.layout-cozy .list-container.list-dayplan {
  display: inline-flex;
  flex-direction: column;
}
body.layout-cozy .list-container.list-dayplan > h3 {
  line-height: 44px;
}
body.layout-cozy .list-container.list-dayplan > h3 .edit-filters {
  padding-top: 6px;
}
body.layout-cozy .list-container.list-dayplan > h3 .collapse-icon.inline {
  margin-top: 0;
  padding-top: 14px;
}
body.layout-cozy .list-container.list-dayplan > h3 .collapse-icon.inline:after {
  bottom: 0;
  right: -10px;
}
body.layout-cozy .list-container.list-dayplan .day-plan-date {
  padding-top: 3px;
}
body.layout-cozy .list-container.list-dayplan .day-plan-date > div {
  text-align: center;
}
body.layout-cozy .list-container.list-dayplan .day-plan-date.show-timezone div {
  line-height: 14px;
}
body.layout-cozy .list-container.list-dayplan > .list-wrapper {
  padding-top: 44px;
  padding-bottom: 0 !important;
  flex-grow: 1;
  will-change: padding-top;
}
body.layout-cozy .list-container.list-dayplan > .list-wrapper.infinite-view {
  padding-top: 0;
}
body.layout-cozy .list-container.list-dayplan > .list-wrapper.infinite-view li.day-header {
  margin-top: 8px;
  padding: 4px 8px;
  background: rgba(0, 0, 0, 0.03);
  border-radius: 6px;
}
body.layout-cozy .list-container.list-dayplan > .list-wrapper.infinite-view li.navigation {
  display: flex;
  margin: 0 -8px;
  background: rgba(0, 0, 0, 0.03);
}
body.layout-cozy .list-container.list-dayplan > .list-wrapper.infinite-view li.navigation:last-of-type {
  margin-top: 8px;
}
body.layout-cozy .list-container.list-dayplan > .list-wrapper.infinite-view li.navigation span {
  flex-grow: 1;
  text-align: center;
  display: inline-block;
  padding: 8px 0;
}
body.layout-cozy .list-container.list-dayplan > .list-wrapper.infinite-view li.navigation div {
  padding: 8px 5%;
}
@media (max-width: 419px) {
  body.layout-cozy .list-container.list-dayplan > .list-wrapper.infinite-view li.navigation div {
    padding: 8px 3%;
  }
}
body.layout-cozy .list-container.list-dayplan .track.horizontal.day-plan-track ul {
  display: block;
  height: 48px;
  padding-top: 0;
}
body.layout-cozy .list-container.list-dayplan .track.horizontal.day-plan-track ul li {
  display: inline-block;
  vertical-align: middle;
  width: 56px;
  height: 100%;
  padding-top: 2px;
}
body.layout-cozy .list-container.list-dayplan .track.horizontal.day-plan-track ul li:before {
  display: none;
}
body.layout-cozy .list-container.list-dayplan .track.horizontal.day-plan-track ul li div.bullets {
  line-height: 8px;
  padding: 2px 0 6px 0;
}
body.layout-cozy .list-container.list-dayplan .track.horizontal.day-plan-track ul li div.bullets span {
  display: inline-block;
  margin: 0 1px;
  width: 6px;
  height: 6px;
  border-radius: 6px;
}
body.layout-cozy .list-container.list-dayplan .track.horizontal.day-plan-track ul li.edge {
  padding: 0 8px;
}
body.layout-cozy .list-container.list-dayplan .track.horizontal.day-plan-track + nav {
  height: 32px;
  background: #fff;
  text-align: right;
}
body.layout-cozy .list-container.list-dayplan .track.horizontal.day-plan-track + nav .ei,
body.layout-cozy .list-container.list-dayplan .track.horizontal.day-plan-track + nav span {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  height: 32px;
  font-size: 24px;
  line-height: 32px;
  padding: 0 20px;
  margin-right: 0;
}
body.layout-cozy .list-container.list-dayplan .track.horizontal.day-plan-track + nav .ei:after,
body.layout-cozy .list-container.list-dayplan .track.horizontal.day-plan-track + nav span:after {
  content: "";
  display: block;
  position: absolute;
  top: -8px;
  bottom: 0;
  left: 0;
  right: 0;
}
body.layout-cozy .list-container.list-dayplan .track.horizontal.day-plan-track.week ul li {
  width: 64px;
}
body.layout-cozy .list-container.list-dayplan .track.horizontal.day-plan-track.week ul li .weekday span,
body.layout-cozy .list-container.list-dayplan .track.horizontal.day-plan-track.week ul li .date span {
  display: inline-block;
  width: 29px;
}
body.layout-cozy .list-container.list-dayplan .track.horizontal.day-plan-track.week ul li .weekday span:first-child,
body.layout-cozy .list-container.list-dayplan .track.horizontal.day-plan-track.week ul li .date span:first-child {
  padding-right: 3px;
  text-align: right;
}
body.layout-cozy .list-container.list-dayplan .track.horizontal.day-plan-track.week ul li .weekday span:last-child,
body.layout-cozy .list-container.list-dayplan .track.horizontal.day-plan-track.week ul li .date span:last-child {
  padding-left: 3px;
  text-align: left;
}
body.layout-cozy .list-container.list-dayplan .track.horizontal.day-plan-track.week ul li .weekday span:only-child,
body.layout-cozy .list-container.list-dayplan .track.horizontal.day-plan-track.week ul li .date span:only-child {
  width: 100%;
  text-align: center;
}
body.layout-cozy .list-container.list-dayplan .time-slot.begin-end-my-day {
  padding: 0 8px 8px;
  overflow: visible;
}
body.layout-cozy .list-container.list-dayplan .time-slot > ul {
  padding: 0;
}
body.layout-cozy .list-container.list-dayplan .time-slot.productivity {
  padding: 5px;
  margin: 8px 0 0;
  border-radius: 8px;
}
body.layout-cozy .list-container.list-dayplan .time-slot.productivity.sleep.no-items.can-drop-hint {
  padding: 5px;
}
body.layout-cozy .list-container.list-dayplan .time-slot.productivity.can-drop-hint {
  padding: 4px;
}
body.layout-cozy .list-container.list-dayplan .time-slot.productivity .zone-header {
  padding: 5px 0;
}
body.layout-cozy .list-container.list-dayplan .time-slot.productivity .zone-header .drop-zone > div {
  height: 100%;
  padding: 5px;
}
body.layout-cozy .list-container.list-dayplan .time-slot.productivity > ul li:not(.zone-header) {
  display: none;
}
body.layout-cozy .list-container.list-dayplan .time-slot.productivity > ul.expanded li:not(.zone-header) {
  display: block;
}
body.layout-cozy .list-container.list-dayplan .time-slot.productivity .badge {
  display: inline-block;
  border-radius: 12px;
  font-size: 10px;
  min-width: 14px;
  height: 14px;
  line-height: 14px;
  text-align: center;
  margin: 0 0 0 2px;
  padding: 0 4px;
}
body.layout-cozy .list-container.list-dayplan .drop-clock {
  position: absolute;
  right: 15px;
  top: 50%;
  height: 100px;
  padding: 30px 10px 30px 30px;
  margin-top: -50px;
  border-radius: 100px 0 0 100px;
  z-index: 3;
}
body.mobile body.layout-cozy .list-container.list-dayplan .drop-clock {
  right: 10px;
}
body.layout-cozy .list-container.list-dayplan .drop-clock .ei {
  font-size: 38px;
}
body.layout-cozy .list-container.list-dayplan .drop-clock div {
  width: 0;
  height: 38px;
}
body.layout-cozy .list-container.list-dayplan .drop-clock div span {
  width: 100px;
  height: 38px;
}
body.layout-cozy .list-container.list-dayplan .drop-clock.visible.hovering div {
  width: 100px;
}
body.layout-cozy .list-container.list-dayplan > .list-wrapper > ul.day-plan-fade-exit {
  opacity: 1;
  transition: opacity 300ms;
}
body.layout-cozy .list-container.list-dayplan > .list-wrapper > ul.day-plan-fade-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}
body.layout-cozy .list-container.list-dayplan > .list-wrapper > ul + ul {
  position: absolute;
  top: 44px;
  right: 15px;
  left: 0;
  width: auto;
  height: calc(100% - 44px);
  overflow: hidden;
  padding-right: 8px;
  z-index: 2;
  background: #dbf3e6;
}
body.layout-cozy .list-container .action-container {
  padding: 1px 5px;
  margin-bottom: 8px;
  border-radius: 6px;
}
body.layout-cozy .list-container .action-container > h4 {
  margin: 0;
  padding: 7px 6px;
}
body.layout-cozy .list-container .action-container > h4 .ei {
  font-size: 16px;
  padding: 0 8px;
}
body.layout-cozy .list-container .action-container ul {
  height: auto;
  padding-bottom: 8px;
  margin: 0 0 8px 0;
}
body.layout-cozy .list-container .action-container.habits {
  margin-top: 8px;
}
body.layout-cozy .list-container .action-container.habits ul {
  padding: 0 8px 8px;
}
body.layout-cozy .list-container .action-container.habits.expanded ul {
  margin-bottom: 0;
}
body.layout-cozy .list-container .action-container.goal-details {
  margin-top: 8px;
}
body.layout-cozy .list-container .action-container.goal-details > h4 span .ei.no-float {
  padding: 0;
}
body.layout-cozy .list-container .action-container.goal-details ul.details {
  padding: 0 8px;
  margin-bottom: 0;
}
body.layout-cozy .list-container .action-container.goal-details ul.details li {
  margin: 4px 0;
}
body.layout-cozy .list-container .action-container .header-drop-zone {
  height: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
}
body.layout-cozy .list-container .action-container .header-drop-zone.expanded {
  height: 36px;
}
body.layout-cozy .list-container .action-container .header-drop-zone .drop-zone {
  padding-top: 0;
}
body.layout-cozy .list-container .action-container .header-drop-zone .drop-zone > div {
  height: 36px;
  padding: 4px;
  border-radius: 4px 4px 6px 6px;
}
body.layout-cozy .list-container .action-container .header-drop-zone .drop-zone > div .ei {
  font-size: 24px;
  margin-right: 10px;
}
body.layout-cozy .list-container ul li.footer {
  align-self: flex-start;
  flex-shrink: 1;
  max-width: 50%;
  height: 22px;
  padding: 2px 8px 0;
  background: #fff;
  border-radius: 0 8px;
  position: relative;
  bottom: -2px;
  left: -8px;
  display: flex;
}
body.layout-cozy .list-container ul li.footer .ei {
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 16px;
  line-height: 28px;
  vertical-align: middle;
}
body.layout-cozy .list-container ul li.footer span {
  color: #000018;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
}
body.layout-cozy .list-container.embedded .list-wrapper ul {
  overflow: visible;
}
body.layout-cozy .list-wrapper {
  z-index: 1;
  width: calc(100% + 15px);
  height: 100%;
  margin: 0 -15px 0 0;
  padding: 0 0 35px 0;
}
.tree-view body.layout-cozy .list-wrapper {
  width: 100%;
  margin: 0;
}
body.layout-cozy .list-wrapper > .shadow {
  right: 15px;
}
body.layout-cozy .list-wrapper > .shadow.bottom.new-layout {
  right: 18px;
}
body.mobile body.layout-cozy .list-wrapper {
  width: calc(100% + 10px);
  margin-right: -10px;
}
body.mobile body.layout-cozy .list-wrapper > .shadow {
  right: 10px;
}
body.mobile body.layout-cozy .list-wrapper > .shadow.bottom.new-layout {
  right: 13px;
}
body.layout-cozy .list-container.embedded > h3 .edit-filters,
body.layout-cozy .list-container.embedded > h3 .collapse-arrow {
  padding-top: 8px;
}
body.layout-cozy .list-container.embedded > h3 .collapse-arrow {
  width: 22px;
}
body.layout-cozy .list-container.embedded > h3 .collapse-arrow.ei-angle-right {
  padding-left: 7px;
}
body.layout-cozy .list-container.embedded > h3 .embedded-list-arrow {
  width: 20px;
  margin-left: -8px;
  margin-right: -6px;
}
body.layout-cozy .list-container.embedded > h3 .embedded-list-icon {
  width: 20px;
}
body.layout-cozy .list-container.embedded > h3 .embedded-list-icon.left {
  margin-left: -8px;
}
body.layout-cozy .list-container.embedded .list-wrapper {
  padding-bottom: 8px;
  height: calc(100% - 36px);
}
body.desktop body.layout-cozy .list-container.embedded .list-wrapper {
  width: 100%;
}
body.layout-cozy .list-container.embedded .list-wrapper .shadow.bottom {
  bottom: 0;
}
body.layout-cozy .dnd-container .vertical-container ul li.embedded-list-item.static-list {
  padding-bottom: 8px;
}
body.layout-cozy .dnd-container .vertical-container ul li.embedded-list-item.static-list .list-container.embedded {
  margin: 8px 0 0;
  width: 100%;
}
body.layout-cozy .dnd-container .vertical-container ul li.embedded-list-item.static-list .list-container.embedded .list-wrapper {
  border-radius: 0 0 8px 8px;
  padding: 0 5px 5px;
  width: 100%;
}
body.layout-cozy .actions-container {
  position: absolute;
  z-index: 5;
  bottom: -30px;
  right: 10px;
  width: auto;
  height: 56px;
  padding: 0;
}
body.layout-cozy .actions-container > .ei {
  display: inline-block;
  vertical-align: middle;
  width: 42px;
  height: 42px;
  margin-right: 14px;
  border-radius: 28px;
  font-size: 24px;
  line-height: 42px;
}
body.layout-cozy .actions-container > .ei.close {
  display: none;
}
body.layout-cozy .actions-container > .ei.add-item {
  margin-right: 0;
  width: 56px;
  height: 56px;
  line-height: 56px;
}
body.layout-cozy .actions-container .backdrop {
  position: absolute;
  z-index: 1;
}
body.layout-cozy .list-container.embedded .actions-container {
  bottom: -26px;
  height: 42px;
}
body.layout-cozy .list-container.embedded .actions-container > .ei {
  width: 36px;
  height: 36px;
  line-height: 32px;
  margin-right: 0;
}
body.layout-cozy .list-container.embedded.collapsed {
  margin-bottom: -8px !important;
  height: 38px;
}
body.layout-cozy .list-container.embedded.collapsed .actions-container {
  position: static;
  width: auto;
  height: auto;
  padding: 0 7px 0 0;
}
body.layout-cozy .list-container.embedded.collapsed .actions-container > .ei {
  position: relative;
  border: 0 none;
  overflow: visible;
}
body.layout-cozy .list-container.embedded.collapsed .actions-container > .ei:after {
  content: "";
  display: block;
  position: absolute;
  top: -8px;
  left: -6px;
  right: -8px;
  bottom: -6px;
  z-index: 2;
  pointer-events: all;
}
body.layout-cozy .list-container.deleted {
  width: 0;
  margin: 0;
}
@media (orientation: landscape), (min-width: 500px) {
  body.layout-cozy .list-container.deleted {
    max-width: 400px;
  }
}
body.layout-cozy .list-container.deleted.just-deleted {
  width: 0;
}
body.layout-cozy .root {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
body.layout-cozy .list-context-menu button {
  height: 48px;
  line-height: 30px;
  width: 100%;
  margin-right: 0;
  margin-top: 6px;
}
body.layout-cozy .list-context-menu button .ei {
  font-size: 24px;
  margin-right: 10px;
  top: -2px;
}
body.layout-cozy .list-context-menu button.add-custom-item-action {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@media (min-width: 421px) {
  body.layout-cozy .list-context-menu .left,
  body.layout-cozy .list-context-menu .right {
    width: 50%;
  }
  body.layout-cozy .list-context-menu .left {
    padding-right: 3px;
  }
  body.layout-cozy .list-context-menu .right {
    padding-left: 3px;
  }
  body.layout-cozy .list-context-menu.single-column .left,
  body.layout-cozy .list-context-menu.single-column .right {
    width: 100%;
    padding: 0;
  }
}
body.layout-cozy .landing-container {
  width: 100%;
  height: 100%;
}
body.layout-cozy .landing-container h1 {
  margin: 0;
  padding: 0;
}
body.layout-cozy .landing-container .btn {
  width: 40%;
  min-width: 240px;
  height: 80px;
  line-height: 20px;
  border-radius: 40px;
  margin-top: 20px;
  padding: 11px 0;
}
body.layout-cozy .landing-container .btn .ei {
  font-size: 32px;
  margin-bottom: 5px;
}
body.layout-cozy section.main.public-route {
  overflow-x: hidden;
}
@-webkit-keyframes autofill {
  to {
    color: #000018;
    background: transparent;
  }
}
body.layout-cozy *:-webkit-autofill {
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}
body.layout-cozy .field {
  margin: 0 0 4px 0;
}
body.layout-cozy .field label:not(.MuiFormControlLabel-root) {
  margin: 0;
  padding: 0;
}
body.layout-cozy .field label:not(.MuiFormControlLabel-root) .required {
  line-height: 12px;
}
body.layout-cozy .field label:not(.MuiFormControlLabel-root).inline {
  display: inline-block;
}
body.layout-cozy .field.floating-label label:not(.MuiFormControlLabel-root) {
  z-index: 2;
  line-height: 12px;
}
body.layout-cozy .field .error:not(.field) {
  height: 14px;
  font-size: 14px;
  line-height: 14px;
  margin: 2px 0 0 0;
}
body.layout-cozy .field .error:not(.field) .ei {
  margin-right: 3px;
}
body.layout-cozy .field.disabled input,
body.layout-cozy .field.disabled .value,
body.layout-cozy .field.disabled textarea {
  padding: 0 !important;
}
body.layout-cozy .field.display.summary {
  padding-top: 4px;
}
body.layout-cozy .field ul {
  padding: 0;
  list-style: none;
  list-style-type: none;
}
body.layout-cozy .field.inline {
  display: inline-block;
  margin-right: 8px;
}
body.layout-cozy .space-above {
  position: relative;
  top: 8px;
}
body.layout-cozy form.settings-desktops-form {
  height: 100%;
  padding: 0;
}
body.layout-cozy .captcha {
  margin-top: 8px;
  max-width: 100%;
  overflow: hidden;
  display: inline-flex;
  width: 80%;
  max-width: 304px;
}
body.layout-cozy span.tag {
  padding: 0 8px;
  border-radius: 12px;
  height: 24px;
  line-height: 24px;
  margin-right: 5px;
}
.field-display body.layout-cozy span.tag {
  line-height: 18px;
  height: 18px;
}
.field-display body.layout-cozy span.tag.compact {
  height: 3px;
  line-height: 3px;
  width: 32px;
}
body.layout-cozy .field-lang > div.field {
  max-width: 100%;
}
body.layout-cozy .field-lang > .field.visibility {
  width: 42px;
  margin-right: -8px;
  padding: 20px 0 0;
}
body.layout-cozy .field-lang > .field.visibility i.ei {
  margin-right: 0;
}
body.layout-cozy .field-lang.show-in-slideshow {
  float: right;
  margin-top: -8px;
  margin-right: -50px;
  margin-bottom: -8px;
}
body.layout-cozy .field-lang.show-in-slideshow.no-right {
  margin-right: 6px;
}
body.layout-cozy .field-lang .label + button {
  margin: 17px 0 0 5px;
}
body.layout-cozy .get-link {
  width: 100%;
  height: 42px;
  margin: 0;
  padding: 0 5px 0 10px;
  border-radius: 8px;
  line-height: 42px;
}
body.layout-cozy .field.text .auto-width {
  top: -10000px;
  left: -10000px;
  padding: 0 5px 0 10px;
  margin: 0;
  min-width: 60px;
  max-width: 180px;
}
@media (min-width: 400px) {
  body.layout-cozy .field.text .auto-width {
    max-width: 260px;
  }
}
@media (min-width: 480px) {
  body.layout-cozy .field.text .auto-width {
    max-width: 340px;
  }
}
@media (min-width: 560px) {
  body.layout-cozy .field.text .auto-width {
    max-width: 400px;
  }
}
body.layout-cozy .field.text.forgot-password input {
  padding-right: 70px;
}
body.layout-cozy .field.text.forgot-password .forgot-btn {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  height: 35px;
  padding: 0 10px;
  line-height: 34px;
}
body.layout-cozy .field.text input, body.layout-cozy .field.text.mobile .value {
  width: 100%;
  height: 36px;
  margin: 0;
  padding: 0 5px 0 10px;
  border-radius: 0;
}
body.layout-cozy .field.text .field-wrapper {
  width: 100%;
}
body.layout-cozy .field.text .field-wrapper > a {
  width: 42px;
  line-height: 36px;
}
body.layout-cozy .field.text .description {
  padding-top: 8px;
}
body.layout-cozy .field.text.inline {
  margin-bottom: 0;
}
body.layout-cozy .field.text.inline input, body.layout-cozy .field.text.inline.mobile .value {
  width: auto;
}
body.layout-cozy .field.text.mobile .value {
  line-height: 22px;
  padding: 10px;
  min-height: 36px;
}
body.layout-cozy .field.text.mobile.multiline .value {
  height: auto;
}
body.layout-cozy .field.text.mobile.multiline .value span {
  margin-left: 5px;
}
body.layout-cozy .field.text.clear input,
body.layout-cozy .field.text.clear .value {
  padding-right: 36px;
}
body.layout-cozy .field.text.clear .input-wrapper > .ei {
  font-size: 24px;
  position: absolute;
  top: 0;
  right: 0;
  line-height: 36px;
  width: 36px;
  margin-right: 0;
}
body.layout-cozy .field.text.clear .input-wrapper.has-label > .ei {
  top: 17px;
}
body.layout-cozy .field.text.text-select .value {
  padding: 0;
}
body.layout-cozy .field.text.text-select .value input {
  line-height: 40px;
}
body.layout-cozy .field.text.text-select .value.has-options input {
  padding-right: 42px;
  height: 40px;
}
body.layout-cozy .field.text.text-select .value.has-options > .ei {
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
  height: 40px;
  padding: 8px 0;
  z-index: 2;
  font-size: 24px;
}
body.layout-cozy .field.text.text-select .value.has-options ul {
  margin: -16px 0 0 0;
  padding: 16px 0 0 0;
  height: 0;
  position: absolute;
  top: 36px;
  left: 0;
  right: 0;
  max-height: 130px;
  border-radius: 0 0 8px 8px;
}
body.layout-cozy .field.text.text-select .value.has-options ul li {
  padding: 5px 10px;
}
body.layout-cozy .field.text.text-select.text-select-expanded .value.has-options ul {
  height: auto;
  margin: -8px 0 0 0;
}
body.layout-cozy .field.text button.map-button {
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0 0 0 5px;
  padding: 7px;
  vertical-align: middle;
}
body.layout-cozy .field.text button.map-button .ei {
  font-size: 24px;
}
body.layout-cozy .field.text > .field.multiple-lines,
body.layout-cozy .field.textarea > .field.multiple-lines {
  float: right;
  padding: 10px 0 0 5px;
  margin: -10px 0 0;
}
body.layout-cozy .field.textarea textarea {
  width: 100%;
  line-height: 22px;
  margin: 0;
  padding: 0 5px 0 10px;
  border-radius: 0;
}
body.layout-cozy .field.textarea.floating-label {
  padding-top: 8px;
}
body.layout-cozy .row-count-helper {
  position: absolute;
  top: -100%;
  height: auto;
  z-index: -1;
  width: 100%;
  line-height: 22px;
  margin: 0;
  padding: 0;
}
body.layout-cozy .field.checkbox {
  padding: 10px 0;
}
body.layout-cozy .field.checkbox .ei {
  font-size: 16px;
  line-height: 18px;
  margin-right: 6px;
}
body.layout-cozy .field.checkbox label.editable {
  display: inline-block;
  font-size: 14px;
}
body.layout-cozy .field.checkbox label.editable input {
  font-size: 14px;
}
body.layout-cozy .field.color-picker .value {
  display: block;
  width: 100%;
  height: 42px;
  margin: 0;
  padding: 3px;
  border-radius: 8px;
  line-height: 42px;
}
body.layout-cozy .field.color-picker .value div {
  border-radius: 6px;
  width: 100%;
  height: 100%;
}
body.layout-cozy .field.color-picker .value div .ei {
  font-size: 16px;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  padding: 4px 0;
  margin-top: -10px;
}
body.layout-cozy .field.color-picker.inline {
  width: auto;
  min-width: 48px;
  margin-bottom: 0;
  margin-right: 5px;
}
body.layout-cozy .field.color-picker.inline .value {
  width: 100%;
}
body.layout-cozy .field.color-picker.inline:last-child {
  margin-right: 0;
}
body.layout-cozy .color-picker-popup {
  position: absolute;
  z-index: 12;
  left: 0;
  right: 0;
  bottom: 0;
}
body.layout-cozy .color-picker-popup .picker-container > .undo {
  margin-top: -50px;
  padding-left: 0;
  padding-right: 0;
  width: 40px;
}
body.layout-cozy .color-picker-popup .hue-picker {
  height: 40px;
}
body.layout-cozy .color-picker-popup .hue-picker div {
  float: left;
  width: 7.1428%;
  height: 100%;
  padding-top: 24px;
}
body.layout-cozy .color-picker-popup .hue-picker div.black, body.layout-cozy .color-picker-popup .hue-picker div.white {
  padding-top: 0;
}
body.layout-cozy .color-picker-popup .hue-picker div.black .ei, body.layout-cozy .color-picker-popup .hue-picker div.white .ei {
  font-size: 24px;
  line-height: 40px;
}
body.layout-cozy .color-picker-popup .hue-slider {
  width: 100%;
  height: 40px;
}
body.layout-cozy .color-picker-popup .hue-slider .cursor {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 4px;
  margin-left: -2px;
}
body.layout-cozy .color-picker-popup .hue-slider .cursor.active {
  top: -4px;
  bottom: -4px;
  width: 8px;
  margin-left: -4px;
}
body.layout-cozy .color-picker-popup .colors > div {
  height: 40px;
  width: 12.5%;
}
@media (min-width: 480px) {
  body.layout-cozy .color-picker-popup .colors > div {
    width: 8.333%;
  }
}
@media (min-width: 640px) {
  body.layout-cozy .color-picker-popup .colors > div {
    width: 6.25%;
  }
}
body.layout-cozy .color-picker-popup .colors > div .ei {
  font-size: 24px;
  line-height: 40px;
}
body.layout-cozy .popups-container .popup-container.color-picker-popup-container .popup-content {
  height: 434px;
}
@media (min-width: 480px) {
  body.layout-cozy .popups-container .popup-container.color-picker-popup-container .popup-content {
    height: 354px;
  }
}
@media (min-width: 640px) {
  body.layout-cozy .popups-container .popup-container.color-picker-popup-container .popup-content {
    height: 314px;
  }
}
body.layout-cozy .color-picker-in-popup {
  position: absolute;
  z-index: 12;
  left: 0;
  right: 0;
  bottom: 0;
}
body.layout-cozy .color-picker-in-popup .preview {
  height: 72px;
  padding: 10px 6px;
}
body.layout-cozy .color-picker-in-popup .preview label {
  display: block;
  height: 18px;
}
body.layout-cozy .color-picker-in-popup .preview > div {
  height: 52px;
  width: 100%;
  border-radius: 8px;
}
body.layout-cozy .color-picker-in-popup .hue-picker {
  height: 40px;
}
body.layout-cozy .color-picker-in-popup .hue-picker div {
  float: left;
  width: 7.1428%;
  height: 100%;
  padding-top: 24px;
}
body.layout-cozy .color-picker-in-popup .hue-picker div.black, body.layout-cozy .color-picker-in-popup .hue-picker div.white {
  padding-top: 0;
}
body.layout-cozy .color-picker-in-popup .hue-picker div.black .ei, body.layout-cozy .color-picker-in-popup .hue-picker div.white .ei {
  font-size: 24px;
  line-height: 40px;
}
body.layout-cozy .color-picker-in-popup .hue-slider {
  width: 100%;
  height: 40px;
}
body.layout-cozy .color-picker-in-popup .hue-slider .cursor {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 4px;
  margin-left: -2px;
}
body.layout-cozy .color-picker-in-popup .hue-slider .cursor.active {
  top: -4px;
  bottom: -4px;
  width: 8px;
  margin-left: -4px;
}
body.layout-cozy .color-picker-in-popup .colors > div {
  height: 40px;
  width: 12.5%;
}
@media (min-width: 480px) {
  body.layout-cozy .color-picker-in-popup .colors > div {
    width: 8.333%;
  }
}
@media (min-width: 640px) {
  body.layout-cozy .color-picker-in-popup .colors > div {
    width: 6.25%;
  }
}
body.layout-cozy .color-picker-in-popup .colors > div .ei {
  font-size: 24px;
  line-height: 40px;
}
body.layout-cozy .field.radio .option .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}
body.layout-cozy .field.radio .option > .ei.radio {
  font-size: 24px;
  padding-right: 8px;
}
body.layout-cozy .field.radio .option .field.text input[type=number] {
  width: 70px;
}
body.layout-cozy .field.select {
  width: 100%;
}
body.layout-cozy .field.select .value {
  width: 100%;
  height: 36px;
  margin: 0;
  padding: 0 32px 0 10px;
  line-height: 36px;
}
body.layout-cozy .field.select .value > .ei.right {
  font-size: 24px;
  position: absolute;
  right: 14px;
  top: 6px;
}
body.layout-cozy .field.select .value > span {
  max-width: 100%;
}
@media (max-width: 600px) {
  body.layout-cozy .field.select .value {
    padding: 0 26px 0 10px;
  }
  body.layout-cozy .field.select .value > .ei.right {
    right: 8px;
  }
}
body.layout-cozy .field.select.inline {
  width: auto;
  margin-bottom: 0;
}
body.layout-cozy .field.select.inline .value {
  width: auto;
}
body.layout-cozy .field.select.disabled .value {
  height: 24px;
  line-height: 24px;
  border-radius: 0;
}
body.layout-cozy .field.select.tags.floating-label {
  padding-top: 2px;
}
body.layout-cozy .field.datetime .value {
  width: 100%;
  height: 36px;
  line-height: 22px;
  margin: 0;
  padding: 10px 24px 10px 10px;
}
body.layout-cozy .field.datetime .value > .ei.arrow,
body.layout-cozy .field.datetime .value > .ei.clear {
  font-size: 24px;
  position: absolute;
  right: 12px;
  top: 6px;
}
body.layout-cozy .field.datetime .value > .ei.clear {
  padding: 6px 12px 10px;
  top: 0;
  right: 0;
}
body.layout-cozy .field.datetime.clearable.has-value .value {
  padding-right: 62px;
}
body.layout-cozy .field.datetime.clearable.has-value .value > .ei.arrow {
  right: 48px;
}
body.layout-cozy .field.datetime.disabled .value {
  height: 24px;
}
body.layout-cozy .field.datetime.disabled.floating-label .value {
  height: 36px;
}
.end-my-day-picker body.layout-cozy .field.datetime .value {
  padding: 0;
}
body.layout-cozy .datetime-popup {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
}
body.layout-cozy .datetime-popup .datetime-popup-content {
  height: 100%;
  max-height: 460px;
  width: 90%;
  border-radius: 8px;
  border: 0 none;
  padding: 44px 0 0 0;
}
body.layout-cozy .datetime-popup .datetime-popup-content:before {
  content: "";
  display: block;
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
body.layout-cozy .datetime-popup .datetime-popup-content > section {
  position: absolute;
  top: 44px;
  left: 0;
  right: 0;
  bottom: 0;
}
body.layout-cozy .datetime-popup.only-time .datetime-popup-content header.open {
  border-bottom: 0 none;
}
body.layout-cozy .datetime-popup.only-time .datetime-popup-content > section {
  position: static;
  overflow: hidden;
}
@media (min-width: 640px) {
  body.layout-cozy .datetime-popup.open .datetime-popup-content {
    width: 500px;
  }
}
body.layout-cozy .datetime-popup.open.only-time .datetime-popup-content {
  height: auto;
  width: 320px;
}
body.layout-cozy .datetime-popup section > .display {
  padding: 10px 0;
}
body.layout-cozy .datetime-popup section > label {
  padding-left: 8px;
}
body.layout-cozy .track {
  width: 100%;
}
body.layout-cozy .track:before, body.layout-cozy .track:after {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 2;
  width: 20px;
  margin: 0;
}
body.layout-cozy .track:before {
  left: 0;
}
body.layout-cozy .track:after {
  right: 0;
}
body.layout-cozy .track ul {
  line-height: 36px;
}
body.layout-cozy .track ul,
body.layout-cozy .track ul li {
  margin: 0;
  padding: 0;
}
body.layout-cozy .track ul li {
  padding: 0 5px;
}
body.layout-cozy .track ul li:before {
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 0;
  height: 0;
  margin-left: -8px;
  z-index: 2;
}
body.layout-cozy .track.horizontal ul {
  width: 100%;
  height: 36px;
}
body.layout-cozy .track.vertical ul {
  width: 36px;
  height: 100%;
}
body.layout-cozy .day-picker {
  width: 100%;
  height: 221px;
}
body.layout-cozy .day-picker.header {
  width: 100%;
  height: 22px;
  margin: 0;
  padding: 0;
}
body.layout-cozy .day-picker.header div {
  width: 14.285%;
  max-width: 14.285%;
  padding: 2px 5px;
}
body.layout-cozy .day-picker:before {
  position: absolute;
  z-index: 2;
  top: 0;
  bottom: 0;
  width: 5px;
}
body.layout-cozy .day-picker.left:before {
  left: 0;
}
body.layout-cozy .day-picker.right:before {
  right: 0;
  width: 7px;
}
body.layout-cozy .day-picker section.calendar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
body.layout-cozy .day-picker section.calendar.prev {
  left: -100%;
}
body.layout-cozy .day-picker section.calendar.next {
  left: 100%;
}
body.layout-cozy .day-picker section.calendar .week .day {
  width: 14.285%;
  max-width: 14.285%;
  height: 36px;
  line-height: 36px;
}
body.layout-cozy .day-picker section.calendar .week .day.today:before {
  content: "";
  position: absolute;
  right: 1px;
  bottom: 1px;
  width: 0;
  height: 0;
}
body.layout-cozy .slider.horizontal {
  padding: 0 0 10px;
  display: flex;
}
body.layout-cozy .slider.horizontal .ei {
  display: block;
  z-index: 2;
  width: 32px;
  font-size: 24px;
  line-height: 40px;
  margin: 0;
  flex-shrink: 0;
}
body.layout-cozy .slider.horizontal .track-line {
  margin: 0 60px;
  height: 42px;
  flex-grow: 1;
}
body.layout-cozy .slider.horizontal .track-line:before {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 1px;
  z-index: 1;
}
body.layout-cozy .slider.horizontal .track-line .min,
body.layout-cozy .slider.horizontal .track-line .max {
  height: 25px;
  min-width: 25px;
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 0;
  margin: 0;
  padding: 0 7px;
  line-height: 21px;
  border-radius: 10px;
}
body.layout-cozy .slider.horizontal .track-line .max {
  left: auto;
  right: 0;
}
body.layout-cozy .slider.horizontal .track-line .value {
  position: absolute;
  z-index: 3;
  top: 50%;
  border-radius: 13px;
  height: 26px;
  min-width: 26px;
  line-height: 24px;
}
body.layout-cozy .day-picker-inline-container {
  margin: 4px 0 0;
  padding: 4px 0;
}
body.layout-cozy .field.notifications-field .notification-line {
  padding: 4px 55px 4px 0;
}
body.layout-cozy .field.notifications-field .notification-line > button {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
body.layout-cozy .field.notifications-field .notification-line .field.inline {
  padding: 4px 0;
}
body.layout-cozy .field.notifications-field .notification-line .field.inline .value, body.layout-cozy .field.notifications-field .notification-line .field.inline input {
  margin: 0 4px;
}
body.layout-cozy .field.notifications-field .notification-line .field.inline .value {
  padding: 0 18px 0 5px;
  line-height: 40px;
}
body.layout-cozy .field.notifications-field .notification-line .field.inline.unit .value {
  margin-left: -4px;
}
body.layout-cozy .field.notifications-field .notification-line .field.inline input {
  padding: 0 10px 3px;
  min-width: 40px;
}
body.layout-cozy .field.notifications-field .notification-line .field.inline.select .value > .ei.right {
  right: 5px;
}
body.layout-cozy .field.notifications-field .notification-line .field.inline.select .value > span {
  line-height: 36px;
}
body.layout-cozy .field.notifications-field .notification-line .field.inline.before input {
  width: 70px;
  padding-bottom: 0;
}
body.layout-cozy .field.notifications-field .notification-line span.label {
  line-height: 36px;
}
body.layout-cozy .field.notifications-field > button {
  margin-top: 10px;
}
body.layout-cozy .field.notifications-field > button .ei {
  margin-right: 5px;
}
body.layout-cozy .field.dynamic-fields-list ul,
body.layout-cozy .field.dynamic-fields-list li {
  margin: 0;
  padding: 8px 0 0;
}
body.layout-cozy .field.dynamic-fields-list button.add {
  margin-top: 10px;
}
body.layout-cozy .field.dynamic-fields-list li {
  display: flex;
  align-items: stretch;
  justify-content: stretch;
}
body.layout-cozy .field.dynamic-fields-list li .field-actions {
  display: flex;
  padding-top: 15px;
  flex-direction: row;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
}
body.layout-cozy .field.dynamic-fields-list li .field-actions .ei {
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  margin: 0;
  padding: 0;
  font-size: 16px;
}
body.layout-cozy .field.dynamic-fields-list li .field-action-button {
  margin: 0;
  padding: 0 0 10px 10px;
}
body.layout-cozy .field.dynamic-fields-list li .field-action-button button.add {
  margin: 0;
}
@media (max-width: 479px) {
  body.layout-cozy .field.dynamic-fields-list li.field-type-image .field-actions {
    flex-direction: column;
  }
}
body.layout-cozy .field.dynamic-fields-list li > .field {
  flex-grow: 1;
  min-width: 0;
}
body.layout-cozy .field.dynamic-fields-list li .field:last-of-type {
  margin-bottom: 0;
}
body.layout-cozy .dynamic-fields-summary {
  padding-top: 5px;
  margin-left: -8px;
}
body.layout-cozy .dynamic-fields-summary .timestamp:only-child {
  display: inline-block;
  padding-top: 5px;
  font-size: 14px;
}
body.layout-cozy .dynamic-fields-summary .summary-placeholder {
  padding-left: 8px;
}
body.layout-cozy .dynamic-card-title {
  margin: 0 auto;
  color: #12378F;
}
body.layout-cozy .dynamic-card-title .ei {
  display: inline-block;
  font-size: 16px;
  vertical-align: middle;
  padding: 0 5px;
  color: #bbb;
  position: relative;
}
body.layout-cozy .dynamic-card-title .ei:after {
  content: "";
  display: block;
  position: absolute;
  top: -12px;
  bottom: -6px;
  left: -8px;
  right: 0;
}
body.layout-cozy .dynamic-card-title .ei + .ei:after {
  left: 0;
  right: -8px;
}
body.layout-cozy .dynamic-card-title .ei:empty {
  width: 24px;
}
body.layout-cozy .dynamic-card-title span {
  position: relative;
}
body.layout-cozy .dynamic-card-title span:after {
  content: "";
  display: block;
  position: absolute;
  top: -12px;
  bottom: -6px;
  left: 0;
  right: -20px;
}
body.layout-cozy .dynamic-card-title span .ei:after {
  display: none;
}
body.layout-cozy .field.array-list-field ul,
body.layout-cozy .field.array-list-field li {
  margin: 0;
  padding: 0;
}
body.layout-cozy .field.array-list-field ul li {
  padding-bottom: 0;
  display: flex;
  align-items: center;
}
body.layout-cozy .field.array-list-field ul li.array-list-item {
  display: inline-block;
  vertical-align: middle;
}
body.layout-cozy .field.array-list-field ul li .field-wrapper {
  flex-grow: 1;
}
body.layout-cozy .field.array-list-field ul li .field-actions {
  flex-grow: 0;
  flex-shrink: 0;
}
body.layout-cozy .field.array-list-field ul li .field-actions .ei {
  flex-grow: 0;
  flex-shrink: 0;
  display: block;
  width: 32px;
  height: 32px;
  margin: 0;
  padding: 0;
  font-size: 16px;
  text-align: center;
  line-height: 32px;
}
body.layout-cozy .field.array-list-field.zebra ul li {
  padding: 0 8px 5px;
}
body.layout-cozy .field.file-upload .container {
  min-height: 100px;
  padding-top: 4px;
}
body.layout-cozy .field.file-upload .container > * {
  margin-right: 8px;
}
body.layout-cozy .field.file-upload .container > *:last-child {
  margin-right: 0;
}
body.layout-cozy .field.file-upload .container > *.no-margin {
  margin-right: 0;
}
body.layout-cozy .field.file-upload .container .picture-operations {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
}
body.layout-cozy .field.file-upload .container .picture-operations > div {
  flex-grow: 1;
  text-align: center;
  cursor: pointer;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  padding: 3px 6px;
  margin-bottom: 2px;
  border-radius: 6px;
  font-size: 11px;
  max-height: 50%;
}
body.layout-cozy .field.file-upload .container .picture-operations > div:last-child {
  margin-bottom: 0;
}
body.layout-cozy .field.file-upload .buttons {
  border-radius: 8px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  margin-right: 7px !important;
}
body.layout-cozy .field.file-upload .buttons button {
  margin: 0;
  padding: 0 20px;
  height: auto;
  border-radius: 0;
  box-shadow: none;
}
@media (max-width: 479px) {
  body.layout-cozy .field.file-upload .buttons button {
    padding: 0 10px;
  }
}
@media (max-width: 429px) {
  body.layout-cozy .field.file-upload .buttons {
    flex-direction: column;
  }
  body.layout-cozy .field.file-upload .buttons button {
    padding: 0 4px;
    flex-direction: row;
    justify-content: flex-start;
    flex-grow: 1;
    border-left: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
  body.layout-cozy .field.file-upload .buttons button:first-child {
    border-top: 0;
  }
  body.layout-cozy .field.file-upload .buttons button svg {
    margin-right: 4px;
  }
}
@media (max-width: 379px) {
  body.layout-cozy .field.file-upload .buttons {
    margin-right: 0;
  }
  body.layout-cozy .field.file-upload .buttons button {
    font-size: 12px;
  }
  body.layout-cozy .field.file-upload .buttons button svg {
    margin-right: 2px;
  }
}
body.layout-cozy .field.file-upload .file-input-container input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}
body.layout-cozy .field.file-upload .file-input-container .ei {
  font-size: 32px;
  margin: 0 8px;
}
body.layout-cozy .field.file-upload .file-input-container .ei.small {
  font-size: 18px;
}
body.layout-cozy .field.file-upload .ei.clear {
  height: 32px;
  font-size: 24px;
  padding: 0 10px;
}
body.layout-cozy .image-preview,
body.layout-cozy .image-thumbnail {
  margin: 0;
  padding: 0;
  border-radius: 8px;
  overflow: hidden;
}
body.layout-cozy .image-preview .ei.overlay,
body.layout-cozy .image-thumbnail .ei.overlay {
  display: block;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  overflow: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -16px 0 0 -16px;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  font-size: 24px;
  text-align: center;
  line-height: 32px;
}
body.layout-cozy .image-preview .ei.overlay.ei-playlist_play:before,
body.layout-cozy .image-thumbnail .ei.overlay.ei-playlist_play:before {
  position: relative;
  top: 1px;
  left: 2px;
}
body.layout-cozy .image-preview.video,
body.layout-cozy .image-thumbnail.video {
  width: 113px;
  height: 64px;
  background: #000;
}
body.layout-cozy .image-preview.video img,
body.layout-cozy .image-thumbnail.video img {
  width: 113px;
}
body.layout-cozy .reset-image {
  display: flex;
  align-items: center;
}
body.layout-cozy .reset-image .ei {
  display: flex;
  align-items: center;
}
body.layout-cozy .history-list-container {
  height: 100%;
  width: 100%;
}
.card-summary body.layout-cozy .history-list-container {
  padding: 6px 8px 6px 0;
}
body.layout-cozy .history-list-container h3 {
  margin: 0;
  padding: 0 0 5px 0;
}
body.layout-cozy .history-list-container h3 .show-history {
  margin: -9px 0;
  padding: 10px 0 10px 5px;
}
body.layout-cozy .history-list-container ul.comments-list, body.layout-cozy .history-list-container ul.comments-list li {
  padding: 0;
  margin: 0;
}
body.layout-cozy .history-list-container ul.comments-list li {
  padding: 3px 0;
  border-radius: 4px;
}
body.layout-cozy .history-list-container ul.comments-list li .speech-bubble {
  margin-top: 7px;
  border-radius: 0 8px 8px;
  padding: 8px;
  min-height: 42px;
}
body.layout-cozy .history-list-container ul.comments-list li .speech-bubble:before {
  position: absolute;
  top: -8px;
  left: 0;
  width: 0;
  height: 0;
}
body.layout-cozy .history-list-container ul.comments-list li .speech-bubble .comment {
  padding: 8px 8px 10px;
}
body.layout-cozy .history-list-container ul.comments-list li .speech-bubble .button {
  margin: 0;
  padding: 0;
  width: 42px;
  height: 42px;
  margin: -8px -8px 0 0;
}
body.layout-cozy .history-list-container ul.comments-list li .speech-bubble .button i {
  font-size: 20px;
  margin-top: -2px;
}
body.layout-cozy .history-list-container .field.textarea {
  padding-top: 10px;
}
body.layout-cozy .recurrence-edit > span {
  padding-right: 5px;
}
body.layout-cozy .recurrence-edit .weekdays {
  padding-bottom: 0;
}
body.layout-cozy .recurrence-edit .weekdays span {
  height: 36px;
  line-height: 36px;
  padding: 0 9px;
  margin-left: -1px;
}
body.layout-cozy .recurrence-edit .weekdays span:first-child {
  margin-left: 0;
  border-radius: 8px 0 0 8px;
}
body.layout-cozy .recurrence-edit .weekdays span:last-child {
  border-radius: 0 8px 8px 0;
}
body.layout-cozy .form-tabs-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}
body.layout-cozy .form-tabs-container > nav {
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  z-index: 4;
  width: 51px;
}
body.layout-cozy .form-tabs-container > nav button {
  padding: 5px 0;
  line-height: auto;
  font-size: 30px;
  width: 48px;
  height: auto;
  border-radius: 0 12px 12px 0;
  margin: 3px 0;
}
body.layout-cozy .form-tabs-container > nav button:first-child {
  margin-top: 0;
}
body.layout-cozy .form-tabs-container > nav button .ei {
  font-size: 30px;
  margin: 20px 0;
}
body.layout-cozy .form-tabs-container > nav button.active {
  width: 50px;
  margin-left: -2px;
}
body.layout-cozy .form-tabs-container .form-tab {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 10px;
}
body.layout-cozy .form-tabs-container .form-tab.active {
  z-index: 3;
}
body.layout-cozy .form-tabs-container.has-tabs .form-tab {
  right: 49px;
}
body.layout-cozy .field-display.text {
  padding: 4px 0;
}
.MuiGrid-root body.layout-cozy .field-display.text {
  padding: 0;
  float: right;
}
.MuiGrid-root body.layout-cozy .field-display.text .value-wrapper {
  padding: 0;
}
body.layout-cozy .field-display.text > .ei {
  font-size: 16px;
  width: 18px;
  margin-right: 6px;
}
body.layout-cozy .field-display.text.summary-placeholder {
  padding: 6px 0;
}
body.layout-cozy .field-display.text .value-wrapper {
  padding-right: 8px;
}
body.layout-cozy .field-display.text.touched.error .ei.ei-error {
  margin-right: 4px;
}
body.layout-cozy .field-display.text a {
  display: inline-block;
  overflow-wrap: anywhere;
  text-decoration: none;
}
body.layout-cozy .field-display.text > div {
  padding-right: 8px;
  overflow-wrap: anywhere;
}
body.layout-cozy .field-display.text > div div.link {
  display: inline-block;
}
body.layout-cozy .field-display.text.icon-on-right > div {
  padding-right: 4px;
}
body.layout-cozy .field-display.text.icon-on-right > div div.link {
  display: flex;
  align-items: flex-start;
}
body.layout-cozy .field-display.text.icon-on-right > div div.link span {
  order: 1;
  flex-grow: 1;
  overflow-wrap: anywhere;
}
body.layout-cozy .field-display.text.icon-on-right > div div.link .float-right {
  order: 2;
  flex-shrink: 0;
  margin: 2px 0 0 8px;
  position: relative;
}
body.layout-cozy .field-display.text.icon-on-right > div div.link .float-right:before {
  content: "";
  display: block;
  position: absolute;
  top: -8px;
  left: -8px;
  bottom: -4px;
  right: -4px;
}
body.layout-cozy .field-display.text.location .value-wrapper {
  padding-right: 4px;
}
body.layout-cozy .field-display.status {
  border-radius: 5px;
  margin: 4px 8px 0 4px;
  padding: 2px 6px;
}
body.layout-cozy .field-display.tags {
  padding: 4px 0px 4px 0px;
}
body.layout-cozy .field-display.tags label {
  margin: 0 0 4px 8px;
}
body.layout-cozy .field-display.tags p {
  padding: 4px 8px 0 6px;
}
body.layout-cozy .field-display.tags p.tags-list.compact {
  line-height: 3px;
  margin: 0;
}
body.layout-cozy .field-display.layout-vertical {
  display: block;
  padding-left: 16px !important;
  line-height: 20px;
}
body.layout-cozy .field-display.layout-vertical label {
  font-size: 11px;
  margin-left: -8px !important;
  text-transform: capitalize;
  flex-shrink: 0;
}
body.layout-cozy .field-display.flex {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
}
body.layout-cozy .field-display.flex label {
  flex-grow: 0;
}
body.layout-cozy .field-display.flex label,
body.layout-cozy .field-display.flex .value-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
body.layout-cozy .field-display.has-actions .value-wrapper button {
  display: inline-block;
  margin: 0 0 0 8px;
  vertical-align: middle;
}
body.layout-cozy .field-display.image {
  display: block;
  padding: 4px 0 6px;
}
body.layout-cozy .show-icon {
  font-size: 20px;
  padding: 4px 4px 5px 5px;
  width: 29px;
  height: 29px;
  margin: 0 6px;
}
body.layout-cozy .show-icon.active {
  border-radius: 20px;
}
body.layout-cozy .display-field-padding {
  width: 100%;
  padding: 0 6px;
}
body.layout-cozy table.summary th {
  padding-right: 5px;
  min-width: 110px;
}
body.layout-cozy table.summary td button.det-sms {
  padding-left: 0px;
  padding-right: 0px;
}
body.layout-cozy table.summary.auto-title-width th {
  min-width: auto;
}
body.layout-cozy table.summary.title-width-80 th {
  min-width: 80px;
}
body.layout-cozy ul.summary-list {
  margin: 0;
  padding: 0;
}
body.layout-cozy ul.summary-list,
body.layout-cozy ul.summary-list li {
  list-style: none;
  list-style-type: none;
}
body.layout-cozy ul.summary-list.text li:before {
  content: "- ";
}
body.layout-cozy .progress-bar {
  position: absolute;
  z-index: 10;
  margin-right: 0 !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
body.layout-cozy .progress-bar .loading-bar,
body.layout-cozy .progress-bar .solid-bar {
  height: 8px;
  width: 50%;
  border-radius: 4px;
  margin-bottom: 8px;
}
body.layout-cozy .progress-bar .loading-bar div,
body.layout-cozy .progress-bar .solid-bar div {
  width: 0;
  height: 100%;
  border-radius: 4px;
}
body.layout-cozy .progress-bar.tall .loading-bar,
body.layout-cozy .progress-bar.tall .solid-bar {
  height: 16px;
}
body.layout-cozy .progress-bar.tall .loading-bar,
body.layout-cozy .progress-bar.tall .loading-bar div,
body.layout-cozy .progress-bar.tall .solid-bar,
body.layout-cozy .progress-bar.tall .solid-bar div {
  border-radius: 8px;
}
body.layout-cozy .progress-bar.overlaid .loading-bar,
body.layout-cozy .progress-bar.overlaid .solid-bar {
  position: relative;
}
body.layout-cozy .progress-bar.overlaid .loading-bar div,
body.layout-cozy .progress-bar.overlaid .solid-bar div {
  position: absolute;
  width: auto;
  height: auto;
  top: -3px;
  bottom: -3px;
  left: -3px;
  margin-right: -3px;
  border-radius: 12px;
}
body.layout-cozy .progress-bar.inline {
  position: static;
  padding: 12px 0 16px;
}
body.layout-cozy .progress-bar.inline .loading-bar,
body.layout-cozy .progress-bar.inline .solid-bar {
  width: 90%;
}
body.layout-cozy .progress-bar.full-width {
  padding: 8px 0;
}
body.layout-cozy .progress-bar.full-width .loading-bar,
body.layout-cozy .progress-bar.full-width .solid-bar {
  width: 100%;
}
body.layout-cozy .progress-bar button .ei {
  margin-right: 5px;
}
body.layout-cozy .progress-loading-bar {
  height: 8px;
  width: 50%;
  border-radius: 4px;
}
body.layout-cozy .progress-loading-bar div {
  width: 0;
  height: 100%;
  border-radius: 4px;
}
body.layout-cozy .dnd-container .vertical-container {
  width: 90%;
}
body.layout-cozy .list-wrapper {
  padding-bottom: 47px;
}
body.layout-cozy .list-wrapper .shadow.bottom {
  bottom: 47px;
}
body.layout-cozy .list-container > h3 {
  height: 48px;
  line-height: 42px;
}
body.layout-cozy .list-container > h3 .edit-list {
  padding: 12px 6px 0 14px;
}
body.layout-cozy .list-container > h3 .edit-list:after {
  top: -12px;
  left: -18px;
}
body.layout-cozy .list-container > h3 .edit-filters {
  padding: 3px 10px;
}
body.layout-cozy .list-container > h3 .edit-filters:after {
  top: -20px;
}
body.layout-cozy .list-container > h3 span.line.title .edit-filters {
  height: 24px;
  align-self: center;
  padding-top: 2px;
}
body.layout-cozy .list-container > h3 .ei.clone {
  padding: 10px 14px 0 0;
}
body.layout-cozy .slider.horizontal .track-line .value {
  height: 34px;
  min-width: 34px;
  border-radius: 17px;
}
body.layout-cozy button {
  height: 46px;
  padding: 13px 15px;
}
body.layout-cozy button.icon-only {
  width: 46px;
  border-radius: 23px;
  margin-right: 0;
}
body.layout-cozy .field {
  margin-bottom: 10px;
}
body.layout-cozy .field.text input,
body.layout-cozy .field.text.mobile .value,
body.layout-cozy .field.select .value,
body.layout-cozy .field.datetime .value {
  height: 44px;
}
body.layout-cozy .field.select .value,
body.layout-cozy .field.datetime.inline .value {
  line-height: 46px;
}
body.layout-cozy .field.select .value > .ei.right {
  top: 10px;
}
body.layout-cozy .field.datetime .value > .ei.right {
  top: 10px;
}
body.layout-cozy .field.radio .option {
  padding: 5px 0;
}
body.layout-cozy .field.checkbox {
  padding: 15px 0;
}
body.layout-cozy .field.textarea textarea {
  line-height: 24px;
}
body.layout-cozy .field.text.text-select .value.has-options ul li {
  padding: 12px 10px;
}
body.layout-cozy .field.color-picker.inline {
  min-width: 54px;
}
body.layout-cozy .desktop-picker > div {
  padding-top: 13px;
  padding-bottom: 13px;
  border-radius: 26px 0 0 26px;
}
body.layout-cozy .settings-container.start-my-day h4 {
  padding: 13px 10px 15px;
}
body.layout-cozy .list-container.list-dayplan .day-plan-date {
  padding: 6px 13px;
}
body.layout-cozy .list-container.embedded > h3 .collapse-arrow {
  padding-top: 11px;
}